import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { Mutation } from '@apollo/react-components';
import Hashids from 'hashids';
import 'element-scroll-polyfill';

import { sanitizeInput } from '../../../../abstracts/helpers';

import { Flex, Col, P, H3, H5, Button } from '../../../Universal/style';
import { Plus, TextArea, Select, Label } from '../style';
import { Form } from './style';
import { ErrorWrapper, Li } from '../../../Error/style';
import Loading from '../../../Loading';
import { SEND_PENDING_SCORE } from '../../../Universal/queries';
import { ScrollIndicator } from '../style';

const hashids = new Hashids('fleximazing', 10);

// Filtering the current week fixture data.
// Querying the current week opponent.
const INITIAL_STATE = {
  opponentScore: '-',
  yourScore: '-',
  opponentScore2: '-',
  yourScore2: '-',
  moreScore: [],
  feedback: '',
  checkedWinner: null,
  adjustment: '',

  blankError: false,
  noWinner: false,
  adjustmentError: false,

  // Handling scroll for score upload
  hasScroll: false,
  topPosition: 0,
  leftPosition: 0,
  indicatorHeight: 0,
  arrowBorder: 0,

  isPending: true,
};

// Upload Score Form
// Only need the opponent data and the session data as props. These are used to display relevant user info.
class UploadScoreForm extends Component {
  constructor() {
    super();
    this.state = INITIAL_STATE;
  }

  // Handling scroll for score upload - START
  scrollElement = element => {
    if (element) {
      this.element = element;
      const clientWidth = element.clientWidth;
      const scrollWidth = element.scrollWidth;
      const topPosition = element.clientHeight / 2;

      if (scrollWidth > clientWidth) {
        this.setState({
          hasScroll: true,
          topPosition,
          indicatorHeight: element.clientHeight / 3,
          arrowBorder: element.clientHeight / 27,
        });
      }
    }
  };

  handleOnScroll = event => {
    const { clientWidth, scrollLeft, scrollWidth } = event.target;

    this.setState({ leftPosition: scrollLeft });
    if (scrollLeft === scrollWidth - clientWidth) {
      this.setState({ hasScroll: false });
    } else {
      this.setState({ hasScroll: true });
    }
  };

  handleRightShift = () => {
    const scrollWidth = this.element.scrollWidth;
    this.element.scrollTo(scrollWidth, 0);
    this.setState({ hasScroll: false });
  };
  // Handling scroll for score upload - END

  // Updating input changes with the states
  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onMoreScoreChange = (event, index) => {
    const { name, value } = event.target;
    this.setState(prev => {
      const moreScore = prev.moreScore.map((theScore, scoreIndex) => {
        if (scoreIndex === index) {
          return {
            ...theScore,
            [name]: value,
          };
        } else {
          return theScore;
        }
      });

      return { moreScore };
    });
  };

  // Adding another score field using the setState Previous state argument.
  // Basically setting a new array with previous values + new values and returning it.
  addAnotherScore = () => {
    if (this.state.moreScore.length < 3) {
      this.setState(prev => {
        const moreScore = [
          ...prev.moreScore,
          {
            opponentScore: '-',
            yourScore: '-',
          },
        ];

        return { moreScore };
      });
    }
  };

  // Removing last moreScore index
  removeScoreInstance = () => {
    if (this.state.moreScore.length > 0) {
      this.setState(prev => {
        const withoutLast = prev.moreScore.filter((_, i) => i !== prev.moreScore.length - 1);
        return { moreScore: withoutLast };
      });
    }
  };

  onSubmit = async (event, scoreApproval, noWinnerDetermined, winnerWasChecked) => {
    event.preventDefault();
    const { isPending } = this.state;

    if (isPending) {
      this.setState({ isPending: false });

      // Reseting states of errors
      this.setState({ blankError: false });
      this.setState({ noWinner: false });
      this.setState({ adjustmentError: false });

      let hasErrors = false;

      // Determining if there are blank values
      const { opponentScore, yourScore, opponentScore2, yourScore2, moreScore, adjustment } = this.state;
      const filteredMoreScore = moreScore.some(score => {
        return score.opponentScore === '-' || score.yourScore === '-';
      });

      // If winner was checked then it by-passes the empty selection of the scores.
      if (winnerWasChecked) {
        hasErrors = false;
        // If in pop-up form -> need to input adjustment text.
        if (adjustment === '' && this.props.type === 'update') {
          this.setState({ adjustmentError: true, isPending: true });
          hasErrors = true;
        }
      } else {
        if (
          opponentScore === '-' ||
          yourScore === '-' ||
          opponentScore2 === '-' ||
          yourScore2 === '-' ||
          filteredMoreScore
        ) {
          this.setState({ blankError: true, isPending: true });
          hasErrors = true;
        }

        // Update error -> no winners
        if (noWinnerDetermined) {
          this.setState({ noWinner: true, isPending: true });
          hasErrors = true;
        }

        // If in pop-up form -> need to input adjustment text.
        if (adjustment === '' && this.props.type === 'update') {
          this.setState({ adjustmentError: true, isPending: true });
          hasErrors = true;
        }
      }

      if (!hasErrors) {
        await scoreApproval().then(async ({ data }) => {
          await this.props.refetch();
        });
        // Only execute the restartDeny function - setting state of "deny" to false so that the modal doesn't show when the approval pending screen displays.
        // In file pendingApproval.js
        if (this.props.type === 'update') {
          await this.props.restartDeny();
        }
      }
    }
  };

  render() {
    const {
      opponentScore,
      yourScore,
      opponentScore2,
      yourScore2,
      moreScore,
      checkedWinner,
      blankError,
      noWinner,
      adjustmentError,
      hasScroll,
      topPosition,
      leftPosition,
      indicatorHeight,
      arrowBorder,
    } = this.state;
    let { feedback, adjustment } = this.state;
    const halfHeight = indicatorHeight / 2;

    // Sanitize inputs
    feedback = sanitizeInput({ string: feedback });
    adjustment = sanitizeInput({ string: adjustment });

    // Error handling
    const hasErrors = false || noWinner || blankError || adjustmentError;
    const filteredMoreScore = moreScore.some(score => {
      return score.opponentScore === '-' || score.yourScore === '-';
    });
    let isInvalid =
      opponentScore === '-' || yourScore === '-' || opponentScore2 === '-' || yourScore2 === '-' || filteredMoreScore;

    // Updating final score as the State gets updated so that we can send it to the server on submit.
    // If we update this in the "onSubmit" function then we won't be able to retrieve the actual final score because of how setState works.
    const finalScore = [
      [opponentScore, yourScore],
      [opponentScore2, yourScore2],
      ...moreScore.map(values => {
        return Object.values(values);
      }),
    ];

    // Determine the winner
    let noWinnerDetermined = false;
    let winnerTally = {};
    winnerTally['opponent'] = 0;
    winnerTally['user'] = 0;
    finalScore.forEach(score => {
      if (score[0] !== score[1]) {
        if (score[0] > score[1]) {
          winnerTally['opponent'] += 1;
        } else {
          winnerTally['user'] += 1;
        }
      }
    });

    // If scores are identical -> no winners will throw an error
    if (winnerTally['opponent'] === winnerTally['user']) {
      noWinnerDetermined = true;
    }

    const { fixtureId, opponentId, opponentFirstname, opponentLastname, session, type, genderType } = this.props;
    const userIdEncoded = hashids.encode(session.me.id);
    const opponentIdEncoded = hashids.encode(opponentId);
    // If there is a value in "checkedWinner" than store the decoded instance
    let winnerWasChecked = false;
    if (checkedWinner !== null) {
      winnerWasChecked = true;
      isInvalid = false;
      var theWinner = hashids.decode(checkedWinner)[0];
    }

    return (
      <Mutation
        mutation={SEND_PENDING_SCORE}
        variables={{
          type,
          fixtureId,
          opponentId,
          score: finalScore,
          checkedWinner: theWinner,
          feedback,
          adjustment,
        }}
      >
        {(scoreApproval, { loading }) => (
          <Form onSubmit={event => this.onSubmit(event, scoreApproval, noWinnerDetermined, winnerWasChecked)}>
            <div className="grid">
              <P fontsize={'18px'} MB={'10px'} width={'100%'} color={'#569600'}>
                {type === 'update' ? 'Please Submit New Scores For Approval' : 'Input Scores Below'}
              </P>
              <div id="input-scores-section" ref={this.scrollElement} onScroll={this.handleOnScroll}>
                <div className="inner">
                  <Col flex={'1 auto'} padding={'0'} wrap="true" style={{ marginRight: '30px', width: '255px' }}>
                    <Fragment>
                      <H3 width={'300px'} MB={'0'}>
                        Opponent<span>:</span>{' '}
                        <div className="gray">
                          {opponentFirstname} {opponentLastname}
                        </div>
                      </H3>
                      <H3 width={'300px'}>
                        You<span>:</span>{' '}
                        <div className="gray">
                          {session.me.firstname} {session.me.lastname}
                        </div>
                      </H3>
                    </Fragment>
                  </Col>
                  <Col padding={'0'} wrap="true">
                    <Flex alignCenter noWrap>
                      <Flex column MR={'7px'} noWrap>
                        <Select MB={'7px'} name="opponentScore" value={opponentScore} onChange={this.onChange}>
                          <option disabled value={'-'}>
                            -
                          </option>
                          {[...Array(8).keys()].map((digit, digitIndex) => (
                            <option key={digitIndex} value={digit}>
                              {digit}
                            </option>
                          ))}
                        </Select>
                        <Select name="yourScore" value={yourScore} onChange={this.onChange}>
                          <option disabled value={'-'}>
                            -
                          </option>
                          {[...Array(8).keys()].map((digit, digitIndex) => (
                            <option key={digitIndex} value={digit}>
                              {digit}
                            </option>
                          ))}
                        </Select>
                      </Flex>
                      <Flex column MR={'7px'}>
                        <Select MB={'7px'} name="opponentScore2" value={opponentScore2} onChange={this.onChange}>
                          <option disabled value={'-'}>
                            -
                          </option>
                          {[...Array(8).keys()].map((digit, digitIndex) => (
                            <option key={digitIndex} value={digit}>
                              {digit}
                            </option>
                          ))}
                        </Select>
                        <Select name="yourScore2" value={yourScore2} onChange={this.onChange}>
                          <option disabled value={'-'}>
                            -
                          </option>
                          {[...Array(8).keys()].map((digit, digitIndex) => (
                            <option key={digitIndex} value={digit}>
                              {digit}
                            </option>
                          ))}
                        </Select>
                      </Flex>
                      {moreScore &&
                        moreScore.length > 0 &&
                        moreScore.map((theScore, scoreIndex) => {
                          // If the moreScore state array has contents inside, then loop the below contents.
                          // The moreScore state gets populated when the user clicks on the plus sign to add additional sets.
                          return (
                            <Fragment key={scoreIndex}>
                              <Flex column MR={'7px'}>
                                <Select
                                  MB={'7px'}
                                  name="opponentScore"
                                  value={theScore.opponentScore}
                                  onChange={e => this.onMoreScoreChange(e, scoreIndex)}
                                >
                                  <option disabled value={'-'}>
                                    -
                                  </option>
                                  {scoreIndex === 2
                                    ? [...Array(21).keys()].map((digit, digitIndex) => (
                                        <option key={digitIndex} value={digit}>
                                          {digit}
                                        </option>
                                      ))
                                    : [...Array(8).keys()].map((digit, digitIndex) => (
                                        <option key={digitIndex} value={digit}>
                                          {digit}
                                        </option>
                                      ))}
                                </Select>
                                <Select
                                  name="yourScore"
                                  value={theScore.yourScore}
                                  onChange={e => this.onMoreScoreChange(e, scoreIndex)}
                                >
                                  <option disabled value={'-'}>
                                    -
                                  </option>
                                  {scoreIndex === 2
                                    ? [...Array(21).keys()].map((digit, digitIndex) => (
                                        <option key={digitIndex} value={digit}>
                                          {digit}
                                        </option>
                                      ))
                                    : [...Array(8).keys()].map((digit, digitIndex) => (
                                        <option key={digitIndex} value={digit}>
                                          {digit}
                                        </option>
                                      ))}
                                </Select>
                              </Flex>
                            </Fragment>
                          );
                        })}
                      {/* Adding and removing array items */}
                      <div>
                        {moreScore && moreScore.length < 3 && (
                          <Plus onClick={() => this.addAnotherScore()}>
                            <FontAwesomeIcon icon={faPlusCircle} />
                          </Plus>
                        )}
                        {moreScore && moreScore.length > 0 && (
                          <Plus remove onClick={() => this.removeScoreInstance()}>
                            <FontAwesomeIcon icon={faMinusCircle} />
                          </Plus>
                        )}
                      </div>
                    </Flex>
                  </Col>
                </div>
                {hasScroll && (
                  <ScrollIndicator
                    top={topPosition - halfHeight}
                    right={leftPosition}
                    height={indicatorHeight}
                    arrowBorder={arrowBorder}
                    onClick={() => this.handleRightShift()}
                  >
                    <div className="scroll-indicator"></div>
                  </ScrollIndicator>
                )}
              </div>
            </div>
            <Flex>
              {type === 'update' ? (
                <Fragment>
                  <H3 width={'100%'} MB={'0'}>
                    Provide comment for opponent about the adjustment
                    <span className="red">*</span>
                  </H3>
                  <TextArea
                    name="adjustment"
                    value={adjustment}
                    onChange={this.onChange}
                    rows="5"
                    placeholder="Eg. I recall the scores were as adjusted. We've concluded that it is correct."
                  ></TextArea>
                </Fragment>
              ) : (
                <Fragment>
                  <H3 width={'100%'} MB={'0'}>
                    Provide feedback on game <span>(</span>optional
                    <span>)</span>
                  </H3>
                  <TextArea
                    name="feedback"
                    value={feedback}
                    onChange={this.onChange}
                    rows="5"
                    placeholder="Eg. The match went really well. John was very friendly and had an excellent forehand. Looking forward for our next match."
                  ></TextArea>
                </Fragment>
              )}
            </Flex>
            <Flex>
              <H5 width={'100%'} MB={'5px'}>
                Unforeseen Circumstances <span>(</span>optional
                <span>)</span>
              </H5>
              <P MT={'5px'} width={'100%'}>
                If the game was forfeited by either you or the opponent, please check the winner below:
              </P>
              <Flex column>
                <Flex noWrap alignCenter>
                  <Label>
                    <input
                      style={{ marginRight: '7px' }}
                      type="radio"
                      name="checkedWinner"
                      onChange={this.onChange}
                      value={opponentIdEncoded}
                    />
                    <P margin={'5px 0'}>
                      {opponentFirstname} {opponentLastname}
                    </P>
                  </Label>
                </Flex>
                <Flex noWrap alignCenter MB={'20px'}>
                  <Label>
                    <input
                      style={{ marginRight: '7px' }}
                      type="radio"
                      name="checkedWinner"
                      onChange={this.onChange}
                      value={userIdEncoded}
                    />
                    <P margin={'5px 0'}>
                      {session.me.firstname} {session.me.lastname}
                    </P>
                  </Label>
                </Flex>
              </Flex>
            </Flex>

            <Button normal genderType={genderType} type="submit" disabled={isInvalid}>
              {loading ? <Loading /> : 'Submit Scores'}
            </Button>

            {hasErrors && (
              <ErrorWrapper style={{ marginTop: '30px' }}>
                {noWinner && (
                  <Li>
                    There must be a winner. If there was an unforeseen circumstance, please check the game winner
                    accordingly.
                  </Li>
                )}
                {blankError && <Li>Score fields must not be blank. Please select an input.</Li>}
                {adjustmentError && (
                  <Li>Adjustment comment must be provided to the opponent. Please input reason for the adjustment.</Li>
                )}
              </ErrorWrapper>
            )}
          </Form>
        )}
      </Mutation>
    );
  }
}

export default UploadScoreForm;
