import React from 'react';
import { Mutation } from '@apollo/react-components';
import { ALL_CREATED_DATES, REMOVE_SEASON_DATES_MUTATION } from '../queries';
import { Button } from '../../../Universal/style';

const DeleteDate = ({ theId }) => (
  <Mutation
    mutation={REMOVE_SEASON_DATES_MUTATION}
    variables={{ id: theId }}
    update={cache => {
      const data = cache.readQuery({
        query: ALL_CREATED_DATES,
      });

      cache.writeQuery({
        query: ALL_CREATED_DATES,
        data: {
          ...data,
          showAllDates: [...data.showAllDates.filter(aDateObject => aDateObject.id !== theId)],
        },
      });
    }}
  >
    {removeDates => (
      <Button type="button" normal onClick={removeDates}>
        Delete
      </Button>
    )}
  </Mutation>
);

export default DeleteDate;
