import styled, { theme, css, keyframes } from '../../theme';
import homeBk from '../../resources/images/home-bk.png';

const backgroundChange = keyframes`
  0% {
    background: rgba(255, 255, 255, 1);
  }
  5% {
    background: rgba(255, 255, 255, 0.8);
  }
  95% {
    background: rgba(255, 255, 255, 0.8);
  }
  100% {
    background: rgba(255, 255, 255, 1);
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;
export const SignInFormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-image: url(${props => props.bkImage});
  background-size: cover;
  background-position: center center;
  background-color: transparent;
  min-height: 100vh;
  overflow: hidden;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 0;
    animation: ${backgroundChange} 30s linear infinite;
  }

  ${props =>
    props.slidingBack &&
    css`
      background-image: url(${homeBk});

      &:before {
        background: rgba(255, 255, 255, 0.5);
        animation: none;
      }
    `};
`;
export const SignInWidth = styled.div`
  margin: 100px 10px 40px 10px;
  padding: 10px 35px;
  border-radius: 5px;
  /* background: rgba(255, 255, 255, 0.4); */
  background: rgba(255, 255, 255, 0.6);
  border-top: 5px solid ${theme.men.link};
  width: 470px;
  max-width: 100%;
  z-index: 1;

  @media (max-width: 590px) {
    width: 400px;

    h1 {
      font-size: 23px;
      margin-right: 7px;
    }
  }
  @media (max-width: 490px) {
    width: auto;
    padding: 20px;
  }
`;
export const Input = styled.input`
  width: 100%;
  max-width: 100%;
  margin: 5px 0;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  outline: none;
`;
