import styled, { theme } from '../../../theme';

export const LogoSection = styled.div`
  padding-top: 20px;
  text-align: center;
  padding-bottom: 10px;
  background-color: white;
  text-align: center;

  .logo-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    border: 4px solid
      ${props =>
        props.type === 'doubles'
          ? theme.doubles.link
          : props.genderType === 'women'
          ? theme.women.link
          : props.genderType === 'men'
          ? theme.men.link
          : theme.mixed.link};
    background: radial-gradient(
      white 55%,
      ${props =>
        props.type === 'doubles'
          ? theme.doubles.link
          : props.genderType === 'women'
          ? theme.women.link
          : props.genderType === 'men'
          ? theme.men.link
          : theme.mixed.link}
    );
    width: 85px;
    height: 85px;

    img {
      width: 60px;
    }
  }
`;
export const NormalSideBar = styled.div`
  .about-section {
    margin: 15px 0;
    padding: 15px 0;
    border-top: 1px solid ${theme.breakLine};
    border-bottom: 1px solid ${theme.breakLine};
  }
  .giphy-section {
    margin-top: 35px;
  }
`;
export const ScheduleWrapper = styled.div`
  a {
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: capitalize;
    font-family: 'roboto-condensed';
    font-size: 16px;
    color: ${props =>
      props.doubles
        ? theme.doubles.link
        : props.genderType === 'women'
        ? theme.women.link
        : props.genderType === 'men'
        ? theme.men.link
        : theme.mixed.link};
  }
  a:hover,
  a:focus {
    color: ${props =>
      props.doubles
        ? theme.doubles.linkHover
        : props.genderType === 'women'
        ? theme.women.linkHover
        : props.genderType === 'men'
        ? theme.men.linkHover
        : theme.mixed.linkHover};
  }
`;
export const FeedbackSection = styled.div`
  background-color: white;
  padding: 20px 15px;
  border-radius: 5px;
  border: 2px dashed #ffa700;
  overflow-x: hidden;
  margin: 0 auto;

  h5 {
    font-size: 14px;
    margin: 0;
    color: #ffa700;
  }
  p {
    :last-child {
      margin-bottom: 0;
      font-weight: 600;
    }
    .quote {
      font-style: italic;
    }
  }
  #slide {
    display: flex;
    align-items: center;

    div {
      flex: 0 0 100%;
      margin-right: 20px;
      transition: all 1s ease-in-out;
    }
  }

  @media (max-width: 1464px) {
    display: none;
  }
`;
