import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import * as routes from '../../constants/routes';

import { Heading, TimerWrapper, TimerContent, Timer, Digit, H3, SemiCol, Span } from './style';
import { P } from '../Universal/style';

class TimerSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      closed: false,
      loading: true,
    };
  }

  componentDidMount() {
    // Checking if there is a deadline --> shows deadline equals null and an error will error if we don't check.
    // If deadline is null, then set loading to false and the logic below in the return statement will work as normal
    this.interval = setInterval(() => {
      return this.getTimeUntil(this.props.deadline);
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  zeroAdded = digit => {
    if (digit < 10) return `0${digit}`;
    else return digit;
  };

  getTimeUntil = deadline => {
    let deadlineNew = deadline.getTime();
    let now = new Date().getTime();
    let distance = deadlineNew - now;

    if (distance <= 0) {
      // If there's no more time to count then set the timer to stop and display the closed info
      this.setState({ closed: true, loading: false });
      if (this.props.location.pathname !== '/register') {
        this.props.history.push(routes.LANDING);
      }
    } else {
      this.setState({ closed: false, loading: false });
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
      let minutes = Math.floor((distance / (1000 * 60)) % 60);
      let seconds = Math.floor((distance / 1000) % 60);
      this.setState({ days, hours, minutes, seconds });
    }
  };

  render() {
    const { days, hours, minutes, seconds, closed, loading } = this.state;
    const { closedText, headingText, nextSeasonText, nextSeasonStart } = this.props;
    if (nextSeasonStart) {
      const nextDate = nextSeasonStart
        .split(' ')
        .splice(1)
        .join(' ');
      var dateAdjusted =
        nextDate.split(' ').splice(1, 1) +
        '-' +
        nextDate.split(' ').splice(0, 1) +
        '-' +
        nextDate.split(' ').splice(2, 1);
    }
    return (
      <TimerWrapper>
        <Heading className="season-heading-timer">{headingText} Season</Heading>
        <TimerContent wrap="true">
          {/** Set the initial view to loading. If the closed state is set then remove the loading view. If the closed set is not set then the clocks appears. **/}
          {loading ? (
            <H3 fontsize={'25px'}>Loading...</H3>
          ) : closed ? (
            <ClosedSeason closedText={closedText} nextSeasonText={nextSeasonText} dateAdjusted={dateAdjusted} />
          ) : (
            <Fragment>
              <H3 width={'100%'} color={'#484848'} bold fontsize={'20px'} center className="season-subheading-timer">
                Registrations Closes In
              </H3>
              <Timer>
                <div>
                  <Digit className="digit-box">
                    <H3 fontsize={'35px'}>{this.zeroAdded(days)}</H3>
                  </Digit>
                  <H3 center fontsize={'15px'}>
                    {days < 2 ? 'Day' : 'Days'}
                  </H3>
                </div>
                <SemiCol className="semi-colon">:</SemiCol>
                <div>
                  <Digit className="digit-box">
                    <H3 fontsize={'35px'}>{this.zeroAdded(hours)}</H3>
                  </Digit>
                  <H3 center fontsize={'15px'}>
                    {hours < 2 ? 'Hour' : 'Hours'}
                  </H3>
                </div>
                <SemiCol className="semi-colon">:</SemiCol>
                <div>
                  <Digit className="digit-box">
                    <H3 fontsize={'35px'}>{this.zeroAdded(minutes)}</H3>
                  </Digit>
                  <H3 center fontsize={'15px'}>
                    {minutes < 2 ? 'Minute' : 'Minutes'}
                  </H3>
                </div>
                <SemiCol className="semi-colon">:</SemiCol>
                <div>
                  <Digit className="digit-box">
                    <H3 fontsize={'35px'}>{this.zeroAdded(seconds)}</H3>
                  </Digit>
                  <H3 center fontsize={'15px'}>
                    {seconds < 2 ? 'Second' : 'Seconds'}
                  </H3>
                </div>
              </Timer>
            </Fragment>
          )}
        </TimerContent>
      </TimerWrapper>
    );
  }
}

const ClosedSeason = ({ closedText, nextSeasonText, dateAdjusted }) => {
  return (
    <Fragment>
      <P lowercase fontsize={'18px'} color={'black'} center width={'100%'} MB={'0px'}>
        Registrations has closed for the {closedText} Season
      </P>
      <div>
        <H3
          letterSpacing={'2px'}
          fontsize={'19px'}
          color={'#888888'}
          center
          width={'100%'}
          PT={'16px'}
          BT={'1px solid lightgray'}
          lineHeight={'28px'}
          MT={'18px'}
        >
          {nextSeasonText} season starts
          <Span blue block>
            {dateAdjusted}
          </Span>
        </H3>
      </div>
    </Fragment>
  );
};

export default withRouter(TimerSection);
