import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Query } from '@apollo/react-components';
import 'element-scroll-polyfill';

import { ListContainer, TheMessageWrapper } from './style';
import { Flex } from '../../../Universal/style';
import CreateDirectMessage from './createDM';
import {
  CHANNEL_SUBSCRIPTION,
  CHANNEL_QUERY,
  DIRECT_MESSAGE_SUBSCRIPTION,
  DIRECT_MESSAGE_QUERY,
  OPPONENT_QUERY,
} from '../../../Universal/queries';

class MessageQuery extends Component {
  render() {
    const { opponentIdDecoded, session, doubles, genderType } = this.props;

    return (
      // Querying the "channel id" with the opponent Id as the variable.
      <Query query={CHANNEL_QUERY} variables={{ opponentId: opponentIdDecoded[0] }}>
        {({ data, loading, refetch, subscribeToMore }) => {
          if (loading) {
            return '';
          }
          // If there's data then continue --> if not then return nothing --> if we don't do this then an error will occur on signout
          if (data && data.getChannelId) {
            const { getChannelId } = data;

            return (
              <Fragment>
                {/* Update channel Id to the Query if a new channel has been created for the current user */}
                {session && session.me && (
                  <GetChannelId
                    channelData={data}
                    subscribeToMore={subscribeToMore}
                    opponentId={opponentIdDecoded[0]}
                    userId={session.me.id}
                    refetchMessageQuery={refetch}
                  >
                    <ListContainer>
                      {/* Only display the messages if a channel Id exists */}
                      {getChannelId && getChannelId.channelId !== null && (
                        <Query
                          query={DIRECT_MESSAGE_QUERY}
                          variables={{
                            channelId: getChannelId.channelId,
                          }}
                        >
                          {({ data, loading, refetch, fetchMore, subscribeToMore }) => {
                            if (loading) {
                              return '';
                            }
                            if (data && data.directMessages) {
                              const { directMessages } = data;

                              return (
                                <ListMessages
                                  directMessages={directMessages.edges}
                                  subscribeToMore={subscribeToMore}
                                  channelId={getChannelId.channelId}
                                  session={session}
                                  listMessageRefetch={refetch}
                                  fetchMore={fetchMore}
                                  directMessageInfo={directMessages.directMessageInfo}
                                />
                              );
                            } else {
                              return '';
                            }
                          }}
                        </Query>
                      )}
                    </ListContainer>
                  </GetChannelId>
                )}
                {/* This is the form to create a new direct message */}
                <CreateDirectMessage
                  opponentIdDecoded={opponentIdDecoded[0]}
                  getChannelId={getChannelId}
                  doubles={doubles}
                  genderType={genderType}
                />
              </Fragment>
            );
          } else {
            return '';
          }
        }}
      </Query>
    );
  }
}

class GetChannelId extends Component {
  // This function will always run as long as the page is active.
  subscribeToNewChannel = () => {
    this.props.subscribeToMore({
      document: CHANNEL_SUBSCRIPTION,
      // Put variables inside the subscribeToMore function.
      variables: {
        opponentId: this.props.opponentId,
        userId: this.props.userId,
      },
      // The previousResult is the current items in the database.
      // The subscriptionData is the NEW item just added.
      updateQuery: (previousResult, { subscriptionData }) => {
        // If the subcriptionData does not exist - return just the current items in the database.
        if (!subscriptionData.data) {
          return previousResult;
        }

        // If the subcriptionData exists then grab the resolver subscribe "newChannel" data.
        const { newChannel } = subscriptionData.data;

        // create an exact object of the same shape as the initial query data and merge the previous result with the new result.
        return Object.assign({}, previousResult, {
          // getChannelId will return an object as specified in the "directMessage" schema
          getChannelId: {
            // all previous objects inside previously - compounding each items as more items are added.
            ...previousResult.getChannelId,
            ...newChannel,
          },
        });
      },
    });
  };

  componentDidMount() {
    // Refetch the query on every mounting of this component so that it fetches new channels with the opponent Id.
    this.props.refetchMessageQuery();
    this.subscribeToNewChannel();
  }

  render() {
    // If a channel Id exist --> return the children components.
    // If not, return a notification.
    const { getChannelId } = this.props.channelData;
    if (getChannelId && getChannelId.channelId !== null) {
      return this.props.children;
    } else {
      return <p style={{ margin: '0' }}>No messages to show</p>;
    }
  }
}

class ListMessages extends Component {
  // This function will always run as long as the page is active.
  subscribeToDirectMessage = () => {
    this.props.subscribeToMore({
      document: DIRECT_MESSAGE_SUBSCRIPTION,
      // Put variables inside the subscribeToMore function.
      variables: { channelId: this.props.channelId },
      // The previousResult is the current items in the database.
      // The subscriptionData is the NEW item just added.
      updateQuery: (previousResult, { subscriptionData }) => {
        // If the subcriptionData does not exist - return just the current items in the database.
        if (!subscriptionData.data) {
          return previousResult;
        }

        // If the subcriptionData exists then grab the resolver subscribe "newDirectMessage" data.
        const { newDirectMessage } = subscriptionData.data;

        // create an exact object of the same shape as the initial query data and merge the previous result with the new result.
        return Object.assign({}, previousResult, {
          // directMessages will return an array as specified in the "directMessage" schema
          directMessages: {
            ...previousResult.directMessages,
            edges: [...previousResult.directMessages.edges, newDirectMessage],
            directMessageInfo: {
              ...previousResult.directMessages.directMessageInfo,
            },
          },
        });
      },
    });
  };

  scrollToBottom = () => {
    // Scroll the message history to the bottom by default.
    const node = ReactDOM.findDOMNode(this);
    node.scrollTo(0, node.scrollHeight);
  };

  componentDidMount() {
    this.props.listMessageRefetch();
    this.subscribeToDirectMessage();

    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    // If there are NEW messages then ensure that the scroll directs to the bottom
    if (prevProps.directMessages) {
      if (prevProps.directMessages.length !== this.props.directMessages.length) {
        this.scrollToBottom();
      }
    }
  }

  handleScroll = event => {
    const { hasNextPage, endCursor } = this.props.directMessageInfo;
    // If the scrollTop position is at 0 and that the "hasNextPage" is true ---> determined at the server in the "directMessages" resolver.
    if (event.target.scrollTop === 0 && hasNextPage) {
      this.props.fetchMore({
        variables: {
          cursor: endCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult;
          }

          return Object.assign({}, previousResult, {
            // directMessages "edges" will return an array as specified in the "directMessage" schema
            directMessages: {
              ...fetchMoreResult.directMessages,
              edges: [...previousResult.directMessages.edges, ...fetchMoreResult.directMessages.edges],
            },
          });
        },
      });
    }
  };

  render() {
    const { directMessages, session } = this.props;
    const { me } = session;

    return (
      <TheMessageWrapper id="message-wrapper" onScroll={this.handleScroll}>
        {/* display messages if the messages array is greater than 0 (meaning that there are data inside the array) */}
        {/* before looping the "directMessages" array, we need to sort the array by "id" from smallest to largest */}
        {directMessages &&
          directMessages.length > 0 &&
          directMessages
            .slice()
            .sort((a, b) => {
              return a.id - b.id;
            })
            .map(message => {
              const { id, text, userId, createdAt } = message;

              return (
                <div className="message" key={id}>
                  {/* If the "userId" is the current user then display "You" otherwise query the opponent with the userId that didn't match the me.id. */}
                  {userId === me.id ? (
                    <Fragment>
                      <Flex between className="name-wrapper created-name">
                        <h4>You</h4>
                        <DateString createdAt={createdAt} />
                      </Flex>
                      <p>{text}</p>
                    </Fragment>
                  ) : (
                    <OpponentMessage id={id} text={text} opponentId={userId} createdAt={createdAt} />
                  )}
                </div>
              );
            })}
      </TheMessageWrapper>
    );
  }
}

const OpponentMessage = ({ text, opponentId, createdAt, id }) => (
  <Query query={OPPONENT_QUERY} variables={{ id: opponentId }}>
    {({ data, loading }) => (
      <Fragment>
        {loading && <p>Loading...</p>}
        {data && data.user && (
          <Fragment>
            <Flex between className="name-wrapper">
              <h4>
                {data.user.firstname} {data.user.lastname}
              </h4>
              <DateString createdAt={createdAt} />
            </Flex>
            <p>{text}</p>
          </Fragment>
        )}
      </Fragment>
    )}
  </Query>
);

const DateString = ({ createdAt }) => {
  const date = new Date(createdAt);
  return (
    <div className="date-created">
      {date.toLocaleTimeString()} | {date.toLocaleDateString()}
    </div>
  );
};

export default MessageQuery;
