import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';
import { LEAGUE_EMAIL_CHECK, SEASON_STATUS_QUERY } from '../queries';
import { GET_ALL_SUBLEAGUE, CHECK_PLAYERS_LEAGUE, CHECK_TEAMS_LEAGUE } from '../leagueAvailability/queries';
import { ALL_USERS_QUERY } from '../queries';
import { Flex, Button } from '../../Universal/style';

class LeagueStatus extends Component {
  constructor() {
    super();
    this.state = {
      currentSeason: '',
      nextSeason: '',
      fieldError: false,
    };
  }

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event, executeFunction) => {
    event.preventDefault();
    const { currentSeason, nextSeason } = this.state;

    if (currentSeason === '' || nextSeason === '') {
      this.setState({ fieldError: true });
    } else {
      await executeFunction().then(() => {
        this.setState({ fieldError: false, currentSeason: '', nextSeason: '' });
      });
    }
  };

  render() {
    let { currentSeason, nextSeason, fieldError } = this.state;
    const isInvalid = currentSeason === '' || nextSeason === '';
    const formatString = string => `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
    currentSeason = `${formatString(currentSeason)}`;
    nextSeason = `${formatString(nextSeason)}`;

    return (
      <Mutation
        mutation={LEAGUE_EMAIL_CHECK}
        variables={{ currentSeason, nextSeason }}
        refetchQueries={() => [
          { query: SEASON_STATUS_QUERY },
          { query: GET_ALL_SUBLEAGUE },
          { query: CHECK_PLAYERS_LEAGUE },
          { query: CHECK_TEAMS_LEAGUE },
          { query: ALL_USERS_QUERY },
        ]}
      >
        {checkLeagueStatusForAllPlayers => (
          <div className="league-status">
            <p>Use this to email players their league status. Whether their league is active or not.</p>

            <form onSubmit={event => this.handleSubmit(event, checkLeagueStatusForAllPlayers)}>
              <Flex className="flex-wrapper">
                <div className="input-wrapper">
                  <label>Current Season</label>
                  <input
                    type="text"
                    name="currentSeason"
                    placeholder="Input current season"
                    value={currentSeason}
                    onChange={this.onChange}
                  />
                </div>
                <div className="input-wrapper">
                  <label>Next Season</label>
                  <input
                    type="text"
                    name="nextSeason"
                    placeholder="Input next season"
                    value={nextSeason}
                    onChange={this.onChange}
                  />
                </div>
              </Flex>
              <Button normal type="submit" disabled={isInvalid}>
                Email League Status
              </Button>
              {fieldError && <div className="error-message">All fields must be filled.</div>}
            </form>
          </div>
        )}
      </Mutation>
    );
  }
}

export default LeagueStatus;
