import gql from 'graphql-tag';

// Subscription -> upload status subscription
export const UPLOAD_STATUS_SUBSCRIPTION = gql`
  subscription($fixtureId: ID!) {
    uploadScoreStatus(fixtureId: $fixtureId) {
      id
      score
      winnerId
      isApproved
      opponentId
      opponentFixtureId
      userId
      userFixtureId
      adjustment
    }
  }
`;
