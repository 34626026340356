import gql from 'graphql-tag';

// Query - individual and past winners results
export const PAST_RESULTS_QUERY = gql`
  query($genderType: String, $playType: String, $winners: Boolean, $teamOwner: Boolean, $cursor: String) {
    getPastResults(
      genderType: $genderType
      playType: $playType
      winners: $winners
      teamOwner: $teamOwner
      cursor: $cursor
    ) {
      edges {
        id
        season
        league
        division
        group
        rank
        points
        wins
        losses
        year
        playType
        genderType
        teamName
        teamOwner
        ownerId
        friendId
        userId
      }
      pastResultInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

// Query - All past results
export const ALL_PAST_RESULTS_QUERY = gql`
  query {
    getAllPastResults {
      id
      season
      league
      division
      group
      rank
      points
      wins
      losses
      year
      playType
      genderType
      teamName
      teamOwner
      ownerId
      friendId
      userId
    }
  }
`;

// Query - past winners
export const PAST_WINNERS_QUERY = gql`
  query {
    getWinners {
      id
      season
      league
      division
      group
      rank
      points
      wins
      losses
      year
      playType
      genderType
      teamName
      teamOwner
      ownerId
      friendId
      userId
    }
  }
`;
