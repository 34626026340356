import React, { Component } from 'react';
import { Query } from '@apollo/react-components';

import Loading from '../../../Loading';
import { Span } from '../../../Register/style';
import { Flex, H4, DetailsBox } from '../../../Universal/style';
import { ALL_CREATED_DATES, DATES_CREATED } from '../queries';
import DeleteDate from '../datesDelete';

/************************ List All Season Dates ***********************/
const ShowAllSeasonDates = () => (
  <Query query={ALL_CREATED_DATES}>
    {({ data, loading, error, fetchMore, subscribeToMore }) => {
      if (!data) {
        return <p>No season dates have been created yet. Please create some with the form above.</p>;
      }

      const { showAllDates } = data;

      if (loading || !showAllDates) {
        return <Loading />;
      }

      return (
        <Flex between>
          <Lists showAllDates={showAllDates} subscribeToMore={subscribeToMore} />
        </Flex>
      );
    }}
  </Query>
);

class Lists extends Component {
  subscribeToMoreDates = () => {
    this.props.subscribeToMore({
      document: DATES_CREATED,
      updateQuery: (previousResult, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousResult;
        }

        const { datesCreated } = subscriptionData.data;

        return {
          ...previousResult,
          showAllDates: [datesCreated.dates, ...previousResult.showAllDates],
        };
      },
    });
  };

  componentDidMount() {
    this.subscribeToMoreDates();
  }

  render() {
    const { showAllDates } = this.props;

    return showAllDates.map(info => <TheContent key={info.id} info={info} />);
  }
}

/************************ The information of each message ***********************/
const TheContent = ({ info }) => {
  const { id, year, previousSpring, summer, autumn, winter, spring, nextSummer } = info;
  return (
    <div>
      <h3>{year}</h3>
      <DetailsBox>
        <H4 noMB className="date-name">
          <Span blue>Previous Spring:</Span> {new Date(previousSpring).toDateString()}
        </H4>
        <H4 noMB className="date-name">
          <Span blue>Summer:</Span> {new Date(summer).toDateString()}
        </H4>
        <H4 noMB className="date-name">
          <Span blue>Autumn:</Span> {new Date(autumn).toDateString()}
        </H4>
        <H4 noMB className="date-name">
          <Span blue>Winter:</Span> {new Date(winter).toDateString()}
        </H4>
        <H4 noMB className="date-name">
          <Span blue>Spring:</Span> {new Date(spring).toDateString()}
        </H4>
        <H4 noMB className="date-name">
          <Span blue>Next Summer:</Span> {new Date(nextSummer).toDateString()}
        </H4>
      </DetailsBox>
      {/* Deleting the dates */}
      <DeleteDate theId={id} />
    </div>
  );
};

export default ShowAllSeasonDates;
