import gql from 'graphql-tag';

// Mutation to "Generate" doubles fixture
export const GENERATE_DOUBLES_FIXTURE = gql`
  mutation($season: String!, $seasonDate: String!) {
    generateDoublesFixture(season: $season, seasonDate: $seasonDate)
  }
`;
// Query all fixture information for the season
export const DOUBLES_FIXTURE_QUERY = gql`
  query($season: String!, $playType: String!, $year: String!) {
    getAllDoublesFixture(season: $season, playType: $playType, year: $year) {
      id
      week
      startDate
      score
      playType
      teamWinnerId
      opponentTeamId
      pendingScore
      leagueId
      teamId
    }
  }
`;

// Mutation to "Generate" doubles finals fixture
export const GENERATE_DOUBLES_FINALS_FIXTURE = gql`
  mutation($seasonDate: String!) {
    doublesFinalFixture(seasonDate: $seasonDate)
  }
`;
// Fetch FINAL fixture for week 9 - to display upcoming opponent for week 10 and on week 10 the final team opponent - left sidebar && Fixture table.
export const GET_FINAL_DOUBLES_FIXTURE = gql`
  query($season: String!, $playType: String!, $week: String!, $year: String!) {
    getFinalDoublesFixture(season: $season, playType: $playType, week: $week, year: $year) {
      id
      week
      startDate
      opponentTeamId
      score
    }
  }
`;
// Subscription to listen out for updated Fixture data --> when a player approves a score and it gets updated in the database.
// Need this to update the fixture table at the Fixture Parent Page
// Need this to update the week 10 fixture scores --> Singles -> fixtures -> finalFixture.js
export const UPDATED_DOUBLES_FIXTURE_SUBSCRIPTION = gql`
  subscription($teamId: ID!) {
    updatedDoublesFixture(teamId: $teamId) {
      id
      week
      startDate
      score
      playType
      teamWinnerId
      opponentTeamId
      pendingScore
      leagueId
      teamId
    }
  }
`;

// Getting the other team information
export const GET_OTHER_TEAM = gql`
  query($id: ID) {
    getOtherTeam(id: $id) {
      id
      profileImage
      name
      tennisCourt
      playTime
      partnerEmail
      ownerId
      leagueId
    }
  }
`;

// Get the players on the opponent team
export const GET_OPPONENT_TEAM_PLAYERS = gql`
  query($id: ID!) {
    getOtherTeamPlayers(id: $id) {
      id
      profileImage
      firstname
      lastname
    }
  }
`;
