import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';

import Loading from '../../../Loading';
import ErrorMessage from '../../../Error';
import { Input, Button, H4, Flex, InputWrapper } from '../../../Universal/style';

/************************ Create new dates ***********************/
const CREATE_DATES = gql`
  mutation(
    $previousSpring: String!
    $summer: String!
    $autumn: String!
    $winter: String!
    $spring: String!
    $nextSummer: String!
  ) {
    createDates(
      previousSpring: $previousSpring
      summer: $summer
      autumn: $autumn
      winter: $winter
      spring: $spring
      nextSummer: $nextSummer
    ) {
      year
      previousSpring
      summer
      autumn
      winter
      spring
      nextSummer
    }
  }
`;

class CreateDates extends Component {
  constructor() {
    super();
    this.state = {
      previousSpring: '',
      summer: '',
      autumn: '',
      winter: '',
      spring: '',
      nextSummer: '',
    };
  }

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onSubmit = async (event, createDates) => {
    event.preventDefault();

    try {
      await createDates();
      this.setState({
        previousSpring: '',
        summer: '',
        autumn: '',
        winter: '',
        spring: '',
        nextSummer: '',
      });
    } catch (error) {}
  };

  render() {
    const { previousSpring, summer, autumn, winter, spring, nextSummer } = this.state;
    return (
      <Mutation
        mutation={CREATE_DATES}
        variables={{
          previousSpring,
          summer,
          autumn,
          winter,
          spring,
          nextSummer,
        }}
      >
        {(createDates, { data, loading, error }) => {
          return (
            <form onSubmit={event => this.onSubmit(event, createDates)}>
              <InputWrapper>
                <Flex between>
                  <Flex width={'31%'}>
                    <H4 noMB className="date-name">
                      Pre-Spring Date
                    </H4>
                    <Input
                      calibri
                      uppercase
                      name="previousSpring"
                      value={previousSpring}
                      onChange={this.onChange}
                      type="datetime-local"
                      placeholder="Select date for the previous Spring season.."
                    />
                  </Flex>
                  <Flex width={'31%'}>
                    <H4 noMB className="date-name">
                      Summer Date
                    </H4>
                    <Input
                      calibri
                      uppercase
                      name="summer"
                      value={summer}
                      onChange={this.onChange}
                      type="datetime-local"
                      placeholder="Select date for the start of the Summer season.."
                    />
                  </Flex>
                  <Flex width={'31%'}>
                    <H4 noMB className="date-name">
                      Autumn Date
                    </H4>
                    <Input
                      calibri
                      uppercase
                      name="autumn"
                      value={autumn}
                      onChange={this.onChange}
                      type="datetime-local"
                      placeholder="Select date for the start of the Autumn season.."
                    />
                  </Flex>
                  <Flex width={'31%'}>
                    <H4 noMB className="date-name">
                      Winter Date
                    </H4>
                    <Input
                      calibri
                      uppercase
                      name="winter"
                      value={winter}
                      onChange={this.onChange}
                      type="datetime-local"
                      placeholder="Select date for the start of the Winter season.."
                    />
                  </Flex>
                  <Flex width={'31%'}>
                    <H4 noMB className="date-name">
                      Spring Date
                    </H4>
                    <Input
                      calibri
                      uppercase
                      name="spring"
                      value={spring}
                      onChange={this.onChange}
                      type="datetime-local"
                      placeholder="Select date for the start of the Spring season.."
                    />
                  </Flex>
                  <Flex width={'31%'}>
                    <H4 noMB className="date-name">
                      Next Summer Date
                    </H4>
                    <Input
                      calibri
                      uppercase
                      name="nextSummer"
                      value={nextSummer}
                      onChange={this.onChange}
                      type="datetime-local"
                      placeholder="Select date for the next Summer season.."
                    />
                  </Flex>
                </Flex>
                <Button normal type="submit">
                  {loading ? <Loading /> : 'Create Season Dates'}
                </Button>
              </InputWrapper>

              {error && <ErrorMessage error={error} />}
            </form>
          );
        }}
      </Mutation>
    );
  }
}

export default CreateDates;
