import React, { PureComponent } from 'react';
import { NEW_RESULT_SUBSCRIPTION } from '../../../queries';

class NewResultsListener extends PureComponent {
  subscribeToNewResult = () => {
    this.props.subscribeToMore({
      document: NEW_RESULT_SUBSCRIPTION,
      variables: {
        playType: this.props.type,
        leagueId: this.props.leagueId,
        subLeagueId: this.props.getPlayInfo.subLeagueId,
        week: this.props.currentWeek,
        genderType: this.props.getPlayInfo.genderType,
      },
      updateQuery: (previousResult, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousResult;
        }

        return Object.assign({}, previousResult, {
          getResults: [...previousResult.getResults, subscriptionData.data.newResultSubmission],
        });
      },
    });
  };
  componentDidMount() {
    this.subscribeToNewResult();
  }
  render() {
    return <div></div>;
  }
}

export default NewResultsListener;
