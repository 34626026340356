import React, { Fragment, PureComponent } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { Query } from '@apollo/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import Hashids from 'hashids';

import * as routes from '../../../../constants/routes';
import { BackLink, Flex, P, H5 } from '../../../Universal/style';
import { master } from '../../../../resources/resources';
import UploadScoreForm from './uploadScoreForm';
import DisplayPendingApproval from './pendingApproval';
import CheckFinalScore from './checkFinalScore';
import PendingScore from './pendingScore';
import SubscriptionListener from './subscriptionListener';
import Loading from '../../../Loading';
import { GET_OTHER_TEAM } from '../queries';
import { CURRENT_DOUBLES_FIXTURE_QUERY, GET_SCORE_APPROVAL_DOUBLES } from './queries';

const hashids = new Hashids('fleximazing', 10);

class FixtureUpload extends PureComponent {
  componentDidMount() {
    const { fixtureData, match, history, session, teamOwnerId } = this.props;
    const { week } = match.params;
    const fixtureId = hashids.decode(week);

    // Only owners can access the fixture upload screen
    if (session.me.id !== teamOwnerId) {
      history.push(routes.DOUBLES_FIXTURES);
    }

    // Check if there's data in the "fixtureData" array.
    if (fixtureData && fixtureData.getAllDoublesFixture && fixtureData.getAllDoublesFixture.length > 0) {
      // Check if "fixtureId" is part of the current "Fixture".
      const fixtureExist = fixtureData.getAllDoublesFixture.some(fixture => +fixture.id === +fixtureId[0]);
      // Redirect back to fixtures main if the params doesn't exist
      if (!fixtureExist) {
        history.push(routes.DOUBLES_FIXTURES);
      }
    }
  }

  render() {
    // Getting the encoded Fixture ID then decoding it.
    const { week } = this.props.match.params;
    const fixtureId = hashids.decode(week);
    const { fixtureData, refetchFixtureQuery, currentWeek, getTeam, nextSeasonDate } = this.props;

    return (
      <Fragment>
        {fixtureData && fixtureData.getAllDoublesFixture && (
          // Feeding the checked data into the UploadContent component. If we don't check it then the data would be undefined.
          <UploadContent
            fixtureId={fixtureId[0]}
            refetchFixtureQuery={refetchFixtureQuery}
            currentWeek={currentWeek}
            getTeam={getTeam}
            nextSeasonDate={nextSeasonDate}
          />
        )}
      </Fragment>
    );
  }
}

class UploadContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      height: null,
    };
  }

  refCallback = element => {
    if (element) {
      this.elementRef = element;
      this.setState({ height: element.getBoundingClientRect().height });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.elementRef) {
      if (prevState.height !== this.elementRef.getBoundingClientRect().height) {
        this.setState({ height: this.elementRef.getBoundingClientRect().height });
      }
    }
  }

  render() {
    const { height } = this.state;
    const { fixtureId, refetchFixtureQuery, currentWeek, getTeam, nextSeasonDate } = this.props;
    const { uploadPara } = master;

    return (
      // This is to Query the current "fixtureId" (current week of play) from the URL params.
      <Query query={CURRENT_DOUBLES_FIXTURE_QUERY} variables={{ fixtureId }}>
        {({ data, loading, refetch }) => {
          const currentFixtureRefetch = refetch;
          // If the upload page is loading the fixture data then show the loading
          // When the data is received then display the contents of the page
          // If there is no data, then redirect to the fixture main page
          if (loading) {
            return <h3>Loading Upload...</h3>;
          } else if (data && data.getCurrentDoublesFixture) {
            const { week, startDate, pendingScore, opponentTeamId } = data.getCurrentDoublesFixture;

            return (
              <div>
                <BackLink flex spaceBetween alignCenter doubles={true}>
                  <Link to={routes.DOUBLES_FIXTURES} className="singles-back-link">
                    <FontAwesomeIcon icon={faArrowCircleLeft} /> Back to Fixtures
                  </Link>
                  <div>
                    <Flex>
                      <H5 margin={'0'}>{`Week ${week}`}</H5>
                      <div className="weekly-date">
                        <TheDate date={startDate} />
                      </div>
                    </Flex>
                  </div>
                </BackLink>
                <h1>Upload Scores</h1>
                <div>
                  <div>
                    {uploadPara.map((paragraphs, i) => (
                      <P key={i} openSans color={'#484848'} fontsize={'15px'}>
                        {paragraphs}
                      </P>
                    ))}
                    <P color={'crimson'} openSans fontsize={'15px'}>
                      Scores are only submitted when BOTH players agree on the scores.
                    </P>
                  </div>
                  {/* Querying the current opponent for the fixtureId */}
                  <Query query={GET_OTHER_TEAM} variables={{ id: opponentTeamId }}>
                    {({ data, loading }) => {
                      if (loading) {
                        return <p>Loading...</p>;
                      }

                      // Query to fetch the Opponent information
                      if (data && data.getOtherTeam) {
                        const { name, id } = data.getOtherTeam;
                        return (
                          // Query to fetch the score approval for the logged-in user.
                          <div>
                            <Query query={GET_SCORE_APPROVAL_DOUBLES} variables={{ fixtureId }}>
                              {({ data, loading, refetch, subscribeToMore }) => {
                                const refetchGetScoreApproval = refetch;
                                const subscribeGetScoreApproval = subscribeToMore;
                                if (loading) {
                                  return <Loading />;
                                }
                                if (data && data.getScoreApprovalDoubles) {
                                  const { getScoreApprovalDoubles } = data;

                                  return (
                                    // Listen out for the pending approval request and if the logged-in user is the userId of the scoreApproval table
                                    // then display the request form. If not, then display the children components.
                                    <Fragment>
                                      {/* 1. Display final score if it exists in the Fixture table */}
                                      {/* 2. Display pending approval scores when a user submits scores to the opponent */}
                                      <CheckFinalScore
                                        getScoreApprovalDoubles={getScoreApprovalDoubles}
                                        opponentTeamName={name}
                                        refetchGetScoreApproval={refetchGetScoreApproval}
                                      >
                                        <DisplayPendingApproval
                                          getScoreApprovalDoubles={getScoreApprovalDoubles}
                                          fixtureId={fixtureId}
                                          opponentTeamName={name}
                                          opponentTeamId={id}
                                          refetch={currentFixtureRefetch}
                                          refetchFixtureQuery={refetchFixtureQuery}
                                          currentWeek={currentWeek}
                                          currentTeamId={getTeam.id}
                                          currentTeam={getTeam}
                                          height={height}
                                          refCallback={this.refCallback}
                                          nextSeasonDate={nextSeasonDate}
                                        >
                                          {pendingScore && getScoreApprovalDoubles.length > 0 ? (
                                            // When there is a value in the PendingScore - then don't show the Upload Score Form
                                            // PendingScore gets populated when the user submits a score for approval
                                            <PendingScore
                                              getScoreApprovalDoubles={getScoreApprovalDoubles}
                                              opponentTeamName={name}
                                            />
                                          ) : (
                                            <UploadScoreForm
                                              currentTeam={getTeam}
                                              opponentTeamId={id}
                                              opponentTeamName={name}
                                              fixtureId={fixtureId}
                                              type="create"
                                              refetch={currentFixtureRefetch}
                                            />
                                          )}
                                        </DisplayPendingApproval>
                                      </CheckFinalScore>

                                      <SubscriptionListener
                                        subscribeToMore={subscribeGetScoreApproval}
                                        fixtureId={fixtureId}
                                      />
                                    </Fragment>
                                  );
                                }
                                return '';
                              }}
                            </Query>
                          </div>
                        );
                      } else {
                        return '';
                      }
                    }}
                  </Query>
                </div>
              </div>
            );
          } else {
            // Redirect to the Singles Fixtures Parent page if the "fixtureId" does not exist.
            // Checking the encoded "fixtureId" from the url params.
            return <Redirect to={routes.DOUBLES_FIXTURES} />;
          }
        }}
      </Query>
    );
  }
}

// Reformatting the startDate here.
const DateFormater = date => {
  if (date < 10) {
    return `0${date}`;
  } else {
    return date;
  }
};

const TheDate = ({ date }) => {
  const theDate = new Date(date);
  return (
    <H5 margin={'0'}>
      {DateFormater(theDate.getDate())}
      <span>/</span>
      {DateFormater(theDate.getMonth() + 1)}
      <span>/</span>
      {theDate.getFullYear()}
    </H5>
  );
};

export default withRouter(FixtureUpload);
export { TheDate };
