import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';

import { sanitizeInput } from '../../abstracts/helpers';

import ErrorMessage from '../Error';
import Loading from '../Loading';

import { Button, Form } from '../Universal/style';
import { Input } from '../SignIn/style';
import { Notification } from '../SignUp/style';

const FORGOT_PASSWORD = gql`
  mutation($email: String!) {
    forgotPassword(email: $email) {
      result
    }
  }
`;

const INITIAL_STATE = {
  email: '',
  sent: false,
};

class ForgotForm extends Component {
  constructor() {
    super();
    this.state = { ...INITIAL_STATE };
  }

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onSubmit = (event, forgotPassword) => {
    event.preventDefault();
    forgotPassword().then(async ({ data }) => {
      this.setState({ ...INITIAL_STATE });
      this.setState({ sent: true });

      await this.props.refetch();
    });
  };

  render() {
    const { sent } = this.state;
    let { email } = this.state;
    const isInvalid = email === '';

    // Sanitize inputs
    email = sanitizeInput({ string: email, type: 'email' });

    return (
      <Mutation mutation={FORGOT_PASSWORD} variables={{ email }}>
        {(forgotPassword, { loading, error }) =>
          sent ? (
            <Notification>
              <li className="normal">If the account exist, a password reset link will be sent to that email.</li>
            </Notification>
          ) : (
            <Form onSubmit={event => this.onSubmit(event, forgotPassword)}>
              <Input name="email" value={email} onChange={this.onChange} type="email" placeholder="Enter your email" />
              <Button className="outside-screens" disabled={isInvalid || loading} type="submit">
                {loading ? <Loading /> : 'Send reset link'}
              </Button>

              {error && <ErrorMessage error={error} />}
            </Form>
          )
        }
      </Mutation>
    );
  }
}

export default ForgotForm;
