import {
  faPlay,
  faUserEdit,
  faCalendar,
  faEnvelope,
  faUserFriends,
  faChartBar,
  faAward,
} from '@fortawesome/free-solid-svg-icons';

import * as routes from '../constants/routes';

export const master = {
  about: {
    subheading: 'About FTL',
    information: `Based in Australia, Flexi Tennis Leagues is a unique platform that allows tennis enthusiasts to come together and play tennis at their own convenience.`,
  },
  leagueType: ['Men', 'Women', 'Mixed'],
  locations: ['Western Australia', 'South Australia', 'Victoria', 'ACT', 'New South Whales', 'Queensland', 'Tasmania'],
  regions: [
    {
      location: 'Western Australia',
      inner: ['North Perth League', 'South Perth League'],
    },
    {
      location: 'South Australia',
      inner: ['East Adelaide League', 'West Adelaide League'],
    },
    {
      location: 'Victoria',
      inner: [
        'Inner City Melbourne League',
        'North Melbourne League',
        'West Melbourne League',
        'East Melbourne League',
        'South East Melbourne League',
        'Geelong League',
      ],
    },
    {
      location: 'ACT',
      inner: ['Canberra League'],
    },
    {
      location: 'New South Whales',
      inner: [
        'Central Sydney League',
        'North Shore Sydney League',
        'Northern Beaches Sydney League',
        'Inner West Sydney League',
        'Parramatta Sydney League',
        'Eastern Suburbs Sydney League',
        'Sutherland Shire Sydney League',
        'Newcastle League',
      ],
    },
    {
      location: 'Queensland',
      inner: ['Cairns League', 'North Brisbane League', 'South Brisbane League', 'Gold Coast League'],
    },
    {
      location: 'Tasmania',
      inner: ['Hobart League'],
    },
  ],
  skills: [
    {
      name: 'Newbie',
      description:
        'You are just starting out and wanting to gain some experience. You are looking for light and fun matches to get social and develop your game.',
    },
    {
      name: 'Fair',
      description:
        'You have some experience and looking to work on your game. You are able to hold rallies and capable of serving.',
    },
    {
      name: 'Ace',
      description:
        'You are quite competitive and looking for a challenge. You have excellent skillsets acquired through years of experience. You want to meet other players with similar experience and skillsets.',
    },
  ],
  singlesNav: [
    {
      route: routes.SINGLES_DASH,
      text: 'Dashboard',
      icon: faUserEdit,
    },
    {
      route: routes.SINGLES_FIXTURES,
      text: 'View Fixtures',
      icon: faCalendar,
    },
    {
      route: routes.SINGLES_MESSAGE,
      text: 'Messages',
      icon: faEnvelope,
    },
    {
      route: routes.SINGLES_RESULTS,
      text: 'Results',
      icon: faChartBar,
    },
  ],
  doublesNav: [
    {
      route: routes.DOUBLES_TEAM,
      text: 'Team details',
      icon: faUserFriends,
    },
    {
      route: routes.DOUBLES_DASH,
      text: 'Dashboard',
      icon: faUserEdit,
    },
    {
      route: routes.DOUBLES_FIXTURES,
      text: 'View fixtures',
      icon: faCalendar,
    },
    {
      route: routes.DOUBLES_MESSAGE,
      text: 'Messages',
      icon: faEnvelope,
    },
    {
      route: routes.DOUBLES_RESULTS,
      text: 'Results',
      icon: faChartBar,
    },
  ],
  normalNav: [
    {
      route: routes.LANDING,
      text: 'Register',
      icon: faPlay,
    },
    {
      route: routes.ACCOUNT,
      text: 'Dashboard',
      icon: faUserEdit,
    },
    {
      route: routes.PAST_RESULTS,
      text: 'Results',
      icon: faChartBar,
    },
    {
      route: routes.ALL_PAST_WINNERS,
      text: 'Winners',
      icon: faAward,
    },
  ],
  uploadPara: [
    `Please use this section to submit your scores. Scores are updated in real-time, meaning it will 
    appear immediately after submission in the Results section located at the right sidebar. Please note that the Results section showcases only results in the current week of play.`,
    `If you have played an opponent in a different week, then results can be viewed by clicking the View All Results link.`,
    'Scores will also reflect your ranking and points in the Leaderboard section.',
    `If your opponent has already submitted the scores, then you will need to review it and either ACCEPT or DENY the results. If accepted, it will be submitted however if Denied, you will be able to input the scores along with comments to the opponent. The same process will occur for the opponent to either ACCEPT or DENY the results.`,
  ],
  emojiArray: [
    '🤓',
    '🍿',
    '✌️',
    '👨‍🚀',
    '🌭',
    '😃',
    '🍔',
    '👋',
    '😆',
    '🍠',
    '🧙‍♂️',
    '🙃',
    '🙏',
    '🥢',
    '🤷‍♂️',
    '🍨',
    '🥳',
    '🏂',
    '😀',
    '🍣',
    '🏋️‍♂️',
    '🍺',
    '😝',
    '🍪',
    '🥇',
    '🏀',
    '🎳',
    '🤩',
    '🙂',
    '😁',
  ],
};
