import styled, { css, theme, keyframes } from '../../theme';
import tennisBack from '../../resources/images/tennis-back.jpg';

const Slide = keyframes`
  to {
    background-position: 60px 100%;
  }
`;

const MovingBack = keyframes`
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 100% 300%;
  }
`;

// Main Wrapper for whole singles routes
export const SinglesWrapperStyling = styled.div`
  background-image: url(${tennisBack});
  background-position: center;
  background-repeat: repeat;
  background-size: 40%;
  animation: 450s ${MovingBack} infinite;

  .extra-wrapper {
    display: flex;
    flex-direction: column;
    min-height: ${props => props.height}px;
    width: 1680px;
    max-width: 100%;
    margin: 0 auto;
    background-color: white;
  }
  .main-content {
    position: relative;
  }

  .mobile-right-sidebar {
    display: none;
  }
  .mobile-logo {
    display: none;
    flex-direction: column;
    text-align: center;
    margin: 25px 7px;

    h4 {
      margin: 0;
      margin-top: 6px;
      font-size: 16px;
    }
  }

  .fixture-wrapper {
    display: grid;

    .fixture-table-wrapper {
      position: relative;
      overflow-x: scroll;
    }
  }

  .score-adjustment-section {
    margin-bottom: 0;
    padding: 15px 20px;
    border-radius: 4px;
    background-color: #fafafa;
    border: 1px dashed crimson;
  }

  .profile-ranking-view {
    align-items: center;

    #tooltip-wrapper {
      margin-left: 10px;

      .question-icon {
        margin-top: 0;
        margin-left: 0;
      }
      .tooltip {
        width: 260px;
      }
    }
  }

  @media (min-width: 1465px) and (max-width: 1579px) {
    .navigation-wrapper {
      .header-signout-button {
        margin-top: 10px;
        width: 100%;
        margin-right: 0;
      }
    }
  }

  @media (max-width: 1464px) {
    .sidebar-column-one {
      flex: 1 0 0;

      h3 {
        font-size: 16px;
      }
    }
    .sidebar-column-two {
      display: none;
    }
    .mobile-right-sidebar {
      display: block;

      .results-wrapper {
        border-bottom: none;
      }
    }
  }
  @media (max-width: 1327px) {
    .sidebar-column-one {
      flex: 1 0 7%;
    }
  }

  @media (max-width: 1230px) {
    .navigation-wrapper {
      .header-signout-button {
        margin-top: 10px;
        width: 100%;
        margin-right: 0;
      }
    }
  }
  @media (max-width: 1100px) {
    .sidebar-column-one {
      display: none;
    }
    .main-columns {
      margin-bottom: 40px;

      .mobile-logo {
        display: block;
        border-bottom: 1px solid ${theme.breakLine};
        padding-bottom: 25px;
        margin-bottom: 30px;
      }
    }
  }
  @media (max-width: 768px) {
    .main-columns .the-week-progress-bar {
      display: none;
    }
    .navigation-wrapper {
      a {
        border: 1px solid ${theme.breakLine};
        border-radius: 4px;
        margin-right: 7px;

        .text {
          height: 18px;
          width: 20px;
          font-size: 18px;

          svg + div {
            display: none;
          }
        }
      }
    }
  }

  /* Tooltip section  */
  @media (max-width: 1202px) {
    .tooltip {
      right: -130px;

      :before {
        left: 141px !important;
      }
    }
  }
  @media (max-width: 1066px) {
    .tooltip {
      right: -30px;

      :before {
        left: 230px !important;
      }
    }
  }
  @media (max-width: 540px) {
    .profile-ranking-view {
      width: 100%;
      margin-left: 0;

      .tooltip {
        right: 0;
        left: 0;
        width: auto !important;

        :before {
          left: 171px !important;
        }
      }
    }
  }

  @media (max-width: 579px) {
    .main-content {
      padding: 0 10px;
      width: auto;

      .main-columns {
        padding: 0;
        h1 {
          font-size: 25px;
        }
      }

      .progress-bar-heading {
        text-align: center;
        font-size: 15px;
      }
      .message-section-wrapper {
        flex-direction: column;
        border: none;

        .aside-section {
          border-right: none;
          margin-right: 0;
          border: 1px solid #ddd;

          + p {
            padding: 20px;
            margin-top: 20px;
            border: 1px solid #ddd;
          }
        }
        .message-section {
          margin-top: 20px;
          padding: 10px;
          height: 340px;
          border: 1px solid #ddd;

          > div {
            margin-bottom: 0;
          }
        }
      }
      .profile-form {
        display: block;

        .profile-image-wrapper {
          padding: 0 0 20px 0;

          .profile-image-text {
            display: none;
          }
        }
        h4 {
          font-size: 14px;
        }
      }
    }
    .navigation-wrapper {
      ul li {
        display: flex;
        justify-content: center;
        flex: 1;
        margin-right: 2px;

        :last-child {
          margin-right: 0;
        }

        a {
          width: 100%;
          padding: 15px 0px;
          margin-right: 0;

          .text {
            font-size: 16px;
            width: auto;
          }
        }
      }
    }
    .team-profile-form .image-text {
      display: none;
    }
    .team-image-section {
      flex: auto;
      padding: 0;
      margin-bottom: 30px;
    }
    .team-main-section {
      h4 {
        font-size: 14px;
      }
    }
  }
  @media (max-width: 540px) {
    .profile-form .two-columns-details {
      display: block;

      > div > h4 {
        margin-top: 12px;
      }
      > div:first-child > input {
        margin-right: 0;
      }
    }
    .partner-opponent-profile {
      display: block;

      > div:first-child {
        margin-bottom: 20px;
      }
      > div:last-child {
        padding: 0;

        .inner-details > div {
          width: 100%;
          flex: none;
        }
      }
    }
    .team-profile-header-wrapper {
      margin-bottom: 30px;

      .team-profile-heading {
        width: 100%;

        h1 {
          margin-bottom: 0;
        }
      }
    }
  }
  @media (max-width: 480px) {
    .team-info {
      flex-flow: unset;
      flex-direction: column;

      .team-image-wrapper {
        margin-bottom: 30px;
      }
    }
  }
  @media (max-width: 412px) {
    .navigation-wrapper .text {
      font-size: 15px;
    }
  }
  @media (max-width: 396px) {
    .profile-title-ranking-wrapper {
      display: block;
      width: 100%;

      .profile-ranking-view {
        margin-left: 0;
      }
    }
  }
`;

// Backlink wrapper for directing people back to the original route
export const ProgressWrapper = styled.div`
  border: 1px solid
    ${props =>
      props.type === 'singles'
        ? props.genderType === 'men'
          ? theme.men.link
          : props.genderType === 'women'
          ? theme.women.link
          : theme.mixed.link
        : theme.doubles.link};
  border-radius: 8px;
`;
export const Progress = styled.div`
  ${props =>
    props.width &&
    css`
      width: ${props.width}%;
    `};
  height: 10px;
  background-color: ${props =>
    props.type === 'singles'
      ? props.genderType === 'men'
        ? theme.men.link
        : props.genderType === 'women'
        ? theme.women.link
        : theme.mixed.link
      : theme.doubles.link};
  border-radius: 8px;
  position: relative;

  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 20px;
    background: linear-gradient(
      45deg,
      transparent 25%,
      rgba(238, 238, 238, 0.5) 70%,
      rgba(238, 238, 238, 0.5) 30%,
      transparent 30%,
      transparent 35%,
      rgba(238, 238, 238, 0.5) 15%,
      rgba(238, 238, 238, 0.5) 70%,
      transparent 70%
    );
    background-size: 60px 100%;

    animation: ${Slide} 5s infinite;
  }
`;

export const Text = styled.h4`
  position: absolute;
  right: 0;
  top: 21px;
  width: 55px;
  margin: 0;
  font-size: 15px;
`;
export const CountDown = styled.p`
  display: inline-block;
  border: 1px solid gray;
  color: gray;
  padding: 7px 17px;
  border-radius: 3px;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-size: 15px;
`;
