import React from 'react';
import { Query } from '@apollo/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import withAuthorization from '../Session/withAuthorization';
import { MainWrapper } from '../PastResults/style';
import { NoResults } from '../Error/style';
import { ALL_LIFETIME } from './queries';
import AllLifetime from './allLifetime';
import IndividualLifetime from './individualLifetime';
import DashboardLayout from '../DashboardLayout';

const Lifetime = ({ session }) => (
  <DashboardLayout session={session} classExist={'main-result'} breakExist={true}>
    <MainWrapper>
      <h1>Leaderboard</h1>
      <Query query={ALL_LIFETIME}>
        {({ data, loading }) => {
          if (loading) {
            return <h3>Loading...</h3>;
          }
          if (data && data.getAllLifetime && data.getAllLifetime.length > 0) {
            return (
              <div>
                <AllLifetime getAllLifetime={data.getAllLifetime} />
                <IndividualLifetime session={session} getAllLifetime={data.getAllLifetime} />
              </div>
            );
          }
          return (
            <NoResults>
              <div className="no-results-inner">
                <FontAwesomeIcon icon={faInfoCircle} />
                <p>No results to display.</p>
              </div>
            </NoResults>
          );
        }}
      </Query>
    </MainWrapper>
  </DashboardLayout>
);

export default withAuthorization(session => session && session.me && !session.me.paid)(Lifetime);
