import gql from 'graphql-tag';

// Querying the current logged-in user
export const GET_ME = gql`
  {
    me {
      id
      allowEmail
      profileImage
      firstname
      lastname
      email
      about
      tennisCourt
      playTime
      playType
      genderType
      role
      confirmed
      createdAt
      paid
      finalConfetti
    }
  }
`;
