import styled, { theme } from '../../theme';

export const MainWrapper = styled.div`
  .section-wrapper {
    margin-bottom: 25px;

    :last-child {
      h4 {
        margin-top: 50px;
      }
    }
    h4 {
      margin-bottom: 7px;
    }
    .inner-section-wrapper {
      position: relative;
    }
    .table {
      display: table;
      width: 100%;
      text-align: center;

      .heading {
        background-color: #c6c6c6;

        p {
          color: black;
          font-weight: 800;
          margin: 5px 0;
        }
      }
      .individual {
        background-color: #8c8c8c;

        p {
          color: white;
        }
      }
      .table-row {
        display: table-row;

        .table-cell {
          display: table-cell;
          vertical-align: middle;
          padding: 0 5px;

          .profile-wrapper {
            display: flex;
            justify-content: center;

            .profile-inner {
              display: flex;
              align-items: center;
              width: 120px;

              img {
                width: 20px;
                height: 20px;
                border-radius: 2px;
                margin-right: 7px;
              }
              p {
                font-weight: 900;
                word-break: break-word;
                text-align: left;
              }
            }
          }
        }
        :nth-child(2) .table-cell p {
          margin: 10px 0 4px 0;
        }
        :nth-child(n + 3) .table-cell p {
          margin: 4px 0;
        }
        :nth-child(n + 3):nth-child(odd) {
          background-color: ${theme.breakLine};
        }
      }
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 870px) {
    .table-wrapper {
      position: relative;
      overflow-x: scroll;
    }
  }
`;
