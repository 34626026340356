import React, { Fragment, PureComponent } from 'react';
import { Query } from '@apollo/react-components';
import { Image, Transformation } from 'cloudinary-react';
import { Link } from 'react-router-dom';

import { Wrapper } from '../../style';
import { P } from '../../../style';
import { GET_RESULTS, OPPONENT_QUERY } from '../../../queries';
import placeholderProfile from '../../../../../resources/images/placeholder-profile.jpg';
import * as routes from '../../../../../constants/routes';
import NewResultsListener from './newResults';

class WeeklyResults extends PureComponent {
  render() {
    const { type, currentWeek, leagueId, getPlayInfo } = this.props;

    return (
      <Query query={GET_RESULTS} variables={{ week: currentWeek }}>
        {({ data, loading, subscribeToMore }) => (
          <Wrapper genderType={getPlayInfo.genderType}>
            {loading && ''}
            {/* If there is data, then display the results. Otherwise display notification */}
            {data && data.getResults && currentWeek > 0 ? (
              <Fragment>
                {/* Displays the current week if "currentWeek" state is over 0 */}
                <P uppercase bold MT={'0'} MB={'0'} borderBottom padding={'0 16px 13px 16px'}>
                  Week {currentWeek}
                </P>
                {currentWeek === 9 ? (
                  <Fragment>
                    <P margin={'18px 0 0 0'} padding={'0 16px'}>
                      Catch Up Week
                    </P>
                  </Fragment>
                ) : data.getResults.length > 0 ? (
                  // Wrapper for all results to be displayed
                  <div className="result-section">
                    {data.getResults.map(result => {
                      const theScore = JSON.parse(result.score);

                      return (
                        // Displaying score for EACH submission
                        <div className="the-result" key={result.id}>
                          <div className="player-info">
                            {/* Querying the winner information */}
                            <Query query={OPPONENT_QUERY} variables={{ id: +result.winnerId }}>
                              {({ data, loading }) => (
                                // Wrapper for image and player name
                                <div className="player-wrapper">
                                  {loading && 'Loading...'}
                                  {data && data.user && (
                                    <Fragment>
                                      <div className="image-overflow">
                                        {data.user.profileImage ? (
                                          <Image
                                            cloudName={process.env.REACT_APP_CLOUD_NAME}
                                            publicId={data.user.profileImage}
                                          >
                                            <Transformation width="25" height="25" gravity="faces" crop="fill" />
                                          </Image>
                                        ) : (
                                          <img src={placeholderProfile} alt="flexi-profile" />
                                        )}
                                      </div>
                                      <P margin={'5px 0'} color={'#FFA700'} bold>
                                        <Fragment>
                                          {data.user.firstname.charAt(0)}. {data.user.lastname}
                                        </Fragment>
                                      </P>
                                    </Fragment>
                                  )}
                                </div>
                              )}
                            </Query>
                            {/* Querying the loser information */}
                            <Query query={OPPONENT_QUERY} variables={{ id: +result.loserId }}>
                              {({ data, loading }) => (
                                // Wrapper for image and player name
                                <div className="player-wrapper">
                                  {loading && 'Loading...'}
                                  {data && data.user && (
                                    <Fragment>
                                      <div className="image-overflow">
                                        {data.user.profileImage ? (
                                          <Image
                                            cloudName={process.env.REACT_APP_CLOUD_NAME}
                                            publicId={data.user.profileImage}
                                          >
                                            <Transformation width="25" height="25" gravity="faces" crop="fill" />
                                          </Image>
                                        ) : (
                                          <img src={placeholderProfile} alt="flexi-profile" />
                                        )}
                                      </div>
                                      <P margin={'5px 0'}>
                                        <Fragment>
                                          {data.user.firstname.charAt(0)}. {data.user.lastname}
                                        </Fragment>
                                      </P>
                                    </Fragment>
                                  )}
                                </div>
                              )}
                            </Query>
                          </div>
                          {/* This is to display the scores --> loop through the score parent array and then grab each array inside. */}
                          {/* First index is the winner score and the second is the opponent --> I did this intentionally in the "scoreApproval" resolver file for the "approveScore" resolver */}
                          <div className="scores">
                            {theScore.map((score, i) => (
                              <div key={i} className="the-score">
                                <p>{score[0]}</p>
                                <p>{score[1]}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                    <Link to={routes.SINGLES_RESULTS} className="all-results-link">
                      View All Results
                    </Link>
                  </div>
                ) : (
                  <Fragment>
                    {/* Display this if the "getResults" array is empty --> meaning there no players have submitted results yet */}
                    <P margin={'18px 0 0 0'} padding={'0 16px'}>
                      Currently No Results
                    </P>
                  </Fragment>
                )}
              </Fragment>
            ) : (
              // Display this if the season has not started ---> this is outside of the "currentWeek > 0" logic. The current week indicator will not be shown.
              <P margin={'0'} padding={'0 16px'}>
                No Results Found
              </P>
            )}

            {/* Subscription to listen to new results */}
            {currentWeek > 0 && (
              <NewResultsListener
                subscribeToMore={subscribeToMore}
                type={type}
                leagueId={leagueId}
                currentWeek={currentWeek}
                getPlayInfo={getPlayInfo}
              />
            )}
          </Wrapper>
        )}
      </Query>
    );
  }
}

export default WeeklyResults;
