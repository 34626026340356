import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { HeadingWithTooltip } from '../Universal/RightsideBar/style';

const TooltipSection = ({ type, gender, handleTooltip, tooltip, heading, tooltipContent, tooltipClass = '' }) => (
  <HeadingWithTooltip type={type} gender={gender} id="tooltip-wrapper" heading={typeof heading !== 'undefined'}>
    <div className="heading-tooltip">
      {typeof heading !== 'undefined' && <h1>{heading}</h1>}
      <div className="question-icon" onClick={() => handleTooltip()}>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </div>
    </div>
    {tooltip && <div className={`tooltip ${tooltipClass}`}>{tooltipContent['html']}</div>}
  </HeadingWithTooltip>
);

export default TooltipSection;
