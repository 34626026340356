import React, { Fragment, Component } from 'react';
import { Query } from '@apollo/react-components';
import { Link } from 'react-router-dom';
import { Image, Transformation } from 'cloudinary-react';
import Hashids from 'hashids';

import withAuthorization from '../../Session/withAuthorization';
import TeamProfileForm from './teamProfile';
import { MasterStyle } from './style';
import { Flex, P } from '../../Universal/style';
import placeholderProfile from '../../../resources/images/placeholder-profile.jpg';
import { GET_DOUBLES_LEADERBOARD } from '../../Universal/RightsideBar/doubles/queries';
import DoublesUpdatedLeaderBoardChecker from '../../Universal/RightsideBar/doubles/leaderboard/leaderboardChecker';
import UpdatedPartner from './updatedPartner';
import TooltipSection from '../../Account/tooltip';

const hashids = new Hashids('fleximazing', 10);

const DoublesTeam = ({
  getTeam,
  userId,
  allowEmail,
  teamRefetch,
  partnerData,
  partnerLoading,
  leagueInfoData,
  teamPlayInfo,
  currentTeamId,
}) => {
  return (
    <MasterStyle>
      <HeadingSection
        type={'doubles'}
        leagueInfoData={leagueInfoData}
        teamPlayInfo={teamPlayInfo}
        currentTeamId={currentTeamId}
      />
      <Fragment>
        {getTeam && (
          <Fragment>
            {/* Team profile component */}
            <TeamProfileForm getTeam={getTeam} refetch={teamRefetch} userId={userId} allowEmail={allowEmail} />

            {/* Team partner section */}
            <div className="partner-section">
              <h3>Your Partner</h3>
              {/* Fetching the partner information */}
              {!partnerLoading ? (
                <Fragment>
                  {partnerData && partnerData.getPartnerInfo ? (
                    <PartnerSection partnerData={partnerData} />
                  ) : (
                    <div>
                      <p>
                        You have invited player with email <span>{getTeam.partnerEmail}</span> to be your partner. Once
                        the invitation has been accepted, their profile will appear in this section.
                      </p>
                      <UpdatedPartner refetch={teamRefetch} />
                    </div>
                  )}
                </Fragment>
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </Fragment>
        )}
      </Fragment>
    </MasterStyle>
  );
};

/******************** Heading Section *******************/
class HeadingSection extends Component {
  constructor() {
    super();
    this.state = {
      tooltip: false,
    };
  }

  handleTooltip = () => {
    this.setState(prev => ({ tooltip: !prev.tooltip }));
  };

  render() {
    const { tooltip } = this.state;
    const { type, leagueInfoData, teamPlayInfo, currentTeamId } = this.props;
    const tooltipContent = {
      html: (
        <p>
          Ranking based on points. <br />2 points is allocated to the winner, 1 point for the loser, and 1 point if
          games are played prior to or on the week of schedule. Ranking based on points.
        </p>
      ),
    };

    return (
      <Flex alignCenter className="team-profile-header-wrapper">
        <div style={{ marginRight: '50px' }} className="team-profile-heading">
          <h1>Team Profile</h1>
        </div>
        {leagueInfoData && leagueInfoData.getLeagueInfo && (
          <Query query={GET_DOUBLES_LEADERBOARD} variables={{ teamId: currentTeamId }}>
            {({ data, subscribeToMore }) => (
              // Updated leaderboard data checker --> taken from directory Universal -> rightSidebar -> doubles -> leaderboard -> leaderboardChecker.js
              <DoublesUpdatedLeaderBoardChecker
                leaderboardData={data}
                subscribeToMore={subscribeToMore}
                type={type}
                leagueId={leagueInfoData.getLeagueInfo.id}
                subLeagueId={teamPlayInfo.subLeagueId}
              >
                <Flex alignCenter className="profile-ranking-view">
                  {/* Sorting the leaderboard before looping over data and finding teamId that matches the current teamId. */}
                  {data &&
                    data.getDoublesLeaderboard &&
                    data.getDoublesLeaderboard
                      .slice()
                      .sort((a, b) => b.points - a.points)
                      .map((leaderboard, index) => {
                        if (+leaderboard.teamId === +currentTeamId) {
                          return (
                            <Fragment key={leaderboard.id}>
                              <div style={{ marginRight: '8px' }}>
                                <P date>Rank {leaderboard.points === 0 ? '--' : index + 1}</P>
                              </div>
                              <div>
                                <P date>Points {leaderboard.points === 0 ? '--' : leaderboard.points}</P>
                              </div>
                            </Fragment>
                          );
                        }
                        return '';
                      })}

                  {/* Tooltip section */}
                  <TooltipSection
                    type={'doubles'}
                    handleTooltip={this.handleTooltip}
                    tooltip={tooltip}
                    tooltipContent={tooltipContent}
                  />
                </Flex>
              </DoublesUpdatedLeaderBoardChecker>
            )}
          </Query>
        )}
      </Flex>
    );
  }
}

/******************** Partner Section *******************/
const PartnerSection = ({ partnerData }) => {
  const { getPartnerInfo } = partnerData;
  const partnerEncoded = hashids.encode(getPartnerInfo.id);

  return (
    <Fragment>
      {getPartnerInfo && (
        <div className="partner-box">
          <Link to={`/doubles/profile/partner/${partnerEncoded}`}>
            <div className="partner-image">
              {getPartnerInfo.profileImage === null ? (
                <img className="profile-image" src={placeholderProfile} alt="placeholder-profile-flexi" />
              ) : (
                <Image
                  className="profile-image"
                  cloudName={process.env.REACT_APP_CLOUD_NAME}
                  publicId={getPartnerInfo.profileImage}
                >
                  <Transformation width="100" height="80" gravity="faces" crop="fill" />
                </Image>
              )}
            </div>
            <p>{`${getPartnerInfo.firstname.charAt(0).toUpperCase()}. ${getPartnerInfo.lastname}`}</p>
          </Link>
        </div>
      )}
    </Fragment>
  );
};

export { HeadingSection };

export default withAuthorization(
  session => session && session.me && session.me.paid && session.me.playType === 'doubles'
)(DoublesTeam);
