import React, { Fragment } from 'react';
import { Query } from '@apollo/react-components';

import { Flex } from '../../Universal/style';
import { ALL_USERS_QUERY } from '../queries';

const Statistics = () => (
  <Flex className="statistic-wrapper">
    <Query query={ALL_USERS_QUERY}>
      {({ data, loading }) => {
        if (loading) {
          return <p>Loading...</p>;
        }
        if (data && data.users) {
          let emptyObject = {};
          emptyObject['singles'] = 0;
          emptyObject['mixed-singles'] = 0;
          emptyObject['men-singles'] = 0;
          emptyObject['women-singles'] = 0;
          emptyObject['doubles'] = 0;
          emptyObject['unregistered'] = 0;

          const dataObject = data.users.reduce((acc, user) => {
            if (user.playType === 'singles') {
              acc['singles'] += 1;
              if (user.genderType === 'men') {
                acc['men-singles'] += 1;
              } else if (user.genderType === 'women') {
                acc['women-singles'] += 1;
              } else {
                acc['mixed-singles'] += 1;
              }
            } else if (user.playType === 'doubles') {
              acc['doubles'] += 1;
            } else if (!user.paid) {
              acc['unregistered'] += 1;
            }
            return acc;
          }, emptyObject);

          return (
            <Fragment>
              <div className="the-statistic">
                <p>Registered in Singles</p>
                <Flex center className="registered-in-singles">
                  <div>{'singles' in dataObject && dataObject['singles']}</div>
                  <div>M - {'men-singles' in dataObject && dataObject['men-singles']}</div>
                  <div>W - {'women-singles' in dataObject && dataObject['women-singles']}</div>
                  <div>Mixed - {'mixed-singles' in dataObject && dataObject['mixed-singles']}</div>
                </Flex>
              </div>
              <div className="the-statistic">
                <p>Registered in Doubles</p>
                <div>{'doubles' in dataObject && dataObject['doubles']}</div>
              </div>
              <div className="the-statistic">
                <p>Unregistered Users</p>
                <div>{'unregistered' in dataObject && dataObject['unregistered']}</div>
              </div>
            </Fragment>
          );
        }
        return '';
      }}
    </Query>
  </Flex>
);

export default Statistics;
