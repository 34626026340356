import React, { Component, Fragment } from 'react';
import Tilt from 'react-tilt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { ErrorWrapper, FurtherInfo } from './style';
import logo from '../../resources/images/logo-white.png';
import Footer from '../Footer';

class ErrorBoundary extends Component {
  constructor() {
    super();
    this.state = {
      hasError: false,
      moreInfo: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  backgroundEngage = element => {
    if (element) {
      const elementWidth = element.clientWidth / 5;
      const elementHeight = element.clientHeight / 5;

      element.addEventListener('mousemove', event => {
        const mouseX = event.clientX / elementWidth;
        const mouseY = event.clientY / elementHeight;

        element.style.transform = `translate3d(-${mouseX}%, -${mouseY}%, 0)`;
      });
    }
  };

  toggleInfo = () => {
    this.setState(prev => ({ moreInfo: !prev.moreInfo }));
  };

  render() {
    const { hasError, moreInfo } = this.state;
    return (
      <Fragment>
        {hasError ? (
          <ErrorWrapper>
            <div className="main-wrapper">
              <div className="error-content-wrapper">
                <div className="heading-wrapper">
                  <h1>Ooooops, You've Caught The Net!</h1>
                  <div className="site-logo">
                    <Tilt className="Tilt" options={{ max: 35, scale: 1.05 }}>
                      <div className="Tilt-inner">
                        <img src={logo} alt="Logo" />
                      </div>
                    </Tilt>
                  </div>
                </div>

                <div className="support-info">
                  <FontAwesomeIcon icon={faQuestionCircle} onClick={() => this.toggleInfo()} />
                  <div className="text">
                    Please report this error to our support team at{' '}
                    <a href="mailto:support@flexitennisleagues.com.au">support@flexitennisleagues.com.au</a>
                  </div>
                </div>
              </div>
              {moreInfo && (
                <FurtherInfo>
                  <p>To help resolve the issue, it would be helpful that the following points be sent through:</p>
                  <ul>
                    <li>The URL that you are currently on.</li>
                    <li>Actions to arrive at this error screen.</li>
                  </ul>
                </FurtherInfo>
              )}
              <div className="background" ref={this.backgroundEngage}></div>
            </div>

            <Footer />
          </ErrorWrapper>
        ) : (
          this.props.children
        )}
      </Fragment>
    );
  }
}

export default ErrorBoundary;
