import React from 'react';
import { Query, Mutation } from '@apollo/react-components';

import GenerateDoublesFinals from './generateFinals.js';
import { DOUBLES_FINALS_FIXTURE_QUERY } from '../queries';
import { GENERATE_DOUBLES_FINALS_FIXTURE } from '../../../../Doubles/fixtures/queries';

const DoublesControlFinalsFixture = ({ seasonDate }) => (
  // Query finals fixture for week 10 and if array is empty then display button to "generate" final fixtures otherwise don't display button
  <Query query={DOUBLES_FINALS_FIXTURE_QUERY}>
    {({ data, loading, subscribeToMore }) => {
      if (loading) {
        return <p style={{ margin: '0' }}>Loading...</p>;
      }
      if (data && data.doublesAdminGetWeek10Fixture) {
        const { doublesAdminGetWeek10Fixture } = data;
        return (
          // Resolver to generate finals fixture
          <Mutation
            mutation={GENERATE_DOUBLES_FINALS_FIXTURE}
            variables={{ seasonDate }}
            refetchQueries={() => [{ query: DOUBLES_FINALS_FIXTURE_QUERY }]}
          >
            {doublesFinalFixture => (
              <GenerateDoublesFinals
                doublesFinalFixture={doublesFinalFixture}
                subscribeToMore={subscribeToMore}
                doublesAdminGetWeek10Fixture={doublesAdminGetWeek10Fixture}
              />
            )}
          </Mutation>
        );
      }
      return '';
    }}
  </Query>
);

export default DoublesControlFinalsFixture;
