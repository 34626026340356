import gql from 'graphql-tag';

// Update the team information --> Only team owner can do this
export const MUTATION_UPDATE_TEAM = gql`
  mutation($tennisCourt: String, $playTime: String) {
    updateTeamInfo(tennisCourt: $tennisCourt, playTime: $playTime)
  }
`;
// Update the team profile image
export const MUTATION_UPDATE_TEAM_IMAGE = gql`
  mutation($profileImage: String) {
    updateTeamImage(profileImage: $profileImage)
  }
`;

// Query the opponent
export const PARTNER_QUERY = gql`
  query {
    getPartnerInfo {
      id
      online
      profileImage
      firstname
      lastname
      email
      about
      tennisCourt
      playTime
      role
      confirmed
      createdAt
      paid
    }
  }
`;

// Owner ONLY -> Update team partner
export const UPDATE_PARTNER_MUTATION = gql`
  mutation($email: String!) {
    updateTeamPartner(email: $email)
  }
`;
// Owner ONLY -> Resend partner invite
export const RESEND_INVITE_MUTATION = gql`
  mutation {
    resendInvite
  }
`;
