import React, { PureComponent } from 'react';

import { master } from '../../resources/resources';
import { Flex } from './style';

class Footer extends PureComponent {
  constructor() {
    super();
    this.state = {
      currentIndex: 0,
    };
  }

  randomiseArray = emojiArray => {
    let currentIndex = emojiArray.length;
    while (currentIndex !== 0) {
      const randomIndex = Math.floor(currentIndex * Math.random());
      currentIndex -= 1;
      const temporaryValue = emojiArray[currentIndex];

      emojiArray[currentIndex] = emojiArray[randomIndex];
      emojiArray[randomIndex] = temporaryValue;
    }
    return emojiArray;
  };

  rotateEmoji = emojiArray => {
    const { currentIndex } = this.state;
    const randomArray = this.randomiseArray(emojiArray);
    this.timeout = setTimeout(() => {
      this.setState(prev => {
        if (prev.currentIndex < emojiArray.length - 1) {
          return { currentIndex: prev.currentIndex + 1 };
        } else {
          return { currentIndex: 0 };
        }
      });
    }, 1000);
    return randomArray[currentIndex];
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    return (
      <Flex>
        <div className="footer-wrapper">
          <div className="copyright">
            <p>
              {new Date().getFullYear()} &copy; Flexi Tennis Leagues{' '}
              <span style={{ display: 'inline-block' }} role="img" aria-label="tennis-ball">
                🎾
              </span>
            </p>
          </div>
          <div className="middle">
            <p>ABN: 46 285 390 502</p>

            <a href="https://flexitennisleagues.com.au/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>

            <a href="https://flexitennisleagues.com.au/terms" target="_blank" rel="noopener noreferrer">
              Terms & Conditions
            </a>
          </div>

          <div className="creator">
            <p>Created By MadeByHaT {this.rotateEmoji(master.emojiArray)}</p>
          </div>
        </div>
      </Flex>
    );
  }
}

export default Footer;
