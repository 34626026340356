import React, { Component, Fragment } from 'react';
import { Query, Mutation } from '@apollo/react-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import * as routes from '../../../constants/routes';
import { BackLink, Button } from '../../Universal/style';
import { ALL_FEEDBACK_QUERY, ACTIVE_FEEDBACK_MUTATION } from '../queries';
import { OPPONENT_QUERY } from '../../Universal/queries';
import { FeedbackTable, ActiveCircle } from './style';
import { NoResults } from '../../Error/style';

class Feedback extends Component {
  activeFeedbackStatus = async (checkActiveFeedback, refetch) => {
    await checkActiveFeedback();
    await refetch();
  };

  render() {
    return (
      <Fragment>
        <BackLink>
          <Link to={routes.ADMIN}>
            <FontAwesomeIcon icon={faArrowCircleLeft} /> Back to Admin Page
          </Link>
        </BackLink>

        <h2>All Feedbacks</h2>
        <Query query={ALL_FEEDBACK_QUERY}>
          {({ data, loading, fetchMore, refetch }) => {
            if (loading) {
              return <h3>Loading...</h3>;
            }
            if (data && data.allFeedback && data.allFeedback.edges && data.allFeedback.edges.length > 0) {
              const { edges, feedbackInfo } = data.allFeedback;
              const filteredDisplay = edges.filter(object => object.display);
              const filteredNoDisplay = edges.filter(object => !object.display);
              const masterArray = [filteredDisplay, filteredNoDisplay];

              return (
                <Fragment>
                  {masterArray.map((array, index) => {
                    if (array && array.length > 0) {
                      return (
                        <Fragment key={index}>
                          {index === 0 ? <h3>Active</h3> : <h3>Non Active</h3>}
                          <FeedbackTable>
                            <div className="feedback-row">
                              <div className="feedback-cell heading">
                                <p>Status</p>
                              </div>
                              <div className="feedback-cell heading">
                                <p>Feedback</p>
                              </div>
                              <div className="feedback-cell heading">
                                <p>Season</p>
                              </div>
                              <div className="feedback-cell heading">
                                <p>Year</p>
                              </div>
                              <div className="feedback-cell heading">
                                <p>Type</p>
                              </div>
                              <div className="feedback-cell heading">
                                <p>Gender</p>
                              </div>
                              <div className="feedback-cell heading">
                                <p>League</p>
                              </div>
                              <div className="feedback-cell heading">
                                <p>Div</p>
                              </div>
                              <div className="feedback-cell heading">
                                <p>Group</p>
                              </div>
                              <div className="feedback-cell heading">
                                <p>Player</p>
                              </div>
                            </div>
                            {array
                              .slice()
                              .sort((a, b) => b.year - a.year)
                              .map(object => (
                                <Mutation
                                  key={object.id}
                                  mutation={ACTIVE_FEEDBACK_MUTATION}
                                  variables={{ id: object.id }}
                                >
                                  {checkActiveFeedback => (
                                    <div
                                      className="feedback-row"
                                      onClick={() => this.activeFeedbackStatus(checkActiveFeedback, refetch)}
                                    >
                                      <div className="feedback-cell">
                                        <ActiveCircle active={object.display}></ActiveCircle>
                                      </div>
                                      <div className="feedback-cell">
                                        <p className="align-left">{object.feedback}</p>
                                      </div>
                                      <div className="feedback-cell">
                                        <p>{object.season}</p>
                                      </div>
                                      <div className="feedback-cell">
                                        <p>{object.year}</p>
                                      </div>
                                      <div className="feedback-cell">
                                        <p>{object.playType}</p>
                                      </div>
                                      <div className="feedback-cell">
                                        <p>{object.genderType}</p>
                                      </div>
                                      <div className="feedback-cell">
                                        <p>{object.league}</p>
                                      </div>
                                      <div className="feedback-cell">
                                        <p>{object.division}</p>
                                      </div>
                                      <div className="feedback-cell">
                                        <p>{object.subLeagueId}</p>
                                      </div>
                                      <Query query={OPPONENT_QUERY} variables={{ id: object.userId }}>
                                        {({ data, loading }) => (
                                          <div className="feedback-cell">
                                            {loading && <p>Loading..</p>}
                                            {data && data.user && (
                                              <p>
                                                {data.user.firstname} {data.user.lastname}
                                              </p>
                                            )}
                                          </div>
                                        )}
                                      </Query>
                                    </div>
                                  )}
                                </Mutation>
                              ))}
                          </FeedbackTable>
                        </Fragment>
                      );
                    }
                    return '';
                  })}
                  {feedbackInfo.hasNextPage && (
                    <MoreFeedback fetchMore={fetchMore} endCursor={feedbackInfo.endCursor} />
                  )}
                </Fragment>
              );
            } else {
              return (
                <NoResults>
                  <div className="no-results-inner">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <p>No feedbacks to display.</p>
                  </div>
                </NoResults>
              );
            }
          }}
        </Query>
      </Fragment>
    );
  }
}

class MoreFeedback extends Component {
  fetchMoreFeedback = () => {
    this.props.fetchMore({
      variables: {
        cursor: this.props.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        return Object.assign({}, previousResult, {
          allFeedback: {
            ...fetchMoreResult.allFeedback,
            edges: [...fetchMoreResult.allFeedback.edges, ...previousResult.allFeedback.edges],
          },
        });
      },
    });
  };

  render() {
    return <Button onClick={() => this.fetchMoreFeedback()}>More</Button>;
  }
}

export default Feedback;
