import gql from 'graphql-tag';

export const DISPLAY_FEEDBACK_QUERY = gql`
  query {
    displayFeedback {
      id
      year
      feedback
      userId
    }
  }
`;
