import styled from '../../theme';

export const Flex = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  z-index: 1;

  .footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    padding: 10px 15px;
    width: 100%;
    height: 17px;

    p,
    a {
      font-size: 14px;
      margin: 0;
      color: white;
      font-family: 'calibri', 'open sans';
      text-transform: capitalize;
      margin-right: 7px;

      span {
        font-size: 12px;
      }
    }
    .copyright {
      display: flex;
      align-items: center;
      padding-right: 15px;
    }
    .middle {
      display: flex;
      align-items: center;

      p {
        border-right: 1px solid white;
        padding-right: 10px;
      }
      a {
        border-right: 1px solid white;
        padding-right: 10px;
        color: cyan;

        :last-child {
          border-right: none;
        }
        :hover {
          opacity: 0.7;
        }
      }
    }
    .creator {
      display: flex;
      align-items: center;
      padding-left: 15px;

      .builder {
        color: cyan;
        :hover {
          opacity: 0.7;
        }
      }
    }
  }

  @media (max-width: 841px) {
    .footer-wrapper {
      display: block;
      height: auto;

      > div {
        margin-top: 7px;
        justify-content: center;
        padding-right: 0 !important;
      }
    }
  }
  @media (max-width: 496px) {
    .footer-wrapper {
      .middle {
        display: block;
        text-align: center;

        p,
        a {
          border-right: none;
        }
        a {
          margin-top: 7px;
          width: 100%;
        }
      }
      p,
      a {
        padding-right: 0 !important;
        margin-right: 0 !important;
      }
    }
  }
`;
