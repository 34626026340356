import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ErrorMessage from '../Error';
import Loading from '../Loading';
import gql from 'graphql-tag';
import { Mutation } from '@apollo/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import * as routes from '../../constants/routes';
import stripeLogo from '../../resources/images/stripe-logo.png';

import { MyModal, CheckoutWholeWrapper, CheckoutWrapper, InputWrapper } from './style';
import { Button, H4 } from '../Universal/style';
import { StripeFormWrapper } from './style';
import { ErrorWrapper, Li } from '../Error/style';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const STRIPE_CHARGE = gql`
  mutation($type: String!, $partnerEmail: String) {
    stripeCharge(type: $type, partnerEmail: $partnerEmail) {
      clientSecret
    }
  }
`;
const PAID_TO_PLAY = gql`
  mutation(
    $viaPayment: Boolean
    $visaType: String
    $visaNo: String
    $season: String!
    $type: String!
    $genderType: String
    $teamId: ID
    $partnerEmail: String
    $league: String
    $skill: String
  ) {
    paidToPlay(
      viaPayment: $viaPayment
      visaType: $visaType
      visaNo: $visaNo
      season: $season
      type: $type
      genderType: $genderType
      teamId: $teamId
      partnerEmail: $partnerEmail
      league: $league
      skill: $skill
    )
  }
`;

class CheckoutForm extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      visaType: '',
      visaNo: '',
      complete: false,
      isPending: true,
      cardError: {
        status: false,
        message: '',
      },
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState = state => {
    return new Promise(resolve => this.setState(state, resolve));
  };

  onSubmit = async (event, stripeCharge, paidToPlay) => {
    event.preventDefault();
    const { isPending } = this.state;

    if (isPending) {
      // Close the pending to prevent logic from sending multiple times on repeated onSubmit clicks
      this.setState({ isPending: false, cardError: { status: false, message: '' } });

      const { session, history, userRefetch, customer, stripe, elements } = this.props;

      // Stripe.js has not yet loaded -> prevent submission of form
      if (!stripe || !elements) {
        return;
      }

      // First resolver preparing the intent for the charge
      const card = await elements.getElement(CardElement);
      const cardDetails = await stripe.createToken(card);

      stripeCharge()
        .then(async ({ data }) => {
          // Result from the intent
          const result = await stripe.confirmCardPayment(data.stripeCharge.clientSecret, {
            payment_method: {
              card,
              billing_details: {
                name: customer.name,
                email: customer.email,
              },
            },
          });

          if (result.error) {
            this.setState({ isPending: true, cardError: { status: true, message: result.error.message } });
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              await this.updateState({
                complete: true,
                visaType: cardDetails.token.card.brand,
                visaNo: cardDetails.token.card.last4,
              });

              await paidToPlay();
              await userRefetch();
              if (session && session.me && session.me.playType === 'singles') {
                history.push(routes.SINGLES_DASH);
              } else {
                history.push(routes.DOUBLES_TEAM);
              }
            }
          }
        })
        .catch(() => {
          if (this._isMounted) {
            this.setState({ isPending: true });
          }
        });
    }
  };

  render() {
    const { visaType, visaNo, complete, cardError } = this.state;
    const {
      type,
      season,
      genderType,
      teamId,
      partnerEmail,
      closePaymentWindow,
      league,
      skill,
      getSeasonStatus,
    } = this.props;

    const { price } = getSeasonStatus;
    const doubles = type === 'doubles';

    // If we attempt to update the state to an unmounted component then it will not work, that's why I'm doing it after the declarations of the Mutations
    return (
      <Mutation mutation={STRIPE_CHARGE} variables={{ type, partnerEmail }}>
        {(stripeCharge, { loading, error }) => (
          <Mutation
            mutation={PAID_TO_PLAY}
            variables={{
              viaPayment: true,
              type,
              visaType,
              visaNo,
              season,
              genderType,
              teamId,
              partnerEmail,
              league,
              skill,
            }}
          >
            {paidToPlay =>
              // If the "complete" state is updated to TRUE then display "purchase complete" otherwise return the payment form
              complete ? (
                <h1>Purchase Complete</h1>
              ) : (
                <CheckoutWholeWrapper>
                  {/* Close icon */}
                  <div className="close-button" onClick={() => closePaymentWindow()}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </div>

                  <div style={{ textAlign: 'center' }}>
                    <img style={{ width: '270px' }} src={stripeLogo} alt="stripe-logo" />
                    <H4 fontsize={'20px'} noMB>
                      Registrations cost <span>$</span>
                      {price}.
                    </H4>
                    <p>Please complete the payment below.</p>
                  </div>
                  <CheckoutWrapper style={{ marginBottom: '7px' }}>
                    <InputWrapper>
                      <CardElement />
                    </InputWrapper>

                    <Button
                      doubles={doubles}
                      normal
                      genderType={genderType}
                      noMargin
                      type="button"
                      onClick={event => this.onSubmit(event, stripeCharge, paidToPlay)}
                    >
                      {loading || !this.props.stripe ? <Loading /> : 'Send'}
                    </Button>
                  </CheckoutWrapper>

                  {error && <ErrorMessage error={error} />}
                  {cardError.status && (
                    <ErrorWrapper style={{ marginTop: '30px' }}>
                      <Li>{cardError.message}</Li>
                    </ErrorWrapper>
                  )}
                </CheckoutWholeWrapper>
              )
            }
          </Mutation>
        )}
      </Mutation>
    );
  }
}

const StripeSection = ({
  type,
  customer,
  userRefetch,
  session,
  season,
  genderType,
  teamId,
  partnerEmail,
  closePaymentWindow,
  league,
  skill,
  getSeasonStatus,
}) => {
  return (
    <MyModal>
      <StripeFormWrapper>
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <CheckoutForm
                stripe={stripe}
                elements={elements}
                type={type}
                customer={customer}
                userRefetch={userRefetch}
                session={session}
                season={season}
                genderType={genderType}
                teamId={teamId}
                partnerEmail={partnerEmail}
                closePaymentWindow={closePaymentWindow}
                league={league}
                skill={skill}
                getSeasonStatus={getSeasonStatus}
              />
            )}
          </ElementsConsumer>
        </Elements>
      </StripeFormWrapper>
    </MyModal>
  );
};

export default withRouter(StripeSection);
