import React, { PureComponent, Fragment } from 'react';
import { Query, Mutation } from '@apollo/react-components';
import { NavLink, withRouter } from 'react-router-dom';

import { H4, Status } from '../style';
import {
  NEW_OPPONENT_MESSAGE,
  OPPONENT_QUERY,
  UPDATED_USER_SUBSCRIPTION,
  REMOVE_NOTIFICATION,
} from '../../../Universal/queries';
import { NEW_SINGLES_MESSAGES_QUERY } from '../../../Navigation/queries';
import GetNewNotificationsWithRouter from './notification';
import NewMessageSubscription from './newMessageSubscription';

class GetSeasonFixtureUsers extends PureComponent {
  render() {
    const { opponentId, chatLink, session, userEncoded } = this.props;
    return (
      // This Query will get all the opponent messages for the same channel.
      // The variable "opponentId" will search in the DirectMessage table for "userId" that matches the "opponentId" and the "opponentId" as the "userId".
      // This will return all message that the opponent writes to the user.
      <Query query={NEW_OPPONENT_MESSAGE} variables={{ opponentId }}>
        {({ data, loading, refetch, subscribeToMore }) => {
          const newOpponentMessageRefetch = refetch;
          const newMessageSubscribe = subscribeToMore;

          if (loading) {
            return '';
          }
          if (data && data.getOpponentMessage && session && session.me) {
            const { getOpponentMessage } = data;

            return (
              // This Query will query the current "opponentId" and return their data.
              <Query query={OPPONENT_QUERY} variables={{ id: opponentId }}>
                {({ data, loading, refetch, subscribeToMore }) => {
                  if (loading && <p>Loading...</p>);

                  if (data && data.user) {
                    const { user } = data;

                    return (
                      <NavLink style={{ position: 'relative' }} to={chatLink} activeClassName="active-link">
                        {/* Checking if the user is online or not */}
                        {user.online ? (
                          <Status className="status" online></Status>
                        ) : (
                          <Status className="status" offline></Status>
                        )}
                        <DetermineActiveUserWithRouter user={user} userEncoded={userEncoded} />
                        <OnlineChecker
                          subscribeToMore={subscribeToMore}
                          user={user.id}
                          userData={user}
                          updatedUserRefetch={refetch}
                        />

                        {/* If the opponent has send a new message then show an alert (notification) */}
                        {getOpponentMessage && getOpponentMessage.length > 0 && (
                          <Mutation
                            mutation={REMOVE_NOTIFICATION}
                            variables={{ opponentId }}
                            // When the "remove notification" resolver executes, then we need to refetch the opponentMessages to show updated notifcation field from TRUE to FALSE.
                            refetchQueries={() => {
                              return [
                                {
                                  query: NEW_SINGLES_MESSAGES_QUERY,
                                },
                              ];
                            }}
                          >
                            {removeNotification => (
                              <Fragment>
                                <GetNewNotificationsWithRouter
                                  newOpponentMessageRefetch={newOpponentMessageRefetch}
                                  getOpponentMessage={getOpponentMessage}
                                  chatLink={chatLink}
                                  removeNotification={removeNotification}
                                />
                              </Fragment>
                            )}
                          </Mutation>
                        )}

                        <NewMessageSubscription
                          subscribeToMore={newMessageSubscribe}
                          newOpponentMessageRefetch={newOpponentMessageRefetch}
                          opponentId={opponentId}
                          userId={session.me.id}
                        />
                      </NavLink>
                    );
                  } else {
                    return '';
                  }
                }}
              </Query>
            );
          } else {
            return '';
          }
        }}
      </Query>
    );
  }
}

// Subscribe to the opponent Query to see when they are online with the "onlineChecker" subscription resolver.
// Using the variable "UPDATED_USER_SUBSCRIPTION".
class OnlineChecker extends PureComponent {
  subscribeToUpdatedUser = () => {
    this.props.subscribeToMore({
      document: UPDATED_USER_SUBSCRIPTION,
      variables: { userId: this.props.user },
      updateQuery: (previousResult, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousResult;
        }

        return Object.assign({}, previousResult, {
          user: { ...previousResult.user, ...subscriptionData.data.onlineChecker },
        });
      },
    });
  };

  componentDidMount() {
    this.props.updatedUserRefetch();
    this.subscribeToUpdatedUser();
  }

  render() {
    return <div></div>;
  }
}

const DetermineActiveUser = ({ location, user, userEncoded }) => {
  const { pathname } = location;
  const isCurrentUser =
    pathname === `/singles/message/${userEncoded}` || pathname === `/doubles/message/partner/${userEncoded}`;
  return (
    <H4 orange={isCurrentUser}>
      {user.firstname} {user.lastname}
    </H4>
  );
};
const DetermineActiveUserWithRouter = withRouter(DetermineActiveUser);

export default GetSeasonFixtureUsers;
export { OnlineChecker };
