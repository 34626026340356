import React, { Component, Fragment } from 'react';
import { Query } from '@apollo/react-components';

import withAuthorization from '../Session/withAuthorization';
import ProfileForm from './profileForm';
import { Flex, P } from '../Universal/style';
import { GET_LEADERBOARD } from '../Universal/queries';
import UpdatedLeaderBoardChecker from '../Universal/RightsideBar/singles/leaderboard/leaderboardChecker';
import TooltipSection from './tooltip';
import DashboardLayout from '../DashboardLayout';

const AccountPage = ({ session, refetch }) => (
  <DashboardLayout session={session} relative={true} breakExist={true}>
    {session && session.me && <HeadingSection session={session} />}
    {session && session.me && <ProfileForm session={session} refetch={refetch} />}
  </DashboardLayout>
);

/******************** Heading Section *******************/
class HeadingSection extends Component {
  constructor() {
    super();
    this.state = {
      tooltip: false,
    };
  }

  handleTooltip = () => {
    this.setState(prev => ({ tooltip: !prev.tooltip }));
  };

  render() {
    const { tooltip } = this.state;
    const { session, user, leagueId, getPlayInfo } = this.props;
    const theDate = session !== undefined ? session.me.createdAt : user.user.createdAt;
    const tooltipContent = {
      html: (
        <p>
          Ranking based on points. <br />2 points is allocated to the winner, 1 point for the loser, and 1 point if
          games are played prior to or on the week of schedule. Ranking based on points.
        </p>
      ),
    };

    return (
      <Flex between>
        <Flex alignCenter className="profile-title-ranking-wrapper">
          {session && <h1>Profile</h1>}
          {getPlayInfo && (
            <Query query={GET_LEADERBOARD}>
              {({ data, subscribeToMore }) => (
                <Fragment>
                  {/* Updated leaderboard data checker */}
                  <UpdatedLeaderBoardChecker
                    leaderboardData={data}
                    subscribeToMore={subscribeToMore}
                    type={'singles'}
                    leagueId={leagueId}
                    subLeagueId={getPlayInfo.subLeagueId}
                    genderType={getPlayInfo.genderType}
                  >
                    <Flex margin={'0 0 0 30px'} className="profile-ranking-view">
                      {data &&
                        data.getLeaderboard &&
                        data.getLeaderboard
                          .slice()
                          .sort((a, b) => b.points - a.points)
                          .map((eachData, index) => {
                            const { id, points, userId } = eachData;
                            if (+userId === +session.me.id) {
                              return (
                                <Fragment key={id}>
                                  <div style={{ marginRight: '8px' }}>
                                    <P date>Rank {points === 0 ? '--' : index + 1}</P>
                                  </div>
                                  <div>
                                    <P date>Points {points === 0 ? '--' : points}</P>
                                  </div>
                                </Fragment>
                              );
                            } else {
                              return '';
                            }
                          })}

                      {/* Tooltip section */}
                      <TooltipSection
                        type={'singles'}
                        gender={getPlayInfo && getPlayInfo.genderType}
                        handleTooltip={this.handleTooltip}
                        tooltip={tooltip}
                        tooltipContent={tooltipContent}
                      />
                    </Flex>
                  </UpdatedLeaderBoardChecker>
                </Fragment>
              )}
            </Query>
          )}
        </Flex>
        <P date>
          Joined{' '}
          {new Date(theDate)
            .toDateString()
            .split(' ')
            .slice(1)
            .join(' ')}
        </P>
      </Flex>
    );
  }
}

export default withAuthorization(session => session && session.me && !session.me.paid)(AccountPage);

export { ProfileForm, HeadingSection };
