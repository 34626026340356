import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';

import { Button } from '../../Universal/style';
import { MASTER_SEASON_END_MUTATION } from '../queries';

class DeadSwitch extends Component {
  constructor() {
    super();
    this.state = {
      hidden: true,
      resetPoints: false,
    };
  }

  executeDeadSwitch = async seasonEndLogic => {
    await seasonEndLogic();
    await this.props.refetch();
  };

  componentDidMount() {
    // Don't display the dead switch button between the end of the a season and the before the start of the next season.
    const { seasonDates } = this.props;
    let { previousSpring, summer, autumn, winter, spring, nextSummer } = seasonDates;
    // Before season starts
    const beforeSummer = new Date(new Date(summer).setDate(new Date(summer).getDate() - 19));
    const beforeAutumn = new Date(new Date(autumn).setDate(new Date(autumn).getDate() - 19));
    const beforeWinter = new Date(new Date(winter).setDate(new Date(winter).getDate() - 19));
    const beforeSpring = new Date(new Date(spring).setDate(new Date(spring).getDate() - 19));
    const beforeNextSummer = new Date(new Date(nextSummer).setDate(new Date(nextSummer).getDate() - 19));
    // End of season
    const endOfPreviousSpring = new Date(new Date(previousSpring).setDate(new Date(previousSpring).getDate() + 70));
    const endOfSummer = new Date(new Date(summer).setDate(new Date(summer).getDate() + 70));
    const endOfAutumn = new Date(new Date(autumn).setDate(new Date(autumn).getDate() + 70));
    const endOfWinter = new Date(new Date(winter).setDate(new Date(winter).getDate() + 70));
    const endOfSpring = new Date(new Date(spring).setDate(new Date(spring).getDate() + 70));
    // Current Time
    const currentTime = new Date().getTime();

    if (endOfPreviousSpring <= currentTime && currentTime <= beforeSummer) {
      this.setState({ hidden: false, resetPoints: true });
    } else if (endOfSummer <= currentTime && currentTime <= beforeAutumn) {
      this.setState({ hidden: false });
    } else if (endOfAutumn <= currentTime && currentTime <= beforeWinter) {
      this.setState({ hidden: false });
    } else if (endOfWinter <= currentTime && currentTime <= beforeSpring) {
      this.setState({ hidden: false });
    } else if (endOfSpring <= currentTime && currentTime <= beforeNextSummer) {
      this.setState({ hidden: false });
    }

    // If testing -> just replace the endOf... to new Date(spring) and it should appear.
  }

  render() {
    const { hidden, resetPoints } = this.state;
    if (hidden) {
      return <p>Season is currently in progress. Dead switch will be available at season end.</p>;
    } else {
      return (
        <Mutation mutation={MASTER_SEASON_END_MUTATION} variables={{ resetPoints }}>
          {(seasonEndLogic, { error }) => (
            <div className="dead-switch-section">
              <Button normal onClick={() => this.executeDeadSwitch(seasonEndLogic)}>
                Execute
              </Button>
              {error && <div className="execution-error">Already executed.</div>}
            </div>
          )}
        </Mutation>
      );
    }
  }
}

export default DeadSwitch;
