import gql from 'graphql-tag';

// Update the user profile image
export const UPDATE_USER_IMAGE = gql`
  mutation($profileImage: String) {
    updateUserImage(profileImage: $profileImage)
  }
`;

// To allow or disable message email notifications
export const ALLOW_EMAIL_SWITCH_MUTATION = gql`
  mutation {
    allowEmailSwitch
  }
`;
