import React, { Component, Fragment } from 'react';
import { Mutation } from '@apollo/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';

import { sanitizeInput } from '../../../abstracts/helpers';

import { Button } from '../../Universal/style';
import { PROVIDE_USER_LEAGUE, GET_USER_PLAY_INFO } from './queries';
import { GET_ALL_SUBLEAGUE } from '../leagueAvailability/queries';
import { master } from '../../../resources/resources';
import ErrorMessage from '../../Error';

class ProvideLeagueAccess extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      playType: 'select-option',
      genderType: 'select-option',
      location: 'select-option',
      league: 'select-option',
      skill: 'select-option',
      teamName: '',
      partnerEmail: '',
      subLeagueId: 0,
    };
  }

  onChange = event => {
    const { name, value } = event.target;

    if (name === 'location') {
      this.setState({ league: 'select-option' });
    }
    this.setState({ [name]: value });
  };

  onSubmit = (event, provideUserLeague) => {
    event.preventDefault();
    provideUserLeague().then(() => {
      this.setState({
        email: '',
        playType: 'select-option',
        genderType: 'select-option',
        location: 'select-option',
        league: 'select-option',
        skill: 'select-option',
        teamName: '',
        partnerEmail: '',
        subLeagueId: 0,
      });
    });
  };

  render() {
    const { playType, genderType, location, league, skill, subLeagueId } = this.state;
    let { email, teamName, partnerEmail } = this.state;
    const locationsArray = master.locations;
    const regionsArray = master.regions;
    const skillsArray = master.skills;
    const genderArray = master.leagueType;

    const filteredRegion = regionsArray.filter(region => region.location === location);
    const showRegion = location !== 'select-option';

    const isInvalid =
      email === '' ||
      playType === 'select-option' ||
      location === 'select-option' ||
      league === 'select-option' ||
      skill === 'select-option' ||
      subLeagueId === 0;

    // Sanitize inputs
    email = sanitizeInput({ string: email, type: 'email' });
    teamName = sanitizeInput({ string: teamName });
    partnerEmail = sanitizeInput({ string: partnerEmail, type: 'email' });

    return (
      <Mutation
        mutation={PROVIDE_USER_LEAGUE}
        variables={{
          email,
          playType,
          genderType,
          location: league,
          skill,
          teamName,
          partnerEmail,
          subLeagueId: +subLeagueId,
        }}
        refetchQueries={() => [{ query: GET_ALL_SUBLEAGUE }, { query: GET_USER_PLAY_INFO, variables: { email } }]}
      >
        {(provideUserLeague, { error }) => (
          <form onSubmit={event => this.onSubmit(event, provideUserLeague)} className="first-form">
            <div className="heading">
              <FontAwesomeIcon icon={faUserShield} /> <h3>Provide League Access</h3>
            </div>
            <div className="form-content">
              <div className="field">
                <p>Email</p>
                <input name="email" value={email} onChange={this.onChange} placeholder="Input email here" />
              </div>
              <div className="field">
                <p>PlayType</p>
                <select name="playType" value={playType} onChange={this.onChange}>
                  <option disabled value="select-option">
                    Select Option
                  </option>
                  <option value="singles">singles</option>
                  <option value="doubles">doubles</option>
                </select>
              </div>

              {/* Display only if "singles" option is chosen */}
              {playType === 'singles' && (
                <div className="field">
                  <p>Gender</p>
                  <select name="genderType" value={genderType} onChange={this.onChange}>
                    <option disabled value="select-option">
                      Select Option
                    </option>
                    {/* Loop through all genderTypes */}
                    {genderArray.map((type, typeIndex) => {
                      const typeRenamed = type.toLowerCase();
                      return (
                        <option key={typeIndex} value={typeRenamed}>
                          {typeRenamed}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              {/* Display only if "doubles" option is chosen */}
              {playType === 'doubles' && (
                <Fragment>
                  <div className="field">
                    <p>Team Name</p>
                    <input name="teamName" value={teamName} onChange={this.onChange} />
                  </div>
                  <div className="field">
                    <p>Partner Email</p>
                    <input name="partnerEmail" value={partnerEmail} onChange={this.onChange} />
                  </div>
                </Fragment>
              )}

              {/* For the location fields */}
              <div className="field">
                <p>Location</p>
                <select name="location" value={location} onChange={this.onChange}>
                  <option disabled value="select-option">
                    Select Option
                  </option>
                  {locationsArray.map(aLocation => {
                    const joined = aLocation.replace(/\s+/g, '-').toLowerCase();
                    return (
                      <option key={joined} value={aLocation}>
                        {aLocation}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* For the region selection -> only activate/show if a "location" field is chosen. */}
              {/* For the location fields */}
              {showRegion && (
                <div className="field">
                  <p>Region</p>
                  <select name="league" value={league} onChange={this.onChange}>
                    <option disabled value="select-option">
                      Select Option
                    </option>
                    {filteredRegion[0].inner.map(region => {
                      const joined = region.replace(/\s+/g, '-').toLowerCase();
                      return (
                        <option key={joined} value={region}>
                          {region}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              {/* For the level fields */}
              <div className="field">
                <p>Level</p>
                <select name="skill" value={skill} onChange={this.onChange}>
                  <option disabled value="select-option">
                    Select Option
                  </option>
                  {skillsArray.map(aSkill => {
                    const skillName = aSkill.name;
                    const joined = skillName.replace(/\s+/g, '-').toLowerCase();
                    return (
                      <option key={joined} value={skillName}>
                        {skillName}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* For the subleague Id field */}
              <div className="field">
                <p>SubLeagueId</p>
                <input type="number" name="subLeagueId" value={subLeagueId} onChange={this.onChange} />
              </div>
            </div>

            <Button normal type="submit" disabled={isInvalid}>
              Update
            </Button>
            {error && <ErrorMessage error={error} />}
          </form>
        )}
      </Mutation>
    );
  }
}

export default ProvideLeagueAccess;
