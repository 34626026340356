import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Hashids from 'hashids';

import * as routes from '../../../../constants/routes';
import MessageQuery from './messageQuery';
import { Flex } from '../../../Universal/style';

const hashids = new Hashids('fleximazing', 10);

class MessageScreenTeam extends Component {
  componentDidMount() {
    // The logic here is to prevent other URL's to be used if the opponent is not part of the season fixture.
    // If the opponent URL is not part of the fixture then ----> redirect to the parent message screen.
    const { match, getAllDoublesFixture, teamOwnerId, session } = this.props;
    const { team } = match.params;
    const opponentTeamIdDecoded = hashids.decode(team);

    // Check if the opponent team link matches the opponents in the current season fixture.
    const opponentMatch = getAllDoublesFixture.some(fixture => +fixture.opponentTeamId === opponentTeamIdDecoded[0]);

    // If the above logic doesn't match ---> then redirect to the message parent screen
    // Redirect if user is not owner of team
    if (!opponentMatch || teamOwnerId !== session.me.id) {
      this.props.history.push(routes.DOUBLES_MESSAGE);
    }
  }

  render() {
    // Grab the encoded opponent team Id and decode it from the url params.
    const { match, currentTeamId, currentTeamName } = this.props;
    const { team } = match.params;
    const opponentTeamIdDecoded = hashids.decode(team);

    return (
      // Query the search for the team channel Id -> then query the team messages with the team channel Id. If there is a team channel Id then it definitely means that there are messages for both parties.
      <Flex noWrap column between noCenter flex={'1 auto'} padding={'20px 20px 20px 0'} className="message-section">
        <MessageQuery
          opponentTeamIdDecoded={opponentTeamIdDecoded}
          currentTeamId={currentTeamId}
          currentTeamName={currentTeamName}
        />
      </Flex>
    );
  }
}

export default withRouter(MessageScreenTeam);
