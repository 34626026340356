import gql from 'graphql-tag';

export const GET_USER_PLAY_INFO = gql`
  query($email: String!) {
    getUserPlayInfo(email: $email) {
      season
      year
      genderType
      playType
      paid
      userId
      name
      partnerEmail
      ownerId
      subLeagueId
      leagueId
      firstname
      lastname
      role
    }
  }
`;

export const PROVIDE_USER_LEAGUE = gql`
  mutation(
    $email: String!
    $playType: String!
    $genderType: String
    $location: String!
    $skill: String!
    $teamName: String
    $partnerEmail: String
    $subLeagueId: Int!
  ) {
    provideUserLeague(
      email: $email
      playType: $playType
      genderType: $genderType
      location: $location
      skill: $skill
      teamName: $teamName
      partnerEmail: $partnerEmail
      subLeagueId: $subLeagueId
    )
  }
`;

export const REMOVE_MEMBER_ACCESS = gql`
  mutation($email: String!, $type: String!) {
    removeMemberAccess(email: $email, type: $type)
  }
`;
