import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import { Mutation } from '@apollo/react-components';
import Tilt from 'react-tilt';

import { SignInForm } from '../SignIn';
import isLoggedIn from '../Session/isLoggedIn';
import AutomaticBackground from '../SignIn/automaticBackground';
import Footer from '../Footer';
import { RESEND_CONFIRMATION_MUTATION } from './signupForm';

import Loading from '../Loading';
import { SignInWidth } from '../SignIn/style';
import { Green } from './style';
import { Notification } from './style';
import { Flex } from '../Universal/style';
import logo from '../../resources/images/logo-white-bk-circle.png';

const CONFIRMATION_TOKEN = gql`
  mutation($token: String!) {
    confirmationToken(token: $token) {
      result
      theUser {
        id
        email
      }
    }
  }
`;

class Confirmation extends Component {
  constructor() {
    super();
    this.state = {
      sentConfirmation: false,
    };
  }

  sentConfirmation = async resendConfirmation => {
    await resendConfirmation();
    this.setState(prev => ({ sentConfirmation: !prev.sentConfirmation }));
  };

  render() {
    const { sentConfirmation } = this.state;
    const { match, history, refetch } = this.props;
    let { token } = match.params;
    if (!token) token = '';

    return (
      <AutomaticBackground>
        <SignInWidth>
          <Flex noWrap between>
            <h1>Confirmation</h1>
            <Tilt className="Tilt" options={{ max: 35, scale: 1.05 }}>
              <div className="Tilt-inner">
                <img style={{ width: '80px' }} src={logo} alt="Logo" />
              </div>
            </Tilt>
          </Flex>
          <Mutation mutation={CONFIRMATION_TOKEN} variables={{ token }}>
            {(confirmationToken, { data, loading, error }) => (
              <StartMutationOnMount confirmationToken={confirmationToken}>
                {loading && <Loading />}
                {data && data.confirmationToken && (
                  <div style={{ marginBottom: '20px' }}>
                    {data.confirmationToken.result ? (
                      <Fragment>
                        <Notification>
                          <li className="normal">
                            Awesome, your email has been <Green>verified!</Green> You can now login below.
                          </li>
                        </Notification>
                        <SignInForm history={history} refetch={refetch} />
                      </Fragment>
                    ) : (
                      <Fragment>
                        {data.confirmationToken.theUser && (
                          /* Mutation resolver for re-sending confirmation email. */
                          <Mutation
                            mutation={RESEND_CONFIRMATION_MUTATION}
                            variables={{ userId: data.confirmationToken.theUser.id }}
                          >
                            {resendConfirmation => (
                              <Notification>
                                <li>
                                  The token is no longer valid.{' '}
                                  {sentConfirmation ? (
                                    <div style={{ marginTop: '10px' }} className="send-confirmation-button no-hover">
                                      Confirmation has been sent! Please click on the link to verify.
                                    </div>
                                  ) : (
                                    <div
                                      style={{ marginTop: '10px' }}
                                      className="send-confirmation-button"
                                      onClick={() => this.sentConfirmation(resendConfirmation)}
                                    >
                                      Click here to resend the confirmation email to{' '}
                                      {data.confirmationToken.theUser.email}.
                                    </div>
                                  )}
                                </li>
                              </Notification>
                            )}
                          </Mutation>
                        )}
                      </Fragment>
                    )}
                  </div>
                )}
                {error && (
                  <div style={{ marginBottom: '20px' }}>
                    <Notification>
                      <li>The token is no longer valid.</li>
                    </Notification>
                  </div>
                )}
              </StartMutationOnMount>
            )}
          </Mutation>
        </SignInWidth>
        <Footer />
      </AutomaticBackground>
    );
  }
}

class StartMutationOnMount extends Component {
  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.props.confirmationToken();
    }, 1000);
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
  }
  render() {
    return this.props.children;
  }
}

export default withRouter(isLoggedIn(session => session && session.me)(Confirmation));
