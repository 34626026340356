import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Hashids from 'hashids';

import * as routes from '../../../../constants/routes';
import MessageQuery from './messageQuery';
import { Flex } from '../../../Universal/style';

const hashids = new Hashids('fleximazing', 10);

class MessageScreen extends Component {
  componentDidMount() {
    const { match, doubles } = this.props;
    const { user } = match.params;
    const opponentIdDecoded = hashids.decode(user);

    if (!doubles) {
      // The logic here is to prevent other URL's to be used if the opponent is not part of the season fixture.
      // If the opponent URL is not part of the fixture then ----> redirect to the parent message screen.
      const { getAllFixture } = this.props;

      // Check if the opponent link matches the opponents in the current season fixture.
      const opponentMatch = getAllFixture.some(fixture => +fixture.opponentId === opponentIdDecoded[0]);

      // If the above logic doesn't match ---> then redirect to the message parent screen.
      if (!opponentMatch) {
        this.props.history.push(routes.SINGLES_MESSAGE);
      }
    } else {
      const { partnerData } = this.props;
      if (partnerData && partnerData.getPartnerInfo) {
        const { id } = partnerData.getPartnerInfo;
        // If the params user url does not match the current partner then redirect back to parent message screen.
        if (+id !== +opponentIdDecoded[0]) {
          this.props.history.push(routes.DOUBLES_MESSAGE);
        }
      }
    }
  }

  render() {
    // Grab the encoded opponent Id and decode it from the url params.
    const { match, session, doubles, genderType } = this.props;
    const { user } = match.params;
    const opponentIdDecoded = hashids.decode(user);

    return (
      // Query the search for the channel Id -> then query the direct messages with the channel Id. If there is a channel Id then it definitely means that there are messages for both parties.
      <Flex noWrap column between noCenter flex={'1 auto'} padding={'20px 20px 20px 0'} className="message-section">
        <MessageQuery
          opponentIdDecoded={opponentIdDecoded}
          session={session}
          doubles={doubles}
          genderType={genderType}
        />
      </Flex>
    );
  }
}

export default withRouter(MessageScreen);
