export const LANDING = '/register';
export const SINGLES_REG = '/register/singles';
export const DOUBLES_REG = '/register/doubles';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/account';
export const CONFIRMATION = '/confirmation/:token';
export const FORGOT_PASSWORD = '/forgot';
export const RESET = '/forgot/:token';
export const TEAM_INVITE = '/team-invite/:token';
export const PAST_RESULTS = '/past-results';
export const ALL_PAST_WINNERS = '/past-winners';
export const LIFETIME = '/lifetime-leaderboard';

export const ADMIN = '/admin';
export const ADMIN_DATE_MANAGEMENT = '/admin/date-management';
export const CUSTOM_EMAIL_PROMOTION = '/admin/custom-email-promotion';
export const FREE_ACCESS = '/admin/member-access';
export const ADMIN_FEEDBACKS = '/admin/feedbacks';
export const LEAGUE_AVAILABILITY = '/admin/league-availability';
export const LEAGUE_REGISTRATION = '/admin/league-registration';
export const PLAYER_DATA = '/admin/player-data';

export const UNSUBSCRIBE = '/unsubscribe/:token';

export const SINGLES = '/singles';
export const SINGLES_DASH = '/singles/dashboard';
export const SINGLES_FIXTURES = '/singles/fixtures';
export const SINGLES_PROFILES = '/singles/fixtures/profile/:player';
export const SINGLES_UPLOAD = '/singles/fixtures/upload/:week';
export const SINGLES_MESSAGE = '/singles/message';
export const SINGLES_MESSAGE_USER = '/singles/message/:user';
export const SINGLES_MESSAGE_REDIRECT = '/singles/redirect/:user';
export const SINGLES_RESULTS = '/singles/results';

export const DOUBLES = '/doubles';
export const DOUBLES_TEAM = '/doubles/team';
export const DOUBLES_DASH = '/doubles/dashboard';
export const DOUBLES_FIXTURES = '/doubles/fixtures';
export const DOUBLES_PROFILES = '/doubles/fixtures/profile/:team';
export const DOUBLES_PARTNER_PROFILES = '/doubles/profile/partner/:player';
export const DOUBLES_PLAYER_PROFILES = '/doubles/profile/user/:player/:team';
export const DOUBLES_UPLOAD = '/doubles/fixtures/upload/:week';
export const DOUBLES_MESSAGE = '/doubles/message';
export const DOUBLES_MESSAGE_TEAM = '/doubles/message/team/:team';
export const DOUBLES_MESSAGE_USER = '/doubles/message/partner/:user';
export const DOUBLES_MESSAGE_TEAM_REDIRECT = '/doubles/redirect/:team';
export const DOUBLES_MESSAGE_USER_REDIRECT = '/doubles/partner/redirect/:user';
export const DOUBLES_RESULTS = '/doubles/results';

export const TEST = '/test';
