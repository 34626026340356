import React from 'react';
import { Mutation } from '@apollo/react-components';
import { ApolloConsumer } from '@apollo/react-common';

import * as routes from '../../constants/routes';
import history from '../../constants/history';

import { USER_SIGN_OUT } from '../Universal/queries';
import { Button } from '../Universal/style';

const SignOutButton = ({ doubles, genderType }) => (
  <ApolloConsumer>
    {client => (
      <Mutation mutation={USER_SIGN_OUT}>
        {offlineSignout => (
          <Button
            className="header-signout-button"
            doubles={doubles}
            genderType={genderType}
            normal
            noMargin
            type="button"
            onClick={() => signOut(client, offlineSignout)}
          >
            Sign Out
          </Button>
        )}
      </Mutation>
    )}
  </ApolloConsumer>
);

const signOut = async (client, offlineSignout) => {
  if (offlineSignout) {
    await offlineSignout();
  }

  // Remove authentication token from the local storage
  localStorage.removeItem('token');

  // Removes all data from the data -> the queried data and DOESN'T refetch anything -> after that, redirect to the sign-in screen
  client.clearStore().then(() => {
    history.push(routes.SIGN_IN);
  });
};

export { signOut };

export default SignOutButton;
