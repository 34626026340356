import React, { Fragment } from 'react';
import { Button } from '../../../../Universal/style';

// Generate fixture button appears if fixture has not been generated after the season has started
const GenerateFixture = ({ closed, generateFixture, adminGetAllFixture }) => {
  // If there is content in the Fixture table, then return nothing.
  // If there is nothing in the array, then show the button to "generate" fixture.
  // First checking if season is closed --> if not, don't display anything. Only display status when we are inside season date.
  if (closed) {
    if (adminGetAllFixture && adminGetAllFixture.length === 0) {
      return (
        // Showing status bar and button if fixture hasn't been generated
        <Fragment>
          <div className="status-bar empty">Not yet generated</div>
          <Button onClick={() => generateFixture()}>Generate Fixture</Button>
        </Fragment>
      );
    } else {
      return <div className="status-bar active">Singles Generated</div>;
    }
  } else {
    return '';
  }
};

export default GenerateFixture;
