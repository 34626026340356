import styled, { theme } from '../../../theme';

export const ResultsWrapper = styled.div`
  .scroll-wrapper {
    overflow-y: scroll;
    max-height: 650px;

    .all-results-wrapper {
      border-bottom: 1px solid ${theme.breakLine};
      padding-bottom: 20px;

      :last-child {
        border-bottom: none;
      }
      .result-wrapper {
        display: flex;
        flex-flow: row wrap;

        .the-result {
          display: flex;
          align-items: center;
          padding: 10px 16px;
          border: 1px solid ${theme.breakLine};
          margin: 5px;
          border-radius: 3px;

          .player-info {
            width: 160px;
            margin-right: 40px;

            .player-wrapper {
              display: flex;
              align-items: center;

              .image-overflow {
                flex: 0 0 16%;
                overflow: hidden;
                width: 25px;
                height: 25px;
                margin-right: 10px;

                img {
                  width: 100%;
                }
              }
              p {
                word-wrap: break-word;
                width: 120px;
                max-width: 100%;
              }
            }
          }
        }
        .scores {
          display: flex;

          .the-score {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 5px;
            background-color: #fafafa;
            padding: 2px 4px;
            border-radius: 4px;
            p {
              margin: 5px 0;

              :first-child {
                color: ${theme.orange};
              }
            }
            :last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
`;
