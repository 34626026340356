import Color from 'color';
import styled, { css, theme } from '../../theme';
import rightBack from '../../resources/images/right-background.jpg';

// Slight margin, padding adjustments
export const Adjust = styled.div`
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `};
  ${props =>
    props.MT &&
    css`
      margin-top: ${props.MT};
    `};
  ${props =>
    props.MR &&
    css`
      margin-right: ${props.MR};
    `};
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
  ${props =>
    props.fullWidth &&
    css`
      max-width: 100%;
    `};
  ${props =>
    props.relative &&
    css`
      position: relative;
    `};
  ${props =>
    props.MB &&
    css`
      margin-bottom: ${props.MB};
    `};
  ${props =>
    props.noOutline &&
    css`
      outline: none;
    `};
  ${props =>
    props.cursor &&
    css`
      cursor: pointer;
    `};
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `};
`;

// flex, col, width
export const Flex = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  ${props =>
    props.grow &&
    css`
      flex: 1 auto;
    `};
  ${props =>
    props.noWrap &&
    css`
      flex-flow: unset;
    `};
  ${props =>
    props.alignCenter &&
    css`
      align-items: center;
    `};
  ${props =>
    props.column &&
    css`
      flex-direction: column;
    `};
  ${props =>
    props.between &&
    css`
      justify-content: space-between;
      align-items: center;
    `};
  ${props =>
    props.noCenter &&
    css`
      align-items: unset;
    `};
  ${props =>
    props.back &&
    css`
      background-color: ${theme.grayBack};
    `};
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};
  ${props =>
    props.MB &&
    css`
      margin-bottom: ${props.MB};
    `};
  ${props =>
    props.MR &&
    css`
      margin-right: ${props.MR};
    `};
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `};
  ${props =>
    props.justify &&
    css`
      justify-content: space-between;
    `};
  ${props =>
    props.fullHeight &&
    css`
      height: 100vh;
    `};
  ${props =>
    props.flex &&
    css`
      flex: ${props.flex};
    `};
  ${props =>
    props.center &&
    css`
      justify-content: center;
    `};
  ${props =>
    props.messageWrapper &&
    css`
      min-height: 480px;
      border: 1px solid #dddddd;
      border-radius: 4px;
    `};
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  .profile-image {
    max-width: 100%;
    border-radius: 4px;
  }
  .weekly-date {
    margin-left: 10px;
  }

  /* For react router dom transition */
  .switch-wrapper {
    position: relative;

    > div {
      position: absolute;
      width: 100%;
    }
  }
`;
export const Col = styled.div`
  display: flex;
  ${props =>
    props.justify &&
    css`
      justify-content: center;
    `};
  ${props =>
    props.column &&
    css`
      flex-direction: column;
    `};
  ${props =>
    props.wrap &&
    css`
      flex-flow: row wrap;
    `};
  ${props =>
    props.noFlex &&
    css`
      display: block;
    `};
  flex: 1 0 ${props => props.digit};
  ${props =>
    props.flex &&
    css`
      flex: ${props.flex};
    `};
  padding: 0 20px;
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `};
  ${props =>
    props.center &&
    css`
      padding: 0 20px 40px 20px;
    `};
  ${props =>
    props.back &&
    css`
      background-color: #fafafa;
    `};
  ${props =>
    props.imageBack &&
    css`
      position: relative;
      background-image: url(${rightBack});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 80% 50%;
      z-index: 0;

      :before {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.65);
        position: absolute;
      }
    `};
`;
export const Width = styled.div`
  margin-bottom: 40px;
  ${props =>
    props.noMB &&
    css`
      margin-bottom: 0;
    `};
  ${props =>
    props.full &&
    css`
      position: relative;
      width: 100%;
    `};
  ${props =>
    props.relative &&
    css`
      position: relative;
    `};
  ${props =>
    props.MT &&
    css`
      margin-top: ${props.MT};
    `};
`;

// buttons and their wrappers
export const ButtonWrapper = styled.div`
  width: 284px;
  display: flex;
  justify-content: space-between;
`;
export const Button = styled.button`
  width: 100%;
  ${props =>
    props.normal &&
    css`
      width: auto;
    `};
  margin-top: 10px;
  padding: 10px 30px;
  font-size: 14px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  color: white;
  background-color: ${theme.mixed.link};
  ${props =>
    props.noMargin &&
    css`
      margin: 0 7px 0 0;
    `};

  &:hover,
  &:focus {
    color: white;
    background-color: ${theme.mixed.linkHover};
  }
  &:disabled {
    background-color: ${theme.mixed.linkDisabled};
  }

  ${props =>
    props.green &&
    css`
      background-color: ${theme.green};
      &:hover,
      &:focus {
        background-color: ${Color(theme.green)
          .lighten(0.3)
          .hex()};
      }
    `};
  ${props =>
    props.red &&
    css`
      background-color: red;
      &:hover,
      &:focus {
        background-color: #ff3232;
      }
    `};
  ${props =>
    props.genderType === 'men' &&
    css`
      background-color: ${theme.men.link};
      &:hover,
      &:focus {
        background-color: ${theme.men.linkHover};
      }
      :disabled {
        background-color: ${theme.men.linkDisabled};
      }
    `};
  ${props =>
    props.genderType === 'women' &&
    css`
      background-color: ${theme.women.link};
      &:hover,
      &:focus {
        background-color: ${theme.women.linkHover};
      }
      :disabled {
        background-color: ${theme.women.linkDisabled};
      }
    `};
  ${props =>
    props.doubles &&
    css`
      background-color: ${theme.doubles.link};
      &:hover,
      &:focus {
        background-color: ${theme.doubles.linkHover};
      }
      :disabled {
        background-color: ${theme.doubles.linkDisabled};
      }
    `};
`;

// font styling
export const P = styled.p`
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `};
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};
  ${props =>
    props.bold &&
    css`
      font-weight: 600;
    `};
  ${props =>
    props.date &&
    css`
      padding: 8px 13px;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      border: 1px solid black;
      border-radius: 3px;
      color: black;
    `};
  ${props =>
    props.MT &&
    css`
      margin-top: ${props.MT};
    `};
  ${props =>
    props.MB &&
    css`
      margin-bottom: ${props.MB};
    `};
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `};
  ${props =>
    props.darkgray &&
    css`
      color: ${Color(theme.boldgray)
        .darken(0.5)
        .hex()};
    `};
  ${props =>
    props.openSans &&
    css`
      font-family: 'open sans';
      font-size: 14px;
      line-height: 21px;
    `};
  ${props =>
    props.fontsize &&
    css`
      font-size: ${props.fontsize};
    `};
  ${props =>
    props.font &&
    css`
      font-family: ${props.font};
    `};
  ${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `};
  ${props =>
    props.lowercase &&
    css`
      text-transform: capitalize;
    `};
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
  ${props =>
    props.roboto &&
    css`
      font-family: 'roboto-condensed';
    `};
  ${props =>
    props.calibri &&
    css`
      font-family: 'calibri', 'open sans';
    `};
  ${props =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid ${theme.breakLine};
    `};

  a.login-links {
    margin-left: 7px;
  }
  span.green {
    color: ${theme.doubles.link};
    display: inline-block;
  }
  span.red {
    color: crimson;
    display: inline-block;
  }
  span.orange {
    color: darkorange;
    display: inline-block;
  }
  span.black {
    color: black;
    display: inline-block;
  }
`;
export const H2 = styled.h2`
  ${props =>
    props.rightSide &&
    css`
      width: 100%;
      font-size: 31px;
      color: white;
    `};
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `};
`;
export const H3 = styled.h3`
  ${props =>
    props.MT &&
    css`
      margin-top: ${props.MT};
    `};
  ${props =>
    props.MB &&
    css`
      margin-bottom: ${props.MB};
    `};
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `};

  span {
    display: inline-block;
    font-family: 'arial';
  }
  span.red {
    color: red;
  }
  div.gray {
    display: inline-block;
    color: ${theme.lightGray};
  }
`;
export const H4 = styled.h4`
  font-size: 16px;
  ${props =>
    props.noMB &&
    css`
      margin: 12px 0 4px 0;
    `};
  ${props =>
    props.fontsize &&
    css`
      font-size: ${props.fontsize};
    `};
  ${props =>
    props.logo &&
    css`
      font-family: 'casanova';
      text-transform: uppercase;
      font-size: 12px;
      margin-top: 7px;
      padding: 7px 0;
      background-color: #fafafa;
    `};
  ${props =>
    props.dbHeading &&
    css`
      font-family: 'casanova';
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 15px;
      color: ${theme.lightGreyText};
    `};
  ${props =>
    props.small &&
    css`
      font-family: 'calibri', 'open sans';
      font-size: 16px;
      text-transform: capitalize;
      color: ${theme.indianRed};
    `};
  ${props =>
    props.MB &&
    css`
      margin-bottom: ${props.MB};
    `};
  ${props =>
    props.MT &&
    css`
      margin-top: ${props.MT};
    `};
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};

  span {
    font-family: 'arial';
    display: inline-block;
  }
`;
export const H5 = styled.h5`
  font-size: 16px;
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
  ${props =>
    props.MB &&
    css`
      margin-bottom: ${props.MB};
    `};
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `};
  ${props =>
    props.fontsize &&
    css`
      font-size: ${props.fontsize};
    `};
  ${props =>
    props.font &&
    css`
      font-family: ${props.font};
    `};

  span {
    font-family: 'arial';
    display: inline-block;
  }
`;
export const Notification = styled.h3`
  font-size: 16px;
  font-weight: normal;
  text-transform: none;
  font-family: 'calibri', 'open sans';
`;

// form sections
export const Form = styled.form`
  display: flex;
  flex-direction: column;

  .input-wrapper {
    position: relative;
    display: flex;

    label {
      position: absolute;
      top: 15px;
      left: 12px;
      pointer-events: none;
      transition: all 0.2s ease-in-out;
    }
    input {
      &::placeholder {
        opacity: 0;
      }
      &:not(:placeholder-shown) + label,
      &:focus + label {
        font-size: 14px;
        top: -3px;
        background-color: white;
        padding: 0 5px;
      }
    }
  }

  .terms-checkbox {
    display: flex;
    align-items: center;

    label {
      position: relative;
      margin-right: 7px;
      height: 12px;
      width: 12px;
      border: 1px solid #1c3b09;
      border-radius: 2px;

      &:hover {
        cursor: pointer;
      }
      input {
        opacity: 0;
        height: 0;
        width: 0;

        :checked {
          + .tick {
            opacity: 1;
          }
        }
      }
      .tick {
        opacity: 0;
        position: absolute;
        top: -3px;
        transition: all 0.1s ease-in-out;
      }
    }
    p {
      font-size: 14px;
      color: black;

      a {
        font-size: 14px;
        font-family: unset;
        font-weight: bolder;
        text-transform: capitalize;
        text-decoration: underline;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
`;
export const InputWrapper = styled.div`
  ${props =>
    props.flex &&
    css`
      display: flex;
      flex-flow: row wrap;
    `}
  margin-bottom: 18px;

  .input-wrapper {
    display: flex;
    flex-direction: column;
    font-family: 'calibri', sans-serif;
    width: 100%;

    &.league-enquiry-link {
      display: inline-block;
      margin: 10px 0;

      a {
        color: blue;
        font-size: 14px;
        font-family: 'calibri', sans-serif;
        text-transform: none;
        text-decoration: underline;
      }
    }

    .fake-select-wrapper {
      position: relative;

      .select-chosen {
        display: flex;
        justify-content: space-between;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 10px 15px;
        cursor: pointer;

        .select-name {
          pointer-events: none;
        }
        svg {
          transition: all 0.3s ease-in-out;
        }

        &:hover,
        &:focus {
          background-color: #eee;
        }
      }
      .options-wrapper {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 2;

        div {
          color: white;
          background-color: ${theme.boldgray};
          border-bottom: 1px solid white;
          padding: 7px 30px;
          cursor: pointer;
          transition: all 0.1s ease-in-out;

          &:hover,
          &:focus {
            background-color: ${props =>
              props.theme === 'doubles'
                ? theme.doubles.link
                : props.theme === 'women'
                ? theme.women.link
                : props.theme === 'men'
                ? theme.men.link
                : theme.mixed.link};
          }
        }
      }

      &.active-options {
        .select-chosen svg {
          transform: rotate(-180deg);
        }
        .options-wrapper {
          display: block;
        }
      }
    }

    .skills-wrapper {
      display: flex;
      margin-bottom: 20px;

      .skill-box {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        padding: 10px 25px;
        margin: 4px;

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }

        input {
          position: absolute;
          border-radius: 4px;
          background-color: #eee;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          margin: 0;
          -webkit-appearance: none;
          outline: none;
          cursor: pointer;
          transition: all 0.1s ease-in-out;

          + .contents-wrapper {
            position: relative;
            z-index: 1;
            pointer-events: none;

            .heading-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .skill-box-selected {
                position: relative;
                text-align: center;
                font-weight: 800;
                color: ${props =>
                  props.theme === 'doubles'
                    ? theme.doubles.link
                    : props.theme === 'women'
                    ? theme.women.link
                    : props.theme === 'men'
                    ? theme.men.link
                    : theme.mixed.link};
                padding: 2px;
                margin-bottom: 0;
              }
            }
          }

          &:not(:checked) {
            &:hover,
            &:focus {
              background-color: ${props =>
                props.theme === 'doubles'
                  ? theme.doubles.link
                  : props.theme === 'women'
                  ? theme.women.link
                  : props.theme === 'men'
                  ? theme.men.link
                  : theme.mixed.link};

              + .contents-wrapper {
                h4,
                p {
                  color: white;
                }
              }
            }
          }

          &:checked {
            border: 3px solid
              ${props =>
                props.theme === 'doubles'
                  ? theme.doubles.link
                  : props.theme === 'women'
                  ? theme.women.link
                  : props.theme === 'men'
                  ? theme.men.link
                  : theme.mixed.link};
          }
        }
      }
    }
  }
`;
export const Select = styled.select`
  display: block;
  border: none;
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 14px;
  cursor: pointer;
`;
export const Input = styled.input`
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
  ${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `};
  ${props =>
    props.MR &&
    css`
      margin-right: ${props.MR};
    `};
  ${props =>
    props.calibri &&
    css`
      font-family: 'calibri', 'open sans';
    `};
  max-width: 100%;
  margin: 5px 0;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  outline: none;
  :disabled {
    color: darkgray;
  }
  ::placeholder {
    font-style: italic;
  }
`;

// Box wrapper to style the league information for paid users
export const SideBoxWrapper = styled.div`
  border-bottom: 1px solid ${theme.breakLine};
  padding-bottom: 30px;
  margin-bottom: 30px;
  ${props =>
    props.borderTop &&
    css`
      padding-top: 6px;
      border-top: 1px solid ${theme.breakLine};
    `};

  /* Right sidebar */
  .the-result {
    display: flex;
    border-bottom: 1px solid ${theme.breakLine};
    padding: 10px 16px;

    :last-child {
      border-bottom: none;
      padding: 10px 16px 0;
    }
    .player-info {
      width: 160px;
      margin-right: 40px;
    }
  }
  .player-wrapper {
    display: flex;
    align-items: center;

    .image-overflow {
      flex: 0 0 16%;
      overflow: hidden;
      width: 25px;
      height: 25px;
      margin-right: 10px;

      img {
        width: 100%;
      }
    }
    p {
      word-wrap: break-word;
      width: 120px;
      max-width: 100%;
    }
  }
  .scores {
    display: flex;
  }
  .the-score {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 5px;
    background-color: #fafafa;
    padding: 2px 4px;
    border-radius: 4px;

    p {
      margin: 5px 0;

      :first-child {
        color: ${theme.orange};
      }
    }
    :last-child {
      margin-right: 0;
    }
  }
`;
export const DetailsBox = styled.div`
  padding: 14px 16px;
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `};
  border: 1px solid ${theme.breakLine};
  border-radius: 5px;
  background-color: white;

  h4.date-name > span {
    text-transform: capitalize;
  }
`;

// Backlink wrapper for directing people back to the original route
export const BackLink = styled.div`
  background-color: ${theme.grayBack};
  padding: 10px 25px;
  margin-top: 20px;
  ${props =>
    props.flex &&
    css`
      display: flex;
    `};
  ${props =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
    `};
  ${props =>
    props.alignCenter &&
    css`
      align-items: center;
    `};

  a {
    color: ${props =>
      props.doubles
        ? theme.doubles.link
        : props.genderType === 'women'
        ? theme.women.link
        : props.genderType === 'men'
        ? theme.men.link
        : theme.mixed.link};
  }
  a:hover,
  a:focus {
    color: ${props =>
      props.doubles
        ? theme.doubles.linkHover
        : props.genderType === 'women'
        ? theme.women.linkHover
        : props.genderType === 'men'
        ? theme.men.linkHover
        : theme.mixed.linkHover};
  }
`;

// Spacing
export const Break = styled.div`
  ${props =>
    props.MT &&
    css`
      margin-top: ${props.MT};
    `};
  ${props =>
    props.MB &&
    css`
      margin-bottom: ${props.MB};
    `};
`;
