import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Query } from '@apollo/react-components';
import 'element-scroll-polyfill';

import { ListContainer, TheMessageWrapper } from './style';
import { Flex } from '../../../Universal/style';
import CreateDirectMessage from './createDM';
import {
  TEAM_CHANNEL_SUBSCRIPTION,
  TEAM_CHANNEL_QUERY,
  TEAM_MESSAGE_SUBSCRIPTION,
  TEAM_MESSAGE_QUERY,
} from '../queries';
import { GET_OTHER_TEAM } from '../../fixtures/queries';

class MessageQuery extends Component {
  render() {
    const { opponentTeamIdDecoded, currentTeamId, currentTeamName } = this.props;

    return (
      // Querying the "channel id" with the opponent team Id as the variable.
      <Query query={TEAM_CHANNEL_QUERY} variables={{ opponentTeamId: opponentTeamIdDecoded[0], teamId: currentTeamId }}>
        {({ data, loading, refetch, subscribeToMore }) => {
          if (loading) {
            return '';
          }
          // If there's data then continue --> if not then return nothing --> if we don't do this then an error will occur on signout
          if (data && data.getTeamChannelId) {
            const { getTeamChannelId } = data;

            return (
              <Fragment>
                {/* Update channel Id to the Query if a new channel has been created for the current user */}
                <GetChannelId
                  channelData={data}
                  subscribeToMore={subscribeToMore}
                  opponentTeamId={opponentTeamIdDecoded[0]}
                  currentTeamId={currentTeamId}
                  refetchMessageQuery={refetch}
                >
                  <ListContainer>
                    {/* Only display the messages if a channel Id exists */}
                    {getTeamChannelId && getTeamChannelId.teamChannelId !== null && (
                      <Query
                        query={TEAM_MESSAGE_QUERY}
                        variables={{
                          teamChannelId: getTeamChannelId.teamChannelId,
                        }}
                      >
                        {({ data, loading, refetch, fetchMore, subscribeToMore }) => {
                          if (loading) {
                            return '';
                          }
                          if (data && data.teamMessages) {
                            const { teamMessages } = data;

                            return (
                              <ListMessages
                                teamMessages={teamMessages.edges}
                                subscribeToMore={subscribeToMore}
                                teamChannelId={getTeamChannelId.teamChannelId}
                                currentTeamId={currentTeamId}
                                currentTeamName={currentTeamName}
                                listMessageRefetch={refetch}
                                fetchMore={fetchMore}
                                teamMessageInfo={teamMessages.teamMessageInfo}
                              />
                            );
                          } else {
                            return '';
                          }
                        }}
                      </Query>
                    )}
                  </ListContainer>
                </GetChannelId>
                {/* This is the form to create a new direct message */}
                <CreateDirectMessage
                  opponentTeamIdDecoded={opponentTeamIdDecoded[0]}
                  getTeamChannelId={getTeamChannelId}
                  currentTeamId={currentTeamId}
                />
              </Fragment>
            );
          } else {
            return '';
          }
        }}
      </Query>
    );
  }
}

class GetChannelId extends Component {
  subscribeToNewTeamChannel = () => {
    this.props.subscribeToMore({
      document: TEAM_CHANNEL_SUBSCRIPTION,
      variables: {
        opponentTeamId: this.props.opponentTeamId,
        teamId: this.props.currentTeamId,
      },
      // The previousResult is the current items in the database.
      // The subscriptionData is the NEW item just added.
      updateQuery: (previousResult, { subscriptionData }) => {
        // If the subcriptionData does not exist - return just the current items in the database.
        if (!subscriptionData.data) {
          return previousResult;
        }

        // If the subcriptionData exists then grab the resolver subscribe "newTeamChannel" data.
        const { newTeamChannel } = subscriptionData.data;

        // create an exact object of the same shape as the initial query data and merge the previous result with the new result.
        return Object.assign({}, previousResult, {
          // getTeamChannelId will return an object as specified in the "teamMessage" schema
          getTeamChannelId: {
            // all previous objects inside previously - compounding each items as more items are added.
            ...previousResult.getTeamChannelId,
            ...newTeamChannel,
          },
        });
      },
    });
  };

  componentDidMount() {
    // Refetch the query on every mounting of this component so that it fetches new channels with the opponent Team Id.
    this.props.refetchMessageQuery();
    this.subscribeToNewTeamChannel();
  }

  render() {
    // If a channel Id exist --> return the children components.
    // If not, return a notification.
    const { getTeamChannelId } = this.props.channelData;
    if (getTeamChannelId && getTeamChannelId.teamChannelId !== null) {
      return this.props.children;
    } else {
      return <p style={{ margin: '0' }}>No messages to show</p>;
    }
  }
}

class ListMessages extends Component {
  // This function will always run as long as the page is active.
  subscribeToTeamMessage = () => {
    this.props.subscribeToMore({
      document: TEAM_MESSAGE_SUBSCRIPTION,
      // Put variables inside the subscribeToMore function.
      variables: { teamChannelId: this.props.teamChannelId },
      // The previousResult is the current items in the database.
      // The subscriptionData is the NEW item just added.
      updateQuery: (previousResult, { subscriptionData }) => {
        // If the subcriptionData does not exist - return just the current items in the database.
        if (!subscriptionData.data) {
          return previousResult;
        }

        // If the subcriptionData exists then grab the resolver subscribe "newTeamMessage" data.
        const { newTeamMessage } = subscriptionData.data;

        // create an exact object of the same shape as the initial query data and merge the previous result with the new result.
        return Object.assign({}, previousResult, {
          // teamMessages will return an array as specified in the "teamMessages" schema
          teamMessages: {
            ...previousResult.teamMessages,
            edges: [...previousResult.teamMessages.edges, newTeamMessage],
            teamMessageInfo: {
              ...previousResult.teamMessages.teamMessageInfo,
            },
          },
        });
      },
    });
  };

  scrollToBottom = () => {
    // Scroll the message history to the bottom by default.
    const node = ReactDOM.findDOMNode(this);
    node.scrollTo(0, node.scrollHeight);
  };

  componentDidMount() {
    this.props.listMessageRefetch();
    this.subscribeToTeamMessage();

    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    // If there are NEW messages then ensure that the scroll directs to the bottom
    if (prevProps.teamMessages) {
      if (prevProps.teamMessages.length !== this.props.teamMessages.length) {
        this.scrollToBottom();
      }
    }
  }

  handleScroll = event => {
    const { hasNextPage, endCursor } = this.props.teamMessageInfo;
    // If the scrollTop position is at 0 and that the "hasNextPage" is true ---> determined at the server in the "teamMessages" resolver.
    if (event.target.scrollTop === 0 && hasNextPage) {
      this.props.fetchMore({
        variables: {
          cursor: endCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult;
          }

          return Object.assign({}, previousResult, {
            // teamMessages "edges" will return an array as specified in the "teamMessages" schema
            teamMessages: {
              ...fetchMoreResult.teamMessages,
              edges: [...previousResult.teamMessages.edges, ...fetchMoreResult.teamMessages.edges],
            },
          });
        },
      });
    }
  };

  render() {
    const { teamMessages, currentTeamId, currentTeamName } = this.props;

    return (
      <TheMessageWrapper id="message-wrapper" onScroll={this.handleScroll}>
        {/* display messages if the messages array is greater than 0 (meaning that there are data inside the array) */}
        {/* before looping the "teamMessages" array, we need to sort the array by "id" from smallest to largest */}
        {teamMessages &&
          teamMessages.length > 0 &&
          teamMessages
            .slice()
            .sort((a, b) => {
              return a.id - b.id;
            })
            .map(message => {
              const { id, text, teamId, createdAt } = message;

              return (
                <div className="message" key={id}>
                  {/* If the "userId" is the current user then display "You" otherwise query the opponent with the userId that didn't match the me.id. */}
                  {teamId === currentTeamId ? (
                    <Fragment>
                      <Flex between className="name-wrapper current-team">
                        <h4>{currentTeamName}</h4>
                        <DateString createdAt={createdAt} />
                      </Flex>
                      <p>{text}</p>
                    </Fragment>
                  ) : (
                    <OpponentMessage id={id} text={text} opponentTeamId={teamId} createdAt={createdAt} />
                  )}
                </div>
              );
            })}
      </TheMessageWrapper>
    );
  }
}

const OpponentMessage = ({ text, opponentTeamId, createdAt }) => (
  <Query query={GET_OTHER_TEAM} variables={{ id: opponentTeamId }}>
    {({ data, loading }) => (
      <Fragment>
        {loading && <p>Loading...</p>}
        {data && data.getOtherTeam && (
          <Fragment>
            <Flex between className="name-wrapper">
              <h4>{data.getOtherTeam.name}</h4>
              <DateString createdAt={createdAt} />
            </Flex>
            <p>{text}</p>
          </Fragment>
        )}
      </Fragment>
    )}
  </Query>
);

const DateString = ({ createdAt }) => {
  const date = new Date(createdAt);

  return (
    <div className="date-created">
      {date.toLocaleTimeString()} | {date.toLocaleDateString()}
    </div>
  );
};

export default MessageQuery;
