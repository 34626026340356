import React, { Component, Fragment } from 'react';
import { Query } from '@apollo/react-components';
import 'element-scroll-polyfill';

import { Flex, P, H2, H3, Adjust } from '../../../Universal/style';
import { ScoresPendingWrapper, PendingBox } from './style';
import { OPPONENT_QUERY } from '../../../Universal/queries';
import { ScrollIndicator } from '../style';

class CheckFinalScore extends Component {
  // For the scroll handling -- START
  constructor() {
    super();
    this.state = {
      hasScroll: false,
      topPosition: 0,
      leftPosition: 0,
      indicatorHeight: 0,
      arrowBorder: 0,
    };
  }

  scrollElement = element => {
    if (element) {
      this.element = element;
      const clientWidth = element.clientWidth;
      const scrollWidth = element.scrollWidth;
      const topPosition = element.clientHeight / 2;

      if (scrollWidth > clientWidth) {
        this.setState({
          hasScroll: true,
          topPosition,
          indicatorHeight: element.clientHeight / 3,
          arrowBorder: element.clientHeight / 27,
        });
      }
    }
  };

  handleOnScroll = event => {
    const { clientWidth, scrollLeft, scrollWidth } = event.target;

    this.setState({ leftPosition: scrollLeft });
    if (scrollLeft === scrollWidth - clientWidth) {
      this.setState({ hasScroll: false });
    } else {
      this.setState({ hasScroll: true });
    }
  };

  handleRightShift = () => {
    const scrollWidth = this.element.scrollWidth;
    this.element.scrollTo(scrollWidth, 0);
    this.setState({ hasScroll: false });
  };
  // For the scroll handling -- END

  componentDidMount() {
    this.props.refetch();
  }

  render() {
    // For scroll handling
    const { hasScroll, topPosition, leftPosition, indicatorHeight, arrowBorder } = this.state;
    const halfHeight = indicatorHeight / 2;

    const { getScoreApproval, opponentFirstname, opponentLastname } = this.props;

    // If isApproved is true in the ScoreApproval table - meaning the opponent has accepted the scores then proceed with the below
    // isApproved will only be shown to the user who presses the accept button because the scoreApproval data will be refetched when the
    // approveScore mutation gets executed.
    // "finalScore" comes from the "fixtureUpload" parent index.js file from the "current fixture query".
    if (getScoreApproval && getScoreApproval.length > 0 && getScoreApproval[0].isApproved) {
      const { score, winnerId } = getScoreApproval[0];
      const scoreArray = JSON.parse(score);

      return (
        <Fragment>
          <P color={'#484848'} fontsize={'23px'} MT={'30px'} MB={'10px'}>
            Scores vs{' '}
            <span style={{ color: '#484848', display: 'inline-block' }}>
              {opponentFirstname} {opponentLastname}
            </span>
          </P>
          <P color={'green'} MT={'0'} MB={'20px'}>
            Scores were accepted and will appear in the Results section. They will also reflect your ranking in the
            leaderboard.
          </P>
          <ScoresPendingWrapper borderColor={'green'} style={{ display: 'inline-grid' }}>
            <div
              style={{ overflowX: 'scroll', position: 'relative' }}
              ref={this.scrollElement}
              onScroll={this.handleOnScroll}
            >
              <Flex MT={'30px'} alignCenter noWrap>
                <Flex style={{ marginRight: '10px', width: '300px' }} grow>
                  <Fragment>
                    <H2 width={'100%'} margin={'0 0 10px 0'} color={'#484848'}>
                      Winning Player:
                    </H2>
                    <Query query={OPPONENT_QUERY} variables={{ id: +winnerId }}>
                      {({ data, loading }) => {
                        if (loading) {
                          return <p>Loading...</p>;
                        }
                        if (data && data.user) {
                          const { firstname, lastname } = data.user;
                          return (
                            <H3 margin={'0'} color={'green'}>
                              {firstname} {lastname}
                            </H3>
                          );
                        }
                        return '';
                      }}
                    </Query>
                  </Fragment>
                </Flex>
                <Flex center alignCenter noWrap>
                  {scoreArray.map((eachScore, i) => (
                    <Adjust key={i} MR={'5px'}>
                      <PendingBox MB={'5px'} border={'green'}>
                        {eachScore[0]}
                      </PendingBox>
                      <PendingBox border={'green'}>{eachScore[1]}</PendingBox>
                    </Adjust>
                  ))}
                </Flex>
              </Flex>
              {hasScroll && (
                <ScrollIndicator
                  top={topPosition - halfHeight}
                  right={leftPosition}
                  height={indicatorHeight}
                  arrowBorder={arrowBorder}
                  onClick={() => this.handleRightShift()}
                >
                  <div className="scroll-indicator"></div>
                </ScrollIndicator>
              )}
            </div>
          </ScoresPendingWrapper>
        </Fragment>
      );
    } else {
      return this.props.children;
    }
  }
}

export default CheckFinalScore;
