import React from 'react';
import { Mutation } from '@apollo/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import { EmailSetting } from './style';
import { ALLOW_EMAIL_SWITCH_MUTATION } from './queries';
import { GET_ME } from '../Session/queries';

// Component for email setting -> turning the message email notification on/off
const EmailSettingSection = ({ allowEmail }) => (
  <Mutation mutation={ALLOW_EMAIL_SWITCH_MUTATION} refetchQueries={() => [{ query: GET_ME }]}>
    {allowEmailSwitch => (
      <EmailSetting>
        <h4>
          <FontAwesomeIcon icon={faCog} /> Email Setting
        </h4>
        <p>Message Notification: {allowEmail ? 'On' : 'Off'}</p>
        <label>
          <input type="checkbox" defaultChecked={allowEmail} onClick={() => allowEmailSwitch()} />
          <span></span>
        </label>
      </EmailSetting>
    )}
  </Mutation>
);

export default EmailSettingSection;
