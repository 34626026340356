import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Tilt from 'react-tilt';

import { SignUpLink } from '../SignUp';
import * as routes from '../../constants/routes';
import isLoggedIn from '../Session/isLoggedIn';
import { ForgotLink } from '../Forgot';
import { signOut } from '../SignOut';
import SignInForm from './loginForm';
import AutomaticBackground from './automaticBackground';
import logo from '../../resources/images/logo-white-bk-circle.png';
import Footer from '../Footer';

import { Flex, P } from '../Universal/style';
import { SignInWidth } from './style';

class SignInPage extends Component {
  componentDidMount() {
    signOut(this.props.client);
    localStorage.clear();
  }

  render() {
    const { history, refetch } = this.props;
    return (
      <Fragment>
        <AutomaticBackground>
          <SignInWidth>
            <Flex noWrap between>
              <h1>SignIn</h1>
              <Tilt className="Tilt" options={{ max: 35, scale: 1.05 }}>
                <div className="Tilt-inner">
                  <img style={{ width: '80px' }} src={logo} alt="Logo" />
                </div>
              </Tilt>
            </Flex>
            <SignInForm history={history} refetch={refetch} />
            <Flex between>
              <SignUpLink />
              <ForgotLink />
            </Flex>
          </SignInWidth>
          <Footer />
        </AutomaticBackground>
      </Fragment>
    );
  }
}

const SignInLink = () => (
  <P openSans color={'black'}>
    Already have an account?{' '}
    <Link className="login-links outer-link" to={routes.SIGN_IN}>
      Sign In
    </Link>
  </P>
);

export default withRouter(isLoggedIn(session => session && session.me)(SignInPage));

export { SignInForm, SignInLink };
