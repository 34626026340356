import React from 'react';

import Navigation from '../Navigation';
import LeftsideBar from '../Universal/LeftsideBar';
import RightsideBar from '../Universal/RightsideBar';
import Footer from '../Footer';
import { Flex, Col, Width, Break } from '../Universal/style';
import { RegisterWrapperStyling } from '../Register/style';
import { MobileLogo } from '../Universal/Mobile';

const DashboardLayout = ({ session, relative, noWrap, admin, seasonDates, classExist, breakExist, children }) => {
  const setRelative = false || relative;
  const setNoWrap = false || noWrap;
  const setAdmin = false || admin;
  const inputClass = '' || classExist;

  return (
    <RegisterWrapperStyling>
      <div className="extra-wrapper">
        <Flex justify grow noWrap={setNoWrap} className={inputClass}>
          <Col digit={'18%'} back className="sidebar-columns sidebar-column-one">
            <LeftsideBar session={session} seasonDates={seasonDates} admin={setAdmin} />
          </Col>
          <Col digit={'50%'} className="main-columns">
            <Width full relative={setRelative} className="inner-main-columns">
              {/* For mobile --> logo */}
              {session && session.me && <MobileLogo genderType={session.me.genderType} type={session.me.playType} />}
              {/* For mobile --> logo */}
              <Navigation session={session} />

              {/* To create space if component needs it */}
              {breakExist && <Break MT={'40px'}></Break>}

              {/* Outputting the inner components that this component is wrapping */}
              {children}
            </Width>
          </Col>
          <Col digit={'18%'} back className="sidebar-columns sidebar-column-two">
            <RightsideBar session={session} />
          </Col>
        </Flex>
        <Footer />
      </div>
    </RegisterWrapperStyling>
  );
};

export default DashboardLayout;
