import React, { Component, Fragment } from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Query } from '@apollo/react-components';
import { Image, Transformation } from 'cloudinary-react';
import Hashids from 'hashids';

import * as routes from '../../../constants/routes';
import { Flex, Col, BackLink, H4, P, Button } from '../../Universal/style';
import placeholderProfile from '../../../resources/images/placeholder-profile.jpg';
import { GET_OTHER_TEAM, GET_OPPONENT_TEAM_PLAYERS } from './queries';
import { TeamPlayersWrapper } from './style';

const hashids = new Hashids('fleximazing', 10);

class FixtureProfile extends Component {
  componentDidMount() {
    const { fixtureData, match, history } = this.props;
    const { team } = match.params;
    const teamId = hashids.decode(team);

    // Check if there's data in the "fixtureData" array.
    if (fixtureData && fixtureData.getAllDoublesFixture && fixtureData.getAllDoublesFixture.length > 0) {
      // Check if "teamId" is part of the current "Fixture".
      const teamExist = fixtureData.getAllDoublesFixture.some(fixture => +fixture.opponentTeamId === +teamId[0]);

      if (!teamExist) {
        history.push(routes.DOUBLES_FIXTURES);
      }
    }
  }

  render() {
    const { team } = this.props.match.params;
    const chatLink = `/doubles/message/team/${team}`;
    const teamId = hashids.decode(team);
    const { session, teamOwnerId } = this.props;

    return (
      <Query query={GET_OTHER_TEAM} variables={{ id: teamId[0] }}>
        {({ data, loading }) => {
          // If the opponent team page is loading the fixture data then show the loading
          // When the data is received then display the contents of the page
          // If there is no data, then redirect to the fixture main page
          if (loading) {
            return <h3>Loading Profile...</h3>;
          } else if (data && data.getOtherTeam) {
            const { name, profileImage, tennisCourt, playTime } = data.getOtherTeam;
            return (
              <Fragment>
                <BackLink doubles={true}>
                  <Link to={routes.DOUBLES_FIXTURES} className="singles-back-link">
                    <FontAwesomeIcon icon={faArrowCircleLeft} /> Back to Fixtures
                  </Link>
                </BackLink>
                {data && data.getOtherTeam && (
                  <div>
                    <div>
                      <Flex justify>
                        <h1>{name}</h1>
                      </Flex>
                      <Flex className="team-info">
                        <Col
                          digit={'25%'}
                          wrap="true"
                          padding={'0'}
                          className="team-image-wrapper"
                          style={{ marginBottom: '20px' }}
                        >
                          <div>
                            {profileImage ? (
                              <Image
                                className="profile-image"
                                cloudName={process.env.REACT_APP_CLOUD_NAME}
                                publicId={profileImage}
                              >
                                <Transformation width="250" height="250" gravity="faces" crop="fill" />
                              </Image>
                            ) : (
                              <img className="profile-image" src={placeholderProfile} alt="placeholder-profile-flexi" />
                            )}
                          </div>
                        </Col>
                        <Col digit={'60%'}>
                          <Flex column>
                            <H4 noMB MT={'0'} MB={'11px'}>
                              Team Name
                            </H4>
                            <P width={'100%'} MT={'0'} MB={'25px'} calibri fontsize={'16px'}>
                              {name}
                            </P>
                            <H4 noMB MT={'0'} MB={'11px'}>
                              Favourite Tennis Court
                            </H4>
                            <P width={'100%'} MT={'0'} MB={'25px'} calibri fontsize={'16px'}>
                              {tennisCourt ? tennisCourt : 'Information not yet available'}
                            </P>
                            <H4 noMB MT={'0'} MB={'11px'}>
                              Preferred playing day<span>(</span>s<span>)</span> and time<span>(</span>s<span>)</span>
                            </H4>
                            <P width={'100%'} MT={'0'} MB={'25px'} calibri fontsize={'16px'}>
                              {playTime ? playTime : 'Information not yet available'}
                            </P>
                          </Flex>
                        </Col>
                      </Flex>
                      {session.me.id === teamOwnerId && (
                        <Link to={chatLink} style={{ outline: 'none', marginTop: '20px' }}>
                          <Button normal noMargin doubles={true} type="button">
                            Message {name}
                          </Button>
                        </Link>
                      )}
                    </div>
                    <TheTeamPlayers teamId={teamId[0]} />
                  </div>
                )}
              </Fragment>
            );
          } else {
            return <Redirect to={routes.DOUBLES_FIXTURES} />;
          }
        }}
      </Query>
    );
  }
}

// Displaying the players for the opponent team
const TheTeamPlayers = ({ teamId }) => (
  <Query query={GET_OPPONENT_TEAM_PLAYERS} variables={{ id: teamId }}>
    {({ data, loading }) => {
      const encodedTeam = hashids.encode(teamId);
      if (loading) {
        return <h3>Loading players..</h3>;
      }
      if (data && data.getOtherTeamPlayers) {
        const { getOtherTeamPlayers } = data;

        return (
          <TeamPlayersWrapper>
            <h3>Team Members</h3>
            <div className="partner-section">
              {getOtherTeamPlayers.map(object => {
                const { id, profileImage, firstname, lastname } = object;
                const idEncoded = hashids.encode(id);
                return (
                  <div key={id} className="partner-box">
                    <Link to={`/doubles/profile/user/${idEncoded}/${encodedTeam}`}>
                      <div className="partner-image">
                        {profileImage === null ? (
                          <img
                            style={{ width: '100px', height: '80px' }}
                            className="profile-image"
                            src={placeholderProfile}
                            alt="placeholder-profile-flexi"
                          />
                        ) : (
                          <Image
                            className="profile-image"
                            cloudName={process.env.REACT_APP_CLOUD_NAME}
                            publicId={profileImage}
                          >
                            <Transformation width="100" height="80" gravity="faces" crop="fill" />
                          </Image>
                        )}
                      </div>
                      <p>{`${firstname.charAt(0).toUpperCase()}. ${lastname}`}</p>
                    </Link>
                  </div>
                );
              })}
            </div>
          </TeamPlayersWrapper>
        );
      }
      return '';
    }}
  </Query>
);

export default withRouter(FixtureProfile);
