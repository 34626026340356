import React, { Component, Fragment } from 'react';
import { Query } from '@apollo/react-components';
import Flip from 'react-reveal/Flip';

import { FeedbackSection } from '../style';
import { DISPLAY_FEEDBACK_QUERY } from '../queries';
import { OPPONENT_QUERY } from '../../queries';

class FeedbackSlider extends Component {
  sliderRef = element => {
    if (element) {
      // Grab the full width of the container containing the slides.
      const fullWidth = element.scrollWidth;
      // To get the current number of slides, divide by the width of each (slide + the margin right).
      const numberOfSlides = fullWidth / (element.offsetWidth + 20);
      // Grab the first slide -> this is to control the sliding
      const firstSlide = element.firstElementChild;
      let currentSlide = 1;
      let movement = element.offsetWidth + 20;

      // Creating interval to loop through the slides.
      this.interval = setInterval(() => {
        if (currentSlide < numberOfSlides) {
          firstSlide.style.cssText = `margin-left: -${movement}px; transition: all 1s ease-in-out`;
          currentSlide++;
          // increasing the movement value by the same amount of "(element.offsetWidth+20)" for every interval so it moves more to the left
          movement += element.offsetWidth + 20;
        } else {
          // Resetting the default margin left position and the current slide.
          firstSlide.style.cssText = 'margin-left: 0; transition: all 0s;';
          currentSlide = 1;
          movement = element.offsetWidth + 20;
        }
      }, 7000);
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <Query query={DISPLAY_FEEDBACK_QUERY}>
        {({ data, loading }) => (
          <Fragment>
            {loading && ''}
            {data && data.displayFeedback && data.displayFeedback.length > 0 && (
              <Flip left>
                <FeedbackSection>
                  <h5>What players are saying...</h5>
                  <div id="slide" ref={this.sliderRef}>
                    {data.displayFeedback.map((object, feedbackIndex) => (
                      <div key={object.id} className={`feedback-${feedbackIndex}`}>
                        <p className="quote">{object.feedback}</p>
                        <Query query={OPPONENT_QUERY} variables={{ id: object.userId }}>
                          {({ data, loading }) => (
                            <Fragment>
                              {loading && <p>Loading...</p>}
                              {data && data.user && (
                                <p>
                                  - {data.user.firstname} {data.user.lastname}
                                </p>
                              )}
                            </Fragment>
                          )}
                        </Query>
                      </div>
                    ))}
                  </div>
                </FeedbackSection>
              </Flip>
            )}
          </Fragment>
        )}
      </Query>
    );
  }
}

export default FeedbackSlider;
