import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faCaretDown, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { sanitizeInput } from '../../abstracts/helpers';

import { master } from '../../resources/resources';
import Loading from '../Loading';
import ErrorMessage from '../Error';
import { ErrorWrapper, Li } from '../Error/style';
import * as routes from '../../constants/routes';
import StripeSection from './Stripe';
import { Span } from './style';
import { H4, P, ButtonWrapper, Button, InputWrapper, Input, Adjust, BackLink } from '../Universal/style';
import NoPayment from './noPayment';

const UPDATE_USER_LEAGUE = gql`
  mutation(
    $league: String!
    $skill: String!
    $court: String!
    $playing: String!
    $season: String!
    $genderType: String!
  ) {
    updateUserLeague(
      league: $league
      skill: $skill
      court: $court
      playing: $playing
      season: $season
      genderType: $genderType
    )
  }
`;

class SinglesReg extends Component {
  constructor() {
    super();
    this.state = {
      genderType: 'select-option',
    };
  }

  // For custom select -> select change event
  onSelectChange = (stateName, value) => {
    this.setState({ [stateName]: value });
    document.querySelector(`.${stateName}`).parentElement.classList.remove('active-options');
  };

  render() {
    const { genderType } = this.state;
    const { session, userRefetch, customer, season, getSeasonStatus } = this.props;

    return (
      <Fragment>
        <BackLink>
          <Link to={routes.LANDING}>
            <FontAwesomeIcon icon={faArrowCircleLeft} /> Back to register dashboard
          </Link>
        </BackLink>
        <h2>
          Register for: <Span theme={genderType.toLowerCase()}>Singles</Span>
        </h2>
        {session && session.me && (
          <SinglesForm
            session={session}
            userRefetch={userRefetch}
            customer={customer}
            season={season}
            getSeasonStatus={getSeasonStatus}
            genderType={genderType}
            onGenderChange={this.onSelectChange}
          />
        )}
      </Fragment>
    );
  }
}

class SinglesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationState: 'select-option',
      league: 'select-option',
      skill: 'select-option',
      court: this.props.session.me.tennisCourt || '',
      playing: this.props.session.me.playTime || '',
      payment: false,

      genderTypeError: '',
      leagueError: '',
      skillError: '',
    };
  }

  closePaymentWindow = () => {
    this.setState({ payment: false });
  };

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  // For custom select -> function to remove all active classes on fake select element
  resetActiveSelect = (event, fakeSelectAll) => {
    const targetElement = event.target;
    if (!targetElement.classList.contains('select-chosen')) {
      fakeSelectAll.forEach(element => element.classList.remove('active-options'));
    }
  };

  // For custom select -> select click event
  onSelectClick = stateName => {
    // The parent element
    const fakeSelectAll = document.querySelectorAll('.fake-select-wrapper');

    const parentElement = document.querySelector(`.${stateName}`).parentElement;
    // Check if the current parent had the active class -> if so, determine logic in the add active class to toggle no class.
    const currentActiveExisted = parentElement.classList.contains('active-options');

    // Remove active class on all parents before proceeding.
    fakeSelectAll.forEach(element => element.classList.remove('active-options'));

    // Add active class to current element
    if (!currentActiveExisted) {
      parentElement.classList.toggle('active-options');
    }
    // Remove active class on document click
    document.addEventListener('click', event => this.resetActiveSelect(event, fakeSelectAll));
  };

  // For custom select -> select change event
  onSelectChange = (stateName, value) => {
    this.setState({ [stateName]: value });
    // If the state selection is clicked, then it should reset the select value for the region
    if (stateName === 'locationState') {
      this.setState({ league: 'select-option' });
    }
    document.querySelector(`.${stateName}`).parentElement.classList.remove('active-options');
  };

  onSubmit = async (event, updateUserLeague) => {
    event.preventDefault();
    const { league, skill } = this.state;
    const { genderType } = this.props;
    let error = false;

    if (genderType === 'select-option') {
      this.setState({ genderTypeError: 'Please select a league type' });
      error = true;
    }
    if (league === 'select-option') {
      this.setState({ leagueError: 'Please select a league' });
      error = true;
    }
    if (skill === 'select-option') {
      this.setState({ skillError: 'Please select a skill' });
      error = true;
    }

    if (!error) {
      await updateUserLeague().then(async ({ data }) => {
        this.setState({
          payment: true,
        });
      });
    }
  };

  render() {
    const {
      locationState,
      league,
      skill,
      payment,
      genderTypeError,
      leagueError,
      skillError,
    } = this.state;
    let { court, playing } = this.state;

    // Sanitize inputs
    court = sanitizeInput({ string: court });
    playing = sanitizeInput({ string: playing });

    // GenderType is coming from the parent component to control theme colours.
    const { genderType, onGenderChange, getSeasonStatus } = this.props;
    // showGendersRego to enable/disable singles men's / women's registrations
    const { showGendersRego } = getSeasonStatus;

    const invalid = genderType === 'select-option' || league === 'select-option' || skill === 'select-option';
    const errorArray = [genderTypeError, leagueError, skillError];
    const filteredErrors = errorArray.filter(v => v !== '');

    // Filter league type if admin has enabled men + women registrations
    let leagueType = master.leagueType;
    if (!showGendersRego) {
      leagueType = leagueType.filter(type => type === 'Mixed');
    }

    const locations = master.locations;
    const regions = master.regions;
    let regionsActive;
    if (locationState !== 'select-option') {
      regionsActive = regions.filter(region => region.location === locationState)[0].inner;
    }

    // To store the league type AND state location to loop
    const leagueStateArray = [
      {
        title: 'League Type Selection',
        stateName: 'genderType',
        state: genderType,
        options: leagueType,
      },
      {
        title: 'State Selection',
        stateName: 'locationState',
        state: locationState,
        options: locations,
      },
    ];

    const skills = master.skills;

    const { session, season } = this.props;
    // Ensure genderType is all lowercase to pass through to the server
    const editGenderType = genderType.toLowerCase();

    return (
      <Fragment>
        <Mutation
          mutation={UPDATE_USER_LEAGUE}
          variables={{ league, skill, court, playing, season, genderType: editGenderType }}
        >
          {(updateUserLeague, { loading, error }) => (
            // When the user fills the form and submits, it sends to the database and updates the league ID then the "payment" state is set to true so that we can show the stripe form.
            <Adjust relative>
              {payment && (
                <Fragment>
                  {getSeasonStatus.payable && session.me.role !== 'FREE' ? (
                    <StripeSection
                      type={'singles'}
                      customer={this.props.customer}
                      userRefetch={this.props.userRefetch}
                      session={session}
                      season={season}
                      genderType={editGenderType}
                      closePaymentWindow={this.closePaymentWindow}
                      league={league}
                      skill={skill}
                      getSeasonStatus={getSeasonStatus}
                    />
                  ) : (
                    <NoPayment
                      type={'singles'}
                      userRefetch={this.props.userRefetch}
                      session={session}
                      season={season}
                      genderType={editGenderType}
                      closePaymentWindow={this.closePaymentWindow}
                      league={league}
                      skill={skill}
                    />
                  )}
                </Fragment>
              )}
              {season !== null ? (
                <form autoComplete="off" onSubmit={event => this.onSubmit(event, updateUserLeague)}>
                  <InputWrapper flex theme={editGenderType}>
                    {leagueStateArray.map((contents, contentsIndex) => {
                      const { title, stateName, state, options } = contents;
                      return (
                        <Fragment key={contentsIndex}>
                          <SelectWrapper
                            regionsActive={regionsActive}
                            title={title}
                            stateName={stateName}
                            state={state}
                            options={options}
                            onSelectClick={this.onSelectClick}
                            onSelectChange={stateName === 'genderType' ? onGenderChange : this.onSelectChange}
                          />
                        </Fragment>
                      );
                    })}

                    {/* Only display the region field if a STATE location has been chosen */}
                    {regionsActive && (
                      <Fragment>
                        <SelectWrapper
                          title={'Region Selection'}
                          stateName={'league'}
                          state={league}
                          options={regionsActive}
                          onSelectClick={this.onSelectClick}
                          onSelectChange={this.onSelectChange}
                        />

                        {/* External link to FTL website for enquiry form if league is not available */}
                        <ContactLeagueLink />
                      </Fragment>
                    )}

                    <div className="input-wrapper">
                      <SkillsWrapper skills={skills} skill={skill} onChange={this.onChange} />
                    </div>

                    <div className="input-wrapper">
                      <H4 noMB>
                        Where is your favourite tennis court
                        <span>?</span>
                      </H4>
                      <Input
                        name="court"
                        value={court}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Eg. Mt Lawley Tennis Courts"
                      />
                    </div>

                    <div className="input-wrapper">
                      <H4 noMB>
                        What is your preferred playing day<span>(</span>s<span>)</span> and Time<span>(</span>s
                        <span>)?</span>
                      </H4>
                      <Input
                        name="playing"
                        value={playing}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Eg. Tuesdays and Thurdays evening after 7pm"
                      />
                    </div>
                  </InputWrapper>

                  <ButtonWrapper style={{ marginBottom: '30px' }}>
                    <Button genderType={editGenderType} normal disabled={invalid || loading} type="submit">
                      {loading ? <Loading /> : `Register and ${getSeasonStatus.payable ? 'Pay' : 'Confirm'}`}
                    </Button>
                  </ButtonWrapper>

                  {/* Errors from server */}
                  {error && <ErrorMessage error={error} />}
                  {/* Frontend errors */}
                  {filteredErrors.length > 0 && (
                    <ErrorWrapper>
                      {filteredErrors.map((error, i) => (
                        <Li key={i}>{error}</Li>
                      ))}
                    </ErrorWrapper>
                  )}
                </form>
              ) : (
                <P color={'#0080A7'} bold uppercase>
                  Loading...
                </P>
              )}
            </Adjust>
          )}
        </Mutation>
      </Fragment>
    );
  }
}

export const SelectWrapper = ({ title, stateName, state, options, onSelectClick, onSelectChange }) => {
  return (
    <div className="input-wrapper">
      <H4 MB={'10px'}>{title}</H4>

      <div className="fake-select-wrapper">
        <div className={`select-chosen ${stateName}`} onClick={() => onSelectClick(stateName)}>
          <div className="select-name">{state === 'select-option' ? 'Select Option' : state}</div>
          <FontAwesomeIcon icon={faCaretDown} />
        </div>

        <div className="options-wrapper">
          {options.map(option => {
            const joined = option.replace(/\s+/g, '-').toLowerCase();
            return (
              <div key={joined} onClick={() => onSelectChange(stateName, option)}>
                {option}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const SkillsWrapper = ({ skills, skill, onChange }) => (
  <Fragment>
    <H4 MB={'10px'}>Your Skill Level</H4>
    <div className="skills-wrapper">
      {skills.map((theSkill, skillIndex) => {
        const { name, description } = theSkill;

        return (
          <div key={skillIndex} className="skill-box">
            <input type="radio" name="skill" value={name} onChange={onChange} checked={skill === name} />
            <div className="contents-wrapper">
              <div className="heading-wrapper">
                <H4 noMB>{name}</H4>

                {/* If selected -> show */}
                {skill === name && (
                  <div className="skill-box-selected">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </div>
                )}
              </div>
              <p>{description}</p>
            </div>
          </div>
        );
      })}
    </div>
  </Fragment>
);

export const ContactLeagueLink = () => (
  <div className="input-wrapper league-enquiry-link">
    <a href="https://flexitennisleagues.com.au/contact/" target="_blank" rel="noopener noreferrer">
      Don't see your league? Let us know here!
    </a>
  </div>
);

export default SinglesReg;
