import React, { Component, Fragment } from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Query } from '@apollo/react-components';
import { Image, Transformation } from 'cloudinary-react';
import Hashids from 'hashids';

import * as routes from '../../../constants/routes';
import { Flex, Col, BackLink, H4, P, Button } from '../../Universal/style';
import { HeadingSection } from '../../Account';
import placeholderProfile from '../../../resources/images/placeholder-profile.jpg';
import { GET_USER } from '../../Universal/queries';

const hashids = new Hashids('fleximazing', 10);

class FixtureProfile extends Component {
  componentDidMount() {
    const { fixtureData, match, history, doubles, partnerData, partner } = this.props;
    const { player } = match.params;
    const playerId = hashids.decode(player);

    // The "doubles" argument is coming from the directory Doubles -> index.js
    if (!doubles) {
      // Check if there's data in the "fixtureData" array.
      if (fixtureData && fixtureData.getAllFixture && fixtureData.getAllFixture.length > 0) {
        // Check if "playerId" is part of the current "Fixture".
        const playerExist = fixtureData.getAllFixture.some(fixture => +fixture.opponentId === +playerId[0]);

        if (!playerExist) {
          history.push(routes.SINGLES_FIXTURES);
        }
      }
    } else {
      // Redirect back to doubles team page only for the partner.
      // It doesn't matter for the team players profiles in doubles.
      if (partner && partnerData && partnerData.getPartnerInfo) {
        const { id } = partnerData.getPartnerInfo;

        if (+id !== +playerId[0]) {
          this.props.history.push(routes.DOUBLES_TEAM);
        }
      }
    }
  }

  render() {
    const { player, team } = this.props.match.params;
    const chatLink = `/singles/message/${player}`;
    const chatLinkDoubles = `/doubles/message/partner/${player}`;
    const playerId = hashids.decode(player);
    const { genderType, doubles, partner, teamPlayer } = this.props;

    return (
      <Query query={GET_USER} variables={{ id: playerId[0] }}>
        {({ data, loading, error }) => {
          // If the profile page is loading the fixture data then show the loading
          // When the data is received then display the contents of the page
          // If there is no data, then redirect to the fixture main page
          if (loading) {
            return <h3>Loading Profile...</h3>;
          } else if (data && data.user) {
            const { firstname, lastname, profileImage, email, tennisCourt, playTime, about } = data.user;
            return (
              <Fragment>
                <BackLink genderType={genderType} doubles={doubles}>
                  {partner ? (
                    <Link to={routes.DOUBLES_TEAM} className="singles-back-link">
                      <FontAwesomeIcon icon={faArrowCircleLeft} /> Back to Team Details
                    </Link>
                  ) : teamPlayer ? (
                    <Link to={`/doubles/fixtures/profile/${team}`} className="singles-back-link">
                      <FontAwesomeIcon icon={faArrowCircleLeft} /> Back to Fixtures
                    </Link>
                  ) : (
                    <Link to={routes.SINGLES_FIXTURES} className="singles-back-link">
                      <FontAwesomeIcon icon={faArrowCircleLeft} /> Back to Fixtures
                    </Link>
                  )}
                </BackLink>
                {data && data.user && (
                  <div>
                    <Flex justify>
                      <h1>
                        {firstname} {lastname}
                      </h1>
                      <HeadingSection user={data} />
                    </Flex>
                    <Flex className="partner-opponent-profile">
                      <Col digit={'25%'} wrap="true" padding={'0'} style={{ marginBottom: '20px' }}>
                        <div>
                          {profileImage ? (
                            <Image
                              className="profile-image"
                              cloudName={process.env.REACT_APP_CLOUD_NAME}
                              publicId={profileImage}
                            >
                              <Transformation width="250" height="250" gravity="faces" crop="fill" />
                            </Image>
                          ) : (
                            <img className="profile-image" src={placeholderProfile} alt="placeholder-profile-flexi" />
                          )}
                        </div>
                      </Col>
                      <Col digit={'60%'}>
                        <Flex className="inner-details">
                          <Col flex={'0 0 40%'} wrap="true" padding={'0'}>
                            <H4 noMB MT={'0'} MB={'11px'}>
                              First Name
                            </H4>
                            <P width={'100%'} MT={'0'} MB={'25px'} calibri fontsize={'16px'}>
                              {firstname}
                            </P>
                          </Col>
                          <Col flex={'0 0 40%'} wrap="true" padding={'0'}>
                            <H4 noMB MT={'0'} MB={'11px'}>
                              Last Name
                            </H4>
                            <P width={'100%'} MT={'0'} MB={'25px'} calibri fontsize={'16px'}>
                              {lastname}
                            </P>
                          </Col>
                          <Col flex={'0 0 40%'} wrap="true" padding={'0'}>
                            <H4 noMB MT={'0'} MB={'11px'}>
                              Email
                            </H4>
                            <P width={'100%'} MT={'0'} MB={'25px'} calibri fontsize={'16px'}>
                              {email}
                            </P>
                          </Col>
                          <Flex>
                            <Flex>
                              <H4 noMB MT={'0'} MB={'11px'}>
                                Favourite Tennis Court
                              </H4>
                              <P width={'100%'} MT={'0'} MB={'25px'} calibri fontsize={'16px'}>
                                {tennisCourt ? tennisCourt : 'Information not yet available'}
                              </P>
                            </Flex>
                            <Flex>
                              <H4 noMB MT={'0'} MB={'11px'}>
                                Preferred playing day<span>(</span>s<span>)</span> and time<span>(</span>s<span>)</span>
                              </H4>
                              <P width={'100%'} MT={'0'} MB={'25px'} calibri fontsize={'16px'}>
                                {playTime ? playTime : 'Information not yet available'}
                              </P>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Col>
                    </Flex>
                    <div>
                      <H4 noMB MT={'0'} MB={'11px'}>
                        About {firstname}
                      </H4>
                      {about ? (
                        <P openSans width={'100%'} MT={'0'} MB={'25px'} fontsize={'16px'}>
                          {about}
                        </P>
                      ) : (
                        <P width={'100%'} MT={'0'} MB={'25px'} fontsize={'16px'}>
                          Information not yet available
                        </P>
                      )}
                    </div>
                    {!doubles && (
                      <Link to={chatLink} style={{ outline: 'none', marginTop: '20px' }}>
                        <Button normal genderType={genderType} noMargin type="button">
                          Message {firstname}
                        </Button>
                      </Link>
                    )}
                    {/* Display message button for partner */}
                    {partner && (
                      <Link to={chatLinkDoubles} style={{ outline: 'none', marginTop: '20px' }}>
                        <Button normal doubles={true} noMargin type="button">
                          Message {firstname}
                        </Button>
                      </Link>
                    )}
                  </div>
                )}
              </Fragment>
            );
          } else if (!data && doubles) {
            return <Redirect to={routes.DOUBLES_TEAM} />;
          } else {
            return <Redirect to={routes.SINGLES_FIXTURES} />;
          }
        }}
      </Query>
    );
  }
}

export default withRouter(FixtureProfile);
