import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import withAuthorization from '../../Session/withAuthorization';

import * as routes from '../../../constants/routes';
import CreateDates from './datesCreate';
import ShowAllSeasonDates from './dates';
import { BackLink } from '../../Universal/style';
import { DatesMain } from './style';

const DatesPage = () => (
  <Fragment>
    <BackLink>
      <Link to={routes.ADMIN}>
        <FontAwesomeIcon icon={faArrowCircleLeft} /> Back to Admin Page
      </Link>
    </BackLink>

    <h2>Dates Management</h2>
    <DatesMain className="dates-section">
      <CreateDates />
      <ShowAllSeasonDates />
    </DatesMain>
  </Fragment>
);

export default withAuthorization(session => session && session.me && session.me.role === 'ADMIN')(DatesPage);
