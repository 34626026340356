import React, { Component } from 'react';

import { PENDING_SUBSCRIPTION } from '../../../Universal/queries';

class SubscriptionListener extends Component {
  subscribeToPendingApproval = () => {
    this.props.subscribeToMore({
      document: PENDING_SUBSCRIPTION,
      variables: { fixtureId: this.props.fixtureId },
      updateQuery: (previousResult, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousResult;
        }

        return Object.assign({}, previousResult, {
          getScoreApproval: [subscriptionData.data.pendingScoreReceived],
        });
      },
    });
  };
  componentDidMount() {
    this.subscribeToPendingApproval();
  }
  render() {
    return <div></div>;
  }
}

export default SubscriptionListener;
