import styled, { theme, css } from '../../../theme';

export const Table = styled.div`
  display: table;
  width: 100%;
  a p {
    color: ${theme.doubles.link};
  }
  a:hover p {
    color: #52917a;
  }

  .table-row {
    display: table-row;
  }
  .table-row:nth-child(even) {
    background-color: ${theme.grayBack};
  }
  .table-cell {
    display: table-cell;
    padding: 11px 15px;
    text-align: center;

    .user-image-section {
      display: flex;
      align-items: center;
      justify-content: center;

      .image-overflow {
        margin-right: 10px;
        img {
          width: 35px;
        }
      }
      p {
        word-break: break-word;
        width: 120px;
        text-align: left;
      }
    }

    .loading {
      font-size: 12px;
      font-family: 'calibri', 'open sans';
    }
  }
  .align-middle {
    vertical-align: middle;
  }
`;
export const Plus = styled.div`
  margin-left: 10px;
  color: ${theme.blue};
  ${props =>
    props.remove &&
    css`
      margin-top: 5px;
      color: ${theme.crimson};
    `};

  :hover,
  :focus {
    cursor: pointer;
    color: #009ece;
    ${props =>
      props.remove &&
      css`
        color: #cc0000;
      `};
  }
`;
export const Select = styled.select`
  outline: none;
  width: fit-content;
  height: 40px;
  border-radius: 4px;
  border: 1px solid lightgray;
  text-align: center;
  cursor: pointer;
  padding: 0 12px;
  font-size: 16px;
  -webkit-appearance: none;

  ${props =>
    props.MB &&
    css`
      margin-bottom: ${props.MB};
    `};
`;
export const Input = styled.input`
  width: 30px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid lightgray;
  text-align: center;

  ${props =>
    props.MB &&
    css`
      margin-bottom: ${props.MB};
    `};
`;
export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const TextArea = styled.textarea`
  width: 100%;
  max-width: 100%;
  margin: 5px 0;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  outline: none;
  :disabled {
    color: darkgray;
  }
  ::placeholder {
    font-style: italic;
  }
`;

// Upload score link - notification that pops up --> fixture table
export const UploadLinkSection = styled.div`
  .notification-wrapper {
    display: flex;
    color: ${props => props.color};

    .text {
      font-size: 12px;
      font-family: 'calibri', 'open sans';
      margin-right: 5px;
    }
  }
  .singles-link {
    color: ${props => props.color} !important;
  }
`;

// Players for the opponent team section
export const TeamPlayersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 40px;
  border-top: 1px solid ${theme.breakLine};

  .partner-section {
    display: flex;

    .partner-box {
      display: inline-block;
      text-align: center;

      :first-child {
        margin-right: 15px;
      }
      .partner-image {
        width: 100px;
        max-height: 80px;
        overflow: hidden;
        border-radius: 4px;

        .profile-image {
          max-width: 100%;
          border-radius: 4px;
        }
      }
      p {
        text-transform: capitalize;
        color: #086342;
        font-weight: 600;
        margin-bottom: 0;
      }

      :hover {
        opacity: 0.7;
      }
    }
  }
`;
