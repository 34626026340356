import gql from 'graphql-tag';

export const CHECK_PLAYERS_LEAGUE = gql`
  query {
    checkUsersLeague {
      id
      playType
      leagueId
      genderType
      userId
    }
  }
`;

export const CHECK_TEAMS_LEAGUE = gql`
  query {
    checkUsersLeagueDoubles {
      id
      playType
      leagueId
      teamId
    }
  }
`;

export const GET_LEAGUE = gql`
  query($id: ID!) {
    getLeague(id: $id) {
      location
      skill
    }
  }
`;

export const GET_OWNER_FROM_TEAM = gql`
  query($id: ID!) {
    getOwnerFromTeam(id: $id) {
      firstname
      lastname
      email
    }
  }
`;

export const GET_ALL_SUBLEAGUE = gql`
  query {
    getSubleagueInfo {
      id
      playerCount
      playType
      genderType
      subLeagueCount
      leagueId
    }
  }
`;

// Update the users league info
export const UPDATE_USER_LEAGUE = gql`
  mutation(
    $playType: String!
    $genderType: String
    $location: String!
    $skill: String!
    $subLeagueId: Int!
    $userId: ID
    $teamId: ID
  ) {
    updateUserPlay(
      playType: $playType
      genderType: $genderType
      location: $location
      skill: $skill
      subLeagueId: $subLeagueId
      userId: $userId
      teamId: $teamId
    )
  }
`;
