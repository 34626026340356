import React, { Fragment, PureComponent } from 'react';
import { Query } from '@apollo/react-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Flip from 'react-reveal/Flip';

import { H3, H4, DetailsBox, Width, SideBoxWrapper } from '../style';
import { NormalSideBar, HeadingWithTooltip } from './style';
import Leaderboard from './singles/leaderboard';
import WeeklyResults from './singles/weeklyResults';
import WeeklyResultsMobile from './singles/weeklyResultsMobile';
import DoublesLeaderboard from './doubles/leaderboard';
import DoublesWeeklyResults from './doubles/weeklyResults';
import DoublesWeeklyResultsMobile from './doubles/weeklyResultsMobile';
import { MobileLogo } from '../../Universal/Mobile';
import { ALL_PAST_RESULTS_QUERY, PAST_WINNERS_QUERY } from '../../PastResults/queries';
import { ALL_LIFETIME } from '../../Lifetime/queries';
import * as routes from '../../../constants/routes';

const RightsideBar = ({
  mobile,
  session,
  leagueInfoData,
  leagueLoading,
  seasonDates,
  type,
  getPlayInfo,
  teamPlayInfo,
  currentTeamId,
}) => {
  return (
    <Width full>
      {session && session.me && session.me.paid && leagueInfoData ? (
        <Paid
          mobile={mobile}
          leagueInfoData={leagueInfoData}
          leagueLoading={leagueLoading}
          seasonDates={seasonDates}
          type={type}
          currentPlayer={session.me}
          getPlayInfo={getPlayInfo}
          teamPlayInfo={teamPlayInfo}
          currentTeamId={currentTeamId}
        />
      ) : (
        <Normal me={session && session.me} />
      )}
    </Width>
  );
};

class Paid extends PureComponent {
  constructor() {
    super();
    this.state = {
      currentWeek: 0,
      tooltip: false,
    };
  }

  handleTooltip = () => {
    this.setState(prev => ({ tooltip: !prev.tooltip }));
  };

  componentDidMount() {
    const { seasonDates } = this.props;
    let { previousSpring, summer, autumn, winter, spring, nextSummer } = seasonDates;
    const seasonDateArray = [previousSpring, summer, autumn, winter, spring, nextSummer];

    const currentTime = new Date().getTime();

    // Looping through all season dates for the current year.
    seasonDateArray.forEach(season => {
      const week1 = new Date(new Date(season).setDate(new Date(season).getDate() + 7));
      const week2 = new Date(new Date(season).setDate(new Date(season).getDate() + 14));
      const week3 = new Date(new Date(season).setDate(new Date(season).getDate() + 21));
      const week4 = new Date(new Date(season).setDate(new Date(season).getDate() + 28));
      const week5 = new Date(new Date(season).setDate(new Date(season).getDate() + 35));
      const week6 = new Date(new Date(season).setDate(new Date(season).getDate() + 42));
      const week7 = new Date(new Date(season).setDate(new Date(season).getDate() + 49));
      const week8 = new Date(new Date(season).setDate(new Date(season).getDate() + 56));
      const week9 = new Date(new Date(season).setDate(new Date(season).getDate() + 63));
      const week10 = new Date(new Date(season).setDate(new Date(season).getDate() + 70));

      if (new Date(season) <= currentTime && currentTime < week1) {
        this.setState({ currentWeek: 1 });
      } else if (week1 <= currentTime && currentTime < week2) {
        this.setState({ currentWeek: 2 });
      } else if (week2 <= currentTime && currentTime < week3) {
        this.setState({ currentWeek: 3 });
      } else if (week3 <= currentTime && currentTime < week4) {
        this.setState({ currentWeek: 4 });
      } else if (week4 <= currentTime && currentTime < week5) {
        this.setState({ currentWeek: 5 });
      } else if (week5 <= currentTime && currentTime < week6) {
        this.setState({ currentWeek: 6 });
      } else if (week6 <= currentTime && currentTime < week7) {
        this.setState({ currentWeek: 7 });
      } else if (week7 <= currentTime && currentTime < week8) {
        this.setState({ currentWeek: 8 });
      } else if (week8 <= currentTime && currentTime < week9) {
        this.setState({ currentWeek: 9 });
      } else if (week9 <= currentTime && currentTime < week10) {
        this.setState({
          currentWeek: 10,
        });
      }
    });
  }

  render() {
    const {
      mobile,
      leagueInfoData,
      leagueLoading,
      type,
      currentPlayer,
      getPlayInfo,
      teamPlayInfo,
      currentTeamId,
    } = this.props;
    const { currentWeek, tooltip } = this.state;
    const { id } = leagueInfoData.getLeagueInfo;

    return (
      <Fragment>
        <SideBoxWrapper className="leaderboard-wrapper">
          <HeadingWithTooltip gender={getPlayInfo && getPlayInfo.genderType} type={type}>
            <div className="heading-tooltip">
              <H3 MB={'15px'}>Leaderboard</H3>
              <div className="question-icon" onClick={() => this.handleTooltip()}>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </div>
            </div>
            {tooltip && (
              <div className="tooltip">
                <p>
                  Ranking based on points. <br />2 points is allocated to the winner, 1 point for the loser, and 1 point
                  if games are played prior to or on the week of schedule. Grand final winners receive 20 points.
                </p>
              </div>
            )}
          </HeadingWithTooltip>
          <H4 dbHeading small>
            {leagueLoading ? (
              'Loading...'
            ) : leagueInfoData && leagueInfoData.getLeagueInfo ? (
              <LeagueInfo leagueInfoData={leagueInfoData} />
            ) : (
              'Information not yet available'
            )}
          </H4>
          <Flip right>
            <DetailsBox padding={'14px 0'}>
              {type === 'singles' ? (
                <Leaderboard type={type} leagueId={id} currentPlayer={currentPlayer} getPlayInfo={getPlayInfo} />
              ) : (
                <DoublesLeaderboard
                  type={type}
                  leagueId={id}
                  currentTeamId={currentTeamId}
                  teamPlayInfo={teamPlayInfo}
                />
              )}
            </DetailsBox>
          </Flip>
        </SideBoxWrapper>
        <SideBoxWrapper className="results-wrapper">
          <H3 MB={'15px'}>Results</H3>
          <H4 dbHeading small>
            {leagueLoading ? (
              'Loading...'
            ) : leagueInfoData && leagueInfoData.getLeagueInfo ? (
              <LeagueInfo leagueInfoData={leagueInfoData} />
            ) : (
              'Information not yet available'
            )}
          </H4>
          <Flip right>
            <DetailsBox padding={'14px 0'}>
              {/* Fetching weekly results */}
              {type === 'singles' && (
                <Fragment>
                  {mobile ? (
                    // The query for the mobile is exactly like the normal one, just the name has changed so that we prevent calling the same component twice for the subscription since it causes issues
                    <WeeklyResultsMobile
                      type={type}
                      currentWeek={currentWeek}
                      leagueId={id}
                      getPlayInfo={getPlayInfo}
                    />
                  ) : (
                    <WeeklyResults type={type} currentWeek={currentWeek} leagueId={id} getPlayInfo={getPlayInfo} />
                  )}
                </Fragment>
              )}
              {type === 'doubles' && (
                <Fragment>
                  {mobile ? (
                    <DoublesWeeklyResultsMobile
                      type={type}
                      currentWeek={currentWeek}
                      leagueId={id}
                      teamPlayInfo={teamPlayInfo}
                    />
                  ) : (
                    <DoublesWeeklyResults
                      type={type}
                      currentWeek={currentWeek}
                      leagueId={id}
                      teamPlayInfo={teamPlayInfo}
                    />
                  )}
                </Fragment>
              )}
            </DetailsBox>
          </Flip>
        </SideBoxWrapper>
      </Fragment>
    );
  }
}

// Normal Right side bar component -> Displaying past results
class Normal extends PureComponent {
  constructor() {
    super();
    this.state = {
      quote: '',
      author: '',

      // state of tooltip
      tooltip: false,
    };
  }

  handleTooltip = () => {
    this.setState(prev => ({ tooltip: !prev.tooltip }));
  };

  componentDidMount() {
    axios.get('https://quotes.rest/qod.json?category=sports').then(data => {
      const theQuote = data.data.contents.quotes[0];
      this.setState({ quote: theQuote.quote, author: theQuote.author });
    });
  }

  render() {
    const { quote, author, tooltip } = this.state;
    const { me } = this.props;

    return (
      <NormalSideBar>
        {/* logo for mobile resolution */}
        {me && <MobileLogo genderType={me.genderType} type={me.playType} />}
        {/* logo for mobile resolution */}
        <Query query={PAST_WINNERS_QUERY}>
          {({ data }) => {
            const pastWinnersData = data;

            return (
              <Query query={ALL_PAST_RESULTS_QUERY}>
                {({ data }) => {
                  const pastResultsData = data;
                  let hasPastResultsSingles = false;
                  let hasPastResultsDoubles = false;
                  let hasPastWinners = false;

                  if (
                    pastResultsData &&
                    pastResultsData.getAllPastResults &&
                    pastResultsData.getAllPastResults.length > 0
                  ) {
                    const { getAllPastResults } = pastResultsData;
                    hasPastResultsSingles = getAllPastResults.some(result => result.playType === 'singles');
                    hasPastResultsDoubles = getAllPastResults.some(result => result.playType === 'doubles');
                  }
                  if (pastWinnersData && pastWinnersData.getWinners && pastWinnersData.getWinners.length > 0) {
                    hasPastWinners = true;
                  }

                  return (
                    <Fragment>
                      <div className="box-wrapper">
                        <HeadingWithTooltip>
                          <div className="heading-tooltip">
                            <H3 MB={'15px'}>Overall Points</H3>
                            <div className="question-icon" onClick={() => this.handleTooltip()}>
                              <FontAwesomeIcon icon={faQuestionCircle} />
                            </div>
                          </div>
                          {tooltip && (
                            <div className="tooltip">
                              <p>
                                Overall points accumulated in the four seasons; Summer, Autumn, Winter, and Spring.
                                <br />
                                Total points reset yearly.
                              </p>
                            </div>
                          )}
                        </HeadingWithTooltip>

                        <Query query={ALL_LIFETIME}>
                          {({ data, loading }) => {
                            const lifetimeData = data;
                            let lifetimepoints = null;

                            if (loading) {
                              return '';
                            }
                            if (lifetimeData && lifetimeData.getAllLifetime && lifetimeData.getAllLifetime.length > 0) {
                              const currentLifetime = lifetimeData.getAllLifetime.filter(
                                object => object.userId === me.id
                              );
                              if (currentLifetime.length > 0) {
                                lifetimepoints = currentLifetime[0].points;
                              }
                            }

                            return (
                              <Flip right>
                                <DetailsBox padding={'14px 0'} className="light-reflection">
                                  <div className="past-results-separator" style={{ marginBottom: '0' }}>
                                    <p className="lifetime-result">{lifetimepoints !== null ? lifetimepoints : '0'}</p>
                                  </div>
                                  <Link to={routes.LIFETIME}>
                                    <div className="lifetime-button">View Leaderboard</div>
                                  </Link>
                                </DetailsBox>
                              </Flip>
                            );
                          }}
                        </Query>
                      </div>
                      <div className="box-wrapper">
                        <H3 MB={'15px'}>All Past Winners</H3>
                        <Flip right>
                          <DetailsBox padding={'14px 0'}>
                            <div className="past-results-separator" style={{ marginBottom: '0' }}>
                              {hasPastWinners ? (
                                <Link to={routes.ALL_PAST_WINNERS}>
                                  <p className="both back2 hover">See Results</p>
                                </Link>
                              ) : (
                                <p className="no-results back2">No Results Found</p>
                              )}
                            </div>
                          </DetailsBox>
                        </Flip>
                      </div>
                      <div className="box-wrapper">
                        <H3 MB={'15px'}>Past Results</H3>
                        <Flip right>
                          <DetailsBox padding={'14px 0'}>
                            <div className="past-results-separator">
                              <H4 dbHeading>Singles</H4>
                              {hasPastResultsSingles ? (
                                <Link to={routes.PAST_RESULTS}>
                                  <p className="singles hover">See Results</p>
                                </Link>
                              ) : (
                                <p className="no-results">No Results Found</p>
                              )}
                            </div>
                            <div className="past-results-separator">
                              <H4 dbHeading>Doubles</H4>
                              {hasPastResultsDoubles ? (
                                <Link to={routes.PAST_RESULTS}>
                                  <p className="doubles hover">See Results</p>
                                </Link>
                              ) : (
                                <p className="no-results">No Results Found</p>
                              )}
                            </div>
                          </DetailsBox>
                        </Flip>
                      </div>
                    </Fragment>
                  );
                }}
              </Query>
            );
          }}
        </Query>
        <Flip right>
          <div className="quote-section">
            <h5>Quote of the day</h5>
            <p className="quote">{quote}</p>
            <p>- {author}</p>
          </div>
        </Flip>
      </NormalSideBar>
    );
  }
}

// Determine league info and text that should show
const LeagueInfo = ({ leagueInfoData }) => {
  const { location, skill } = leagueInfoData.getLeagueInfo;
  return location + ` - ${skill}`;
};

export default RightsideBar;
