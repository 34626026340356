import React from 'react';
import { Query } from '@apollo/react-components';
import { GET_ME } from './queries';

const withSession = Component => props => (
  <Query query={GET_ME}>
    {({ data, refetch }) => {
      const userData = data;

      return (
        <div>
          <Component {...props} session={userData} refetch={refetch} />
        </div>
      );
    }}
  </Query>
);

export default withSession;
