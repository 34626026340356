import gql from 'graphql-tag';

// Query the season status
export const SEASON_STATUS_QUERY = gql`
  query {
    getSeasonStatus {
      id
      truncateAll
      payable
      price
      emailPromotion
      matchGenerator
      doublesMatchGenerator
      leagueStatus
      showGendersRego
    }
  }
`;
// Subscription for the season status -> used only in the registration screen (main)
// When admin updates payable price + toggle switch + gender switch
export const SEASON_STATUS_SUBSCRIPTION = gql`
  subscription {
    updatedSeasonStatus {
      id
      truncateAll
      payable
      price
      emailPromotion
      matchGenerator
      doublesMatchGenerator
      leagueStatus
      showGendersRego
    }
  }
`;

// Query the feedbacks
export const ALL_FEEDBACK_QUERY = gql`
  query($cursor: String) {
    allFeedback(cursor: $cursor) {
      edges {
        id
        display
        feedback
        season
        year
        playType
        genderType
        league
        division
        subLeagueId
        userId
      }
      feedbackInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

// Query all users in the database
export const ALL_USERS_QUERY = gql`
  query {
    users {
      playType
      genderType
      paid
    }
  }
`;

// Mutation to send promotion emails to all unregistered users
export const EMAIL_PROMOTION_MUTATION = gql`
  mutation(
    $custom: Boolean
    $subject: String
    $heading: String
    $paragraph: String
    $paid: Boolean
    $isMelbourne: Boolean
  ) {
    emailSignUp(
      custom: $custom
      subject: $subject
      heading: $heading
      paragraph: $paragraph
      paid: $paid
      isMelbourne: $isMelbourne
    )
  }
`;

// Mutation to control season payable status
export const PAYABLE_SWITCH_MUTATION = gql`
  mutation {
    payableSwitch
  }
`;
// Mutation to update payable price
export const PAYABLE_PRICE_MUTATION = gql`
  mutation($price: String!) {
    updatePayablePrice(price: $price)
  }
`;

// Mutation to enable/disable Singles Men's and Women's league registration
export const GENDER_SWITCH_MUTATION = gql`
  mutation {
    genderSwitch
  }
`;

// Query all users with free registrations
export const ALL_FREE_USERS_QUERY = gql`
  query {
    allFreeUsers {
      id
      email
    }
  }
`;
// Mutation to provide free access to registrations
export const FREE_ACCESS_MUTATION = gql`
  mutation($email: String!) {
    giveFreeAccess(email: $email)
  }
`;
// Mutation to remove free access to registrations
export const REMOVE_ACCESS_MUTATION = gql`
  mutation($email: String!) {
    removeFreeAccess(email: $email)
  }
`;
// Mutation to provide ADMIN role to email
export const ADMIN_ACCESS_MUTATION = gql`
  mutation($email: String!, $pin: String!) {
    giveAdminAccess(email: $email, pin: $pin)
  }
`;

// Logic to execute at season end -> pushes leaderboard data and ranking to the "past results" table and truncate the tables that no longer need the data.
export const MASTER_SEASON_END_MUTATION = gql`
  mutation($resetPoints: Boolean!) {
    seasonEndLogic(resetPoints: $resetPoints)
  }
`;

// Mutation to enable Feedback for display
export const ACTIVE_FEEDBACK_MUTATION = gql`
  mutation($id: ID!) {
    checkActiveFeedback(id: $id)
  }
`;

// Subscribe user to newsletter
export const SUBSCRIBE_TO_NEWSLETTER_MUTATION = gql`
  mutation($email: String!, $type: String!) {
    subscribeToNewsletter(email: $email, type: $type)
  }
`;

// Delete a user from the database and their direct messages
export const DELETE_USER_MUTATION = gql`
  mutation($email: String!) {
    deleteUser(email: $email)
  }
`;

// Send email to all players whether their registered league has enough players
export const LEAGUE_EMAIL_CHECK = gql`
  mutation($currentSeason: String!, $nextSeason: String!) {
    checkLeagueStatusForAllPlayers(currentSeason: $currentSeason, nextSeason: $nextSeason)
  }
`;
