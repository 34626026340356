import styled, { theme, css } from '../../../theme';

export const FeedbackTable = styled.div`
  display: table;
  width: 100%;
  text-align: center;

  p {
    margin: 3px 0;
  }
  .heading {
    background-color: #c6c6c6;

    p {
      color: black;
      font-weight: 800;
      margin: 5px 0;
    }
  }
  .feedback-row {
    display: table-row;
    cursor: pointer;

    :hover,
    :focus {
      background-color: #f4f4f4;
    }

    .feedback-cell {
      display: table-cell;
      vertical-align: middle;
      padding: 0 5px;

      .align-left {
        text-align: left;
      }
    }
    :nth-child(n + 3):nth-child(odd) {
      background-color: ${theme.breakLine};
    }
  }
`;

export const ActiveCircle = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: black;
  border-radius: 5px;
  ${props =>
    props.active &&
    css`
      background-color: limegreen;
    `};
`;
