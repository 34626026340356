import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { Mutation } from '@apollo/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import * as routes from '../../constants/routes';

import { MyModal, CheckoutWholeWrapper } from './style';
import { Button, H4 } from '../Universal/style';
import Loading from '../Loading';
import ErrorMessage from '../Error';

const PAID_TO_PLAY = gql`
  mutation(
    $season: String!
    $type: String!
    $genderType: String
    $teamId: ID
    $partnerEmail: String
    $league: String
    $skill: String
  ) {
    paidToPlay(
      season: $season
      type: $type
      genderType: $genderType
      teamId: $teamId
      partnerEmail: $partnerEmail
      league: $league
      skill: $skill
    )
  }
`;

class NoPayment extends Component {
  onSubmit = async (event, paidToPlay) => {
    event.preventDefault();
    const { session, history, userRefetch } = this.props;

    await paidToPlay();
    await userRefetch();
    if (session && session.me && session.me.playType === 'singles') {
      history.push(routes.SINGLES_DASH);
    } else {
      history.push(routes.DOUBLES_TEAM);
    }
  };

  render() {
    const { type, season, genderType, teamId, partnerEmail, closePaymentWindow, league, skill } = this.props;
    const doubles = type === 'doubles';

    // Details to display in the popup confirmation screen
    let detailsList = [
      {
        heading: 'Type',
        value: type.charAt(0).toUpperCase() + type.slice(1),
      },
      {
        heading: 'League Type',
        value: genderType ? genderType.charAt(0).toUpperCase() + genderType.slice(1) : '',
      },
      {
        heading: 'League',
        value: league,
      },
      {
        heading: 'Playing Level',
        value: skill,
      },
    ];

    // If coming from the doubles screen -> remove the "League Type / genderType" from the array
    if (doubles) {
      detailsList = detailsList.filter(list => list.heading !== 'League Type');
    }

    return (
      <Mutation
        mutation={PAID_TO_PLAY}
        variables={{
          type,
          season,
          genderType,
          teamId,
          partnerEmail,
          league,
          skill,
        }}
      >
        {(paidToPlay, { loading, error }) => (
          <MyModal>
            <CheckoutWholeWrapper noPay>
              {/* Close icon */}
              <div className="close-button" onClick={() => closePaymentWindow()}>
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>

              <div style={{ marginBottom: '7px' }}>
                <H4 fontsize={'20px'} noMB>
                  League Confirmation
                </H4>
                <p className="sub-heading">You have selected to register for:</p>
                <ul className="details-list">
                  {detailsList.map((item, itemIndex) => (
                    <li key={itemIndex}>
                      <p>
                        <strong>{item.heading}:</strong> {item.value}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>

              {error && <ErrorMessage error={error} />}

              <Button
                doubles={doubles}
                genderType={genderType}
                normal
                noMargin
                type="button"
                onClick={event => this.onSubmit(event, paidToPlay)}
              >
                {loading ? <Loading /> : `Sign me up!`}
              </Button>
            </CheckoutWholeWrapper>
          </MyModal>
        )}
      </Mutation>
    );
  }
}

export default withRouter(NoPayment);
