import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';

import * as routes from '../../constants/routes';
import Loading from '../Loading';
import ErrorMessage from '../Error';

import { Button, Form } from '../Universal/style';
import { Input } from './style';
import { Notification } from '../SignUp/style';
import { RESEND_CONFIRMATION_MUTATION } from '../SignUp/signupForm';

const SIGN_IN = gql`
  mutation($login: String!, $password: String!) {
    signIn(login: $login, password: $password) {
      result
      token
      theUser {
        id
        email
      }
    }
  }
`;

const INITIAL_STATE = {
  login: '',
  password: '',
  sentConfirmation: false,
};

class SignInForm extends Component {
  constructor() {
    super();
    this.state = { ...INITIAL_STATE };
  }

  sentConfirmation = async resendConfirmation => {
    await resendConfirmation();
    this.setState(prev => ({ sentConfirmation: !prev.sentConfirmation }));
  };

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onSubmit = (event, signIn) => {
    event.preventDefault();
    signIn().then(async ({ data }) => {
      if (data.signIn.result) {
        const { history } = this.props;
        this.setState({ ...INITIAL_STATE });

        localStorage.setItem('token', data.signIn.token);
        await this.props.refetch();
        history.push(routes.LANDING);
      }
    });
  };

  render() {
    const { password, sentConfirmation } = this.state;
    let { login } = this.state;
    const isInvalid = password === '' || login === '';

    login = login.toLowerCase();

    return (
      <Mutation mutation={SIGN_IN} variables={{ login, password }}>
        {(signIn, { data, loading, error }) => (
          <Form onSubmit={event => this.onSubmit(event, signIn)}>
            {/* For the email */}
            <div className="input-wrapper">
              <Input name="login" value={login} onChange={this.onChange} type="email" placeholder="Email" />
              <label>Email</label>
            </div>

            {/* For the password */}
            <div className="input-wrapper">
              <Input name="password" value={password} onChange={this.onChange} type="password" placeholder="Password" />
              <label>Password</label>
            </div>

            <Button className="outside-screens" disabled={isInvalid || loading} type="submit">
              {loading ? <Loading /> : 'Sign In'}
            </Button>

            {data && data.signIn && !data.signIn.result && (
              /* Mutation resolver for re-sending confirmation email. */
              <Mutation mutation={RESEND_CONFIRMATION_MUTATION} variables={{ userId: data.signIn.theUser.id }}>
                {resendConfirmation => (
                  <Notification>
                    <li>
                      You have not activated your verification link.{' '}
                      {sentConfirmation ? (
                        <div style={{ marginTop: '10px' }} className="send-confirmation-button no-hover">
                          Confirmation has been sent! Please click on the link to verify.
                        </div>
                      ) : (
                        <div
                          style={{ marginTop: '10px' }}
                          className="send-confirmation-button"
                          onClick={() => this.sentConfirmation(resendConfirmation)}
                        >
                          Click here to resend the confirmation email to {data.signIn.theUser.email}.
                        </div>
                      )}
                    </li>
                  </Notification>
                )}
              </Mutation>
            )}
            {error && <ErrorMessage error={error} />}
          </Form>
        )}
      </Mutation>
    );
  }
}

export default SignInForm;
