import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import withAuthorization from '../../Session/withAuthorization';

import * as routes from '../../../constants/routes';
import { BackLink } from '../../Universal/style';
import { ALL_FREE_USERS_QUERY } from '../queries';

const UsersListPage = () => (
  <Fragment>
    <BackLink>
      <Link to={routes.ADMIN}>
        <FontAwesomeIcon icon={faArrowCircleLeft} /> Back to Admin Page
      </Link>
    </BackLink>

    <h2>Free access list</h2>
    <div className="user-list-section">
      <Query query={ALL_FREE_USERS_QUERY}>
        {({ data, loading }) => (
          <Fragment>
            {loading && <div>Loading...</div>}
            {data && data.allFreeUsers && data.allFreeUsers.length > 0 ? (
              <ol className="list-section">
                {data.allFreeUsers.map((user, i) => (
                  <li key={i} className="the-email">
                    {user.email}
                  </li>
                ))}
              </ol>
            ) : (
              <p>Currently no listings</p>
            )}
          </Fragment>
        )}
      </Query>
    </div>
  </Fragment>
);

export default withAuthorization(session => session && session.me && session.me.role === 'ADMIN')(UsersListPage);
