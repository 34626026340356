import gql from 'graphql-tag';

// Query the team
export const ALL_RESULTS_QUERY = gql`
  query {
    getAllWeeklyResults {
      id
      week
      score
      loserId
      winnerId
      leagueId
    }
  }
`;
