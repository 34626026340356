import styled, { theme } from '../../../theme';

export const Wrapper = styled.div`
  .section {
    margin-bottom: 40px;

    #check-users {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      border-bottom: 1px solid ${theme.breakLine};
      padding-bottom: 40px;
      margin-bottom: 40px;

      .flex-column {
        display: flex;
        flex-direction: column;

        form:first-child {
          margin-bottom: 30px;
        }
      }
      .first-form {
        margin-right: 30px;
      }
      form {
        display: flex;
        flex-direction: column;
        border: 1px dashed #484848;
        width: 330px;
        max-width: 100%;
        padding: 10px 15px;
        border-radius: 3px;
        background-color: whitesmoke;

        .heading {
          display: flex;
          align-items: center;

          svg {
            margin-right: 10px;
          }
          h3 {
            margin: 0;
          }
        }
        .form-content {
          margin-top: 20px;

          .field {
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;

            p {
              margin-top: 0;
              margin-bottom: 7px;
              font-weight: 600;
            }
            input {
              padding: 7px;
              outline: none;
              border-radius: 2px;
              border: 1px solid #dedede;
              font-size: 14px;
              outline: none;
            }
            select {
              width: 100%;
              outline: none;
            }
          }
        }
      }
    }

    .overflow {
      display: grid;

      .wrapper {
        overflow-x: scroll;
      }
    }

    .table {
      display: table;
      width: 100%;
      margin-bottom: 40px;

      .table-row {
        display: table-row;

        :first-child .table-cell p {
          color: black;
        }
        :nth-child(2n + 2) {
          background-color: #dedede;
        }
        .table-cell {
          display: table-cell;
          text-align: center;
          p {
            padding: 0;
            margin: 7px 7px 7px 0;
          }
        }
        .email-cell {
          text-align: left;
        }
      }
    }
    .error {
      color: crimson;
    }
  }

  @media (max-width: 793px) {
    #check-users {
      width: 100% !important;

      .flex-column,
      .first-form {
        margin-right: 0 !important;
        width: 100% !important;
      }
      .flex-column {
        margin-top: 30px;
      }
      form {
        width: auto !important;
      }
    }
  }
`;
