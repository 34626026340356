import React, { Component, Fragment } from 'react';
import { Query } from '@apollo/react-components';
import { Image, Transformation } from 'cloudinary-react';
import 'element-scroll-polyfill';

import { PAST_RESULTS_QUERY } from '../queries';
import { OPPONENT_QUERY } from '../../Universal/queries';
import { FetchMoreButton } from '../individual/doubleResult';
import placeholderImage from '../../../resources/images/placeholder-profile.jpg';
import { ScrollIndicator } from '../../Singles/fixtures/style';

class DoubleResult extends Component {
  constructor() {
    super();
    this.state = {
      hasScroll: false,
      topPosition: 0,
      leftPosition: 0,
      indicatorHeight: 0,
      arrowBorder: 0,
    };
  }

  scrollElement = element => {
    if (element) {
      this.element = element;
      const clientWidth = element.clientWidth;
      const scrollWidth = element.scrollWidth;
      const topPosition = element.clientHeight / 2;

      if (scrollWidth > clientWidth) {
        this.setState({
          hasScroll: true,
          topPosition,
          indicatorHeight: element.clientHeight / 3,
          arrowBorder: element.clientHeight / 27,
        });
      }
    }
  };

  handleOnScroll = event => {
    const { clientWidth, scrollLeft, scrollWidth } = event.target;

    this.setState({ leftPosition: scrollLeft });
    if (scrollLeft === scrollWidth - clientWidth) {
      this.setState({ hasScroll: false });
    } else {
      this.setState({ hasScroll: true });
    }
  };

  handleRightShift = () => {
    const scrollWidth = this.element.scrollWidth;
    this.element.scrollTo(scrollWidth, 0);
    this.setState({ hasScroll: false });
  };

  render() {
    const { hasScroll, topPosition, leftPosition, indicatorHeight, arrowBorder } = this.state;
    const halfHeight = indicatorHeight / 2;

    return (
      <Query query={PAST_RESULTS_QUERY} variables={{ playType: 'doubles', winners: true, teamOwner: true }}>
        {({ data, loading, fetchMore }) => {
          if (loading) {
            return '';
          }
          if (data && data.getPastResults && data.getPastResults.edges && data.getPastResults.edges.length > 0) {
            const { edges, pastResultInfo } = data.getPastResults;
            return (
              <div className="section-wrapper">
                <h2>Doubles</h2>
                <div className="inner-section-wrapper" ref={this.scrollElement} onScroll={this.handleOnScroll}>
                  <div className="table">
                    <div className="table-row heading">
                      <div className="table-cell">
                        <p>Year</p>
                      </div>
                      <div className="table-cell">
                        <p>Season</p>
                      </div>
                      <div className="table-cell">
                        <p>League</p>
                      </div>
                      <div className="table-cell">
                        <p>Level</p>
                      </div>
                      <div className="table-cell">
                        <p>Group</p>
                      </div>
                      <div className="table-cell">
                        <p>Rank</p>
                      </div>
                      <div className="table-cell">
                        <p>Points</p>
                      </div>
                      <div className="table-cell">
                        <p>Wins</p>
                      </div>
                      <div className="table-cell">
                        <p>Losses</p>
                      </div>
                      <div className="table-cell">
                        <p>Team Name</p>
                      </div>
                      <div className="table-cell">
                        <p>Leader</p>
                      </div>
                      <div className="table-cell">
                        <p>Partner</p>
                      </div>
                    </div>
                    {edges.map((result, doublesIndex) => (
                      <div key={doublesIndex} className="table-row">
                        <div className="table-cell">
                          <p>{result.year}</p>
                        </div>
                        <div className="table-cell">
                          <p>
                            {result.season.charAt(0).toUpperCase()}
                            {result.season.slice(1)}
                          </p>
                        </div>
                        <div className="table-cell">
                          <p>{result.league.replace('League', '').trim()}</p>
                        </div>
                        <div className="table-cell">
                          <p>{result.division}</p>
                        </div>
                        <div className="table-cell">
                          <p>{result.group}</p>
                        </div>
                        <div className="table-cell">
                          <p>{result.rank}</p>
                        </div>
                        <div className="table-cell">
                          <p>{result.points}</p>
                        </div>
                        <div className="table-cell">
                          <p>{result.wins}</p>
                        </div>
                        <div className="table-cell">
                          <p>{result.losses}</p>
                        </div>
                        <div className="table-cell">
                          <p>{result.teamName}</p>
                        </div>
                        <Query query={OPPONENT_QUERY} variables={{ id: result.ownerId }}>
                          {({ data, loading }) => (
                            <div className="table-cell">
                              <div className="profile-wrapper">
                                <div className="profile-inner">
                                  {loading ? (
                                    <p>Loading..</p>
                                  ) : data && data.user ? (
                                    <Fragment>
                                      {data.user.profileImage ? (
                                        <Image
                                          className="profile-image"
                                          cloudName={process.env.REACT_APP_CLOUD_NAME}
                                          publicId={data.user.profileImage}
                                        >
                                          <Transformation width="20" height="20" gravity="faces" crop="fill" />
                                        </Image>
                                      ) : (
                                        <img src={placeholderImage} alt="player-profile" />
                                      )}
                                      <p>
                                        {data.user.firstname} {data.user.lastname}
                                      </p>
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      <img src={placeholderImage} alt="player-profile" />
                                      <p>N/A</p>
                                    </Fragment>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </Query>
                        <Query query={OPPONENT_QUERY} variables={{ id: result.friendId }}>
                          {({ data, loading }) => (
                            <div className="table-cell">
                              <div className="profile-wrapper">
                                <div className="profile-inner">
                                  {loading ? (
                                    <p>Loading..</p>
                                  ) : data && data.user ? (
                                    <Fragment>
                                      {data.user.profileImage ? (
                                        <Image
                                          className="profile-image"
                                          cloudName={process.env.REACT_APP_CLOUD_NAME}
                                          publicId={data.user.profileImage}
                                        >
                                          <Transformation width="20" height="20" gravity="faces" crop="fill" />
                                        </Image>
                                      ) : (
                                        <img src={placeholderImage} alt="player-profile" />
                                      )}
                                      <p>
                                        {data.user.firstname} {data.user.lastname}
                                      </p>
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      <img src={placeholderImage} alt="player-profile" />
                                      <p>N/A</p>
                                    </Fragment>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </Query>
                      </div>
                    ))}
                  </div>
                  {hasScroll && (
                    <ScrollIndicator
                      top={topPosition - halfHeight}
                      right={leftPosition}
                      height={indicatorHeight}
                      arrowBorder={arrowBorder}
                      onClick={() => this.handleRightShift()}
                    >
                      <div className="scroll-indicator"></div>
                    </ScrollIndicator>
                  )}
                </div>
                {pastResultInfo.hasNextPage && (
                  <FetchMoreButton fetchMore={fetchMore} endCursor={pastResultInfo.endCursor} />
                )}
              </div>
            );
          }
          return '';
        }}
      </Query>
    );
  }
}

export default DoubleResult;
