import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import isLoggedIn from '../Session/isLoggedIn';
import Tilt from 'react-tilt';

import { SignInLink } from '../SignIn';
import { Flex, P } from '../Universal/style';
import { SignInWidth } from '../SignIn/style';
import * as routes from '../../constants/routes';
import AutomaticBackground from '../SignIn/automaticBackground';
import logo from '../../resources/images/logo-white-bk-circle.png';
import SignUpForm from './signupForm';
import Footer from '../Footer';

class SignUpPage extends Component {
  render() {
    const { history, match, refetch, fromInvitation } = this.props;

    return (
      <AutomaticBackground>
        <SignInWidth>
          <Flex noWrap between>
            <h1>SignUp</h1>
            <Tilt className="Tilt" options={{ max: 35, scale: 1.05 }}>
              <div className="Tilt-inner">
                <img style={{ width: '80px' }} src={logo} alt="Logo" />
              </div>
            </Tilt>
          </Flex>
          <SignUpForm history={history} refetch={refetch} fromInvitation={fromInvitation} match={match} />
          <SignInLink />
        </SignInWidth>
        <Footer />
      </AutomaticBackground>
    );
  }
}

const SignUpLink = () => (
  <P openSans color={'black'} style={{ marginRight: '20px' }}>
    Don't have an account?{' '}
    <Link className="login-links outer-link" to={routes.SIGN_UP}>
      Sign Up
    </Link>
  </P>
);

export default withRouter(isLoggedIn(session => session && session.me)(SignUpPage));

export { SignUpForm, SignUpLink };
