import React, { Component, Fragment, PureComponent } from 'react';
import { withRouter, Route, Link, Redirect, Switch } from 'react-router-dom';

import * as routes from '../../constants/routes';
import { Query } from '@apollo/react-components';
import { CURRENT_YEAR_SEASON_DATES } from '../Universal/queries';

import withAuthorization from '../Session/withAuthorization';
import { formatDate } from '../../abstracts/helpers';

import { Flex, P } from '../Universal/style';
import { RegisterButton, H2, LineBreak, Ribbon } from './style';
import SinglesReg from './singles';
import DoublesReg from './doubles';
import TimerSection from './timer';
import { SEASON_STATUS_QUERY, SEASON_STATUS_SUBSCRIPTION } from '../Admin/queries';
import DashboardLayout from '../DashboardLayout';

const RegisterPage = ({ session, refetch }) => {
  return (
    <Query query={CURRENT_YEAR_SEASON_DATES}>
      {({ data, loading }) => (
        <Fragment>
          {loading && ''}
          {data && data.currentYearSeasonDates && (
            <RegisterPageInner session={session} userRefetch={refetch} seasonDates={data} />
          )}
        </Fragment>
      )}
    </Query>
  );
};

class RegisterPageInner extends Component {
  constructor() {
    super();
    this.state = {
      season: null,
      closedText: '',
      headingText: '',
      nextSeasonText: '',
      nextSeasonStart: null,
      // Randomly choose past date so that a date exists when going into the timer component
      currentDeadline: new Date('Tue Dec 31 2018 21:00:00'),
      active: false,
    };
  }

  determineSeasonLogic = seasonDates => {
    let { previousSpring, summer, autumn, spring, winter, nextSummer } = seasonDates;
    const currentTime = new Date().getTime();

    // Four days BEFORE season starts
    const oneDayPreviousSpring = new Date(new Date(previousSpring).setDate(new Date(previousSpring).getDate() - 4));
    const oneDaySummer = new Date(new Date(summer).setDate(new Date(summer).getDate() - 4));
    const oneDayAutumn = new Date(new Date(autumn).setDate(new Date(autumn).getDate() - 4));
    const oneDayWinter = new Date(new Date(winter).setDate(new Date(winter).getDate() - 4));
    const oneDaySpring = new Date(new Date(spring).setDate(new Date(spring).getDate() - 4));

    // Get the Season closed text
    const beforeSummer = new Date(new Date(summer).setDate(new Date(summer).getDate() - 19));
    const beforeAutumn = new Date(new Date(autumn).setDate(new Date(autumn).getDate() - 19));
    const beforeWinter = new Date(new Date(winter).setDate(new Date(winter).getDate() - 19));
    const beforeSpring = new Date(new Date(spring).setDate(new Date(spring).getDate() - 19));
    const beforeNextSummer = new Date(new Date(nextSummer).setDate(new Date(nextSummer).getDate() - 19));

    if (oneDayPreviousSpring <= currentTime && currentTime <= beforeSummer) {
      this.setState({
        closedText: 'Spring',
        nextSeasonText: 'Summer',
        nextSeasonStart: new Date(summer).toDateString(),
        active: false,
      });
    } else if (oneDaySummer <= currentTime && currentTime <= beforeAutumn) {
      this.setState({
        closedText: 'Summer',
        nextSeasonText: 'Autumn',
        nextSeasonStart: new Date(autumn).toDateString(),
        active: false,
      });
    } else if (oneDayAutumn <= currentTime && currentTime <= beforeWinter) {
      this.setState({
        closedText: 'Autumn',
        nextSeasonText: 'Winter',
        nextSeasonStart: new Date(winter).toDateString(),
        active: false,
      });
    } else if (oneDayWinter <= currentTime && currentTime <= beforeSpring) {
      this.setState({
        closedText: 'Winter',
        nextSeasonText: 'Spring',
        nextSeasonStart: new Date(spring).toDateString(),
        active: false,
      });
    } else if (oneDaySpring <= currentTime && currentTime <= beforeNextSummer) {
      this.setState({
        closedText: 'Spring',
        nextSeasonText: 'Summer',
        nextSeasonStart: new Date(nextSummer).toDateString(),
        active: false,
      });
    }

    // Get the current season heading
    if (oneDayPreviousSpring <= currentTime && currentTime < beforeSummer) {
      this.setState({ headingText: 'Spring' });
    } else if (beforeSummer <= currentTime && currentTime < beforeAutumn) {
      this.setState({ headingText: 'Summer' });
    } else if (beforeAutumn <= currentTime && currentTime < beforeWinter) {
      this.setState({ headingText: 'Autumn' });
    } else if (beforeWinter <= currentTime && currentTime < beforeSpring) {
      this.setState({ headingText: 'Winter' });
    } else if (beforeSpring <= currentTime && currentTime < beforeNextSummer) {
      this.setState({ headingText: 'Spring' });
    }

    // Determine when the registrations open and the season it opens for.
    // Ensuring that the details "currentDeadline", "season" and "active" is for the "one day before the actual season".
    // The admin can then generate the fixtures a day before the season starts and everyone else gets the redirection on the actual day.
    let datesArray = [summer, autumn, winter, spring];
    datesArray.forEach(async (date, i) => {
      const before = new Date(new Date(date).setDate(new Date(date).getDate() - 19));
      if (i === 0 && before <= currentTime && currentTime < oneDaySummer) {
        this.setState({ currentDeadline: oneDaySummer, season: 'Summer', active: true });
      } else if (i === 1 && before <= currentTime && currentTime < oneDayAutumn) {
        this.setState({ currentDeadline: oneDayAutumn, season: 'Autumn', active: true });
      } else if (i === 2 && before <= currentTime && currentTime < oneDayWinter) {
        this.setState({ currentDeadline: oneDayWinter, season: 'Winter', active: true });
      } else if (i === 3 && before <= currentTime && currentTime < oneDaySpring) {
        this.setState({ currentDeadline: oneDaySpring, season: 'Spring', active: true });
      }
    });
  };

  componentDidMount() {
    const { seasonDates } = this.props;
    if (seasonDates && seasonDates.currentYearSeasonDates) {
      // We need to call interval here so that the "determineSeasonLogic" will execute every second and ensure the time is correct to display certain information.
      // Only the parent component "registerPage" can execute every second through the redirect set inside the "timer.js" file BUT we need to fetch season dates first so it's not possible to remount this component all the time.
      // With that in mind, we set the interval here so we can remount this component every second.

      let { summer, autumn, spring, winter } = seasonDates.currentYearSeasonDates;
      const currentTime = new Date().getTime();

      // One day BEFORE season starts
      const oneDaySummer = new Date(new Date(summer).setDate(new Date(summer).getDate() - 4));
      const oneDayAutumn = new Date(new Date(autumn).setDate(new Date(autumn).getDate() - 4));
      const oneDayWinter = new Date(new Date(winter).setDate(new Date(winter).getDate() - 4));
      const oneDaySpring = new Date(new Date(spring).setDate(new Date(spring).getDate() - 4));

      // Get the Season closed text
      const beforeSummer = new Date(new Date(summer).setDate(new Date(summer).getDate() - 19));
      const beforeAutumn = new Date(new Date(autumn).setDate(new Date(autumn).getDate() - 19));
      const beforeWinter = new Date(new Date(winter).setDate(new Date(winter).getDate() - 19));
      const beforeSpring = new Date(new Date(spring).setDate(new Date(spring).getDate() - 19));

      // First time this function executes once only.
      this.determineSeasonLogic(seasonDates.currentYearSeasonDates);

      // These will execute according to the time specified with the season dates and current time.
      this.beforeSummerTimeout = setTimeout(
        () => this.determineSeasonLogic(seasonDates.currentYearSeasonDates),
        beforeSummer - currentTime
      );
      this.beforeAutumnTimeout = setTimeout(
        () => this.determineSeasonLogic(seasonDates.currentYearSeasonDates),
        beforeAutumn - currentTime
      );
      this.beforeWinterTimeout = setTimeout(
        () => this.determineSeasonLogic(seasonDates.currentYearSeasonDates),
        beforeWinter - currentTime
      );
      this.beforeSpringTimeout = setTimeout(
        () => this.determineSeasonLogic(seasonDates.currentYearSeasonDates),
        beforeSpring - currentTime
      );

      this.summerTimeout = setTimeout(
        () => this.determineSeasonLogic(seasonDates.currentYearSeasonDates),
        oneDaySummer - currentTime
      );
      this.autumnTimeout = setTimeout(
        () => this.determineSeasonLogic(seasonDates.currentYearSeasonDates),
        oneDayAutumn - currentTime
      );
      this.winterTimeout = setTimeout(
        () => this.determineSeasonLogic(seasonDates.currentYearSeasonDates),
        oneDayWinter - currentTime
      );
      this.springTimeout = setTimeout(
        () => this.determineSeasonLogic(seasonDates.currentYearSeasonDates),
        oneDaySpring - currentTime
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);

    clearTimeout(this.beforeSummerTimeout);
    clearTimeout(this.beforeAutumnTimeout);
    clearTimeout(this.beforeWinterTimeout);
    clearTimeout(this.beforeSpringTimeout);

    clearTimeout(this.summerTimeout);
    clearTimeout(this.autumnTimeout);
    clearTimeout(this.winterTimeout);
    clearTimeout(this.springTimeout);
  }

  render() {
    const { currentDeadline, season, closedText, headingText, nextSeasonText, nextSeasonStart, active } = this.state;
    const { session, userRefetch } = this.props;
    const customer = session.me && { name: `${session.me.firstname} ${session.me.lastname}`, email: session.me.email };

    return (
      <DashboardLayout session={session} noWrap={true} classExist={'main-content'}>
        <TimerSection
          deadline={currentDeadline}
          season={season}
          closedText={closedText}
          headingText={headingText}
          nextSeasonText={nextSeasonText}
          nextSeasonStart={nextSeasonStart}
        />
        {/* Fetching SeasonStatus to determine whether to display paid screen or not as well as the price */}
        <Query query={SEASON_STATUS_QUERY}>
          {({ data, loading, subscribeToMore }) => (
            <Fragment>
              {loading && ''}
              {data && data.getSeasonStatus && data.getSeasonStatus.length > 0 && (
                <SeasonStatusTableUpdates subscribeToMore={subscribeToMore}>
                  <LineBreak className="under-timer-wrapper">
                    <Switch>
                      <Route
                        exact
                        path={routes.LANDING}
                        render={() => (
                          <WelcomeDash
                            session={session}
                            season={season}
                            nextSeasonText={nextSeasonText}
                            nextSeasonStart={nextSeasonStart}
                            active={active}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={routes.SINGLES_REG}
                        render={() => (
                          <SinglesReg
                            session={session}
                            customer={customer}
                            userRefetch={userRefetch}
                            season={season}
                            getSeasonStatus={data.getSeasonStatus[0]}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={routes.DOUBLES_REG}
                        render={() => (
                          <DoublesReg
                            session={session}
                            customer={customer}
                            userRefetch={userRefetch}
                            season={season}
                            getSeasonStatus={data.getSeasonStatus[0]}
                          />
                        )}
                      />
                      <Redirect to={routes.LANDING} />
                    </Switch>
                  </LineBreak>
                </SeasonStatusTableUpdates>
              )}
            </Fragment>
          )}
        </Query>
      </DashboardLayout>
    );
  }
}

// Subscription to listen which the payable toggle switch is active or not.
class SeasonStatusTableUpdates extends Component {
  subscribeToPayableToggle = () => {
    this.props.subscribeToMore({
      document: SEASON_STATUS_SUBSCRIPTION,
      updateQuery: (previousResult, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousResult;
        }
        // Don't need to merge the subscription data because we are using the ID to map the update. This only works if ID is queried.
        return Object.assign({}, previousResult, {
          getSeasonStatus: [...previousResult.getSeasonStatus],
        });
      },
    });
  };

  componentDidMount() {
    this.subscribeToPayableToggle();
  }

  render() {
    return this.props.children;
  }
}

// Welcome screen
class WelcomeDash extends PureComponent {
  render() {
    const { session, season, nextSeasonText, nextSeasonStart, active } = this.props;
    const theDate = new Date(nextSeasonStart);
    const beforeDate = new Date(theDate.setDate(theDate.getDate() - 19));

    return (
      <Fragment>
        <h2>Welcome {session && session.me && session.me.firstname}!</h2>
        <p className="register-intro-text promotion">
          All registrations for both Singles and Doubles leagues are FREE.
        </p>
        <p className="register-intro-text">
          Whether you are a novice, intermediate or advanced player, FTL caters for all skill levels with{' '}
          <strong>over 200 leagues Australia wide</strong> in both Singles and Doubles with dedicated Women’s and Men’s
          Singles leagues available.
          <br />
          <br />
          So partner with a mate and compete in Doubles or battle it solo in a Singles league near you! <br />
          <strong>Simply select an option below to start playing.</strong>
          <br />
          <br />
        </p>
        {active ? (
          <OpenRego season={season} />
        ) : (
          <Ribbon>
            <P color={'white'} bold fontsize={'18px'} lowercase margin={'0'}>
              <span role="img" aria-label="tennis-ball" style={{ display: 'inline-block' }}>
                🎾
              </span>{' '}
              {nextSeasonText} season registrations open {formatDate(beforeDate)}.
            </P>
          </Ribbon>
        )}
      </Fragment>
    );
  }
}

// Open registration with three registration buttons
const OpenRego = ({ season }) => (
  <Fragment>
    <P className="register-intro-text" style={{ color: 'black' }}>
      Registrations are still open for the {season} Season. Register below!
    </P>
    <Flex margin={'55px 0 55px 13px'} className="open-regos-wrapper">
      {/* Singles registrations */}
      <RegisterButton blue singles={true} className="register-button">
        <Link to={routes.SINGLES_REG}>
          <H2 center white>
            Register for play singles
          </H2>
          <div className="border" />
        </Link>
      </RegisterButton>

      {/* Doubles registration */}
      <RegisterButton green className="register-button">
        <Link to={routes.DOUBLES_REG}>
          <H2 center white>
            Register to play doubles
          </H2>
          <div className="border" />
        </Link>
      </RegisterButton>
    </Flex>
  </Fragment>
);

export default withRouter(withAuthorization(session => session && session.me && !session.me.paid)(RegisterPage));
