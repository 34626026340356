import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import 'element-scroll-polyfill';

import * as routes from '../../../constants/routes';

import FixtureProfile from './fixtureProfile';
import FixtureUpload from './fixtureUpload';
import DisplayFixture from './fixtureTable';
import { NoResults } from '../../Error/style';
import { ScrollIndicator } from './style';

class SinglesFixtures extends Component {
  render() {
    const {
      session,
      type,
      year,
      currentSeason,
      currentSeasonDate,
      nextSeasonDate,
      fixtureData,
      fixtureLoading,
      refetchFixtureQuery,
      currentWeek,
      genderType,
      getSeasonStatus,
    } = this.props;

    return (
      <div className="fixture-wrapper">
        {session && session.me && getSeasonStatus.length > 0 && (
          <Fragment>
            <Switch>
              <Route
                exact
                path={routes.SINGLES_FIXTURES}
                render={() => (
                  <FixtureMain
                    fixtureData={fixtureData}
                    loading={fixtureLoading}
                    currentSeason={currentSeason}
                    currentSeasonDate={currentSeasonDate}
                    type={type}
                    year={year}
                    userId={session.me.id}
                    genderType={genderType}
                    currentWeek={currentWeek}
                    getSeasonStatus={getSeasonStatus[0]}
                  />
                )}
              />
              <Route
                exact
                path={routes.SINGLES_PROFILES}
                render={() => <FixtureProfile fixtureData={fixtureData} genderType={genderType} />}
              />
              <Route
                exact
                path={routes.SINGLES_UPLOAD}
                render={() => (
                  <FixtureUpload
                    fixtureData={fixtureData}
                    session={session}
                    refetchFixtureQuery={refetchFixtureQuery}
                    currentWeek={currentWeek}
                    genderType={genderType}
                    nextSeasonDate={nextSeasonDate}
                  />
                )}
              />
              <Redirect to={routes.SINGLES_FIXTURES} />
            </Switch>
          </Fragment>
        )}
      </div>
    );
  }
}

// This is the MAIN fixtures page. If there is data from the "generateFixture" resolver, then display the main content. If not, then display other message.
class FixtureMain extends Component {
  constructor() {
    super();
    this.state = {
      hasScroll: false,
      topPosition: 0,
      leftPosition: 0,
      indicatorHeight: 0,
      arrowBorder: 0,
    };
  }

  scrollElement = element => {
    if (element) {
      this.element = element;
      const clientWidth = element.clientWidth;
      const scrollWidth = element.scrollWidth;
      const topPosition = element.clientHeight / 2;

      if (scrollWidth > clientWidth) {
        this.setState({
          hasScroll: true,
          topPosition,
          indicatorHeight: element.clientHeight / 3,
          arrowBorder: element.clientHeight / 27,
        });
      }
    }
  };

  handleOnScroll = event => {
    const { clientWidth, scrollLeft, scrollWidth } = event.target;
    this.setState({ leftPosition: scrollLeft });
    if (scrollLeft === scrollWidth - clientWidth) {
      this.setState({ hasScroll: false });
    } else {
      this.setState({ hasScroll: true });
    }
  };

  handleRightShift = () => {
    const scrollWidth = this.element.scrollWidth;
    this.element.scrollTo(scrollWidth, 0);
    this.setState({ hasScroll: false });
  };

  render() {
    const {
      fixtureData,
      loading,
      currentSeasonDate,
      currentSeason,
      type,
      year,
      userId,
      genderType,
      currentWeek,
      getSeasonStatus,
    } = this.props;
    const { hasScroll, topPosition, leftPosition, indicatorHeight, arrowBorder } = this.state;

    return (
      <Fragment>
        <h1>Fixtures</h1>
        <div ref={this.scrollElement} onScroll={this.handleOnScroll} className="fixture-table-wrapper">
          {loading ? (
            <h3>Loading Fixtures...</h3>
          ) : fixtureData.getAllFixture.length > 0 ? (
            <DisplayFixture
              getAllFixture={fixtureData.getAllFixture}
              currentSeasonDate={currentSeasonDate}
              currentSeason={currentSeason}
              type={type}
              year={year}
              userId={userId}
              genderType={genderType}
              currentWeek={currentWeek}
            />
          ) : (
            <NoResults>
              <div className="no-results-inner">
                <FontAwesomeIcon icon={faInfoCircle} />

                {getSeasonStatus.matchGenerator ? (
                  <Fragment>
                    <p>There weren't enough players in your league for the current season.</p>
                    <p style={{ marginTop: '6px' }}>A minimum of 4 players is needed.</p>
                  </Fragment>
                ) : (
                  <p>Fixtures are not yet available. Please check back later.</p>
                )}
              </div>
            </NoResults>
          )}
          {hasScroll && (
            <ScrollIndicator
              top={topPosition - indicatorHeight / 2}
              right={leftPosition}
              height={indicatorHeight}
              arrowBorder={arrowBorder}
              onClick={() => this.handleRightShift()}
            >
              <div className="scroll-indicator"></div>
            </ScrollIndicator>
          )}
        </div>
      </Fragment>
    );
  }
}

export default SinglesFixtures;
