import gql from 'graphql-tag';

// Query all with limit
export const ALL_LIFETIME = gql`
  query {
    getAllLifetime {
      id
      points
      wins
      losses
      userId
    }
  }
`;
