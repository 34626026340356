import styled, { theme } from '../../theme';

export const CountDown = styled.p`
  display: inline-block;
  border: 1px solid gray;
  color: gray;
  padding: 7px 17px;
  border-radius: 3px;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-size: 15px;
`;
export const AdminMain = styled.div`
  .admin-section {
    margin-bottom: 20px;
    background-color: #fafafa;
    padding: 15px;
    border-radius: 7px;
  }
  .dead-switch-wrapper {
    border: 2px solid crimson;
    border-radius: 3px;
    h4 {
      color: crimson;
    }
    button {
      background-color: crimson;
      :hover,
      :focus {
        background-color: crimson;
        opacity: 0.9;
      }
    }
    .execution-error {
      margin-top: 6px;
      color: ${theme.crimson};
      font-family: 'calibri';
    }
  }
  h4 {
    margin-top: 0;
    color: ${theme.text};

    svg {
      margin-right: 10px;
    }
  }
  h2 {
    color: ${theme.text};
  }
  p {
    margin-bottom: 5px;
    font-weight: 600;
  }
  button {
    background-color: ${theme.admin.link};
    outline: none;

    :hover,
    :focus {
      background-color: ${theme.admin.linkHover};
    }
    :disabled {
      background-color: ${theme.admin.linkDisabled};
    }
  }

  .dates-section {
    margin-bottom: 50px;
  }

  .statistic-wrapper {
    margin-top: 10px;

    .the-statistic {
      padding: 15px 20px;
      border: 2px dashed #484848;
      border-radius: 5px;
      background-color: white;
      text-align: center;
      margin-right: 10px;

      :last-child {
        margin-right: 0;
      }
      p {
        color: #4e4e4e;
        text-transform: capitalize;
        margin-top: 0;
      }
      > div {
        font-family: 'calibri';
      }
      .registered-in-singles {
        > div {
          background-color: ${theme.breakLine};
          padding: 1px 10px;
          margin-right: 5px;

          :last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .member-access-wrapper {
    .member-access-form-wrapper {
      margin-top: 10px;
      margin-right: 20px;

      .error-message {
        margin-top: 6px;
        color: ${theme.crimson};
        font-family: 'calibri';
      }
      form {
        display: flex;
        flex-direction: column;
        margin-right: 10px;

        label {
          font-family: 'calibri';
        }
        input {
          padding: 7px 10px;
          border: 1px solid #dddddd;
          outline: none;
          border-radius: 2px;
          font-size: 13px;
        }

        :last-child {
          margin-right: 0;
        }
      }
      .admin-form-section {
        width: 100%;

        .admin-access-input-wrapper {
          flex-flow: nowrap;

          input {
            width: 100%;
            margin-right: 10px;

            :last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .view-free-members {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      text-transform: capitalize;
      padding: 10px 15px;
      border: 1px dashed #484848;
      border-radius: 3px;
      background-color: whitesmoke;

      strong {
        font-family: 'calibri';
        color: black;
      }
    }
  }
  .user-list-section {
    p {
      padding: 10px 20px;
      border: 1px dashed #484848;
      border-radius: 3px;
      background-color: whitesmoke;
      margin: 0;
      color: black;
      text-transform: capitalize;
    }

    .list-section {
      padding: 15px 10px 15px 40px;
      border: 1px dashed #484848;
      border-radius: 3px;
      background-color: whitesmoke;

      .the-email {
        font-family: 'calibri';
        font-size: 14px;
      }
    }
  }

  .payable-status {
    .payable-column-1 {
      margin-right: 30px;
    }
    .payable-column-2 {
      .price-section {
        padding: 10px 15px;
        border: 1px dashed #484848;
        border-radius: 3px;
        background-color: whitesmoke;

        strong {
          font-family: calibri;
        }
        form {
          display: flex;
          flex-direction: column;
          width: 200px;
          max-width: 100%;

          input {
            padding: 5px 10px;
            font-size: 12px;
            outline: none;
          }
        }
      }
    }
  }

  .email-promotion-column-1 {
    margin-right: 40px;
  }
  .custom-email-section {
    .inner-form-fields {
      display: flex;
      flex-direction: column;
      border: 1px dashed #484848;
      width: 400px;
      max-width: 100%;
      padding: 10px 15px;
      border-radius: 3px;
      background-color: whitesmoke;

      label {
        font-family: arial;
        margin: 5px 0;
        font-size: 14px;
        color: #484848;
      }
      input,
      textarea {
        padding: 7px 10px;
        margin: 2px 0;
        border: 1px solid ${theme.breakLine};
        outline: none;
        border-radius: 2px;
        font-size: 13px;
      }
    }
    button {
      :disabled {
        background-color: #bebebe;
      }
    }
  }
  .a-form-section {
    .a-form-wrapper form {
      display: flex;
      flex-direction: column;
      font-family: 'calibri';

      :first-child {
        margin-right: 10px;
      }
      input {
        padding: 7px 10px;
        border: 1px solid #dddddd;
        outline: none;
        border-radius: 2px;
        font-size: 13px;
      }
      .error-message {
        margin-top: 6px;
        color: ${theme.crimson};
        font-family: 'calibri';
      }
    }
  }

  .league-status {
    form {
      font-family: 'calibri';

      .input-wrapper {
        display: flex;
        flex-direction: column;
        margin-right: 0.5rem;

        input {
          padding: 7px 10px;
          border: 1px solid #dddddd;
          outline: none;
          border-radius: 2px;
          font-size: 13px;
        }
      }
      button:disabled {
        background-color: darkgray;
      }
      .error-message {
        margin-top: 0.6rem;
        color: ${theme.crimson};
        font-family: 'calibri';
      }
    }
  }

  .control-title {
    background-color: #4e4e4e;
    color: white;
    padding: 3px 20px;
    border-radius: 3px;
    text-transform: uppercase;
    margin-top: 20px;
    font-size: 14px;

    svg {
      margin-right: 10px;
    }
  }
  .status-bar {
    border-radius: 3px;
    height: 20px;
    width: 260px;
    font-family: 'calibri', 'open sans';
    padding: 3px 0;
    font-size: 14px;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 13px;
    border: 1px solid gray;
  }
  .active {
    background-color: limegreen;
    color: white;
    border: none;
  }

  .fixture-gen-wrapper {
    display: flex;
    flex-flow: row wrap;
    margin-top: 10px;
  }
  .fixture-gen-section {
    padding: 15px 20px;
    border: 2px dashed #484848;
    border-radius: 5px;
    background-color: white;
  }
  .fixture-gen-section:first-child {
    margin-right: 20px;
  }
  .fixture-gen-section p {
    margin-top: 0;
  }

  .league-available-1 {
    margin-right: 10px;
  }

  .date-name {
    color: #484848;
  }

  @media (max-width: 732px) {
    .member-access-wrapper {
      div:first-child {
        width: 100%;
      }

      .member-access-form-wrapper {
        flex-direction: column;
        margin-right: 0;
        margin-top: 20px;
        margin-bottom: 20px;

        form {
          margin-right: 0;
          width: 100%;
          margin-bottom: 20px;

          :last-child {
            margin-bottom: 0;
          }
        }
      }
      .view-free-members {
        width: 100%;
      }
    }
  }
  @media (max-width: 765px) {
    .statistic-wrapper .the-statistic {
      margin-right: 0;
      width: 100%;
      margin-top: 5px;
      padding: 9px 20px;
      border: 1px solid #484848;
    }
  }
  @media (max-width: 643px) {
    .fixture-gen-section {
      width: 100%;
      margin-top: 10px;

      :first-child {
        margin-right: 0;
      }
      .status-bar {
        width: 100%;
      }
    }
  }
  @media (max-width: 596px) {
    .admin-access-input-wrapper {
      flex-flow: row wrap !important;
      input {
        margin-right: 0 !important;
        margin-top: 6px !important;

        :first-child {
          margin-top: 0;
        }
      }
    }
  }
  @media (max-width: 327px) {
    .email-promotion-column-1 {
      margin-right: 0;

      button {
        width: 100%;
      }
    }
  }
`;

export const ToggleLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    height: 0;
    width: 0;

    :checked {
      + .slider {
        background-color: ${theme.orange};

        :before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }
      }
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #ccc;
    border-radius: 34px;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    :before {
      position: absolute;
      content: '';
      left: 4px;
      bottom: 4px;
      width: 26px;
      height: 26px;
      background-color: white;
      border-radius: 50%;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
`;
