import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';
import 'element-scroll-polyfill';

import { sanitizeInput } from '../../../../abstracts/helpers';

import { Form, FormInner } from './style';
import { Button } from '../../../Universal/style';
import { CREATE_CHANNEL, CREATE_DIRECT_MESSAGE } from '../../../Universal/queries';

class CreateDirectMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      channelId: null || this.props.getChannelId.channelId,
    };
  }

  // This update occurs when props or state values changes -> second time the component renders.
  // I'm checking the state value of channelId with the props getChannelId.channelId value --> if not same, then update state.
  // Must have a condition in if statement otherwise in infinite loop.
  componentDidUpdate(prevProps, prevState) {
    if (this.props.getChannelId) {
      if (prevState.channelId !== this.props.getChannelId.channelId) {
        this.setState({
          channelId: this.props.getChannelId.channelId,
        });
      }
    }
  }

  // Grabing the input and changing the state of the text state.
  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  // Making setState as async
  setStateAsync = state => {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  };

  // When the user hits Enter, then scroll to the bottom of the message viewport to see the latest message.
  scrollToBottom = () => {
    const messageWrapper = document.getElementById('message-wrapper');

    if (messageWrapper) {
      // The first parameter is the width, and the second is the height. So we are scrolling to the bottom of the scrollHeight.
      const scrollHeight = messageWrapper.scrollHeight;
      messageWrapper.scrollTo(0, scrollHeight);
    }
  };

  // When the form submits --> execute the Mutations createChannelId and then the createDirectMessage.
  // First check if a channelId exists ---> if not, activate the createChannelId
  // Then execute createDirectMessage Mutation resolver.
  onSubmit = async (event, createChannelId, createDirectMessage) => {
    event.preventDefault();
    const { getChannelId } = this.props;

    if (this.state.text.length > 0) {
      // If the channel Id does not exist ---> create a new channel for the user and opponent
      if (getChannelId && getChannelId.channelId === null) {
        await createChannelId().then(({ data }) => {
          this.setState({
            channelId: data.createChannelId.channelId,
          });
        });
      }

      await createDirectMessage();
      // Set text state back to default value.
      await this.setStateAsync({ text: '' });
      // Scroll to the bottom of the message screen for every new message.
      await this.scrollToBottom();
    }
  };

  render() {
    const { opponentIdDecoded, doubles, genderType } = this.props;
    const { channelId } = this.state;
    let { text } = this.state;
    const isInvalid = text === '';

    // Sanitize inputs
    text = sanitizeInput({ string: text });

    return (
      <Mutation mutation={CREATE_CHANNEL} variables={{ opponentId: opponentIdDecoded }}>
        {createChannelId => (
          <Mutation
            mutation={CREATE_DIRECT_MESSAGE}
            variables={{
              channelId,
              text,
              opponentId: opponentIdDecoded,
            }}
          >
            {createDirectMessage => (
              <Form onSubmit={event => this.onSubmit(event, createChannelId, createDirectMessage)}>
                <FormInner>
                  <input
                    type="text"
                    name="text"
                    placeholder="Enter your message here..."
                    onChange={this.onChange}
                    value={text}
                  />
                  <Button doubles={doubles} genderType={genderType} type="submit" disabled={isInvalid}>
                    Send
                  </Button>
                </FormInner>
              </Form>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}

export default CreateDirectMessage;
