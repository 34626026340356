import styled, { css, theme } from '../../../../theme';

export const ScoresPendingWrapper = styled.div`
  display: inline-block;
  border: 1px solid ${theme.breakLine};
  padding: 10px 25px;
  border-radius: 4px;
  ${props =>
    props.borderColor &&
    css`
      border: 1px solid ${props.borderColor};
    `};
`;
export const PendingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid lightgray;
  padding: 0 12px;
  font-size: 16px;
  font-family: 'roboto-condensed';
  ${props =>
    props.MB &&
    css`
      margin-bottom: ${props.MB};
    `};
  ${props =>
    props.border &&
    css`
      border: 1px solid ${props.border};
    `};
`;
export const DenyFormWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.75);
  margin-top: -${props => props.MT}px;
`;
export const DenyFormInner = styled.div`
  display: inline-table;
  background-color: white;
  border: 1px solid ${theme.breakLine};
  max-width: 100%;
  padding: 10px 25px 30px 25px;
  border-radius: 7px;
  justify-content: center;
  margin-bottom: 30px;
`;
export const Cross = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 24px;
  color: crimson;
  cursor: pointer;

  :hover {
    color: #ff3232;
  }
`;
