import styled, { theme } from '../../../theme';

export const Wrapper = styled.div`
  .section {
    margin-bottom: 40px;

    .grid {
      display: grid;

      .overflow {
        overflow-x: scroll;
      }
    }
    #check-users {
      border-bottom: 1px solid ${theme.breakLine};
      margin-bottom: 40px;
    }
    .table {
      display: table;
      width: 100%;
      margin-bottom: 40px;

      .table-row-user,
      .table-row-team {
        cursor: pointer;
      }
      .active {
        background-color: limegreen !important;

        .table-cell p {
          color: white;
        }
      }
      .table-row {
        display: table-row;

        :first-child .table-cell p {
          color: black;
        }
        :nth-child(2n + 2) {
          background-color: #dedede;
        }
        .table-cell {
          display: table-cell;
          text-align: center;
          p {
            padding: 0;
            margin: 7px 7px 7px 0;
          }
        }
        .email-cell {
          text-align: left;
        }
      }
    }
    .update-form {
      margin-bottom: 40px;
      padding: 15px 30px;
      background-color: #eeee;
      border-radius: 4px;

      .flex {
        display: flex;
        justify-content: space-between;

        h3 {
          margin-top: 0;
        }
        span {
          display: inline-block;
          color: #484848;
          font-family: 'roboto-condensed';
        }
      }
      form {
        display: flex;
        flex-direction: column;

        .form-content {
          display: flex;

          .field {
            margin-right: 10px;
          }
        }
      }
      .error {
        color: crimson;
      }
    }
  }

  @media (max-width: 500px) {
    .update-form form .form-content {
      flex-direction: column;
    }
  }
`;
