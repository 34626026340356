import React, { Component, Fragment } from 'react';
import { Mutation } from '@apollo/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { Image, Transformation } from 'cloudinary-react';

import { sanitizeInput } from '../../../abstracts/helpers';

import { Adjust, Flex, Col, ButtonWrapper, Button, H4, InputWrapper } from '../../Universal/style';
import { Input, ProfileImageIcon, PartnerNotification } from './style';
import Loading from '../../Loading';
import ErrorMessage from '../../Error';
import { MUTATION_UPDATE_TEAM, MUTATION_UPDATE_TEAM_IMAGE } from './queries';

import placeholderProfile from '../../../resources/images/placeholder-profile.jpg';
import imageLoader from '../../../resources/images/image-loader.svg';
import EmailSettingSection from '../../Account/emailSetting';

/******************** Profile Form *******************/
class TeamProfileForm extends Component {
  constructor(props) {
    super(props);
    const { getTeam } = this.props;
    this.state = {
      ownerId: getTeam.ownerId,
      profileImage: getTeam.profileImage || null,
      name: getTeam.name,
      tennisCourt: getTeam.tennisCourt || '',
      playTime: getTeam.playTime || '',

      invalid: true,
      edit: false,
      imageLoading: false,
    };
  }

  enableForm = () => {
    if (this.state.edit !== true) {
      this.setState({
        edit: true,
        invalid: false,
      });
    }
  };

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    this.setState({
      invalid: false,
    });
  };

  // Making this.setState Syncronous
  setStateAsync = state => {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  };

  onDrop = async (files, rejectedFiles, updateTeamImage) => {
    if (files) {
      await this.setStateAsync({
        imageLoading: true,
      });
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('upload_preset', process.env.REACT_APP_UPLOAD_PRESET_DOUBLES);

      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`,
        formData
      );

      if (response) {
        await this.setStateAsync({
          profileImage: response.data.public_id,
        });
        await updateTeamImage();
        await this.setStateAsync({
          imageLoading: false,
        });
        await this.props.refetch();
      }
    }

    if (files[0]) {
      this.setState({ invalid: false });
    }
  };

  onSubmit = async (event, updateTeamInfo) => {
    event.preventDefault();

    await updateTeamInfo().then(async ({ data }) => {
      this.setState({
        invalid: true,
        edit: false,
      });

      await this.props.refetch();
    });
  };

  render() {
    const { ownerId, profileImage, invalid, edit, imageLoading } = this.state;
    let { name, tennisCourt, playTime } = this.state;
    const { userId, allowEmail } = this.props;

    // Sanitize inputs
    name = sanitizeInput({ string: name });
    tennisCourt = sanitizeInput({ string: tennisCourt });
    playTime = sanitizeInput({ string: playTime });

    return (
      <Mutation
        mutation={MUTATION_UPDATE_TEAM}
        variables={{
          tennisCourt,
          playTime,
        }}
      >
        {(updateTeamInfo, { loading, error }) => (
          <Mutation mutation={MUTATION_UPDATE_TEAM_IMAGE} variables={{ profileImage }}>
            {updateTeamImage => (
              <Fragment>
                <form
                  autoComplete="off"
                  onSubmit={event => this.onSubmit(event, updateTeamInfo)}
                  className="team-profile-form"
                >
                  <Flex>
                    <Col digit={'25%'} padding={'0 20px 0 0'} className="team-image-section" column>
                      <Dropzone onDrop={(files, rejectedFiles) => this.onDrop(files, rejectedFiles, updateTeamImage)}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <Adjust
                              relative
                              {...getRootProps()}
                              noOutline
                              cursor="true"
                              fullWidth
                              style={{ display: 'inline-block' }}
                            >
                              <input {...getInputProps()} />

                              <ProfileImageIcon imageLoading={imageLoading}>
                                {imageLoading ? (
                                  <img style={{ zIndex: '1' }} src={imageLoader} alt="loader" />
                                ) : (
                                  <FontAwesomeIcon icon={faCamera} />
                                )}
                              </ProfileImageIcon>

                              {profileImage === null ? (
                                <img
                                  className="profile-image"
                                  src={placeholderProfile}
                                  alt="placeholder-profile-flexi"
                                />
                              ) : (
                                // This is executed when the "profileImage" state is filled.
                                // This means that the user has already uploaded then image and is retrieved from cloudinary.
                                <Image
                                  className="profile-image"
                                  cloudName={process.env.REACT_APP_CLOUD_NAME}
                                  publicId={profileImage}
                                >
                                  <Transformation width="250" height="250" gravity="faces" crop="fill" />
                                </Image>
                              )}
                            </Adjust>
                            <H4 center MT={'10px'} className="image-text">
                              Team Image
                            </H4>
                          </section>
                        )}
                      </Dropzone>

                      {/* Section for email setting */}
                      {ownerId === userId && <EmailSettingSection allowEmail={allowEmail} />}
                    </Col>
                    <Col digit={'70%'} noFlex padding={'0'} className="team-main-section">
                      <InputWrapper flex>
                        <H4 noMB MT={'0'}>
                          Team Name
                        </H4>
                        <Input disabled={true} name="name" value={name} type="text" placeholder="Team Name" />
                        <H4 noMB>
                          Team favourite tennis court
                          <span>?</span>
                        </H4>
                        <Input
                          disabled={invalid}
                          name="tennisCourt"
                          value={tennisCourt}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Eg. Mt Lawley Tennis Courts"
                        />
                        <H4 noMB>
                          Team preferred playing day
                          <span>(</span>s<span>)</span> and Time<span>(</span>s<span>)</span>
                        </H4>
                        <Input
                          disabled={invalid}
                          name="playTime"
                          value={playTime}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Eg. Weekend evenings after 6pm"
                        />
                      </InputWrapper>

                      {/* If ownerId of the "Team" is the same as the current user then show edit button --> ONLY owner of team can see this */}
                      {ownerId === userId ? (
                        <ButtonWrapper>
                          <Button
                            normal
                            doubles
                            onClick={() => this.enableForm()}
                            disabled={edit || loading}
                            type="button"
                          >
                            Edit Info
                          </Button>
                          <Button normal doubles disabled={invalid || loading} type="submit">
                            {loading ? <Loading /> : 'Update'}
                          </Button>
                        </ButtonWrapper>
                      ) : (
                        <PartnerNotification>Only team owners can update</PartnerNotification>
                      )}
                    </Col>
                  </Flex>

                  {error && (
                    <div style={{ marginTop: '10px' }}>
                      <ErrorMessage error={error} />
                    </div>
                  )}
                </form>
              </Fragment>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}

export default TeamProfileForm;
