import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import Tilt from 'react-tilt';

import * as routes from '../../constants/routes';
import ErrorMessage from '../Error';
import Loading from '../Loading';
import isLoggedIn from '../Session/isLoggedIn';
import Footer from '../Footer';

import { ForgotForm } from './index';
import ResetForm from './resetForm';
import AutomaticBackground from '../SignIn/automaticBackground';
import logo from '../../resources/images/logo-white-bk-circle.png';

import { Flex, Button } from '../Universal/style';
import { SignInWidth } from '../SignIn/style';

const CHECK_RESET_TOKEN = gql`
  mutation($token: String!) {
    checkResetToken(token: $token) {
      result
    }
  }
`;

const ResetPage = props => {
  let { token } = props.match.params;
  if (!token) token = '';
  return (
    <AutomaticBackground>
      <SignInWidth>
        <Flex noWrap between>
          <h1>Reset password</h1>
          <Tilt className="Tilt" options={{ max: 35, scale: 1.05 }}>
            <div className="Tilt-inner">
              <img style={{ width: '80px' }} src={logo} alt="Logo" />
            </div>
          </Tilt>
        </Flex>
        <Mutation mutation={CHECK_RESET_TOKEN} variables={{ token }}>
          {(checkResetToken, { loading, error }) => (
            <StartMutationOnMount checkResetToken={checkResetToken} {...props} loading={loading} checkError={error}>
              <ResetForm refetch={props.refetch} {...props} />
              <Link to={routes.SIGN_IN} style={{ width: '100%' }}>
                <Button>Back to Login</Button>
              </Link>
            </StartMutationOnMount>
          )}
        </Mutation>
      </SignInWidth>
      <Footer />
    </AutomaticBackground>
  );
};

class StartMutationOnMount extends Component {
  componentDidMount() {
    this.props.checkResetToken();
  }

  render() {
    const { checkError, loading } = this.props;
    return (
      <div>
        {loading && <Loading />}
        {checkError ? (
          <div style={{ marginBottom: '20px' }}>
            <ErrorMessage error={checkError} />
            <ForgotForm refetch={this.props.refetch} />
            <Link to={routes.SIGN_IN} style={{ width: '100%' }}>
              <Button>Back to Login</Button>
            </Link>
          </div>
        ) : (
          <div style={{ marginBottom: '20px' }}>{this.props.children}</div>
        )}
      </div>
    );
  }
}

export default withRouter(isLoggedIn(session => session && session.me)(ResetPage));
