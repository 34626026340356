import gql from 'graphql-tag';

// Remove paid membership status of user on season end
export const REMOVE_MEMBERSHIP_MUTATION = gql`
  mutation {
    removeMembership
  }
`;

export const REMOVE_CONFETTI_MUTATION = gql`
  mutation {
    removeConfetti
  }
`;

/******************* Fetching all play infos ******************/
export const GET_PLAYINFO = gql`
  query {
    getPlayInfo {
      subLeagueId
      genderType
    }
  }
`;

/******************* Admin screen - Query all fixture --> determining if fixture has been generated yet ******************/
export const GET_COMPLETE_FIXTURES = gql`
  query {
    adminGetAllFixture {
      score
      winnerId
    }
  }
`;

// Generate finals fixture
export const GENERATE_FINALS_FIXTURE = gql`
  mutation($seasonDate: String!) {
    finalFixture(seasonDate: $seasonDate)
  }
`;
// Fetch week 10 fixtures
export const FINALS_FIXTURE_QUERY = gql`
  query {
    adminGetWeek10Fixture {
      id
      score
      winnerId
    }
  }
`;

/******************* Query current year season dates ******************/
export const CURRENT_YEAR_SEASON_DATES = gql`
  query {
    currentYearSeasonDates {
      id
      year
      previousSpring
      summer
      autumn
      winter
      spring
      nextSummer
    }
  }
`;

/******************* Right Sidebar ******************/
// Query - Weekly results
export const GET_RESULTS = gql`
  query($week: Int!) {
    getResults(week: $week) {
      id
      score
      loserId
      winnerId
    }
  }
`;
// Query - Weekly results -> Mobile
export const GET_RESULTS_MOBILE = gql`
  query($week: Int!) {
    getResultsMobile(week: $week) {
      id
      score
      loserId
      winnerId
    }
  }
`;

// Subscription to listen to new submitted results to the "Scores" database table
export const NEW_RESULT_SUBSCRIPTION = gql`
  subscription($playType: String!, $leagueId: ID!, $subLeagueId: ID!, $week: Int!, $genderType: String!) {
    newResultSubmission(
      playType: $playType
      leagueId: $leagueId
      subLeagueId: $subLeagueId
      week: $week
      genderType: $genderType
    ) {
      id
      score
      loserId
      winnerId
    }
  }
`;
// Query - Leaderboard on rightsidebar
export const GET_LEADERBOARD = gql`
  query {
    getLeaderboard {
      id
      points
      wins
      losses
      userId
    }
  }
`;
// Subscription to listen to new submitted results to the "Scores" database table
export const UPDATED_LEADERBOARD_SUBSCRIPTION = gql`
  subscription($playType: String!, $leagueId: ID!, $subLeagueId: ID!, $genderType: String!) {
    updatedLeaderboard(playType: $playType, leagueId: $leagueId, subLeagueId: $subLeagueId, genderType: $genderType) {
      id
      points
      wins
      losses
      userId
    }
  }
`;

/******************* Signout Resolver ******************/
export const USER_SIGN_OUT = gql`
  mutation {
    offlineSignout
  }
`;

/******************* Singles Parent ******************/
export const GET_LEAGUE_INFO = gql`
  {
    getLeagueInfo {
      id
      location
      skill
    }
  }
`;

/******************* Fixture Screens - Parent ******************/
export const GENERATE_FIXTURE = gql`
  mutation($season: String!, $seasonDate: String!) {
    generateFixture(season: $season, seasonDate: $seasonDate)
  }
`;
// Subscription to listen out for updated Fixture data --> when a player approves a score and it gets updated in the database.
// Need this to update the fixture table at the Fixture Parent Page
export const UPDATED_FIXTURE_SUBSCRIPTION = gql`
  subscription UpdatedFixture($userId: ID!) {
    updatedFixture(userId: $userId) {
      id
      week
      startDate
      score
      playType
      winnerId
      opponentId
      pendingScore
      leagueId
      subLeagueId
      userId
    }
  }
`;
// Fetch FINAL fixture for week 9 - to display upcoming opponent for week 10 and on week 10 the final opponent - left sidebar && Fixture table.
export const GET_FINAL_FIXTURE = gql`
  query($season: String!, $playType: String!, $week: String!, $year: String!) {
    getFinalFixture(season: $season, playType: $playType, week: $week, year: $year) {
      id
      week
      startDate
      opponentId
      score
    }
  }
`;

export const OPPONENT_QUERY = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      online
      profileImage
      firstname
      lastname
      email
      about
      tennisCourt
      playTime
      role
      confirmed
      createdAt
      paid
      playInfo {
        id
        season
        year
        playType
        paid
        leagueId
      }
    }
  }
`;

/******************* Fixture Screens - Fixture Profile ******************/
export const GET_USER = gql`
  query($id: ID!) {
    user(id: $id) {
      profileImage
      firstname
      lastname
      email
      about
      tennisCourt
      playTime
      confirmed
      createdAt
      playInfo {
        id
        season
        year
        playType
        paid
        leagueId
      }
    }
  }
`;

/******************* Fixture Screens - Fixture Profile - Fixture Upload - Parent ******************/
// Fetch the current week fixture
export const CURRENT_FIXTURE_QUERY = gql`
  query($fixtureId: ID!) {
    getCurrentFixture(fixtureId: $fixtureId) {
      id
      week
      startDate
      opponentId
      pendingScore
      score
    }
  }
`;
// Query score to approve
export const GET_SCORE_APPROVAL = gql`
  query($fixtureId: ID) {
    getScoreApproval(fixtureId: $fixtureId) {
      id
      score
      winnerId
      isApproved
      opponentId
      opponentFixtureId
      userId
      userFixtureId
      adjustment
    }
  }
`;

/******************* Fixture Screens - Fixture Profile - Fixture Upload - Pending Approval ******************/
// Listen for when a score needs to be approved
export const PENDING_SUBSCRIPTION = gql`
  subscription PendingApproval($fixtureId: ID!) {
    pendingScoreReceived(fixtureId: $fixtureId) {
      id
      score
      winnerId
      isApproved
      opponentId
      opponentFixtureId
      userId
      userFixtureId
      adjustment
    }
  }
`;

// Approve the score
export const APPROVE_SCORE = gql`
  mutation(
    $scoreApprovalId: ID!
    $opponentFixtureId: ID!
    $userFixtureId: ID!
    $score: String!
    $currentWeek: Int!
    $nextSeasonDate: String!
  ) {
    approveScore(
      scoreApprovalId: $scoreApprovalId
      opponentFixtureId: $opponentFixtureId
      userFixtureId: $userFixtureId
      score: $score
      currentWeek: $currentWeek
      nextSeasonDate: $nextSeasonDate
    ) {
      score
    }
  }
`;

/******************* Fixture Screens - Fixture Profile - Fixture Upload - Upload Score Form ******************/
export const SEND_PENDING_SCORE = gql`
  mutation(
    $type: String!
    $fixtureId: ID!
    $opponentId: ID!
    $score: [[String]]!
    $checkedWinner: Int
    $adjustment: String
    $feedback: String
  ) {
    scoreApproval(
      type: $type
      fixtureId: $fixtureId
      opponentId: $opponentId
      score: $score
      checkedWinner: $checkedWinner
      adjustment: $adjustment
      feedback: $feedback
    ) {
      id
      score
      winnerId
    }
  }
`;

/******************* Message Screens - Parent ******************/
// Query all fixture information for the season
export const FIXTURE_QUERY = gql`
  query($season: String!, $playType: String!, $year: String!) {
    getAllFixture(season: $season, playType: $playType, year: $year) {
      id
      week
      startDate
      score
      playType
      winnerId
      opponentId
      pendingScore
      leagueId
      subLeagueId
      userId
    }
  }
`;

// New opponent message subscription
export const NEW_OPPONENT_MESSAGE_SUBSCRIPTION = gql`
  subscription($opponentId: ID, $userId: ID) {
    newOpponentMessage(opponentId: $opponentId, userId: $userId) {
      id
      opponentId
      userId
      notification
    }
  }
`;
// This Query is to notify the user of new Opponent messages
export const NEW_OPPONENT_MESSAGE = gql`
  query($opponentId: ID!) {
    getOpponentMessage(opponentId: $opponentId) {
      id
      opponentId
      userId
      notification
    }
  }
`;
// Set the notification to false when the user lands on the chat URL for an opponent
export const REMOVE_NOTIFICATION = gql`
  mutation($opponentId: ID) {
    removeNotification(opponentId: $opponentId)
  }
`;

/******************* Message Screens - message Screen ******************/
// Subscribe to the channel that belongs to both the user and the opponent
export const CHANNEL_SUBSCRIPTION = gql`
  subscription($opponentId: ID!, $userId: ID!) {
    newChannel(opponentId: $opponentId, userId: $userId) {
      opponentId
      userId
      channelId
    }
  }
`;
// Query the channel that belongs to both the user and the opponent
export const CHANNEL_QUERY = gql`
  query($opponentId: ID!) {
    getChannelId(opponentId: $opponentId) {
      opponentId
      userId
      channelId
    }
  }
`;

// Subscribe to the direct messages
export const DIRECT_MESSAGE_SUBSCRIPTION = gql`
  subscription($channelId: ID) {
    newDirectMessage(channelId: $channelId) {
      id
      text
      userId
      opponentId
      createdAt
    }
  }
`;
// Query direct messages for the user
export const DIRECT_MESSAGE_QUERY = gql`
  query($cursor: String, $channelId: ID) {
    directMessages(cursor: $cursor, channelId: $channelId) {
      edges {
        id
        text
        userId
        opponentId
        createdAt
      }
      directMessageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

// Subscribe to updated user information
export const UPDATED_USER_SUBSCRIPTION = gql`
  subscription OnlineChecker($userId: ID) {
    onlineChecker(userId: $userId) {
      id
      online
      profileImage
      firstname
      lastname
    }
  }
`;

/******************* Message Screens - message Screen - create messages ******************/
// Create a new channel for user and opponent if a channel doesn't exist for both
export const CREATE_CHANNEL = gql`
  mutation($opponentId: ID!) {
    createChannelId(opponentId: $opponentId) {
      opponentId
      userId
      channelId
    }
  }
`;
// Create a direct message
export const CREATE_DIRECT_MESSAGE = gql`
  mutation($channelId: ID!, $text: String!, $opponentId: ID!) {
    createDirectMessage(channelId: $channelId, text: $text, opponentId: $opponentId) {
      text
      userId
      channelId
      opponentId
    }
  }
`;
