import styled, { theme, css } from '../../../theme';

// The master styling component for DoublesTeam Component
export const MasterStyle = styled.div`
  .partner-section {
    margin-top: 50px;
    border-top: 1px solid ${theme.breakLine};

    .partner-options-section {
      margin-bottom: 20px;
      align-items: center;

      .invite-sent {
        color: green;
        font-family: 'calibri';
        margin-right: 10px;
        padding: 10px 15px;
        border: 1px dashed green;
        border-radius: 4px;
      }
      .partner-option {
        font-family: 'calibri';
        padding: 10px 15px;
        background-color: lightgray;
        margin-right: 10px;
        cursor: pointer;
        border-radius: 3px;

        :hover {
          opacity: 0.8;
        }
      }
    }
    form {
      display: flex;
      flex-direction: column;
      width: 260px;
      max-width: 100%;

      label {
        font-family: 'calibri';
        text-transform: capitalize;
        margin-bottom: 5px;
        color: #484848;
      }
      input {
        padding: 8px 10px;
        font-size: 14px;
        border: 1px solid #d0d0d0;
        border-radius: 3px;
        outline: none;
      }
    }
    .partner-box {
      display: inline-block;
      text-align: center;

      a > p {
        text-transform: capitalize;
        color: #086342;
        font-weight: 600;
      }
      a:hover {
        opacity: 0.8;
      }
    }
    .partner-image {
      width: 100px;
      max-height: 80px;
      overflow: hidden;
      border-radius: 4px;
    }
    p span {
      display: inline-block;
      color: ${theme.orange};
      font-weight: 900;
    }
  }
`;

// Team page --> partner notification
export const PartnerNotification = styled.p`
  display: inline-block;
  margin-bottom: 0;
  padding: 5px 25px;
  background-color: #4e4e4e;
  color: white;
  text-transform: capitalize;
  border-left: 5px solid crimson;
`;

export const ProfileImageIcon = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  color: ${theme.orange};

  svg {
    z-index: 1;
  }

  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${props => (props.imageLoading ? 'rgba(255, 255, 255, 0.65)' : 'rgba(255, 255, 255, 0.4)')};
    z-index: 0;
  }
`;
export const Input = styled.input`
  width: 100%;
  max-width: 100%;
  margin: 5px 0;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  outline: none;
  ${props =>
    props.MR &&
    css`
      margin-right: ${props.MR};
    `};

  :disabled {
    color: darkgray;
  }
  ::placeholder {
    font-style: italic;
  }
`;
export const TextArea = styled.textarea`
  width: 100%;
  max-width: 100%;
  margin: 5px 0;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  outline: none;
  :disabled {
    color: darkgray;
  }
  ::placeholder {
    font-style: italic;
  }
`;
