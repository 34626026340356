import React, { Component, Fragment } from 'react';
import { Query, Mutation } from '@apollo/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import 'element-scroll-polyfill';

import { Flex, P, H3, H2, H5, Adjust, Button } from '../../../Universal/style';
import { ScoresPendingWrapper, PendingBox, DenyFormWrapper, DenyFormInner, Cross } from './style';
import UploadScoreForm from './uploadScoreForm';
import { GET_SCORE_APPROVAL_DOUBLES, DOUBLES_APPROVE_SCORE } from './queries';
import { GET_OTHER_TEAM } from '../queries';
import { ScrollIndicator } from '../../../Singles/fixtures/style';
import {
  GET_DOUBLES_RESULTS,
  GET_DOUBLES_RESULTS_MOBILE,
  GET_DOUBLES_LEADERBOARD,
} from '../../../Universal/RightsideBar/doubles/queries';

class DisplayPendingApproval extends Component {
  constructor() {
    super();
    this.state = {
      deny: false,

      hasScroll: false,
      topPosition: 0,
      leftPosition: 0,
      indicatorHeight: 0,
      arrowBorder: 0,
    };
  }

  // For the scroll handling -- START
  scrollElement = element => {
    if (element) {
      this.element = element;
      const clientWidth = element.clientWidth;
      const scrollWidth = element.scrollWidth;
      const topPosition = element.clientHeight / 2;

      if (scrollWidth > clientWidth) {
        this.setState({
          hasScroll: true,
          topPosition,
          indicatorHeight: element.clientHeight / 3,
          arrowBorder: element.clientHeight / 27,
        });
      }
    }
  };

  handleOnScroll = event => {
    const { clientWidth, scrollLeft, scrollWidth } = event.target;

    this.setState({ leftPosition: scrollLeft });
    if (scrollLeft === scrollWidth - clientWidth) {
      this.setState({ hasScroll: false });
    } else {
      this.setState({ hasScroll: true });
    }
  };

  handleRightShift = () => {
    const scrollWidth = this.element.scrollWidth;
    this.element.scrollTo(scrollWidth, 0);
    this.setState({ hasScroll: false });
  };
  // For the scroll handling -- END

  restartDeny = () => {
    this.setState({ deny: false });
  };

  onDeny = () => {
    this.setState({ deny: true });
  };

  render() {
    // For scroll handling
    const { hasScroll, topPosition, leftPosition, indicatorHeight, arrowBorder } = this.state;
    const halfHeight = indicatorHeight / 2;

    const {
      fixtureId,
      getScoreApprovalDoubles,
      currentTeamId,
      currentTeam,
      opponentTeamId,
      opponentTeamName,
      refetch,
      refetchFixtureQuery,
      currentWeek,
      height,
      refCallback,
      nextSeasonDate,
    } = this.props;
    const { deny } = this.state;

    // This displays the score box - put in function so it can be re-used.
    const scoresBox = (color, teamWinnerId, scoreArray) => (
      <ScoresPendingWrapper borderColor={color} style={{ display: 'inline-grid' }}>
        <div
          style={{ overflowX: 'scroll', position: 'relative' }}
          ref={this.scrollElement}
          onScroll={this.handleOnScroll}
        >
          <Flex MT={'30px'} alignCenter noWrap>
            <Flex style={{ marginRight: '10px', width: '300px' }} grow>
              <Fragment>
                <H2 width={'100%'} margin={'0 0 10px 0'} color={color}>
                  Winning Team:
                </H2>
                <Query query={GET_OTHER_TEAM} variables={{ id: +teamWinnerId }}>
                  {({ data, loading }) => (
                    <Fragment>
                      {loading && <div>Loading...</div>}
                      {data && data.getOtherTeam && <H3 margin={'0'}>{data.getOtherTeam.name}</H3>}
                    </Fragment>
                  )}
                </Query>
              </Fragment>
            </Flex>
            <Flex center alignCenter noWrap>
              {scoreArray.length > 0 &&
                scoreArray.map((eachScore, i) => (
                  <Adjust key={i} MR={'5px'}>
                    <PendingBox MB={'5px'} border={color}>
                      {eachScore[0]}
                    </PendingBox>
                    <PendingBox border={color}>{eachScore[1]}</PendingBox>
                  </Adjust>
                ))}
            </Flex>
          </Flex>
          {hasScroll && (
            <ScrollIndicator
              top={topPosition - halfHeight}
              right={leftPosition}
              height={indicatorHeight}
              arrowBorder={arrowBorder}
              onClick={() => this.handleRightShift()}
            >
              <div className="scroll-indicator"></div>
            </ScrollIndicator>
          )}
        </div>
      </ScoresPendingWrapper>
    );

    if (
      getScoreApprovalDoubles &&
      getScoreApprovalDoubles.length > 0 &&
      getScoreApprovalDoubles[0].score !== null &&
      getScoreApprovalDoubles[0].teamId === currentTeamId
    ) {
      const { score, teamWinnerId, adjustment } = getScoreApprovalDoubles[0];
      const scoreArray = JSON.parse(score);
      return (
        <Fragment>
          <div ref={refCallback}>
            <div>
              <P color={'black'} fontsize={'20px'}>
                <span className="orange">{opponentTeamName}</span> has submitted the following scores for approval:
              </P>
            </div>
            {scoresBox('darkorange', teamWinnerId, scoreArray)}
            {adjustment && adjustment.length > 0 && (
              <Adjust MT={'35px'} MB={'40px'}>
                <H5 color={'dimgray'} MB={'6px'} fontsize={'17px'}>
                  Opponents Comment
                </H5>
                <P MT={'0'} font={'open sans'} color={'crimson'} className="score-adjustment-section">
                  {adjustment}
                </P>
              </Adjust>
            )}
            <div>
              <P>
                Please either <span className="black">ACCEPT</span> or <span className="black">DENY</span> the above
                scores. Accepting will submit the scores.
              </P>
              <Flex>
                <div style={{ marginRight: '10px' }}>
                  <ApproveScoreButton
                    getScoreApprovalDoubles={getScoreApprovalDoubles[0]}
                    refetchFixtureQuery={refetchFixtureQuery}
                    currentWeek={currentWeek}
                    nextSeasonDate={nextSeasonDate}
                    currentTeamId={currentTeamId}
                  />
                </div>
                <Button normal red onClick={() => this.onDeny()}>
                  Deny
                </Button>
              </Flex>
            </div>
          </div>
          {deny && (
            <DenyFormWrapper MT={height}>
              <DenyFormInner>
                <UploadScoreForm
                  type={'update'}
                  fixtureId={fixtureId}
                  opponentTeamId={opponentTeamId}
                  opponentTeamName={opponentTeamName}
                  currentTeam={currentTeam}
                  refetch={refetch}
                  restartDeny={this.restartDeny}
                />
              </DenyFormInner>
              <Cross onClick={() => this.restartDeny()}>
                <FontAwesomeIcon icon={faTimesCircle} />
              </Cross>
            </DenyFormWrapper>
          )}
        </Fragment>
      );
    } else {
      return this.props.children;
    }
  }
}

class ApproveScoreButton extends Component {
  constructor() {
    super();
    this.state = {
      isPending: true,
    };
  }

  onSubmit = async (event, approveScore) => {
    event.preventDefault();
    const { isPending } = this.state;

    if (isPending) {
      this.setState({ isPending: false });
      await approveScore();
      // The "refetchFixtureQuery" comes from the Fixture parent index.js file where we are fetching the Fixture Query
      await this.props.refetchFixtureQuery();
    }
  };

  render() {
    const { id, opponentTeamFixtureId, teamFixtureId, score } = this.props.getScoreApprovalDoubles;
    // CurrentWeek comes from the parent singles page. It will determine if the player has played in the week of schedule. If so, then in the scoreApproval "approveScore" resolver, add 1 point to both players.
    const { currentWeek, nextSeasonDate, currentTeamId } = this.props;

    return (
      <Mutation
        mutation={DOUBLES_APPROVE_SCORE}
        variables={{
          scoreApprovalId: id,
          opponentTeamFixtureId,
          teamFixtureId,
          score,
          currentWeek,
          nextSeasonDate,
        }}
        refetchQueries={() => {
          return [
            {
              query: GET_SCORE_APPROVAL_DOUBLES,
              variables: {
                fixtureId: teamFixtureId,
              },
            },
            {
              query: GET_DOUBLES_RESULTS,
              variables: {
                week: currentWeek,
              },
            },
            {
              query: GET_DOUBLES_RESULTS_MOBILE,
              variables: {
                week: currentWeek,
              },
            },
            {
              query: GET_DOUBLES_LEADERBOARD,
              variables: {
                teamId: currentTeamId,
              },
            },
          ];
        }}
      >
        {approveScore => (
          <form onSubmit={event => this.onSubmit(event, approveScore)}>
            <Button normal green>
              Accept
            </Button>
          </form>
        )}
      </Mutation>
    );
  }
}

export default DisplayPendingApproval;
