import React from 'react';

const customFindObject = (array, callback) => {
  let index = -1;
  let result = [];
  for (let i = 0; i < array.length; i++) {
    if (callback(array[i])) {
      index = i;
      result.push(array[i]);
    }
  }
  return {
    index,
    result,
  };
};

const IndividualLifetime = ({ session, getAllLifetime }) => {
  const hasResult = customFindObject(getAllLifetime, object => object.userId === session.me.id);
  return (
    <div className="section-wrapper">
      <h4>Your Rank</h4>
      <div className="table-wrapper">
        <div className="table">
          <div className="table-row heading individual">
            <div className="table-cell">
              <p>Rank</p>
            </div>
            <div className="table-cell">
              <p>Points</p>
            </div>
            <div className="table-cell">
              <p>Wins</p>
            </div>
            <div className="table-cell">
              <p>Losses</p>
            </div>
          </div>
          {hasResult && hasResult.index > -1 ? (
            <div className="table-row">
              <div className="table-cell">
                <p>{hasResult.index + 1}</p>
              </div>
              <div className="table-cell">
                <p>{hasResult.result[0].points}</p>
              </div>
              <div className="table-cell">
                <p>{hasResult.result[0].wins}</p>
              </div>
              <div className="table-cell">
                <p>{hasResult.result[0].losses}</p>
              </div>
            </div>
          ) : (
            <NoResults />
          )}
        </div>
      </div>
    </div>
  );
};

const NoResults = () => (
  <div className="table-row">
    <div className="table-cell">
      <p>N/A</p>
    </div>
    <div className="table-cell">
      <p>0</p>
    </div>
    <div className="table-cell">
      <p>0</p>
    </div>
    <div className="table-cell">
      <p>0</p>
    </div>
  </div>
);

export default IndividualLifetime;
