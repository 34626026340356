import React, { Component, Fragment } from 'react';
import { Query } from '@apollo/react-components';
import { Link } from 'react-router-dom';
import { Image, Transformation } from 'cloudinary-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

import { Adjust, P, Flex } from '../../../Universal/style';
import { UploadLinkSection } from '../style';
import placeholderProfile from '../../../../resources/images/placeholder-profile.jpg';
import { GET_SCORE_APPROVAL } from '../../../Universal/queries';
import { UPLOAD_STATUS_SUBSCRIPTION } from './queries';

// Displaying the fixture table
const FixtureTable = ({
  data,
  week,
  theDate,
  score,
  profileLink,
  uploadLink,
  normal,
  blank,
  final,
  bye,
  uploadDecodedId,
  currentUserId,
  currentWeek,
}) => {
  const DateFormater = date => {
    if (date < 10) {
      return `0${date}`;
    } else {
      return date;
    }
  };

  return (
    <div className="table-row">
      <div className="table-cell align-middle">
        <P bold MT={'0'} MB={'0'}>
          {blank ? 9 : final ? 10 : week}
        </P>
      </div>
      <div className="table-cell align-middle">
        <P bold MT={'0'} MB={'0'}>
          {DateFormater(theDate.getDate())}/{DateFormater(theDate.getMonth() + 1)}/{theDate.getFullYear()}
        </P>
      </div>
      <div className="table-cell align-middle">
        {bye ? (
          <P bold MT={'0'} MB={'0'}>
            Bye Week
          </P>
        ) : blank ? (
          <P bold MT={'0'} MB={'0'}>
            Catch Up Week
          </P>
        ) : final && currentWeek === 10 ? (
          <P bold MT={'0'} MB={'0'}>
            Not In Finals
          </P>
        ) : final ? (
          <P bold MT={'0'} MB={'0'}>
            --
          </P>
        ) : (
          <div className="user-image-section">
            {data && data.user && (
              <Fragment>
                <div className="image-overflow">
                  {data.user.profileImage ? (
                    <Image cloudName={process.env.REACT_APP_CLOUD_NAME} publicId={data.user.profileImage}>
                      <Transformation width="35" height="35" gravity="faces" crop="fill" />
                    </Image>
                  ) : (
                    <img src={placeholderProfile} alt="flexi-profile" />
                  )}
                </div>
                <P bold MT={'0'} MB={'0'}>
                  {data.user.firstname} {data.user.lastname}
                </P>
              </Fragment>
            )}
          </div>
        )}
      </div>
      <div className="table-cell align-middle">
        {blank || bye || final ? (
          <P bold MT={'0'} MB={'0'}>
            --
          </P>
        ) : score ? (
          <Flex center noWrap>
            {score.map((theScore, i) => (
              <Adjust key={i} padding={'0 5px'}>
                <P bold MT={'0'} MB={'0'} borderBottom>
                  {theScore[1]}
                </P>
                <P bold MT={'0'} MB={'0'}>
                  {theScore[0]}
                </P>
              </Adjust>
            ))}
          </Flex>
        ) : (
          <P bold MT={'0'} MB={'0'}>
            TBC
          </P>
        )}
      </div>
      <div className="table-cell align-middle">
        {normal && (
          <Link to={profileLink}>
            <P className="singles-link" bold MT={'0'} MB={'0'} lowercase>
              View Profile
            </P>
          </Link>
        )}
      </div>
      <div className="table-cell align-middle">
        {normal && (
          // Get the score approval data --> determining notification pop up on fixture table
          <Query query={GET_SCORE_APPROVAL} variables={{ fixtureId: +uploadDecodedId }}>
            {({ data, loading, refetch, subscribeToMore }) => {
              const scoreApprovalRefetch = refetch;
              const subscribeGetScoreApproval = subscribeToMore;
              const scoreApprovalLoading = loading;
              if (scoreApprovalLoading) {
                return <p className="loading">Loading...</p>;
              }
              if (data && data.getScoreApproval) {
                const { getScoreApproval } = data;
                return (
                  <Link to={uploadLink}>
                    <ScoreWaitingApproval
                      scoreApprovalRefetch={scoreApprovalRefetch}
                      getScoreApproval={getScoreApproval}
                      subscribeToMore={subscribeGetScoreApproval}
                      fixtureId={uploadDecodedId}
                      currentUserId={currentUserId}
                      score={score}
                    >
                      <UploadScoreLink normal={true} />
                    </ScoreWaitingApproval>
                  </Link>
                );
              }
              return '';
            }}
          </Query>
        )}
      </div>
    </div>
  );
};

// Component to listen out for new/updated score approval information. Notification will alert players about pending scores.
class ScoreWaitingApproval extends Component {
  // This function will always run as long as the page is active.
  subscribeToScoreWaitingApproval = () => {
    this.props.subscribeToMore({
      document: UPLOAD_STATUS_SUBSCRIPTION,
      // Put variables inside the subscribeToMore function.
      variables: { fixtureId: this.props.fixtureId },
      // The previousResult is the current items in the database.
      // The subscriptionData is the NEW item just added.
      updateQuery: (previousResult, { subscriptionData }) => {
        // If the subcriptionData does not exist - return just the current items in the database.
        if (!subscriptionData.data) {
          return previousResult;
        }
        // If the subcriptionData exists then grab the resolver subscribe "getScoreApproval" data.
        const { uploadScoreStatus } = subscriptionData.data;

        // create an exact object of the same shape as the initial query data and merge the previous result with the new result.
        return Object.assign({}, previousResult, {
          // getScoreApproval  will return an array as specified in the "scoreApproval" schema
          getScoreApproval: [
            // all previous objects inside previously - compounding each items as more items are added.
            uploadScoreStatus,
          ],
        });
      },
    });
  };

  componentDidMount() {
    this.subscribeToScoreWaitingApproval();
    this.props.scoreApprovalRefetch();
  }

  render() {
    const { currentUserId, getScoreApproval, score } = this.props;

    if (getScoreApproval && getScoreApproval.length > 0) {
      const { userId, opponentId, isApproved } = getScoreApproval[0];

      return (
        <Fragment>
          {+currentUserId === +userId && !isApproved ? (
            // For the player that receives score to approve
            <UploadScoreLink text={'New score alert'} normal={false} color={'crimson'} />
          ) : +currentUserId === +opponentId && !isApproved && !score ? (
            // For the player that sends the scores for approval
            <UploadScoreLink text={'Scores pending'} normal={false} color={'#FFA700'} />
          ) : isApproved || (score && score.length > 0) ? (
            // For when scores are approved
            <UploadScoreLink text={'Scores approved'} normal={false} color={'#569600'} />
          ) : (
            this.props.children
          )}
        </Fragment>
      );
    } else {
      return this.props.children;
    }
  }
}

// The upload score link and notification
const UploadScoreLink = ({ text, normal, color }) => (
  <UploadLinkSection color={color}>
    {!normal && (
      <div className="notification-wrapper">
        <div className="text">{text}</div>
        <FontAwesomeIcon icon={faBell} />
      </div>
    )}
    <P className="singles-link" bold MT={'0'} MB={'0'} lowercase>
      Upload Scores
    </P>
  </UploadLinkSection>
);

export default FixtureTable;
