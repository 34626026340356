import React, { Component, Fragment } from 'react';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { Image, Transformation } from 'cloudinary-react';

import { sanitizeInput } from '../../abstracts/helpers';

import EmailSettingSection from './emailSetting';
import { Adjust, Flex, Col, ButtonWrapper, Button, H4 } from '../Universal/style';
import { Input, TextArea, ProfileImageIcon } from './style';
import Loading from '../Loading';
import ErrorMessage from '../Error';
import { UPDATE_USER_IMAGE } from './queries';

import placeholderProfile from '../../resources/images/placeholder-profile.jpg';
import imageLoader from '../../resources/images/image-loader.svg';

const UPDATE_USER = gql`
  mutation(
    $firstname: String!
    $lastname: String!
    $email: String!
    $about: String
    $tennisCourt: String
    $playTime: String
  ) {
    updateUser(
      firstname: $firstname
      lastname: $lastname
      email: $email
      about: $about
      tennisCourt: $tennisCourt
      playTime: $playTime
    )
  }
`;

/******************** Profile Form *******************/
class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileImage: this.props.session.me.profileImage || null,
      firstname: this.props.session.me.firstname,
      lastname: this.props.session.me.lastname,
      email: this.props.session.me.email,
      about: this.props.session.me.about || '',
      tennisCourt: this.props.session.me.tennisCourt || '',
      playTime: this.props.session.me.playTime || '',

      invalid: true,
      edit: false,
      imageLoader: false,
    };
  }

  enableForm = () => {
    if (this.state.edit !== true) {
      this.setState({
        edit: true,
        invalid: false,
      });
    }
  };

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    this.setState({
      invalid: false,
    });
  };

  // Making this.setState Syncronous
  setStateAsync = state => {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  };

  onDrop = async (files, rejectedFiles, updateUserImage) => {
    if (files) {
      await this.setStateAsync({
        imageLoading: true,
      });
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('upload_preset', process.env.REACT_APP_UPLOAD_PRESET_SINGLES);

      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`,
        formData
      );

      if (response) {
        await this.setStateAsync({
          profileImage: response.data.public_id,
        });
        await updateUserImage();
        await this.setStateAsync({
          imageLoading: false,
        });
        await this.props.refetch();
      }
    }

    if (files[0]) {
      this.setState({ invalid: false });
    }
  };

  onSubmit = async (event, updateUser) => {
    event.preventDefault();

    await updateUser().then(async ({ data }) => {
      this.setState({
        invalid: true,
        edit: false,
      });

      await this.props.refetch();
    });
  };

  render() {
    const { profileImage, invalid, edit, imageLoading } = this.state;
    let { firstname, lastname, email, about, tennisCourt, playTime } = this.state;
    const { session, emailSetting } = this.props;
    const doubles = false || this.props.doubles;

    // Format string to be capitalized
    // Sanitize inputs
    email = sanitizeInput({ string: email, type: 'email' }).toLowerCase();
    firstname = sanitizeInput({ string: firstname }).toLowerCase();
    firstname = firstname.charAt(0).toUpperCase() + firstname.slice(1);
    lastname = sanitizeInput({ string: lastname }).toLowerCase(); 
    lastname = lastname.charAt(0).toUpperCase() + lastname.slice(1);
    about = sanitizeInput({ string: about });
    tennisCourt = sanitizeInput({ string: tennisCourt });
    playTime = sanitizeInput({ string: playTime });

    return (
      <Mutation
        mutation={UPDATE_USER}
        variables={{
          firstname,
          lastname,
          email,
          about,
          tennisCourt,
          playTime,
        }}
      >
        {(updateUser, { loading, error }) => (
          <Mutation mutation={UPDATE_USER_IMAGE} variables={{ profileImage }}>
            {updateUserImage => (
              <Fragment>
                <form autoComplete="off" onSubmit={event => this.onSubmit(event, updateUser)}>
                  <Flex className="profile-form">
                    <Col digit={'25%'} padding={'0 20px 0 0'} className="profile-image-wrapper" column>
                      <Dropzone onDrop={(files, rejectedFiles) => this.onDrop(files, rejectedFiles, updateUserImage)}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <Adjust
                              relative
                              {...getRootProps()}
                              noOutline
                              cursor="true"
                              fullWidth
                              style={{ display: 'inline-block' }}
                            >
                              <input {...getInputProps()} />

                              <ProfileImageIcon imageLoading={imageLoading}>
                                {imageLoading ? (
                                  <img style={{ zIndex: '1' }} src={imageLoader} alt="loader" />
                                ) : (
                                  <FontAwesomeIcon icon={faCamera} />
                                )}
                              </ProfileImageIcon>

                              {profileImage === null ? (
                                <img
                                  className="profile-image"
                                  src={placeholderProfile}
                                  alt="placeholder-profile-flexi"
                                />
                              ) : (
                                // This is executed when the "profileImage" state is filled.
                                // This means that the user has already uploaded then image and is retrieved from cloudinary.
                                <Image
                                  className="profile-image"
                                  cloudName={process.env.REACT_APP_CLOUD_NAME}
                                  publicId={profileImage}
                                >
                                  <Transformation width="250" height="250" gravity="faces" crop="fill" />
                                </Image>
                              )}
                            </Adjust>
                            <H4 center MT={'10px'} className="profile-image-text">
                              Profile Image
                            </H4>
                          </section>
                        )}
                      </Dropzone>

                      {/* Section for email setting */}
                      {emailSetting && <EmailSettingSection allowEmail={session.me.allowEmail} />}
                    </Col>
                    <Col digit={'70%'} noFlex padding={'0'}>
                      <Flex column>
                        <H4 noMB MT={'0'}>
                          First Name
                        </H4>
                        <Input
                          disabled={invalid}
                          name="firstname"
                          value={firstname}
                          onChange={this.onChange}
                          type="text"
                          placeholder="First Name"
                        />
                        <H4 noMB>Last Name</H4>
                        <Input
                          disabled={invalid}
                          name="lastname"
                          value={lastname}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Last Name"
                        />
                        <H4 noMB>Email</H4>
                        <Input
                          disabled={invalid}
                          name="email"
                          value={email}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Email Address"
                        />
                        <H4 noMB>About Me Section</H4>
                        <TextArea
                          disabled={invalid}
                          name="about"
                          value={about}
                          onChange={this.onChange}
                          rows="5"
                          placeholder="Eg. I’ve been playing tennis for about 6 years and consider myself quite competitive. I’m ready to play against other passionate players."
                        ></TextArea>
                        <H4 noMB>
                          Where is your favourite tennis court
                          <span>?</span>
                        </H4>
                        <Input
                          disabled={invalid}
                          name="tennisCourt"
                          value={tennisCourt}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Eg. Mt Lawley Tennis Courts"
                        />
                        <H4 noMB>
                          What is your preferred playing day
                          <span>(</span>s<span>)</span> and Time<span>(</span>s<span>)</span>
                        </H4>
                        <Input
                          disabled={invalid}
                          name="playTime"
                          value={playTime}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Eg. Tuesdays and Thurdays evening after 7pm"
                        />
                      </Flex>

                      <ButtonWrapper>
                        <Button
                          normal
                          doubles={doubles}
                          genderType={session.me.genderType}
                          onClick={() => this.enableForm()}
                          disabled={edit || loading}
                          type="button"
                        >
                          Edit Info
                        </Button>
                        <Button
                          normal
                          doubles={doubles}
                          genderType={session.me.genderType}
                          disabled={invalid || loading}
                          type="submit"
                        >
                          {loading ? <Loading /> : 'Update'}
                        </Button>
                      </ButtonWrapper>
                    </Col>
                  </Flex>

                  {error && (
                    <div style={{ marginTop: '10px' }}>
                      <ErrorMessage error={error} />
                    </div>
                  )}
                </form>
              </Fragment>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}

export default ProfileForm;
