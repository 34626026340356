import { Component } from 'react';
import { UPDATED_LEADERBOARD_SUBSCRIPTION } from '../../../queries';

// Subscription to listen to updated "leaderboard" data
class UpdatedLeaderBoardChecker extends Component {
  // This function will always run as long as the page is active.
  subscribeToUpdatedLeaderboard = () => {
    this.props.subscribeToMore({
      document: UPDATED_LEADERBOARD_SUBSCRIPTION,
      // Put variables inside the subscribeToMore function.
      variables: {
        playType: this.props.type,
        leagueId: this.props.leagueId,
        subLeagueId: this.props.subLeagueId,
        genderType: this.props.genderType,
      },
      // The previousResult is the current items in the database.
      // The subscriptionData is the NEW item just added.
      updateQuery: (previousResult, { subscriptionData }) => {
        // If the subcriptionData does not exist - return just the current items in the database.
        if (!subscriptionData.data) {
          return previousResult;
        }

        // create an exact object of the same shape as the initial query data and merge the previous result with the new result.
        return Object.assign({}, previousResult, {
          // getLeaderboard will return an array as specified in the "leaderboard" schema
          getLeaderboard: [...previousResult.getLeaderboard],
        });
      },
    });
  };

  componentDidMount() {
    this.subscribeToUpdatedLeaderboard();
  }

  render() {
    return this.props.children;
  }
}

export default UpdatedLeaderBoardChecker;
