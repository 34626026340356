import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad } from '@fortawesome/free-solid-svg-icons';
import { faBatteryThreeQuarters } from '@fortawesome/free-solid-svg-icons';

import ControlNormalFixture from './singles/controlNormalFixture';
import ControlFinalsFixture from './singles/controlFinalsFixture';
import DoublesControlNormalFixture from './doubles/controlNormalFixture';
import DoublesControlFinalsFixture from './doubles/controlFinalsFixture';

import { H4 } from '../../Universal/style';
import { CountDown } from '../style';

// Fixture status for the ADMIN page
class FixtureStatus extends Component {
  constructor() {
    super();
    this.state = {
      currentSeason: '',
      currentSeasonDate: '',
      currentWeek: '',
      year: '',
      closed: false,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      countdown: false,
      countdownWeek10: false,
    };
  }

  componentDidMount() {
    const { seasonDates } = this.props;
    // Current time to do the logic
    const currentTime = new Date().getTime();

    this.datesLogic(seasonDates, currentTime);
  }

  // Creating a custom async forEach function
  forEachAsync = async (array, callback) => {
    for (let i = 0; i < array.length; i++) {
      await callback(array[i], i, array);
    }
  };

  // Determining season data and name
  datesLogic = async (seasonDates, currentTime) => {
    let { previousSpring, summer, autumn, winter, spring, nextSummer } = seasonDates;

    // Create BEFORE the season dates. Should be rewind by 3 weeks.
    const beforePreviousSpring = new Date(new Date(previousSpring).setDate(new Date(previousSpring).getDate() - 19));
    const beforeSummer = new Date(new Date(summer).setDate(new Date(summer).getDate() - 19));
    const beforeAutumn = new Date(new Date(autumn).setDate(new Date(autumn).getDate() - 19));
    const beforeWinter = new Date(new Date(winter).setDate(new Date(winter).getDate() - 19));
    const beforeSpring = new Date(new Date(spring).setDate(new Date(spring).getDate() - 19));
    const beforeNextSummer = new Date(new Date(nextSummer).setDate(new Date(nextSummer).getDate() - 19));

    // Setting dates to be FOUR DAYS BEFORE the season starts - so ADMIN can generate fixtures.
    const oneDayPreviousSpring = new Date(new Date(previousSpring).setDate(new Date(previousSpring).getDate() - 4));
    const oneDaySummer = new Date(new Date(summer).setDate(new Date(summer).getDate() - 4));
    const oneDayAutumn = new Date(new Date(autumn).setDate(new Date(autumn).getDate() - 4));
    const oneDayWinter = new Date(new Date(winter).setDate(new Date(winter).getDate() - 4));
    const oneDaySpring = new Date(new Date(spring).setDate(new Date(spring).getDate() - 4));
    const oneDayNextSummer = new Date(new Date(nextSummer).setDate(new Date(nextSummer).getDate() - 4));
    // Setting the above dates to be 5 minutes after to ensure all users are registered
    const afterOneDayPreviousSpring = new Date(oneDayPreviousSpring.setMinutes(oneDayPreviousSpring.getMinutes() + 5));
    const afterOneDaySummer = new Date(oneDaySummer.setMinutes(oneDaySummer.getMinutes() + 5));
    const afterOneDayAutumn = new Date(oneDayAutumn.setMinutes(oneDayAutumn.getMinutes() + 5));
    const afterOneDayWinter = new Date(oneDayWinter.setMinutes(oneDayWinter.getMinutes() + 5));
    const afterOneDaySpring = new Date(oneDaySpring.setMinutes(oneDaySpring.getMinutes() + 5));
    const afterOneDayNextSummer = new Date(oneDayNextSummer.setMinutes(oneDayNextSummer.getMinutes() + 5));

    // As long as the current time is between A SEASON DATE and the BEFORE next SEASON DATE then update the state accordingly to the values.
    if (beforePreviousSpring <= currentTime && currentTime <= beforeSummer) {
      this.setState({ currentSeason: 'spring' });
    } else if (beforeSummer <= currentTime && currentTime <= beforeAutumn) {
      this.setState({ currentSeason: 'summer' });
    } else if (beforeAutumn <= currentTime && currentTime <= beforeWinter) {
      this.setState({ currentSeason: 'autumn' });
    } else if (beforeWinter <= currentTime && currentTime <= beforeSpring) {
      this.setState({ currentSeason: 'winter' });
    } else if (beforeSpring <= currentTime && currentTime <= beforeNextSummer) {
      this.setState({ currentSeason: 'spring' });
    }

    if (oneDayPreviousSpring <= currentTime && currentTime <= beforeSummer) {
      this.setState({
        currentSeasonDate: previousSpring,
        closed: true,
        year: new Date(previousSpring).getFullYear().toString(),
      });
    } else if (oneDaySummer <= currentTime && currentTime <= beforeAutumn) {
      this.setState({
        currentSeasonDate: summer,
        closed: true,
        year: new Date(summer).getFullYear().toString(),
      });
    } else if (oneDayAutumn <= currentTime && currentTime <= beforeWinter) {
      this.setState({
        currentSeasonDate: autumn,
        closed: true,
        year: new Date(autumn).getFullYear().toString(),
      });
    } else if (oneDayWinter <= currentTime && currentTime <= beforeSpring) {
      this.setState({
        currentSeasonDate: winter,
        closed: true,
        year: new Date(winter).getFullYear().toString(),
      });
    } else if (oneDaySpring <= currentTime && currentTime <= beforeNextSummer) {
      this.setState({
        currentSeasonDate: spring,
        closed: true,
        year: new Date(spring).getFullYear().toString(),
      });
    }

    // Timer that ends FOUR DAYS BEFORE the season starts
    if (beforePreviousSpring <= currentTime && currentTime <= afterOneDayPreviousSpring) {
      this.interval = setInterval(() => this.getTimeUntil(afterOneDayPreviousSpring), 1000);
      this.setState({ countdown: true });
    } else if (beforeSummer <= currentTime && currentTime <= afterOneDaySummer) {
      this.interval = setInterval(() => this.getTimeUntil(afterOneDaySummer), 1000);
      this.setState({ countdown: true });
    } else if (beforeAutumn <= currentTime && currentTime <= afterOneDayAutumn) {
      this.interval = setInterval(() => this.getTimeUntil(afterOneDayAutumn), 1000);
      this.setState({ countdown: true });
    } else if (beforeWinter <= currentTime && currentTime <= afterOneDayWinter) {
      this.interval = setInterval(() => this.getTimeUntil(afterOneDayWinter), 1000);
      this.setState({ countdown: true });
    } else if (beforeSpring <= currentTime && currentTime <= afterOneDaySpring) {
      this.interval = setInterval(() => this.getTimeUntil(afterOneDaySpring), 1000);
      this.setState({ countdown: true });
    } else if (beforeNextSummer <= currentTime && currentTime <= afterOneDayNextSummer) {
      this.interval = setInterval(() => this.getTimeUntil(afterOneDayNextSummer), 1000);
      this.setState({ countdown: true });
    }

    const seasonDateArray = [previousSpring, summer, autumn, spring, winter, nextSummer];

    // Logic to determine the weekly dates
    await this.forEachAsync(seasonDateArray, season => {
      const week1 = new Date(new Date(season).setDate(new Date(season).getDate() + 7));
      const week2 = new Date(new Date(season).setDate(new Date(season).getDate() + 14));
      const week3 = new Date(new Date(season).setDate(new Date(season).getDate() + 21));
      const week4 = new Date(new Date(season).setDate(new Date(season).getDate() + 28));
      const week5 = new Date(new Date(season).setDate(new Date(season).getDate() + 35));
      const week6 = new Date(new Date(season).setDate(new Date(season).getDate() + 42));
      const week7 = new Date(new Date(season).setDate(new Date(season).getDate() + 49));
      const week8 = new Date(new Date(season).setDate(new Date(season).getDate() + 56));
      const week9 = new Date(new Date(season).setDate(new Date(season).getDate() + 63));
      const week10 = new Date(new Date(season).setDate(new Date(season).getDate() + 70));

      // Date to reload page
      const slightlyAfterWeek9 = new Date(week9.setSeconds(week9.getSeconds() + 5));
      const redirectWeek = new Date(slightlyAfterWeek9.setSeconds(slightlyAfterWeek9.getSeconds() + 4));

      // Timer will appear during week 9 until slightly after the week 10 date to ensure all users have submitted scores.
      if (new Date(season) <= currentTime && currentTime < week1) {
        this.setState({ currentWeek: 1 });
      } else if (week1 <= currentTime && currentTime < week2) {
        this.setState({ currentWeek: 2 });
      } else if (week2 <= currentTime && currentTime < week3) {
        this.setState({ currentWeek: 3 });
      } else if (week3 <= currentTime && currentTime < week4) {
        this.setState({ currentWeek: 4 });
      } else if (week4 <= currentTime && currentTime < week5) {
        this.setState({ currentWeek: 5 });
      } else if (week5 <= currentTime && currentTime < week6) {
        this.setState({ currentWeek: 6 });
      } else if (week6 <= currentTime && currentTime < week7) {
        this.setState({ currentWeek: 7 });
      } else if (week7 <= currentTime && currentTime < week8) {
        this.setState({ currentWeek: 8 });
      } else if (week8 <= currentTime && currentTime < slightlyAfterWeek9) {
        this.setState({ currentWeek: 9, countdownWeek10: true });
        this.interval = setInterval(() => this.getTimeUntil(redirectWeek), 1000);
      } else if (slightlyAfterWeek9 <= currentTime && currentTime <= week10) {
        this.setState({ currentWeek: 10 });
      }
    });
  };

  // Coundown timer until season starts
  getTimeUntil = deadline => {
    let deadlineNew = new Date(deadline).getTime();
    let now = new Date().getTime();
    let distance = deadlineNew - now;

    if (distance <= 0) {
      window.location.reload();
    } else {
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
      let minutes = Math.floor((distance / (1000 * 60)) % 60);
      let seconds = Math.floor((distance / 1000) % 60);
      this.setState({ days, hours, minutes, seconds });
    }
  };

  // Must clear interval on componentWillUnmount so that we don't get an error with the setState before component mounts.
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {
      year,
      closed,
      currentSeason,
      currentSeasonDate,
      currentWeek,
      countdownWeek10,
      days,
      hours,
      minutes,
      seconds,
      countdown,
    } = this.state;

    const controlsArray = [
      {
        title: 'Singles Control',
        normal: ControlNormalFixture,
        final: ControlFinalsFixture,
      },
      {
        title: 'Doubles Control',
        normal: DoublesControlNormalFixture,
        final: DoublesControlFinalsFixture,
      },
    ];

    return (
      <Fragment>
        <H4 MB={'0'} fontsize={'19px'}>
          <FontAwesomeIcon icon={faBatteryThreeQuarters} /> Fixture Generator Status
        </H4>
        {controlsArray.map((control, controlIndex) => (
          <div key={controlIndex}>
            <p className="control-title">
              <FontAwesomeIcon icon={faGamepad} /> {control.title}
            </p>
            {mainFixtureStatusControls(
              control.normal,
              control.final,
              currentSeason,
              currentSeasonDate,
              currentWeek,
              countdownWeek10,
              closed,
              countdown,
              days,
              hours,
              minutes,
              seconds,
              year
            )}
          </div>
        ))}
      </Fragment>
    );
  }
}

const mainFixtureStatusControls = (
  ComponentNormal,
  ComponentFinals,
  currentSeason,
  currentSeasonDate,
  currentWeek,
  countdownWeek10,
  closed,
  countdown,
  days,
  hours,
  minutes,
  seconds,
  year
) => (
  <div className="fixture-gen-wrapper">
    <div className="fixture-gen-section">
      {/* Determining the current season name and whether it is closed or not */}
      <p>
        Season - {currentSeason.charAt(0).toUpperCase()}
        {currentSeason.slice(1)} is {closed ? 'closed' : 'open'}
      </p>
      {/* Display countdown only when we are between the "before" and season start date */}
      {countdown && (
        <CountDown>
          {days} days | {hours} hours | {minutes} minutes | {seconds} seconds
        </CountDown>
      )}
      <ComponentNormal season={currentSeason} seasonDate={currentSeasonDate} closed={closed} year={year} />
    </div>
    {/* If the currentWeek is greater than 0 --> means that we are inside the season now --> then display the week and also generate finals fixture when week 10 comes */}
    {currentWeek > 0 && (
      <div className="fixture-gen-section">
        <p>
          Active Season - {currentSeason.charAt(0).toUpperCase()}
          {currentSeason.slice(1)} - Week {currentWeek}
        </p>
        <Fragment>
          {/* During week 9, the timer will appear to countdown until week 10 */}
          {countdownWeek10 && (
            <CountDown>
              {days} days | {hours} hours | {minutes} minutes | {seconds} seconds
            </CountDown>
          )}
          {/* If current week is 10 then display "generate finals" fixture button. */}
          {currentWeek > 9 && <ComponentFinals seasonDate={currentSeasonDate} />}
        </Fragment>
      </div>
    )}
  </div>
);

export default FixtureStatus;
