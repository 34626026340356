import React, { PureComponent } from 'react';
import { NEW_OPPONENT_TEAM_MESSAGE_SUBSCRIPTION } from '../queries';

// This component will determine if there are any NEW opponent messages for each opponent.
class NewMessageSubscription extends PureComponent {
  // This function will always run as long as the page is active.
  subscribeToNotifications = () => {
    this.props.subscribeToMore({
      document: NEW_OPPONENT_TEAM_MESSAGE_SUBSCRIPTION,
      variables: {
        opponentTeamId: this.props.opponentTeamId,
        teamId: this.props.currentTeamId,
      },
      updateQuery: (previousResult, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousResult;
        }

        return Object.assign({}, previousResult, {
          getOpponentTeamMessage: [
            ...previousResult.getOpponentTeamMessage,
            subscriptionData.data.newOpponentTeamMessage,
          ],
        });
      },
    });
  };

  componentDidMount() {
    this.subscribeToNotifications();
    this.props.newOpponentMessageRefetch();
  }

  render() {
    return <div></div>;
  }
}

export default NewMessageSubscription;
