import React from 'react';

import withAuthorization from '../../Session/withAuthorization';
import { ProfileForm, HeadingSection } from '../../Account';

const DoublesDash = ({ session, refetch }) => {
  return (
    <div>
      {session && session.me && <HeadingSection session={session} />}
      {session && session.me && <ProfileForm session={session} refetch={refetch} doubles={true} />}
    </div>
  );
};

export default withAuthorization(
  session => session && session.me && session.me.paid && session.me.playType === 'doubles'
)(DoublesDash);
