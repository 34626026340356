import gql from 'graphql-tag';

/******************* Channel Query - Doubles ******************/
// Query the team channel that belongs to both the team and the opponent team
export const TEAM_CHANNEL_QUERY = gql`
  query($opponentTeamId: ID!, $teamId: ID!) {
    getTeamChannelId(opponentTeamId: $opponentTeamId, teamId: $teamId) {
      opponentTeamId
      teamId
      teamChannelId
    }
  }
`;
// Subscribe to the team channel that belongs to both the team and the opponent team
export const TEAM_CHANNEL_SUBSCRIPTION = gql`
  subscription($opponentTeamId: ID!, $teamId: ID!) {
    newTeamChannel(opponentTeamId: $opponentTeamId, teamId: $teamId) {
      opponentTeamId
      teamId
      teamChannelId
    }
  }
`;

/******************* Create Channel for Team - Doubles ******************/
// Create a new team channel for current team and opponent team if a channel doesn't exist for both
export const CREATE_TEAM_CHANNEL = gql`
  mutation($opponentTeamId: ID!, $teamId: ID!) {
    createTeamChannelId(opponentTeamId: $opponentTeamId, teamId: $teamId) {
      opponentTeamId
      teamId
      teamChannelId
    }
  }
`;
// Create a team message
export const CREATE_TEAM_MESSAGE = gql`
  mutation($teamChannelId: ID!, $text: String!, $opponentTeamId: ID!, $teamId: ID!) {
    createTeamMessage(teamChannelId: $teamChannelId, text: $text, opponentTeamId: $opponentTeamId, teamId: $teamId) {
      text
      teamId
      teamChannelId
      opponentTeamId
    }
  }
`;

/******************* Message Query - Doubles ******************/
// Query team messages for the team
export const TEAM_MESSAGE_QUERY = gql`
  query($cursor: String, $teamChannelId: ID) {
    teamMessages(cursor: $cursor, teamChannelId: $teamChannelId) {
      edges {
        id
        text
        teamId
        opponentTeamId
        createdAt
      }
      teamMessageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
// Subscribe to the team messages
export const TEAM_MESSAGE_SUBSCRIPTION = gql`
  subscription($teamChannelId: ID) {
    newTeamMessage(teamChannelId: $teamChannelId) {
      id
      text
      teamId
      opponentTeamId
      createdAt
    }
  }
`;

/******************* Opponent Team Message Query - Doubles ******************/
// This Query is to notify the team of new Opponent team messages
export const NEW_OPPONENT_TEAM_MESSAGE = gql`
  query($opponentTeamId: ID!, $teamId: ID!) {
    getOpponentTeamMessage(opponentTeamId: $opponentTeamId, teamId: $teamId) {
      id
      opponentTeamId
      teamId
      notification
    }
  }
`;
// New opponent team message subscription
export const NEW_OPPONENT_TEAM_MESSAGE_SUBSCRIPTION = gql`
  subscription($opponentTeamId: ID, $teamId: ID) {
    newOpponentTeamMessage(opponentTeamId: $opponentTeamId, teamId: $teamId) {
      id
      opponentTeamId
      teamId
      notification
    }
  }
`;
// Set the notification to false when the current team lands on the chat URL for an opponent team
export const REMOVE_TEAM_NOTIFICATION = gql`
  mutation($opponentTeamId: ID, $teamId: ID) {
    removeTeamNotification(opponentTeamId: $opponentTeamId, teamId: $teamId)
  }
`;
