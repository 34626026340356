import styled, { theme } from '../../../../theme';

// The message list section
export const ListContainer = styled.div`
  margin-bottom: 30px;
  height: 373px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
export const TheMessageWrapper = styled.div`
  overflow-y: scroll;
  margin-bottom: 7px;

  .message {
    margin-bottom: 20px;

    .name-wrapper {
      border-bottom: 1px solid ${theme.breakLine};
      padding-bottom: 3px;
    }
    .current-team h4 {
      color: ${theme.orange};
    }
    .date-created {
      font-size: 13px;
      font-family: 'Open Sans', sans-serif;
      color: gray;
    }
  }
  h4 {
    font-size: 13px;
    margin: 0;
    margin-right: 10px;
  }
  p {
    margin: 0;
    padding-top: 5px;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-flow: row wrap;

  input {
    margin-right: 7px;
    width: 100%;
    padding: 8px 10px;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid ${theme.breakLine};
    outline: none;
    flex: 0 1 80%;
  }
`;
export const FormInner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  button {
    width: auto;
    flex: 1 auto;
    margin-top: 0;
  }
`;
