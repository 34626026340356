import React, { Fragment } from 'react';
import { Query } from '@apollo/react-components';
import { Image, Transformation } from 'cloudinary-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import withAuthorization from '../../Session/withAuthorization';
import { ALL_RESULTS_QUERY } from './queries';

import { P } from '../../Universal/style';
import { OPPONENT_QUERY } from '../../Universal/queries';
import { ResultsWrapper } from './style';
import placeholderProfile from '../../../resources/images/placeholder-profile.jpg';
import { NoResults } from '../../Error/style';

const Results = () => (
  <ResultsWrapper>
    <h1>Results</h1>

    {/* Query the season fixture for the user */}
    <Query query={ALL_RESULTS_QUERY}>
      {({ data, loading }) => {
        if (loading) {
          return <h3>Loading...</h3>;
        }

        // If the fixture for the season exists --> then proceed with finding the opponent info etc..
        if (data && data.getAllWeeklyResults && data.getAllWeeklyResults.length > 0) {
          const { getAllWeeklyResults } = data;
          const week1Filtered = getAllWeeklyResults.filter(result => +result.week === 1);
          const week2Filtered = getAllWeeklyResults.filter(result => +result.week === 2);
          const week3Filtered = getAllWeeklyResults.filter(result => +result.week === 3);
          const week4Filtered = getAllWeeklyResults.filter(result => +result.week === 4);
          const week5Filtered = getAllWeeklyResults.filter(result => +result.week === 5);
          const week6Filtered = getAllWeeklyResults.filter(result => +result.week === 6);
          const week7Filtered = getAllWeeklyResults.filter(result => +result.week === 7);
          const week8Filtered = getAllWeeklyResults.filter(result => +result.week === 8);
          const week10Filtered = getAllWeeklyResults.filter(result => +result.week === 10);

          const masterArray = [
            {
              heading: 'Week 1',
              data: week1Filtered,
            },
            {
              heading: 'Week 2',
              data: week2Filtered,
            },
            {
              heading: 'Week 3',
              data: week3Filtered,
            },
            {
              heading: 'Week 4',
              data: week4Filtered,
            },
            {
              heading: 'Week 5',
              data: week5Filtered,
            },
            {
              heading: 'Week 6',
              data: week6Filtered,
            },
            {
              heading: 'Week 7',
              data: week7Filtered,
            },
            {
              heading: 'Week 8',
              data: week8Filtered,
            },
            {
              heading: 'Week 10',
              data: week10Filtered,
            },
          ];

          return (
            <div className="scroll-wrapper">
              <div>
                {masterArray.map((object, masterIndex) => (
                  <div key={masterIndex} className="all-results-wrapper">
                    <h4>{object.heading}</h4>
                    <div className="result-wrapper">
                      {object.data.length > 0 ? (
                        object.data.map(result => {
                          const theScore = JSON.parse(result.score);
                          return (
                            // Displaying score for EACH submission
                            <div className="the-result" key={result.id}>
                              <div className="player-info">
                                {/* Querying the winner information */}
                                <Query query={OPPONENT_QUERY} variables={{ id: +result.winnerId }}>
                                  {({ data, loading }) => (
                                    // Wrapper for image and player name
                                    <div className="player-wrapper">
                                      {loading && 'Loading...'}
                                      {data && data.user && (
                                        <Fragment>
                                          <div className="image-overflow">
                                            {data.user.profileImage ? (
                                              <Image
                                                cloudName={process.env.REACT_APP_CLOUD_NAME}
                                                publicId={data.user.profileImage}
                                              >
                                                <Transformation width="25" height="25" gravity="faces" crop="fill" />
                                              </Image>
                                            ) : (
                                              <img src={placeholderProfile} alt="flexi-profile" />
                                            )}
                                          </div>
                                          <P margin={'5px 0'} color={'#FFA700'} bold>
                                            <Fragment>
                                              {data.user.firstname.charAt(0)}. {data.user.lastname}
                                            </Fragment>
                                          </P>
                                        </Fragment>
                                      )}
                                    </div>
                                  )}
                                </Query>
                                {/* Querying the loser information */}
                                <Query query={OPPONENT_QUERY} variables={{ id: +result.loserId }}>
                                  {({ data, loading }) => (
                                    // Wrapper for image and player name
                                    <div className="player-wrapper">
                                      {loading && 'Loading...'}
                                      {data && data.user && (
                                        <Fragment>
                                          <div className="image-overflow">
                                            {data.user.profileImage ? (
                                              <Image
                                                cloudName={process.env.REACT_APP_CLOUD_NAME}
                                                publicId={data.user.profileImage}
                                              >
                                                <Transformation width="25" height="25" gravity="faces" crop="fill" />
                                              </Image>
                                            ) : (
                                              <img src={placeholderProfile} alt="flexi-profile" />
                                            )}
                                          </div>
                                          <P margin={'5px 0'}>
                                            <Fragment>
                                              {data.user.firstname.charAt(0)}. {data.user.lastname}
                                            </Fragment>
                                          </P>
                                        </Fragment>
                                      )}
                                    </div>
                                  )}
                                </Query>
                              </div>
                              {/* This is to display the scores --> loop through the score parent array and then grab each array inside. */}
                              {/* First index is the winner score and the second is the opponent --> I did this intentionally in the "scoreApproval" resolver file for the "approveScore" resolver */}
                              <div className="scores">
                                {theScore.map((score, i) => (
                                  <div key={i} className="the-score">
                                    <p>{score[0]}</p>
                                    <p>{score[1]}</p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p style={{ marginTop: '0' }}>Currently No Results</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        } else {
          return (
            <NoResults>
              <div className="no-results-inner">
                <FontAwesomeIcon icon={faInfoCircle} />
                <p>Currently No Results</p>
              </div>
            </NoResults>
          );
        }
      }}
    </Query>
  </ResultsWrapper>
);

export default withAuthorization(
  session => session && session.me && session.me.paid && session.me.playType === 'singles'
)(Results);
