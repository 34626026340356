import React, { Fragment } from 'react';
import { Query } from '@apollo/react-components';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import * as routes from '../../../constants/routes';

import { Flex } from '../../Universal/style';
import MessageScreen from './messageScreen';
import { FIXTURE_QUERY } from '../../Universal/queries';
import SideBar from './sidebar';
import { NoResults } from '../../Error/style';

// Message to show when no season fixture has been detected.
const NoFixtureInfo = ({ getSeasonStatus }) => {
  return (
    <NoResults>
      <div className="no-results-inner">
        <FontAwesomeIcon icon={faInfoCircle} />
        {getSeasonStatus.matchGenerator ? (
          <Fragment>
            <p>There weren't enough players in your league for the current season.</p>
            <p style={{ marginTop: '6px' }}>A minimum of 4 players is needed.</p>
          </Fragment>
        ) : (
          <Fragment>
            <p>Fixtures have not been created.</p>
            <p style={{ marginTop: '6px' }}>
              You will be able to message other players after the fixture has been generated.
            </p>
          </Fragment>
        )}
      </div>
    </NoResults>
  );
};

const SinglesMessage = ({ session, season, year, type, genderType, getSeasonStatus }) => {
  return (
    <Fragment>
      <h1>Messages</h1>

      {/* Query the season fixture for the user */}
      <Query query={FIXTURE_QUERY} variables={{ season, playType: type, year }}>
        {({ data, loading }) => {
          if (loading) {
            return <h3>Loading...</h3>;
          }

          // If the fixture for the season exists --> then proceed with finding the opponent info etc..
          if (data && data.getAllFixture && data.getAllFixture.length > 0) {
            const { getAllFixture } = data;

            return (
              <Flex noWrap messageWrapper className="message-section-wrapper">
                {/* Display the side bar with all opponents */}
                <SideBar getAllFixture={getAllFixture} session={session} />
                {/* The below are "routes" to the message screen --> from chatLinks created in the SideBar section */}
                <Switch>
                  <Route exact path={routes.SINGLES_MESSAGE} render={() => <DefaultScreen />} />
                  <Route
                    path={routes.SINGLES_MESSAGE_USER}
                    render={() => (
                      <MessageScreen session={session} getAllFixture={getAllFixture} genderType={genderType} />
                    )}
                  />
                  <Redirect to={routes.SINGLES_MESSAGE} />
                </Switch>
              </Flex>
            );
          } else {
            // Display notification that no Fixture info exists yet
            return (
              <Fragment>
                {getSeasonStatus.length > 0 && <NoFixtureInfo getSeasonStatus={getSeasonStatus[0]} />}
              </Fragment>
            );
          }
        }}
      </Query>
    </Fragment>
  );
};

// Displays the default message screen (before any of the sidebar players are clicked)
const DefaultScreen = () => <p>Message a player by clicking on their name located on the left.</p>;

// Redirect screen to land on prior to the actual message screen with another player -> this is to get the "MessageScreen" component to "remount".
const RedirectScreen = props => {
  const { user } = props.match.params;
  return <Redirect to={`/singles/message/${user}`} />;
};
const RedirectScreenWithRouter = withRouter(RedirectScreen);

export default SinglesMessage;
export { RedirectScreenWithRouter };
