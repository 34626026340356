import React, { PureComponent, Fragment } from 'react';
import confetti from 'canvas-confetti';
import { WinnerNotification } from './style';

class IsWinner extends PureComponent {
  constructor() {
    super();
    this.state = {
      winnerNotification: false,
    };
  }

  displayConfetti = () => {
    const { type, currentTeamId, session, finalConfetti, fixtureData, removeConfetti } = this.props;

    if (type === 'singles') {
      if (fixtureData && fixtureData.getAllFixture && fixtureData.getAllFixture.length > 0) {
        const week10 = fixtureData.getAllFixture.filter(v => v.week === '10');

        if (week10 && week10.length > 0) {
          if (week10[0].winnerId === session.me.id && finalConfetti) {
            this.setState({ winnerNotification: true });
            this.confettiExplode(removeConfetti);
          }
        }
      }
    } else {
      if (fixtureData && fixtureData.getAllDoublesFixture && fixtureData.getAllDoublesFixture.length > 0) {
        const week10 = fixtureData.getAllDoublesFixture.filter(v => v.week === '10');

        if (week10 && week10.length > 0) {
          if (week10[0].teamWinnerId === currentTeamId && finalConfetti) {
            this.setState({ winnerNotification: true });
            this.confettiExplode(removeConfetti);
          }
        }
      }
    }
  };

  // Function to create the confetti explosion
  confettiExplode = removeConfetti => {
    const end = Date.now() + 7 * 1000;
    var confettiInterval = setInterval(() => {
      if (Date.now() > end) {
        this.removeConfettiScreen(removeConfetti, confettiInterval);
      }

      confetti({
        startVelocity: 30,
        spread: 360,
        ticks: 60,
        shapes: ['square'],
        origin: {
          x: Math.random(),
          y: Math.random() - 0.2,
        },
      });
    }, 200);
  };
  // Used in confettiExplode function
  removeConfettiScreen = async (removeConfetti, confettiInterval) => {
    await removeConfetti().then(() => {
      this.setState({ winnerNotification: false });
    });
    clearInterval(confettiInterval);
  };

  componentDidMount() {
    this.displayConfetti();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fixtureData !== this.props.fixtureData) {
      this.displayConfetti();
    }
  }

  render() {
    const { currentSeason } = this.props;
    const { winnerNotification } = this.state;
    if (winnerNotification) {
      return (
        <Fragment>
          <WinnerNotification>
            <h2>{currentSeason.charAt(0).toUpperCase() + currentSeason.slice(1)} Season Winner!</h2>
            <p>Congratulations for winning this season!</p>
          </WinnerNotification>
          {this.props.children}
        </Fragment>
      );
    } else {
      return this.props.children;
    }
  }
}

export default IsWinner;
