import gql from 'graphql-tag';

export const VIEW_LEAGUE_REGISTRATION = gql`
  query($registeredStatus: String!, $location: String, $skill: String, $playedPastSeason: Boolean!) {
    checkLeagueRegistration(
      registeredStatus: $registeredStatus
      location: $location
      skill: $skill
      playedPastSeason: $playedPastSeason
    ) {
      firstname
      lastname
      email
    }
  }
`;
