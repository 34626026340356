import React, { PureComponent } from 'react';
import Hashids from 'hashids';

import GetSeasonFixtureUsers from './getSeasonFixtureUsers';
import { Aside } from '../style';

const hashids = new Hashids('fleximazing', 10);

// Using the PureComponent to eliminate the re-rendering of the component and it's children -> components that are declared inside it as well.
class SideBar extends PureComponent {
  constructor() {
    super();
    this.state = {
      allFixture: [],
    };
  }

  componentDidMount() {
    const { getAllFixture } = this.props;
    let duplicateItem = [];
    let set = new Set();

    // Creating a hash table to determine if an opponentId is found more then once.
    // If so, then push the ID of that fixture into an array which we will filter out below.
    getAllFixture.forEach(v => {
      if (set.has(v.opponentId)) {
        duplicateItem.push(v.id);
      } else {
        set.add(v.opponentId);
      }
    });

    // Excluding the fixture Id that has the duplicate opponentId.
    const newArray = getAllFixture.filter(v => !duplicateItem.includes(v.id));
    this.setState({ allFixture: newArray });
  }

  render() {
    const { session } = this.props;
    const { allFixture } = this.state;

    return (
      <Aside className="aside-section">
        {/* Loop through the "getAllFixture" array to get each players opponentId */}
        {allFixture.map(fixture => {
          const { opponentId, id } = fixture;
          const userEncoded = hashids.encode(opponentId);
          const chatLink = `/singles/redirect/${userEncoded}`;
          return (
            <GetSeasonFixtureUsers
              key={id}
              opponentId={opponentId}
              chatLink={chatLink}
              session={session}
              userEncoded={userEncoded}
            />
          );
        })}
      </Aside>
    );
  }
}

export default SideBar;
