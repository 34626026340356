import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';

import { sanitizeInput } from '../../../abstracts/helpers';

import { Flex, Button } from '../../Universal/style';
import { DELETE_USER_MUTATION } from '../queries';

class UserDeletion extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      closed: false,
    };
  }

  componentDidMount() {
    const currentTime = new Date().getTime();
    const { previousSpring, summer, autumn, winter, spring, nextSummer } = this.props.seasonDates;
    // Setting dates to be FOUR DAYS BEFORE the season starts -> the actual closing registration date
    const oneDayPreviousSpring = new Date(new Date(previousSpring).setDate(new Date(previousSpring).getDate() - 4));
    const oneDaySummer = new Date(new Date(summer).setDate(new Date(summer).getDate() - 4));
    const oneDayAutumn = new Date(new Date(autumn).setDate(new Date(autumn).getDate() - 4));
    const oneDayWinter = new Date(new Date(winter).setDate(new Date(winter).getDate() - 4));
    const oneDaySpring = new Date(new Date(spring).setDate(new Date(spring).getDate() - 4));

    // End dates
    const beforeSummer = new Date(new Date(summer).setDate(new Date(summer).getDate() - 19));
    const beforeAutumn = new Date(new Date(autumn).setDate(new Date(autumn).getDate() - 19));
    const beforeWinter = new Date(new Date(winter).setDate(new Date(winter).getDate() - 19));
    const beforeSpring = new Date(new Date(spring).setDate(new Date(spring).getDate() - 19));
    const beforeNextSummer = new Date(new Date(nextSummer).setDate(new Date(nextSummer).getDate() - 19));

    if (oneDayPreviousSpring <= currentTime && currentTime <= beforeSummer) {
      this.setState({ closed: true });
    } else if (oneDaySummer <= currentTime && currentTime <= beforeAutumn) {
      this.setState({ closed: true });
    } else if (oneDayAutumn <= currentTime && currentTime <= beforeWinter) {
      this.setState({ closed: true });
    } else if (oneDayWinter <= currentTime && currentTime <= beforeSpring) {
      this.setState({ closed: true });
    } else if (oneDaySpring <= currentTime && currentTime <= beforeNextSummer) {
      this.setState({ closed: true });
    }
  }

  onChange = event => {
    this.setState({ email: event.target.value });
  };

  onSubmit = async (event, deleteUser) => {
    event.preventDefault();
    await deleteUser();
    this.setState({ email: '' });
  };

  render() {
    const { closed } = this.state;
    let { email } = this.state;

    // Sanitize input
    email = sanitizeInput({ string: email, type: 'email' });

    return (
      <div className="a-form-section">
        <p>
          Enter user email for deletion -{' '}
          <span style={{ color: 'crimson', display: 'inline-block' }}>Only when season has NOT started</span>
        </p>
        {!closed && (
          <Flex className="a-form-wrapper">
            <Mutation mutation={DELETE_USER_MUTATION} variables={{ email }}>
              {(deleteUser, { error }) => (
                <form>
                  <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    placeholder="Enter email of user"
                  />
                  <Button normal onClick={event => this.onSubmit(event, deleteUser)}>
                    Delete
                  </Button>
                  {error && <div className="error-message">Email doesn't exist.</div>}
                </form>
              )}
            </Mutation>
          </Flex>
        )}
      </div>
    );
  }
}

export default UserDeletion;
