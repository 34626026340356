import React, { Fragment } from 'react';
import { Query } from '@apollo/react-components';

import { GET_LEAGUE } from './queries';

const FilterAvailability = ({ getSubleagueInfo }) => {
  const filterSingles = getSubleagueInfo.filter(object => object.playType === 'singles');
  const filterDoubles = getSubleagueInfo.filter(object => object.playType === 'doubles');
  const masterArray = [filterSingles, filterDoubles];

  return masterArray.map((filteredArray, filteredIndex) => (
    <Fragment key={filteredIndex}>
      {filteredArray.length > 0 && (
        <Fragment>
          <Fragment>{filteredIndex === 0 ? <h3>Singles Availability</h3> : <h3>Doubles Availability</h3>}</Fragment>
          <div className="grid">
            <div className="overflow">
              <div className="table">
                <div className="table-row">
                  <div className="table-cell">
                    <p>{filteredIndex === 0 ? 'Player Count' : 'Team Count'}</p>
                  </div>
                  <div className="table-cell">
                    <p>playType</p>
                  </div>
                  {filteredIndex === 0 && (
                    <div className="table-cell">
                      <p>Gender</p>
                    </div>
                  )}
                  <div className="table-cell">
                    <p>Subleague Count</p>
                  </div>
                  <div className="table-cell">
                    <p>Location</p>
                  </div>
                  <div className="table-cell">
                    <p>Skill</p>
                  </div>
                </div>
                {filteredArray.map(object => {
                  const { id, playerCount, playType, genderType, subLeagueCount, leagueId } = object;
                  return (
                    <div key={id} className="table-row">
                      <div className="table-cell">
                        <p>{playerCount}</p>
                      </div>
                      <div className="table-cell">
                        <p>{playType}</p>
                      </div>
                      {filteredIndex === 0 && (
                        <div className="table-cell">
                          <p>{genderType}</p>
                        </div>
                      )}
                      <div className="table-cell">
                        <p>{subLeagueCount}</p>
                      </div>
                      <Query query={GET_LEAGUE} variables={{ id: leagueId }}>
                        {({ data, loading }) => (
                          <Fragment>
                            {loading && <p>Loading...</p>}
                            {data && data.getLeague && (
                              <Fragment>
                                <div className="table-cell">
                                  <p>{data.getLeague.location}</p>
                                </div>
                                <div className="table-cell">
                                  <p>{data.getLeague.skill}</p>
                                </div>
                              </Fragment>
                            )}
                          </Fragment>
                        )}
                      </Query>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  ));
};

export default FilterAvailability;
