import gql from 'graphql-tag';

// Query - new singles messages
export const NEW_SINGLES_MESSAGES_QUERY = gql`
  query {
    allNewOpponentMessages {
      id
    }
  }
`;
// Subscription - new singles messages
export const NEW_SINGLES_MESSAGES_SUBSCRIPTION = gql`
  subscription($userId: ID) {
    allNewOpponentMessagesSub(userId: $userId) {
      id
    }
  }
`;

// Query - new doubles messages
export const NEW_DOUBLES_MESSAGES_QUERY = gql`
  query {
    allOpponentTeamMessages {
      id
    }
  }
`;
// Subscription - new doubles messages
export const NEW_DOUBLES_MESSAGES_SUBSCRIPTION = gql`
  subscription($teamId: ID) {
    allOpponentTeamMessagesSub(teamId: $teamId) {
      id
    }
  }
`;
