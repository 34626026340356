import styled, { theme, css } from '../../../theme';
import messageBackground from '../../../resources/images/message-back-bw.jpg';

export const Aside = styled.div`
  display: flex;
  flex: 0 0 25%;
  margin-right: 20px;
  border-right: 1px solid ${theme.breakLine};
  flex-direction: column;
  justify-content: space-between;

  .opponent-section h5,
  .partner-section h5 {
    padding: 5px 10px;
    color: white;
  }
  .opponent-section h5 {
    background-color: #323232;
    margin: 0;
  }
  .partner-section h5 {
    background-color: #086342;
    margin-bottom: 0;
  }

  a {
    display: flex;
    align-items: center;
    color: grey;
    padding: 11px 20px;
    background-color: white;
    border-bottom: 1px solid ${theme.breakLine};
  }
  a:hover {
    background-color: gray;
  }
  a:hover .status {
    background-color: white;
  }
  a:hover h4 {
    color: white;
  }
  .active-link {
    text-decoration: none;
    background-color: darkgray;
  }
  .active-link h4 {
    color: white;
  }
  .active-link .status {
    background-color: white;
  }
`;
export const H5 = styled.h5`
  ${props =>
    props.noMarginTop &&
    css`
      margin-top: 0;
    `};
`;
export const H4 = styled.h4`
  color: grey;
  font-size: 15px;
  margin: 0;
  ${props =>
    props.orange &&
    css`
      color: orange;
    `};
`;
export const P = styled.p`
  ${props =>
    props.back &&
    css`
      background-image: url(${messageBackground});
      background-size: cover;
      background-position: center;
      padding: 70px 10px;
      color: white;
      text-align: center;
      border-radius: 4px;
    `};
`;

export const Notification = styled.div`
  position: absolute;
  top: 7px;
  right: 9px;
  color: ${theme.crimson};
  font-size: 14px;
  font-family: "open sans";
  font-weight: 600;

  :before {
    content: "${props => props.amount}";
    margin-right: 3px;
    font-size: 12px;
  }

  @media (max-width: 768px) {
    :before {
      display: none;
    }
  }
`;

export const Status = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: ${props => (props.online ? 'limegreen' : 'darkgray')};
  margin-right: 10px;
`;
