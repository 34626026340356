import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import { sanitizeInput } from '../../../abstracts/helpers';

import * as routes from '../../../constants/routes';
import { BackLink } from '../../Universal/style';
import { Wrapper } from './style';
import withAuthorization from '../../Session/withAuthorization';
import { GET_USER_PLAY_INFO } from './queries';
import { GET_LEAGUE } from '../leagueAvailability/queries';
import { OPPONENT_QUERY } from '../../Universal/queries';
import SearchBoxSection from './searchBox';
import ProvideLeagueAccess from './provideLeagueAccess';
import RemoveAccess from './removeAccess';

class UserPlayInfo extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      searchEmail: '',
    };
  }

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onSubmitSearchBox = event => {
    event.preventDefault();
    const { email } = this.state;
    this.setState({ searchEmail: email });
  };

  render() {
    const { searchEmail } = this.state;
    let { email } = this.state;
    const isInvalid = email === '';

    // Sanitize inputs
    email = sanitizeInput({ string: email, type: 'email' });

    return (
      <Fragment>
        <BackLink>
          <Link to={routes.ADMIN}>
            <FontAwesomeIcon icon={faArrowCircleLeft} /> Back to Admin Page
          </Link>
        </BackLink>

        <Wrapper>
          {/* Getting data from the subleagues table and filtering the playTypes (singles / doubles) for easier readability */}
          <div className="section">
            <h2>Player Data</h2>

            <div id="check-users">
              {/* To provide user with membership - after registration closes */}
              <ProvideLeagueAccess />

              <div className="flex-column">
                {/* To check a user data */}
                <SearchBoxSection
                  onSubmitSearchBox={this.onSubmitSearchBox}
                  onChange={this.onChange}
                  email={email}
                  isInvalid={isInvalid}
                />
                <RemoveAccess />
              </div>
            </div>

            {searchEmail !== '' && (
              <Query query={GET_USER_PLAY_INFO} variables={{ email: searchEmail }}>
                {({ data, loading, error }) => (
                  <Fragment>
                    {loading && <p>Loading...</p>}
                    {data && data.getUserPlayInfo && data.getUserPlayInfo.length > 0 && (
                      <DisplayUserData getUserPlayInfo={data.getUserPlayInfo} />
                    )}
                    {error && <p className="error">User does not exist</p>}
                  </Fragment>
                )}
              </Query>
            )}
          </div>
        </Wrapper>
      </Fragment>
    );
  }
}

class DisplayUserData extends Component {
  render() {
    const { getUserPlayInfo } = this.props;
    const { playType } = getUserPlayInfo[0];
    let isValid = playType && playType.length > 0;

    if (isValid) {
      return (
        <div>
          {playType === 'singles' ? <h3>Singles Details</h3> : <h3>Doubles Details</h3>}
          <div className="overflow">
            <div className="wrapper">
              <div className="table" style={{ marginBottom: '20px' }}>
                <div className="table-row">
                  <div className="table-cell">
                    <p>Season</p>
                  </div>
                  <div className="table-cell">
                    <p>Year</p>
                  </div>
                  {playType === 'singles' && (
                    <div className="table-cell">
                      <p>Gender</p>
                    </div>
                  )}
                  <div className="table-cell">
                    <p>Group</p>
                  </div>
                  <div className="table-cell">
                    <p>Location</p>
                  </div>
                  <div className="table-cell">
                    <p>Skill</p>
                  </div>
                  {playType === 'singles' && (
                    <div className="table-cell">
                      <p>Name</p>
                    </div>
                  )}
                </div>
                <div className="table-row">
                  <div className="table-cell">
                    <p>{getUserPlayInfo[0].season}</p>
                  </div>
                  <div className="table-cell">
                    <p>{getUserPlayInfo[0].year}</p>
                  </div>
                  {getUserPlayInfo[0].playType === 'singles' && (
                    <div className="table-cell">
                      <p>{getUserPlayInfo[0].genderType}</p>
                    </div>
                  )}
                  <div className="table-cell">
                    <p>{getUserPlayInfo[0].subLeagueId}</p>
                  </div>
                  <Query query={GET_LEAGUE} variables={{ id: getUserPlayInfo[0].leagueId }}>
                    {({ data, loading }) => (
                      <Fragment>
                        {loading && <p>Loading...</p>}
                        {data && data.getLeague && (
                          <Fragment>
                            <div className="table-cell">
                              <p>{data.getLeague.location}</p>
                            </div>
                            <div className="table-cell">
                              <p>{data.getLeague.skill}</p>
                            </div>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </Query>
                  {playType === 'singles' && (
                    <Query query={OPPONENT_QUERY} variables={{ id: getUserPlayInfo[0].userId }}>
                      {({ data, loading }) => (
                        <Fragment>
                          {loading && <p>Loading...</p>}
                          {data && data.user && (
                            <Fragment>
                              <div className="table-cell">
                                <p>
                                  {data.user.firstname} {data.user.lastname}
                                </p>
                              </div>
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Query>
                  )}
                </div>
              </div>

              <div className="table" style={{ marginBottom: '20px' }}>
                <div className="table-row">
                  <div className="table-cell">
                    <p>Fullname</p>
                  </div>
                  <div className="table-cell">
                    <p>Role</p>
                  </div>
                  <div className="table-cell">
                    <p>PlayType</p>
                  </div>
                  {playType === 'singles' && (
                    <div className="table-cell">
                      <p>GenderType</p>
                    </div>
                  )}
                </div>
                <div className="table-row">
                  <div className="table-cell">
                    <p>
                      {getUserPlayInfo[1].firstname} {getUserPlayInfo[1].lastname}
                    </p>
                  </div>
                  <div className="table-cell">
                    <p>{getUserPlayInfo[1].role}</p>
                  </div>
                  <div className="table-cell">
                    <p>{getUserPlayInfo[1].playType}</p>
                  </div>
                  {playType === 'singles' && (
                    <div className="table-cell">
                      <p>{getUserPlayInfo[1].genderType}</p>
                    </div>
                  )}
                </div>
              </div>

              {/* Only doubles will have two datas in the array */}
              {playType === 'doubles' && (
                <div className="table">
                  <div className="table-row">
                    <div className="table-cell">
                      <p>Team Name</p>
                    </div>
                    <div className="table-cell">
                      <p>Partner Email</p>
                    </div>
                    <div className="table-cell">
                      <p>Owner Name</p>
                    </div>
                    <div className="table-cell">
                      <p>Owner Email</p>
                    </div>
                  </div>
                  <div className="table-row">
                    <div className="table-cell">
                      <p>{getUserPlayInfo[2].name}</p>
                    </div>
                    <div className="table-cell">
                      <p>{getUserPlayInfo[2].partnerEmail}</p>
                    </div>
                    <Query query={OPPONENT_QUERY} variables={{ id: getUserPlayInfo[2].ownerId }}>
                      {({ data, loading }) => (
                        <Fragment>
                          {loading && <p>Loading...</p>}
                          {data && data.user && (
                            <Fragment>
                              <div className="table-cell">
                                <p>
                                  {data.user.firstname} {data.user.lastname}
                                </p>
                              </div>
                              <div className="table-cell">
                                <p>{data.user.email}</p>
                              </div>
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Query>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      const { firstname, lastname } = getUserPlayInfo[0];
      return (
        <p className="error">
          {firstname} {lastname} has not registered in a league yet.
        </p>
      );
    }
  }
}

export default withAuthorization(session => session && session.me && session.me.role === 'ADMIN')(UserPlayInfo);
