import React, { Fragment } from 'react';
import { Query } from '@apollo/react-components';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import * as routes from '../../constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faUsers,
  faEnvelopeOpenText,
  faFileInvoiceDollar,
  faUserShield,
  faTrashAlt,
  faMarker,
  faVihara,
  faRestroom,
} from '@fortawesome/free-solid-svg-icons';

import withAuthorization from '../Session/withAuthorization';

import DatesPage from './datesPage';
import { CURRENT_YEAR_SEASON_DATES } from '../Universal/queries';
import { Flex, Button, H4 } from '../Universal/style';
import { AdminMain } from './style';
import FixtureStatus from './fixtureStatus';
import Statistics from './statistics';
import ClickEmailPromotion from './emailPromotion';
import CustomEmailPage from './emailPromotion/customEmail';
import SubscribeToNewsletter from './subscribeNewsletter';
import PayableToggleSwitch from './payableToggleSwitch';
import GenderToggleSwitch from './genderToggleSwitch';
import MemberAccess from './memberAccess';
import UsersListPage from './memberAccess/usersList';
import { SEASON_STATUS_QUERY } from './queries';
import DeadSwitch from './deadSwitch';
import Feedback from './feedback';
import UserDeletion from './userDeletion';
import LeagueAvailability from './leagueAvailability';
import UserPlayInfo from './userPlayInfo';
import LeagueStatus from './leagueStatus';
import LeagueRegistration from './leagueRegistration';
import DashboardLayout from '../DashboardLayout';

const AdminPage = ({ session }) => (
  <Query query={CURRENT_YEAR_SEASON_DATES}>
    {({ data }) => {
      return (
        <Fragment>
          {data && data.currentYearSeasonDates && (
            <AdminPageInner session={session} seasonDates={data.currentYearSeasonDates} />
          )}
        </Fragment>
      );
    }}
  </Query>
);

const AdminPageInner = ({ session, seasonDates }) => (
  <DashboardLayout session={session} admin={true} seasonDates={seasonDates} breakExist={true}>
    <AdminMain>
      <h1>Admin</h1>

      <Switch>
        <Route exact path={routes.ADMIN} render={() => <MainAdmin seasonDates={seasonDates} />} />
        <Route exact path={routes.ADMIN_DATE_MANAGEMENT} render={() => <DatesPage />} />
        <Route exact path={routes.CUSTOM_EMAIL_PROMOTION} render={() => <CustomEmailPage />} />
        <Route exact path={routes.FREE_ACCESS} render={() => <UsersListPage />} />
        <Route exact path={routes.ADMIN_FEEDBACKS} render={() => <Feedback />} />
        <Route exact path={routes.LEAGUE_AVAILABILITY} render={() => <LeagueAvailability />} />
        <Route exact path={routes.LEAGUE_REGISTRATION} render={() => <LeagueRegistration />} />
        <Route exact path={routes.PLAYER_DATA} render={() => <UserPlayInfo />} />
        <Redirect to={routes.ADMIN} />
      </Switch>
    </AdminMain>
  </DashboardLayout>
);

const MainAdmin = ({ seasonDates }) => {
  return (
    <Query query={SEASON_STATUS_QUERY}>
      {({ data, loading, refetch }) => (
        <Fragment>
          {loading && <h3>Loading...</h3>}
          {data && data.getSeasonStatus && data.getSeasonStatus.length > 0 && (
            <Fragment>
              {/* The statistics section */}
              <div className="admin-section">
                <H4 MB={'0'} fontsize={'19px'}>
                  <FontAwesomeIcon icon={faUsers} /> Statistics
                </H4>
                <Statistics />
              </div>

              {/* Controlling the payable status section */}
              <div className="admin-section">
                <H4 MB={'0'} fontsize={'19px'}>
                  <FontAwesomeIcon icon={faFileInvoiceDollar} /> Manage Payable Status
                </H4>
                <PayableToggleSwitch getSeasonStatus={data.getSeasonStatus[0]} refetch={refetch} />
              </div>

              {/* Controlling whether to enable Singles Men's and Women's registrations -> Gender Play Switch */}
              <div className="admin-section">
                <H4 MB={'0'} fontsize={'19px'}>
                  <FontAwesomeIcon icon={faRestroom} /> Gender Registrations
                </H4>
                <GenderToggleSwitch getSeasonStatus={data.getSeasonStatus[0]} refetch={refetch} />
              </div>

              {/* Master trunacating tables section */}
              <div className="admin-section dead-switch-wrapper">
                <Fragment>
                  <H4 MB={'0'} fontsize={'19px'}>
                    <FontAwesomeIcon icon={faTrashAlt} /> Truncate DB <span>+</span> Feed Past Results
                  </H4>
                  {data.getSeasonStatus[0].truncateAll ? (
                    <p>All database data has been truncated.</p>
                  ) : (
                    <DeadSwitch refetch={refetch} seasonDates={seasonDates} />
                  )}
                </Fragment>
              </div>

              {/* Grant membership section */}
              <div className="admin-section">
                <H4 MB={'0'} fontsize={'19px'}>
                  <FontAwesomeIcon icon={faUserShield} /> Grant Membership
                </H4>
                <MemberAccess />
              </div>

              {/* Email Promotion section */}
              <div className="admin-section">
                <H4 MB={'0'} fontsize={'19px'}>
                  <FontAwesomeIcon icon={faEnvelopeOpenText} /> Email Promotion
                </H4>
                <Flex className="email-promotion-columns">
                  <div className="email-promotion-column-1">
                    <ClickEmailPromotion getSeasonStatus={data.getSeasonStatus[0]} refetch={refetch} />
                  </div>
                  <div className="email-promotion-column-2">
                    <p>Manage custom emails to all users.</p>
                    <Link to={routes.CUSTOM_EMAIL_PROMOTION}>
                      <Button normal>Manage Custom Emails</Button>
                    </Link>
                  </div>
                </Flex>
                <SubscribeToNewsletter />
              </div>

              {/* Date management section */}
              <div className="admin-section">
                <H4 MB={'0'} fontsize={'19px'}>
                  <FontAwesomeIcon icon={faCalendarAlt} /> Date Management
                </H4>
                <p>To manage the Season Dates for each year, click here.</p>
                <Link to={routes.ADMIN_DATE_MANAGEMENT}>
                  <Button normal>Manage Season Dates</Button>
                </Link>
              </div>

              {/* Check league availability section */}
              <div className="admin-section">
                <H4 MB={'0'} fontsize={'19px'}>
                  <FontAwesomeIcon icon={faVihara} /> League availability
                </H4>
                <Flex>
                  <div className="league-available-col league-available-1">
                    <p>To view and manage league availability for users, click here.</p>
                    <Link to={routes.LEAGUE_AVAILABILITY}>
                      <Button normal>View availability</Button>
                    </Link>
                  </div>
                  <div className="league-available-col league-available-2">
                    <p>To view a player data, click here.</p>
                    <Link to={routes.PLAYER_DATA}>
                      <Button normal>Check</Button>
                    </Link>
                  </div>
                </Flex>
              </div>

              {/* View all league registration section */}
              <div className="admin-section">
                <H4 MB={'0'} fontsize={'19px'}>
                  <FontAwesomeIcon icon={faVihara} /> League Registrations
                </H4>
                <p>To view league registrations, click here.</p>
                <Link to={routes.LEAGUE_REGISTRATION}>
                  <Button normal>View Registrations</Button>
                </Link>
              </div>

              {/* Send email to all players about their league status */}
              <div className="admin-section">
                <H4 MB={'0'} fontsize={'19px'}>
                  <FontAwesomeIcon icon={faCalendarAlt} /> League Status Notification
                </H4>
                {data.getSeasonStatus[0].leagueStatus ? (
                  <p>
                    Email League Status has been sent to all players{' '}
                    <span style={{ display: 'inline-block' }} role="img" aria-label="Rocket">
                      🚀
                    </span>
                  </p>
                ) : (
                  <LeagueStatus />
                )}
              </div>

              {/* Master control of the Singles and Doubles fixtures section */}
              <div className="admin-section">
                {/* Fixture status for season and for finals */}
                <FixtureStatus seasonDates={seasonDates} />
              </div>
              {/* Master control of the Feedbacks */}
              <div className="admin-section">
                <H4 MB={'0'} fontsize={'19px'}>
                  <FontAwesomeIcon icon={faMarker} /> All Feedbacks
                </H4>
                <p>To view/manage the display of feedbacks, click here.</p>
                <Link to={routes.ADMIN_FEEDBACKS}>
                  <Button normal>Manage Feedbacks</Button>
                </Link>
              </div>
              {/* Deleting a user section */}
              <div className="admin-section">
                <H4 MB={'0'} fontsize={'19px'}>
                  <FontAwesomeIcon icon={faTrashAlt} /> User Deletion
                </H4>
                <UserDeletion seasonDates={seasonDates} />
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </Query>
  );
};

export default withAuthorization(session => session && session.me && session.me.role === 'ADMIN')(AdminPage);
