import React, { Component } from 'react';
import { Query } from '@apollo/react-components';
import Hashids from 'hashids';

import FixtureTable from './fixtureTable';
import { GET_OTHER_TEAM } from '../queries';

const hashids = new Hashids('fleximazing', 10);

// Get final fixture
class FinalFixture extends Component {
  render() {
    const { getFinalFixture, week10Date, currentTeamId, session, teamOwnerId, currentWeek } = this.props;
    // Check if the "getFinalFixture" is an object --> if so, turn into array and check if first value is equal to null.
    // If there is no data, then null is return for all values. We will just check first property.
    if (getFinalFixture && getFinalFixture.length > 0) {
      const { id, score, startDate, opponentTeamId } = getFinalFixture[0];
      const scoreData = JSON.parse(score);
      const theDate = new Date(startDate);
      const otherTeam = hashids.encode(opponentTeamId);
      const uploadEncrypt = hashids.encode(id);
      const uploadDecodedId = id;
      const profileLink = `/doubles/fixtures/profile/${otherTeam}`;
      const uploadLink = `/doubles/fixtures/upload/${uploadEncrypt}`;

      return (
        <Query query={GET_OTHER_TEAM} variables={{ id: opponentTeamId }}>
          {({ data }) => (
            <FixtureTable
              data={data}
              week={'10'}
              theDate={theDate}
              score={scoreData}
              profileLink={profileLink}
              uploadLink={uploadLink}
              uploadDecodedId={uploadDecodedId}
              currentTeamId={currentTeamId}
              normal={true}
              session={session}
              teamOwnerId={teamOwnerId}
            />
          )}
        </Query>
      );
    } else {
      // Only need to pass down the "date". Since final fixture doesn't have data, then we need to pass "date" down.
      return <FixtureTable theDate={week10Date} final={true} currentWeek={currentWeek} />;
    }
  }
}

export default FinalFixture;
