import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { Mutation } from '@apollo/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import { sanitizeInput } from '../../abstracts/helpers';

import * as routes from '../../constants/routes';
import { SelectWrapper, SkillsWrapper, ContactLeagueLink } from './singles';
import { Span } from './style';
import StripeSection from './Stripe';
import { master } from '../../resources/resources';
import Loading from '../Loading';
import ErrorMessage from '../Error';
import { ErrorWrapper, Li } from '../Error/style';
import { H4, P, ButtonWrapper, Button, InputWrapper, Input, Adjust, BackLink } from '../Universal/style';
import NoPayment from './noPayment';

const CREATE_TEAM = gql`
  mutation(
    $season: String!
    $league: String!
    $skill: String!
    $name: String!
    $partnerEmail: String!
    $tennisCourt: String!
    $playTime: String!
  ) {
    createTeam(
      season: $season
      league: $league
      skill: $skill
      name: $name
      partnerEmail: $partnerEmail
      tennisCourt: $tennisCourt
      playTime: $playTime
    ) {
      id
      partnerEmail
    }
  }
`;

const DoublesReg = ({ session, userRefetch, customer, season, getSeasonStatus }) => {
  return (
    <Fragment>
      <BackLink doubles={true}>
        <Link to={routes.LANDING}>
          <FontAwesomeIcon icon={faArrowCircleLeft} /> Back to register dashboard
        </Link>
      </BackLink>
      <h2>
        Register for: <Span green>Doubles</Span>
      </h2>
      {session && session.me && (
        <DoublesForm
          customer={customer}
          userRefetch={userRefetch}
          session={session}
          season={season}
          type={'doubles'}
          getSeasonStatus={getSeasonStatus}
        />
      )}
    </Fragment>
  );
};

class DoublesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationState: 'select-option',
      league: 'select-option',
      skill: 'select-option',
      teamName: '',
      partnerEmail: '',
      court: '',
      playing: '',
      payment: false,

      teamId: '',

      leagueError: '',
      skillError: '',
    };
  }

  closePaymentWindow = () => {
    this.setState({ payment: false });
  };

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  // For custom select -> function to remove all active classes on fake select element
  resetActiveSelect = (event, fakeSelectAll) => {
    const targetElement = event.target;
    if (!targetElement.classList.contains('select-chosen')) {
      fakeSelectAll.forEach(element => element.classList.remove('active-options'));
    }
  };

  // For custom select -> select click event
  onSelectClick = stateName => {
    // The parent element
    const fakeSelectAll = document.querySelectorAll('.fake-select-wrapper');

    const parentElement = document.querySelector(`.${stateName}`).parentElement;
    // Check if the current parent had the active class -> if so, determine logic in the add active class to toggle no class.
    const currentActiveExisted = parentElement.classList.contains('active-options');

    // Remove active class on all parents before proceeding.
    fakeSelectAll.forEach(element => element.classList.remove('active-options'));

    // Add active class to current element
    if (!currentActiveExisted) {
      parentElement.classList.toggle('active-options');
    }
    // Remove active class on document click
    document.addEventListener('click', event => this.resetActiveSelect(event, fakeSelectAll));
  };

  // For custom select -> select change event
  onSelectChange = (stateName, value) => {
    this.setState({ [stateName]: value });
    // If the state selection is clicked, then it should reset the select value for the region
    if (stateName === 'locationState') {
      this.setState({ league: 'select-option' });
    }
    document.querySelector(`.${stateName}`).parentElement.classList.remove('active-options');
  };

  onSubmit = async (event, createTeam) => {
    event.preventDefault();
    const { league, skill } = this.state;
    let error = false;

    if (league === 'select-option') {
      this.setState({ leagueError: 'Please select a league' });
      error = true;
    }
    if (skill === 'select-option') {
      this.setState({ skillError: 'Please select a skill' });
      error = true;
    }

    if (!error) {
      await createTeam().then(async ({ data }) => {
        this.setState({
          payment: true,
          teamId: data.createTeam.id,
          partnerEmail: data.createTeam.partnerEmail,
        });
      });
    }
  };

  render() {
    const { locationState, league, skill, payment, teamId, leagueError, skillError } = this.state;
    let { teamName, partnerEmail, court, playing } = this.state;

    const invalid = league === 'select-option' || skill === 'select-option' || teamName === '' || partnerEmail === '';
    const errorArray = [leagueError, skillError];
    const filteredErrors = errorArray.filter(v => v !== '');

    const locations = master.locations;
    const regions = master.regions;
    let regionsActive;
    if (locationState !== 'select-option') {
      regionsActive = regions.filter(region => region.location === locationState)[0].inner;
    }

    const skills = master.skills;
    const { session, season, type, getSeasonStatus } = this.props;

    // Format string to be capitalized
    // Sanitize inputs
    partnerEmail = sanitizeInput({ string: partnerEmail, type: 'email' }).toLowerCase();
    teamName = sanitizeInput({ string: teamName }).toLowerCase();
    teamName = teamName.charAt(0).toUpperCase() + teamName.slice(1);
    court = sanitizeInput({ string: court });
    playing = sanitizeInput({ string: playing });

    return (
      <Fragment>
        <Mutation
          mutation={CREATE_TEAM}
          variables={{
            season,
            league,
            skill,
            name: teamName,
            partnerEmail,
            tennisCourt: court,
            playTime: playing,
          }}
        >
          {(createTeam, { loading, error }) => (
            // When the user fills the form and submits, it sends to the database and updates the league ID then the "payment" state is set to true so that we can show the stripe form.
            <Adjust relative>
              {payment && (
                <Fragment>
                  {getSeasonStatus.payable && session.me.role !== 'FREE' ? (
                    <StripeSection
                      type={type}
                      customer={this.props.customer}
                      userRefetch={this.props.userRefetch}
                      session={session}
                      season={season}
                      teamId={teamId}
                      partnerEmail={partnerEmail}
                      closePaymentWindow={this.closePaymentWindow}
                      league={league}
                      skill={skill}
                      price={getSeasonStatus.price}
                    />
                  ) : (
                    <NoPayment
                      type={type}
                      userRefetch={this.props.userRefetch}
                      session={session}
                      season={season}
                      teamId={teamId}
                      partnerEmail={partnerEmail}
                      closePaymentWindow={this.closePaymentWindow}
                      league={league}
                      skill={skill}
                    />
                  )}
                </Fragment>
              )}
              {season !== null ? (
                <form autoComplete="off" onSubmit={event => this.onSubmit(event, createTeam)}>
                  <InputWrapper flex theme={'doubles'}>
                    {/* For the state locations select */}
                    <SelectWrapper
                      title={'State Selection'}
                      stateName={'locationState'}
                      state={locationState}
                      options={locations}
                      onSelectClick={this.onSelectClick}
                      onSelectChange={this.onSelectChange}
                    />

                    {/* Only display the region field if a STATE location has been chosen */}
                    {regionsActive && (
                      <Fragment>
                        <SelectWrapper
                          title={'Region Selection'}
                          stateName={'league'}
                          state={league}
                          options={regionsActive}
                          onSelectClick={this.onSelectClick}
                          onSelectChange={this.onSelectChange}
                        />

                        {/* External link to FTL website for enquiry form if league is not available */}
                        <ContactLeagueLink />
                      </Fragment>
                    )}

                    <div className="input-wrapper">
                      <SkillsWrapper skills={skills} skill={skill} onChange={this.onChange} />
                    </div>

                    <div className="input-wrapper">
                      <H4 noMB>Your team name</H4>
                      <Input
                        name="teamName"
                        value={teamName}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Enter Your Team Name"
                      />
                    </div>

                    <div className="input-wrapper">
                      <H4 noMB>Your Partners Email</H4>
                      <Input
                        name="partnerEmail"
                        value={partnerEmail}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Partner's Email (they will receive an invitation to join"
                      />
                    </div>

                    <div className="input-wrapper">
                      <H4 noMB>
                        Where is your favourite tennis court
                        <span>?</span>
                      </H4>
                      <Input
                        name="court"
                        value={court}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Eg. Mt Lawley Tennis Courts"
                      />
                    </div>

                    <div className="input-wrapper">
                      <H4 noMB>
                        What is your preferred playing day<span>(</span>s<span>)</span> and Time<span>(</span>s
                        <span>)?</span>
                      </H4>
                      <Input
                        name="playing"
                        value={playing}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Eg. Tuesdays and Thurdays evening after 7pm"
                      />
                    </div>
                  </InputWrapper>

                  <ButtonWrapper style={{ marginBottom: '30px' }}>
                    <Button doubles={true} normal disabled={invalid || loading} type="submit">
                      {loading ? <Loading /> : `Register and ${getSeasonStatus.payable ? 'Pay' : 'Confirm'}`}
                    </Button>
                  </ButtonWrapper>

                  {/* Errors from server */}
                  {error && <ErrorMessage error={error} />}
                  {/* Frontend errors */}
                  {filteredErrors.length > 0 && (
                    <ErrorWrapper>
                      {filteredErrors.map((error, i) => (
                        <Li key={i}>{error}</Li>
                      ))}
                    </ErrorWrapper>
                  )}
                </form>
              ) : (
                <P color={'#569600'} bold uppercase>
                  Loading...
                </P>
              )}
            </Adjust>
          )}
        </Mutation>
      </Fragment>
    );
  }
}

export default DoublesReg;
