import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

import { Notification } from '../style';

// This component will determine if there are any NEW opponent messages for each opponent.
class GetNewNotifications extends PureComponent {
  removeRefetch = async () => {
    const { removeTeamNotification, newOpponentMessageRefetch } = this.props;
    await removeTeamNotification();
    await newOpponentMessageRefetch();
  };

  componentDidMount() {
    const { location, chatLink } = this.props;
    const { pathname } = location;
    const encodedChatString = chatLink.split('/');

    // If the URL pathname is the same as the chatLink then set the "notification" state to false and activate the "removeNotification" resolver to set all TRUE notification to FALSE
    if (pathname === `/doubles/message/team/${encodedChatString[3]}`) {
      this.removeRefetch();
    }
  }

  componentDidUpdate(prevProp) {
    const { getOpponentTeamMessage, location, chatLink } = this.props;
    const { pathname } = location;
    const encodedChatString = chatLink.split('/');
    // If the length of the "getOpponentTeamMessage" changes/increases and ALSO on the URL, then execute the "removeNotification" resolver.
    if (
      pathname === `/doubles/message/team/${encodedChatString[3]}` &&
      prevProp.getOpponentTeamMessage.length !== getOpponentTeamMessage.length
    ) {
      this.removeRefetch();
    }
  }

  render() {
    const pathname = this.props.location.pathname;
    const { chatLink, getOpponentTeamMessage } = this.props;
    const encodedChatString = chatLink.split('/');

    if (pathname === `/doubles/message/team/${encodedChatString[3]}`) {
      return <div></div>;
    } else {
      return (
        <Notification amount={getOpponentTeamMessage.length}>
          <FontAwesomeIcon icon={faBell} />
        </Notification>
      );
    }
  }
}

// Since we need to determine which PATH LOCATION we are in, we need to access the react router dom "withRouter" HOC.
const GetNewNotificationsWithRouter = withRouter(GetNewNotifications);
export default GetNewNotificationsWithRouter;
