import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

import { Notification } from '../style';

// This component will determine if there are any NEW opponent messages for each opponent.
class GetNewNotifications extends PureComponent {
  removeRefetch = async () => {
    const { removeNotification, newOpponentMessageRefetch } = this.props;
    await removeNotification();
    await newOpponentMessageRefetch();
  };

  componentDidMount() {
    const { location, chatLink } = this.props;
    const { pathname } = location;
    const encodedString = chatLink.split('/');

    // If the URL pathname is the same as the chatLink then set the "notification" state to false and activate the "removeNotification" resolver to set all TRUE notification to FALSE
    if (
      pathname === `/singles/message/${encodedString[3]}` ||
      pathname === `/doubles/message/partner/${encodedString[4]}`
    ) {
      this.removeRefetch();
    }
  }

  componentDidUpdate(prevProp) {
    const { getOpponentMessage, location, chatLink } = this.props;
    const { pathname } = location;
    const encodedString = chatLink.split('/');

    // If the length of the "getOpponentMessage" changes/increases, then display the NEW number of length.
    if (prevProp.getOpponentMessage.length !== getOpponentMessage.length) {
      if (
        pathname === `/singles/message/${encodedString[3]}` ||
        pathname === `/doubles/message/partner/${encodedString[4]}`
      ) {
        this.removeRefetch();
      }
    }
  }

  render() {
    const pathname = this.props.location.pathname;
    const { chatLink, getOpponentMessage } = this.props;
    const encodedString = chatLink.split('/');

    if (
      pathname === `/singles/message/${encodedString[3]}` ||
      pathname === `/doubles/message/partner/${encodedString[4]}`
    ) {
      return <div></div>;
    } else {
      return (
        <Notification amount={getOpponentMessage.length}>
          <FontAwesomeIcon icon={faBell} />
        </Notification>
      );
    }
  }
}

// Since we need to determine which PATH LOCATION we are in, we need to access the react router dom "withRouter" HOC.
const GetNewNotificationsWithRouter = withRouter(GetNewNotifications);
export default GetNewNotificationsWithRouter;
