import React, { Component } from 'react';

import { PENDING_DOUBLES_SUBSCRIPTION } from './queries';

class SubscriptionListener extends Component {
  subscribeToPendingApproval = () => {
    this.props.subscribeToMore({
      document: PENDING_DOUBLES_SUBSCRIPTION,
      variables: { fixtureId: this.props.fixtureId },
      updateQuery: (previousResult, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousResult;
        }

        const { pendingDoublesScoreReceived } = subscriptionData.data;
        return Object.assign({}, previousResult, {
          getScoreApprovalDoubles: [pendingDoublesScoreReceived],
        });
      },
    });
  };

  componentDidMount() {
    this.subscribeToPendingApproval();
  }

  render() {
    return <div></div>;
  }
}

export default SubscriptionListener;
