import React, { Component, Fragment } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Query, Mutation } from '@apollo/react-components';
import * as routes from '../../constants/routes';

import withAuthorization from '../Session/withAuthorization';

import Navigation from '../Navigation';
import LeftsideBar from '../Universal/LeftsideBar';
import RightsideBar from '../Universal/RightsideBar';
import Footer from '../Footer';
import { Flex, Col, Width, Break } from '../Universal/style';
import IsWinner from './isWinner';
import ProgressBarWithRouter from './progressBar';
import SinglesDash from './dashboard';
import SinglesFixtures from './fixtures';
import SinglesMessage from './message';
import Results from './results';
import { RedirectScreenWithRouter } from './message/';
import {
  CURRENT_YEAR_SEASON_DATES,
  GET_LEAGUE_INFO,
  FIXTURE_QUERY,
  UPDATED_FIXTURE_SUBSCRIPTION,
  GET_PLAYINFO,
  REMOVE_CONFETTI_MUTATION,
  REMOVE_MEMBERSHIP_MUTATION,
  GET_LEADERBOARD,
} from '../Universal/queries';
import { SinglesWrapperStyling } from './style';
import { MobileLogo } from '../Universal/Mobile';
import { GET_ME } from '../Session/queries';
import { SEASON_STATUS_QUERY } from '../Admin/queries';

// First Query will be to fetch the season dates for the year. Need this information to do DATES and TIME logics.
const SinglesMaster = ({ session, refetch }) => (
  <Query query={CURRENT_YEAR_SEASON_DATES}>
    {({ data }) => {
      const seasonDates = data;
      return (
        <Fragment>
          <Query query={GET_PLAYINFO}>
            {({ data }) => (
              <Fragment>
                {seasonDates && seasonDates.currentYearSeasonDates && data && data.getPlayInfo && (
                  <SinglesMasterInner
                    seasonDates={seasonDates.currentYearSeasonDates}
                    session={session}
                    refetchUser={refetch}
                    getPlayInfo={data.getPlayInfo}
                  />
                )}
              </Fragment>
            )}
          </Query>
        </Fragment>
      );
    }}
  </Query>
);

// Grab all season dates for the current year and determine what "season" we are currently inside.
// Pass the current "season" and "date" to the "FIXTURE_QUERY" Query resolver and "SinglesFixtures" component.
class SinglesMasterInner extends Component {
  constructor() {
    super();
    this.state = {
      currentSeason: '',
      currentSeasonDate: null,
      nextSeasonDate: null,
      year: '',
      currentWeek: '',
      redirectDate: null,
      adminRedirect: null,
      endDate: null,
      nextDateTrigger: null,

      // View port height
      height: 0,
    };
  }

  // View port height
  updateWindowDimensions = () => {
    this.setState({ height: window.innerHeight });
  };

  // Making this.setState Syncronous
  setStateAsync = state => {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  };

  datesLogic = async (seasonDates, currentTime) => {
    let { previousSpring, summer, autumn, winter, spring, nextSummer } = seasonDates;

    // Four days BEFORE season starts
    const oneDayPreviousSpring = new Date(new Date(previousSpring).setDate(new Date(previousSpring).getDate() - 4));
    const oneDaySummer = new Date(new Date(summer).setDate(new Date(summer).getDate() - 4));
    const oneDayAutumn = new Date(new Date(autumn).setDate(new Date(autumn).getDate() - 4));
    const oneDayWinter = new Date(new Date(winter).setDate(new Date(winter).getDate() - 4));
    const oneDaySpring = new Date(new Date(spring).setDate(new Date(spring).getDate() - 4));

    // Create BEFORE the season dates. Should be rewind by 3 weeks.
    const beforeSummer = new Date(new Date(summer).setDate(new Date(summer).getDate() - 19));
    const beforeAutumn = new Date(new Date(autumn).setDate(new Date(autumn).getDate() - 19));
    const beforeWinter = new Date(new Date(winter).setDate(new Date(winter).getDate() - 19));
    const beforeSpring = new Date(new Date(spring).setDate(new Date(spring).getDate() - 19));
    const beforeNextSummer = new Date(new Date(nextSummer).setDate(new Date(nextSummer).getDate() - 19));

    // As long as the current time is between A SEASON DATE and the BEFORE next SEASON DATE then update the state accordingly to the values.
    if (oneDayPreviousSpring <= currentTime && currentTime <= beforeSummer) {
      this.setState({
        currentSeason: 'spring',
        currentSeasonDate: new Date(previousSpring),
        nextSeasonDate: new Date(summer),
        year: new Date(previousSpring).getFullYear().toString(),
      });
    } else if (oneDaySummer <= currentTime && currentTime <= beforeAutumn) {
      this.setState({
        currentSeason: 'summer',
        currentSeasonDate: new Date(summer),
        nextSeasonDate: new Date(autumn),
        year: new Date(summer).getFullYear().toString(),
      });
    } else if (oneDayAutumn <= currentTime && currentTime <= beforeWinter) {
      this.setState({
        currentSeason: 'autumn',
        currentSeasonDate: new Date(autumn),
        nextSeasonDate: new Date(winter),
        year: new Date(autumn).getFullYear().toString(),
      });
    } else if (oneDayWinter <= currentTime && currentTime <= beforeSpring) {
      this.setState({
        currentSeason: 'winter',
        currentSeasonDate: new Date(winter),
        nextSeasonDate: new Date(spring),
        year: new Date(winter).getFullYear().toString(),
      });
    } else if (oneDaySpring <= currentTime && currentTime <= beforeNextSummer) {
      this.setState({
        currentSeason: 'spring',
        currentSeasonDate: new Date(spring),
        nextSeasonDate: new Date(nextSummer),
        year: new Date(spring).getFullYear().toString(),
      });
    }

    const seasonDateArray = [previousSpring, summer, autumn, winter, spring, nextSummer];

    // Logic to determine the weekly dates
    seasonDateArray.forEach(season => {
      // Just make it go back 3 weeks before the start date like the register screen.
      // Need the "weekBeforeStart" so that the "currentTime" can work for the current season. Otherwise it will pick up the next season.
      // Needs to be less than the currentTime to work.
      const weekBeforeStart = new Date(new Date(season).setDate(new Date(season).getDate() - 19));

      // Date for players to redirect to fixture page.
      // Redirect ALL players at the normal start date.
      // Redirect the ADMIN FOUR DAYS BEFORE start date.
      const redirectDate = new Date(season);
      const adminRedirect = new Date(new Date(season).setDate(new Date(season).getDate() - 4));
      const adminRedirectMinute = new Date(adminRedirect.setMinutes(adminRedirect.getMinutes() + 5));

      const week1 = new Date(new Date(season).setDate(new Date(season).getDate() + 7));
      const week2 = new Date(new Date(season).setDate(new Date(season).getDate() + 14));
      const week3 = new Date(new Date(season).setDate(new Date(season).getDate() + 21));
      const week4 = new Date(new Date(season).setDate(new Date(season).getDate() + 28));
      const week5 = new Date(new Date(season).setDate(new Date(season).getDate() + 35));
      const week6 = new Date(new Date(season).setDate(new Date(season).getDate() + 42));
      const week7 = new Date(new Date(season).setDate(new Date(season).getDate() + 49));
      const week8 = new Date(new Date(season).setDate(new Date(season).getDate() + 56));
      const week9 = new Date(new Date(season).setDate(new Date(season).getDate() + 63));
      const week10 = new Date(new Date(season).setDate(new Date(season).getDate() + 70));
      const noExist = new Date(new Date(season).setDate(new Date(season).getDate() + 77));

      const { session } = this.props;

      // As long as the current time is greater than the season date (after the season date) and less then the week1 time then continue..
      // logic continues throughout.
      // "redirectDate" and "endDate" will be passed down to the "progress bar" at the header.
      if (session && session.me) {
        if (weekBeforeStart <= currentTime && currentTime < redirectDate && session.me.role !== 'ADMIN') {
          this.setState({ currentWeek: 0, redirectDate: redirectDate });
        } else if (weekBeforeStart <= currentTime && currentTime < adminRedirectMinute && session.me.role === 'ADMIN') {
          this.setState({ currentWeek: 0, adminRedirect: adminRedirectMinute });
        } else if (
          (redirectDate <= currentTime && currentTime < week1 && session.me.role !== 'ADMIN') ||
          (adminRedirectMinute <= currentTime && currentTime < week1 && session.me.role === 'ADMIN')
        ) {
          this.setState({ currentWeek: 1, endDate: week10 });
        } else if (week1 <= currentTime && currentTime < week2) {
          this.setState({ currentWeek: 2, endDate: week10 });
        } else if (week2 <= currentTime && currentTime < week3) {
          this.setState({ currentWeek: 3, endDate: week10 });
        } else if (week3 <= currentTime && currentTime < week4) {
          this.setState({ currentWeek: 4, endDate: week10 });
        } else if (week4 <= currentTime && currentTime < week5) {
          this.setState({ currentWeek: 5, endDate: week10 });
        } else if (week5 <= currentTime && currentTime < week6) {
          this.setState({ currentWeek: 6, endDate: week10 });
        } else if (week6 <= currentTime && currentTime < week7) {
          this.setState({ currentWeek: 7, endDate: week10 });
        } else if (week7 <= currentTime && currentTime < week8) {
          this.setState({ currentWeek: 8, endDate: week10 });
        } else if (week8 <= currentTime && currentTime < week9) {
          this.setState({ currentWeek: 9, endDate: week10 });
        } else if (week9 <= currentTime && currentTime <= noExist) {
          this.setState({ currentWeek: 10, endDate: week10 });
        }
      }
    });
  };

  componentDidMount() {
    // View port height
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    const { seasonDates } = this.props;
    // Current time to do the logic
    const currentTime = new Date().getTime();
    this.datesLogic(seasonDates, currentTime);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  render() {
    // "type" variable is fixed. Set to "singles" because we are inside the "singles master" screen
    const type = 'singles';
    // "seasonDates" has all season dates for the current year. Passed down from above "singles master" component.
    const { session, refetchUser, seasonDates, getPlayInfo } = this.props;
    const {
      currentSeason,
      currentSeasonDate,
      nextSeasonDate,
      year,
      currentWeek,
      redirectDate,
      adminRedirect,
      endDate,
      height,
    } = this.state;

    return (
      <SinglesWrapperStyling height={height}>
        <div className="extra-wrapper">
          {/* Query need here to feed the season dates to the progress bar, fixture, and sidebars. */}
          <Flex justify grow noWrap className="main-content">
            {/* Declaring a Query here to fetch the current league the user is registered in and use it in the Left and Right sidebars. */}
            <Query query={GET_LEAGUE_INFO}>
              {({ data, loading }) => {
                const leagueInfoData = data;
                const leagueLoading = loading;

                return (
                  <Query query={SEASON_STATUS_QUERY}>
                    {({ data, loading }) => {
                      const seasonStatusData = data;
                      const seasonStatusLoading = loading;

                      if (seasonStatusLoading) {
                        return '';
                      }

                      return (
                        <Fragment>
                          {/* Need to check the "leagueInfoData" to ensure to can access the data */}
                          {leagueInfoData &&
                            leagueInfoData.getLeagueInfo &&
                            seasonStatusData &&
                            seasonStatusData.getSeasonStatus &&
                            session &&
                            session.me && (
                              // Fetching the fixture for the season when it becomes available --> refetched when the "fixture is generated".
                              <Query query={FIXTURE_QUERY} variables={{ season: currentSeason, playType: type, year }}>
                                {({ data, loading, refetch, subscribeToMore }) => {
                                  const fixtureData = data;
                                  const fixtureLoading = loading;
                                  const refetchFixtureQuery = refetch;
                                  if (fixtureLoading) {
                                    return '';
                                  }

                                  return (
                                    <Fragment>
                                      <Mutation
                                        mutation={REMOVE_CONFETTI_MUTATION}
                                        refetchQueries={() => [{ query: GET_ME }, { query: GET_LEADERBOARD }]}
                                      >
                                        {removeConfetti => (
                                          <IsWinner
                                            type={type}
                                            currentSeason={currentSeason}
                                            session={session}
                                            finalConfetti={session.me.finalConfetti}
                                            fixtureData={fixtureData}
                                            removeConfetti={removeConfetti}
                                          >
                                            {/* Check if fixture data has been updated --> eg. score gets populated in the fixture table because a player approved a score */}
                                            <FixtureUpdatedCheck
                                              subscribeToMore={subscribeToMore}
                                              userId={session.me.id}
                                            >
                                              <Col
                                                digit={'18%'}
                                                back
                                                noFlex
                                                className="sidebar-columns sidebar-column-one"
                                              >
                                                {fixtureData && fixtureData.getAllFixture && (
                                                  <Fragment>
                                                    <LeftsideBar
                                                      session={session}
                                                      seasonDates={seasonDates}
                                                      currentSeason={currentSeason}
                                                      year={year}
                                                      type={type}
                                                      leagueInfoData={leagueInfoData}
                                                      leagueLoading={leagueLoading}
                                                      getAllFixture={fixtureData.getAllFixture}
                                                      genderType={getPlayInfo.genderType}
                                                    />
                                                    {/* Add right sidebar for mobile resolution */}
                                                    <div className="mobile-right-sidebar">
                                                      <RightsideBar
                                                        mobile={true}
                                                        session={session}
                                                        leagueInfoData={leagueInfoData}
                                                        leagueLoading={leagueLoading}
                                                        seasonDates={seasonDates}
                                                        type={type}
                                                        getPlayInfo={getPlayInfo}
                                                      />
                                                    </div>
                                                    {/* Add right sidebar for mobile resolution */}
                                                  </Fragment>
                                                )}
                                              </Col>
                                              <Col digit={'50%'} className="main-columns">
                                                <Width full>
                                                  {/* For mobile --> logo */}
                                                  {session && session.me && (
                                                    <MobileLogo
                                                      genderType={session.me.genderType}
                                                      type={session.me.playType}
                                                    />
                                                  )}
                                                  {/* For mobile --> logo */}
                                                  {/* Timer countdown before the season start and a week before it ends. Also the standard weekly progress status */}
                                                  {/* checking "currentWeek" because when updating profile information, it returns to "" so we need to wait until currentWeek is populated with something */}
                                                  {session && session.me && currentWeek !== '' && (
                                                    <Mutation mutation={REMOVE_MEMBERSHIP_MUTATION}>
                                                      {removeMembership => (
                                                        <ProgressBarWithRouter
                                                          type={type}
                                                          seasonDates={seasonDates}
                                                          session={session}
                                                          currentWeek={currentWeek}
                                                          redirectDate={redirectDate}
                                                          adminRedirect={adminRedirect}
                                                          endDate={endDate}
                                                          genderType={getPlayInfo.genderType}
                                                          removeMembership={removeMembership}
                                                          refetch={refetchUser}
                                                        />
                                                      )}
                                                    </Mutation>
                                                  )}
                                                  <Navigation session={session} />
                                                  <Break MT={'40px'}></Break>

                                                  <Switch>
                                                    <Route
                                                      exact
                                                      path={routes.SINGLES_DASH}
                                                      render={() => (
                                                        <SinglesDash
                                                          session={session}
                                                          refetch={refetchUser}
                                                          leagueId={leagueInfoData.getLeagueInfo.id}
                                                          getPlayInfo={getPlayInfo}
                                                        />
                                                      )}
                                                    />
                                                    <Route
                                                      path={routes.SINGLES_FIXTURES}
                                                      render={() => (
                                                        <SinglesFixtures
                                                          session={session}
                                                          type={type}
                                                          seasonDates={seasonDates}
                                                          currentSeason={currentSeason}
                                                          currentSeasonDate={currentSeasonDate}
                                                          year={year}
                                                          fixtureData={fixtureData}
                                                          fixtureLoading={fixtureLoading}
                                                          refetchFixtureQuery={refetchFixtureQuery}
                                                          currentWeek={currentWeek}
                                                          genderType={getPlayInfo.genderType}
                                                          nextSeasonDate={nextSeasonDate}
                                                          getSeasonStatus={seasonStatusData.getSeasonStatus}
                                                        />
                                                      )}
                                                    />
                                                    <Route
                                                      path={routes.SINGLES_MESSAGE}
                                                      render={() => (
                                                        <SinglesMessage
                                                          session={session}
                                                          season={currentSeason}
                                                          year={year}
                                                          type={type}
                                                          genderType={getPlayInfo.genderType}
                                                          getSeasonStatus={seasonStatusData.getSeasonStatus}
                                                        />
                                                      )}
                                                    />
                                                    <Route path={routes.SINGLES_RESULTS} render={() => <Results />} />

                                                    {/* Redirect message route prior to landing on the actual one so that a remount of component occurs -> needed for the subscription of message to work. */}
                                                    <Route
                                                      path={routes.SINGLES_MESSAGE_REDIRECT}
                                                      render={() => <RedirectScreenWithRouter />}
                                                    />

                                                    <Redirect to={routes.SINGLES_FIXTURES} />
                                                  </Switch>
                                                </Width>
                                              </Col>
                                              <Col digit={'18%'} back className="sidebar-columns sidebar-column-two">
                                                <RightsideBar
                                                  session={session}
                                                  leagueInfoData={leagueInfoData}
                                                  leagueLoading={leagueLoading}
                                                  seasonDates={seasonDates}
                                                  type={type}
                                                  getPlayInfo={getPlayInfo}
                                                />
                                              </Col>
                                            </FixtureUpdatedCheck>
                                          </IsWinner>
                                        )}
                                      </Mutation>
                                    </Fragment>
                                  );
                                }}
                              </Query>
                            )}
                        </Fragment>
                      );
                    }}
                  </Query>
                );
              }}
            </Query>
          </Flex>
          <Footer />
        </div>
      </SinglesWrapperStyling>
    );
  }
}

// Checking for updated Fixture data --> when player approves a SCORE, so it should update immediately on the Fixture table
// It will ONLY update for the opponent.
// The player that APPROVES the score will need to "refetch" the fixture query.
// The reason why the subscription wouldn't work to return data for BOTH the "approver" and the "opponent" is because the "payload" is different.
// Every player fetches different fixtures.
// There can ONLY be 1 payload returning for a subscription, so we will return the opponent fixture updated data and...
// check that the userId passed through the subscription "userId" matches the opponent fixture "userId" then return it to the opponent.
class FixtureUpdatedCheck extends Component {
  // This function will always run as long as the page is active.
  subscribeToUpdatedFixture = () => {
    this.props.subscribeToMore({
      document: UPDATED_FIXTURE_SUBSCRIPTION,
      // Put variables inside the subscribeToMore function.
      variables: { userId: this.props.userId },
      // The previousResult is the current items in the database.
      // The subscriptionData is the NEW item just added.
      updateQuery: (previousResult, { subscriptionData }) => {
        // If the subcriptionData does not exist - return just the current items in the database.
        if (!subscriptionData.data) {
          return previousResult;
        }

        // create an exact object of the same shape as the initial query data and merge the previous result with the new result.
        return Object.assign({}, previousResult, {
          // getAllFixture will return an array as specified in the "fixture" schema.
          // We don't need to update the previousResult with the subscription result because it's just updating data and NOT adding more data.
          // If we add the subscription data then there will be duplicate items in the array.
          getAllFixture: [...previousResult.getAllFixture],
        });
      },
    });
  };

  componentDidMount() {
    this.subscribeToUpdatedFixture();
  }

  render() {
    return this.props.children;
  }
}

export default withAuthorization(
  session => session && session.me && session.me.paid && session.me.playType === 'singles'
)(SinglesMaster);
