import styled, { theme } from '../../theme';

export const Input = styled.input`
  max-width: 100%;
  margin: 5px 0;
  padding: 10px;
  font-size: 14px;
  border: 1px solid ${theme.extraLightGray};
  border-radius: 3px;
  outline: none;
  z-index: 1;
`;
export const Notification = styled.ul`
  border-top: 1px solid ${theme.breakLine};
  padding-top: 20px;
  margin-top: 0;

  li {
    color: ${theme.errorBack};
    margin: 0 auto;
    font-family: 'open sans', sans-serif;
    font-size: 14px;
    margin-bottom: 5px;

    .send-confirmation-button {
      display: inline-block;
      color: black;
      text-decoration: underline;
      cursor: pointer;
      :hover {
        opacity: 0.7;
      }
    }
    .no-hover {
      cursor: default;
      :hover {
        opacity: 1;
      }
    }
  }
  li.green {
    color: green;
  }
  li:last-child {
    margin-bottom: 0;
  }
  .normal {
    color: black;
  }
`;
export const Green = styled.span`
  color: ${theme.doubles.link};
  display: inline-block;
`;
