import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { Button } from '../../Universal/style';

const SearchBoxSection = ({ onSubmitSearchBox, onChange, email, isInvalid }) => (
  <form onSubmit={event => onSubmitSearchBox(event)}>
    <div className="heading">
      <FontAwesomeIcon icon={faSearch} /> <h3>Search Box</h3>
    </div>
    <div className="form-content">
      <div className="field">
        <input name="email" value={email} onChange={onChange} placeholder="Input email here" />
        <Button normal type="submit" disabled={isInvalid}>
          Search
        </Button>
      </div>
    </div>
  </form>
);

export default SearchBoxSection;
