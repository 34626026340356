import styled, { css, theme, keyframes } from '../../theme';
import SinglesBack from '../../resources/images/singles-bw.jpg';
import DoublesBack from '../../resources/images/doubles-bw.jpg';
import tennisBack from '../../resources/images/tennis-back.jpg';

const MovingBack = keyframes`
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 100% 300%;
  }
`;

export const RegisterWrapperStyling = styled.div`
  background-image: url(${tennisBack});
  background-position: center;
  background-repeat: repeat;
  background-size: 40%;
  animation: 450s ${MovingBack} infinite;

  .extra-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 1680px;
    max-width: 100%;
    margin: 0 auto;
    background-color: white;
  }
  .register-intro-text {
    font-family: 'calibri', sans-serif;
    font-size: 17px;
    strong {
      font-weight: 900;
      color: #0080a7;
      font-style: italic;
    }
  }
  .promotion {
    font-family: 'calibri-bold', sans-serif;
    color: darkorange;
    text-transform: capitalize;
    font-size: 20px;
  }
  .open-regos-wrapper {
    .register-button {
      &:first-child {
        flex: 0 1;
        margin-right: 25px;
      }
      &:nth-child(2) {
        flex: 0 1;
      }
      &:last-child {
        flex: 0 1;
        margin-left: 25px;
      }
    }
  }
  .mobile-logo {
    display: none;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 25px 7px;
    padding-bottom: 17px;
    border-bottom: 1px solid ${theme.breakLine};

    h4 {
      margin: 0;
      margin-top: 6px;
      font-size: 16px;
    }
  }

  @media (max-width: 1593px) {
    .sidebar-column-one {
      display: none;
    }
    .sidebar-column-two {
      order: -1;
      flex: 0 0 25%;

      .mobile-logo {
        display: flex;
      }
      .quote-section {
        margin-bottom: 20px;
      }
    }
    .open-regos-wrapper {
      flex-flow: unset;
    }
  }
  @media (max-width: 1190px) {
    .main-columns {
      max-width: 100%;
      margin-bottom: 40px;

      .inner-main-columns {
        max-width: 100%;
        margin: 0 auto;

        .mobile-logo {
          display: flex;
          margin: 25px 7px 0;
        }
      }
    }
    .sidebar-column-two {
      display: none;
    }
    .register-button {
      display: inline-flex;
      width: 280px;
      justify-content: center;

      h2 {
        width: 260px;
      }
    }
  }
  @media (max-width: 960px) {
    .main-content {
      margin-bottom: 30px;
    }
    .under-timer-wrapper {
      margin-bottom: 0;
    }
    .open-regos-wrapper {
      margin: 40px 0px 20px 0px;
      flex-flow: row wrap;

      .register-button {
        margin-bottom: 20px;

        a {
          height: 95px;
          width: 100%;
        }

        &:first-child,
        &:nth-child(2),
        &:last-child {
          flex: auto;
          width: 100%;
        }
        &:first-child {
          margin-right: 0;
        }
        &:last-child {
          margin-left: 0;
          margin-bottom: 0;
        }
      }
    }
    .register-button {
      border-radius: 0;
      width: 100%;
      background-position: center;

      h2 {
        width: 100%;
      }
      .border {
        top: -5%;
        left: 0%;
        bottom: 0;
        width: 99%;
        height: 99%;
      }
      :before {
        opacity: 0.9 !important;
      }
    }
  }
  @media (max-width: 768px) {
    .main-result {
      display: inline-block;

      .inner-section-wrapper {
        overflow-x: scroll;
      }
    }

    .navigation-wrapper {
      a {
        border-left: 1px solid ${theme.breakLine};
        border-right: 1px solid ${theme.breakLine};
        border-bottom: 1px solid ${theme.breakLine};
        border-radius: 4px;
        margin-right: 7px;

        .text {
          width: 20px;
          font-size: 18px;

          svg + div {
            display: none;
          }
        }
      }
    }
  }
  @media (max-width: 675px) {
    .skills-wrapper {
      flex-direction: column;
    }
  }
  @media (max-width: 600px) {
    .register-button {
      border-radius: 4px;

      .border {
        display: none;
      }
    }
    .season-heading-timer {
      font-size: 30px;
    }
    .semi-colon {
      height: 48px;
      font-size: 40px;
    }
    .digit-box {
      width: 60px;
      height: 52px;

      h3 {
        font-size: 25px;
      }
    }
  }
  @media (max-width: 579px) {
    .navigation-wrapper {
      ul li {
        display: flex;
        flex: 1;
        margin-right: 2px;

        :last-child {
          margin-right: 0;
        }

        a {
          width: 100%;
          padding: 15px 0;
          margin-right: 0;

          .text {
            font-size: 16px;
            width: auto;
          }
        }
      }

      .header-signout-button {
        margin-top: 10px;
        width: 100%;
        margin-right: 0;
      }
    }
  }
  @media (max-width: 540px) {
    .profile-form {
      display: block;
      padding: 0;

      .profile-image-wrapper {
        margin-bottom: 30px;
        padding: 0;

        .profile-image-text {
          display: none;
        }
      }
      .two-columns-details {
        display: block;

        > div > h4 {
          margin-top: 12px;
        }
        > div:first-child > input {
          margin-right: 0;
        }
      }
    }
  }
  @media (max-width: 412px) {
    .navigation-wrapper .text {
      font-size: 15px;
    }
  }
  @media (max-width: 400px) {
    .season-heading-timer {
      font-size: 23px;
    }
    .season-subheading-timer {
      margin-bottom: 10px;
    }
    .semi-colon {
      height: 31px;
      font-size: 23px;
    }
    .digit-box {
      width: 45px;
      height: 32px;

      h3 {
        font-size: 20px;
      }
    }
    .digit-box + h3 {
      font-size: 11px;
    }
    .fixture-gen-section {
      padding: 8px !important;
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

const backLoop = keyframes`
    from {
        transform: translate3d(0,-830px,0);
    }
    to {
        transform: translate3d(0,0,0);
    }
`;

export const RegisterButton = styled.div`
  a {
    position: relative;
    display: flex;
    align-items: center;
    height: 125px;
    width: 250px;
    border-radius: 5px;
    z-index: 0;
    background-image: ${props => (props.singles ? `url(${SinglesBack})` : `url(${DoublesBack})`)};
    background-size: cover;
    cursor: pointer;

    .border {
      position: absolute;
      content: '';
      top: -12px;
      bottom: 0;
      right: 0px;
      left: -12px;
      width: 105%;
      height: 110%;
      border: 6px solid #575757;
      border-radius: 7px;
    }

    &:before {
      position: absolute;
      content: '';
      cursor: pointer;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      border-radius: 7px;
      background-color: ${props => (props.singles ? theme.mixed.link : theme.doubles.link)};
      opacity: 0.7;
      z-index: -1;
    }

    &:hover,
    &:focus {
      .border {
        border: 6px solid ${theme.orange};
      }
    }
  }
`;
export const Heading = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-family: 'casanova';
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 11px;
  color: ${theme.orange};
  ${props =>
    props.white &&
    css`
      color: white;
    `};
`;
export const H2 = styled.h2`
  font-size: 16px;
  margin: 5px 0;
  color: ${theme.lightGray};
  font-weight: 600;
  ${props =>
    props.fontsize &&
    css`
      font-size: ${props.fontsize}px;
    `};
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
  ${props =>
    props.white &&
    css`
      color: white;
    `};
`;
export const H3 = styled.h3`
  font-size: 19px;
  margin: 5px 0;
  color: ${theme.lightGray};
  font-weight: 600;
  ${props =>
    props.fontsize &&
    css`
      font-size: ${props.fontsize};
    `};
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
  ${props =>
    props.white &&
    css`
      color: white;
    `};
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `};
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `};
  ${props =>
    props.PT &&
    css`
      padding-top: ${props.PT};
    `};
  ${props =>
    props.BT &&
    css`
      border-top: ${props.BT};
    `};
  ${props =>
    props.lineHeight &&
    css`
      line-height: ${props.lineHeight};
    `};
  ${props =>
    props.MT &&
    css`
      margin-top: ${props.MT};
    `};
  ${props =>
    props.letterSpacing &&
    css`
      letter-spacing: ${props.letterSpacing};
    `};
`;
export const Span = styled.span`
  display: inline-block;
  color: ${props =>
    props.green
      ? theme.doubles.link
      : props.blue
      ? theme.blue
      : props.theme === 'men'
      ? theme.men.link
      : props.theme === 'women'
      ? theme.women.link
      : theme.mixed.link};
  ${props =>
    props.block &&
    css`
      display: block;
    `};
`;
export const MyModal = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.94);
  width: 100%;
  height: 100%;
  z-index: 2;
`;
export const StripeFormWrapper = styled.div`
  width: 490px;
  max-width: 100%;
  margin: 0 auto;
`;
export const CheckoutWholeWrapper = styled.div`
  position: relative;
  border: 2px solid #d4d4d4;
  border-radius: 4px;
  padding: 15px 40px;
  ${props =>
    props.noPay &&
    css`
      width: 300px;
      margin: 0 auto;

      .sub-heading {
        text-transform: capitalize;
        color: black;
        font-weight: bolder;
      }
      .details-list {
        li p strong {
          color: black;
          font-weight: 800;
        }
      }

      @media (max-width: 500px) {
        width: auto;

        .details-list {
          padding: 0;
        }
      }
    `};

  .close-button {
    position: absolute;
    display: inline-block;
    top: 0;
    right: 0;
    font-size: 24px;
    color: crimson;
    cursor: pointer;

    :hover {
      color: #eb1f48;
    }
  }
`;
export const CheckoutWrapper = styled.div`
  margin-top: 20px;
  width: 400px;
  max-width: 100%;
`;
export const InputWrapper = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  background-color: lightgray;
  border-radius: 4px;
`;

// Timer section
export const TimerWrapper = styled.div`
  position: relative;
  padding: 50px 10px;
  overflow-y: hidden;
  z-index: 0;

  &&:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1000%;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: url(${tennisBack});
    background-repeat: repeat;
    background-size: 100%;
    animation: ${backLoop} 25s linear infinite;
    z-index: -1;
  }
`;
export const TimerContent = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 22px;
  ${props =>
    props.wrap &&
    css`
      flex-flow: row wrap;
    `};
`;
export const Timer = styled.div`
  display: flex;
`;
export const Digit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.lightGray};
  border-radius: 4px;
  font-size: 30px;
  width: 91px;
  height: 75px;
  color: ${theme.lightGray};
`;
export const SemiCol = styled.div`
  display: flex;
  align-items: center;
  height: 75px;
  font-size: 60px;
  margin: 0 8px;
`;

// Line Break
export const LineBreak = styled.div`
  border-top: 1px solid ${theme.breakLine};
  padding-top: 20px;
  margin-bottom: 40px;
`;

// Ribbon
export const Ribbon = styled.div`
  display: inline-block;
  position: relative;
  padding: 10px 25px;
  background-color: ${theme.doubles.link};
  border-left: 4px solid black;
  z-index: 0;

  :before,
  :after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 26px;
    background-color: ${theme.doubles.link};
    z-index: -1;
  }
  :before {
    transform: skew(30deg);
  }
  :after {
    transform: skew(-30deg);
  }
  p {
    z-index: 1;
    display: flex;
    align-items: center;

    span {
      display: inline-block;
      margin-right: 10px;
    }
  }

  @media (max-width: 530px) {
    width: auto;
    padding: 10px;

    :before,
    :after {
      display: none;
    }
  }
`;
