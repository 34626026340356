import React, { Fragment } from 'react';
import { Button } from '../../../../Universal/style';

const GenerateFinals = ({ adminGetWeek10Fixture, finalFixture }) => {
  if (adminGetWeek10Fixture && adminGetWeek10Fixture.length > 0) {
    return <div className="status-bar active">Singles Final Generated</div>;
  } else {
    return (
      <Fragment>
        <div className="status-bar empty">Not yet generated</div>
        <Button onClick={() => finalFixture()}>Generate Finals</Button>
      </Fragment>
    );
  }
};

export default GenerateFinals;
