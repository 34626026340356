import React, { Fragment } from 'react';
import { Query } from '@apollo/react-components';
import { Image, Transformation } from 'cloudinary-react';

import DoublesUpdatedLeaderBoardChecker from './leaderboardChecker';
import { GET_OTHER_TEAM } from '../../../../Doubles/fixtures/queries';
import { GET_DOUBLES_LEADERBOARD } from '../queries';
import { Table } from '../../style';
import { P } from '../../../style';
import placeholderProfile from '../../../../../resources/images/placeholder-profile.jpg';

const DoublesLeaderboard = ({ type, leagueId, teamPlayInfo, currentTeamId }) => (
  <Query query={GET_DOUBLES_LEADERBOARD} variables={{ teamId: currentTeamId }}>
    {({ data, subscribeToMore }) => (
      <Fragment>
        {/* Updated leaderboard data checker */}
        <DoublesUpdatedLeaderBoardChecker
          subscribeToMore={subscribeToMore}
          type={type}
          leagueId={leagueId}
          subLeagueId={teamPlayInfo.subLeagueId}
        >
          {/* Displaying table-like structure for the leaderboard layout */}
          <Table>
            <div className="table-row">
              <div className="table-cell">
                <P uppercase bold MT={'0'} MB={'0'}>
                  Rank
                </P>
              </div>
              <div className="table-cell">
                <P uppercase bold MT={'0'} MB={'0'}>
                  Team
                </P>
              </div>
              <div className="table-cell">
                <P uppercase bold MT={'0'} MB={'0'}>
                  W
                </P>
              </div>
              <div className="table-cell">
                <P uppercase bold MT={'0'} MB={'0'}>
                  L
                </P>
              </div>
              <div className="table-cell">
                <P uppercase bold MT={'0'} MB={'0'}>
                  Points
                </P>
              </div>
            </div>
            {/* Looping through the "leaderboard" data and sorting it by highest points first --> descending order */}
            {/* When it's sorted, we can deterine rank using the INDEX of each element because it's already sorted by winner */}
            {data &&
              data.getDoublesLeaderboard &&
              data.getDoublesLeaderboard
                .slice()
                .sort((a, b) => b.points - a.points)
                .map((eachData, index) => {
                  const { id, points, wins, losses, teamId } = eachData;

                  return (
                    <div key={id} className="table-row">
                      <div className="table-cell">
                        {/* If the points is equal to 0 then just display "--", otherwise display index + 1 --> otherwise 0 would be first which we don't want. */}
                        <P uppercase MT={'0'} MB={'0'}>
                          {points === 0 ? '--' : index + 1}
                        </P>
                      </div>
                      <div className="table-cell">
                        {/* Displaying user Image and Name */}
                        {/* Querying the player information */}
                        <Query query={GET_OTHER_TEAM} variables={{ id: +teamId }}>
                          {({ data, loading }) => (
                            // Wrapper for image and player name
                            <div className="player-wrapper">
                              {loading && (
                                <P MT={'0'} MB={'0'}>
                                  Loading...
                                </P>
                              )}
                              {data && data.getOtherTeam && (
                                <Fragment>
                                  <div className="image-overflow">
                                    {data.getOtherTeam.profileImage ? (
                                      <Image
                                        cloudName={process.env.REACT_APP_CLOUD_NAME}
                                        publicId={data.getOtherTeam.profileImage}
                                      >
                                        <Transformation width="25" height="25" gravity="faces" crop="fill" />
                                      </Image>
                                    ) : (
                                      <img src={placeholderProfile} alt="flexi-profile" />
                                    )}
                                  </div>
                                  {/* If current Team is the same as the current "teamId" in the loop then display orange color for name */}
                                  {+currentTeamId === +teamId ? (
                                    <P margin={'5px 0'} color={'#FFA700'} bold>
                                      <Fragment>{data.getOtherTeam.name}</Fragment>
                                    </P>
                                  ) : (
                                    <P margin={'5px 0'}>
                                      <Fragment>{data.getOtherTeam.name}</Fragment>
                                    </P>
                                  )}
                                </Fragment>
                              )}
                            </div>
                          )}
                        </Query>
                      </div>
                      <div className="table-cell">
                        <P uppercase MT={'0'} MB={'0'}>
                          {points === 0 ? '--' : wins}
                        </P>
                      </div>
                      <div className="table-cell">
                        <P uppercase MT={'0'} MB={'0'}>
                          {points === 0 ? '--' : losses}
                        </P>
                      </div>
                      <div className="table-cell">
                        <P uppercase MT={'0'} MB={'0'}>
                          {points === 0 ? '--' : points}
                        </P>
                      </div>
                    </div>
                  );
                })}
          </Table>
        </DoublesUpdatedLeaderBoardChecker>
      </Fragment>
    )}
  </Query>
);

export default DoublesLeaderboard;
