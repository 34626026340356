import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';
import 'element-scroll-polyfill';

import { sanitizeInput } from '../../../../abstracts/helpers';

import { Form, FormInner } from './style';
import { Button } from '../../../Universal/style';
import { CREATE_TEAM_CHANNEL, CREATE_TEAM_MESSAGE } from '../queries';

class CreateDirectMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      channelId: null || this.props.getTeamChannelId.teamChannelId,
    };
  }

  // This update occurs when props or state values changes -> second time the component renders.
  // I'm checking the state value of channelId with the props getTeamChannelId.teamChannelId value --> if not same, then update state.
  // Must have a condition in if statement otherwise in infinite loop.
  componentDidUpdate(prevProps, prevState) {
    if (this.props.getTeamChannelId) {
      if (prevState.channelId !== this.props.getTeamChannelId.teamChannelId) {
        this.setState({
          channelId: this.props.getTeamChannelId.teamChannelId,
        });
      }
    }
  }

  // Grabing the input and changing the state of the text state.
  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  // Making setState as async
  setStateAsync = state => {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  };

  // When the user hits Enter, then scroll to the bottom of the message viewport to see the latest message.
  scrollToBottom = () => {
    const messageWrapper = document.getElementById('message-wrapper');

    if (messageWrapper) {
      // The first parameter is the width, and the second is the height. So we are scrolling to the bottom of the scrollHeight.
      const scrollHeight = messageWrapper.scrollHeight;
      messageWrapper.scrollTo(0, scrollHeight);
    }
  };

  // When the form submits --> execute the Mutations createTeamChannelId and then the createTeamMessage.
  // First check if a teamChannelId exists ---> if not, activate the createTeamChannelId
  // Then execute createTeamMessage Mutation resolver.
  onSubmit = async (event, createTeamChannelId, createTeamMessage) => {
    event.preventDefault();
    const { getTeamChannelId } = this.props;

    if (this.state.text.length > 0) {
      // If the channel Id does not exist ---> create a new channel for the user and opponent
      if (getTeamChannelId && getTeamChannelId.teamChannelId === null) {
        await createTeamChannelId().then(({ data }) => {
          this.setState({
            channelId: data.createTeamChannelId.teamChannelId,
          });
        });
      }

      await createTeamMessage();
      // Set text state back to default value.
      await this.setStateAsync({ text: '' });
      // Scroll to the bottom of the message screen for every new message.
      await this.scrollToBottom();
    }
  };

  render() {
    const { opponentTeamIdDecoded, currentTeamId } = this.props;
    const { channelId } = this.state;
    let { text } = this.state;
    const isInvalid = text === '';

    // Sanitize inputs
    text = sanitizeInput({ string: text });

    return (
      <Mutation
        mutation={CREATE_TEAM_CHANNEL}
        variables={{ opponentTeamId: opponentTeamIdDecoded, teamId: currentTeamId }}
      >
        {createTeamChannelId => (
          <Mutation
            mutation={CREATE_TEAM_MESSAGE}
            variables={{
              teamChannelId: channelId,
              text,
              opponentTeamId: opponentTeamIdDecoded,
              teamId: currentTeamId,
            }}
          >
            {createTeamMessage => (
              <Form onSubmit={event => this.onSubmit(event, createTeamChannelId, createTeamMessage)}>
                <FormInner>
                  <input
                    type="text"
                    name="text"
                    placeholder="Enter your message here..."
                    onChange={this.onChange}
                    value={text}
                  />
                  <Button doubles={true} type="submit" disabled={isInvalid}>
                    Send
                  </Button>
                </FormInner>
              </Form>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}

export default CreateDirectMessage;
