import * as styledComponents from 'styled-components';
import Color from 'color';

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<IThemeInterface>;

export interface IThemeInterface {
  menuBackActive: string;
  menuColorNormal: string;
  lightGray: string;
  text: string;
  blue: string;
  orange: string;
  green: string;
  breakLine: string;
  grayBack: string;
  extraLightGray: String;
  lightBlue: String;
  extraLightBlue: String;
  errorBack: String;
}

export const theme = {
  menuBackActive: '#4F4F4F',
  menuColorNormal: '#484848',
  lightGray: '#888888',
  boldgray: '#708790',
  text: '#484848',
  lightGreyText: '#797979',
  blue: '#366DA1',
  orange: '#FFA700',
  // green: '#569600',
  green: '#086342',
  purple: 'rebeccapurple',
  breakLine: '#DDDDDD',
  grayBack: '#FAFAFA',
  extraLightGray: '#D0D0D0',
  lightBlue: '#6cafc3',
  extraLightBlue: '#71b9c6',
  errorBack: 'crimson',
  red: '#FF2B2B',
  crimson: 'crimson',
  indianRed: 'indianred',
  men: {
    link: '#29596c',
    linkHover: Color('#29596c')
      .lighten(0.3)
      .hex(),
    linkDisabled: Color('#29596c')
      .lighten(0.4)
      .hex(),
  },
  women: {
    link: '#9E5663',
    linkHover: Color('#9E5663')
      .lighten(0.3)
      .hex(),
    linkDisabled: Color('#9E5663')
      .lighten(0.4)
      .hex(),
  },
  mixed: {
    link: '#366DA1',
    linkHover: Color('#366DA1')
      .lighten(0.3)
      .hex(),
    linkDisabled: Color('#366DA1')
      .lighten(0.4)
      .hex(),
  },
  doubles: {
    link: '#5F8368',
    linkHover: Color('#5F8368')
      .lighten(0.3)
      .hex(),
    linkDisabled: Color('#5F8368')
      .lighten(0.4)
      .hex(),
  },
  admin: {
    link: '#5F8368',
    linkHover: Color('#5F8368')
      .lighten(0.3)
      .hex(),
    linkDisabled: Color('#5F8368')
      .lighten(0.4)
      .hex(),
  },
};

export default styled;
export { css, createGlobalStyle, keyframes, ThemeProvider };
