import React from 'react';
import { ErrorWrapper, Li } from './style';

const ErrorMessage = ({ error }) => {
  var removedDefault = error.message.replace('GraphQL error: ', '');
  if (removedDefault.includes('SP')) {
    removedDefault = removedDefault.split('SP');
  }
  if (removedDefault.includes(',')) {
    removedDefault = removedDefault.split(',');
  }

  if (typeof removedDefault === 'string') {
    removedDefault = new Array(removedDefault);
  }
  return (
    <ErrorWrapper>
      {removedDefault.map((message, i) => {
        return <Li key={i}>{message}</Li>;
      })}
    </ErrorWrapper>
  );
};

export default ErrorMessage;
