import React, { Component, Fragment } from 'react';
import { Query, Mutation } from '@apollo/react-components';
import { Link } from 'react-router-dom';

import { sanitizeInput } from '../../../abstracts/helpers';

import { Flex, Button } from '../../Universal/style';
import ErrorMessage from '../../Error';
import { ALL_FREE_USERS_QUERY, FREE_ACCESS_MUTATION, REMOVE_ACCESS_MUTATION, ADMIN_ACCESS_MUTATION } from '../queries';
import * as routes from '../../../constants/routes';

class MemberAccess extends Component {
  constructor() {
    super();
    this.state = {
      giveEmail: '',
      removeEmail: '',
      adminEmail: '',
      pin: '',
    };
  }

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onSubmit = async (event, executeFunction) => {
    event.preventDefault();
    await executeFunction();
    this.setState({ giveEmail: '', removeEmail: '', adminEmail: '', pin: '' });
  };

  render() {
    let { giveEmail, removeEmail, adminEmail, pin } = this.state;

    // Sanitize inputs
    giveEmail = sanitizeInput({ string: giveEmail, type: 'email' });
    removeEmail = sanitizeInput({ string: removeEmail, type: 'email' });
    adminEmail = sanitizeInput({ string: adminEmail, type: 'email' });
    pin = sanitizeInput({ string: pin, type: 'int' });

    return (
      <Flex className="member-access-wrapper">
        <div>
          <div className="free-section">
            <p>Provide/remove free registration.</p>
            <Flex className="member-access-form-wrapper">
              <Mutation mutation={FREE_ACCESS_MUTATION} variables={{ email: giveEmail }}>
                {(giveFreeAccess, { error }) => (
                  <form>
                    <label>Provide Access</label>
                    <input
                      type="text"
                      name="giveEmail"
                      value={giveEmail}
                      onChange={this.onChange}
                      placeholder="Enter email of user"
                    />
                    <Button normal onClick={event => this.onSubmit(event, giveFreeAccess)}>
                      Provide access
                    </Button>
                    {error && <div className="error-message">Email doesn't exist.</div>}
                  </form>
                )}
              </Mutation>
              <Mutation mutation={REMOVE_ACCESS_MUTATION} variables={{ email: removeEmail }}>
                {(removeFreeAccess, { error }) => (
                  <form>
                    <label>Remove Access</label>
                    <input
                      type="text"
                      name="removeEmail"
                      value={removeEmail}
                      onChange={this.onChange}
                      placeholder="Enter email of user"
                    />
                    <Button normal onClick={event => this.onSubmit(event, removeFreeAccess)}>
                      Remove access
                    </Button>
                    {error && <div className="error-message">Email doesn't exist.</div>}
                  </form>
                )}
              </Mutation>
            </Flex>
          </div>
          <div className="admin-access-section">
            <p>Provide admin membership role.</p>
            <Flex className="member-access-form-wrapper">
              <Mutation mutation={ADMIN_ACCESS_MUTATION} variables={{ email: adminEmail, pin }}>
                {(giveAdminAccess, { error }) => (
                  <form className="admin-form-section">
                    <label>Provide Admin Access</label>
                    <Flex className="admin-access-input-wrapper">
                      <input
                        type="text"
                        name="adminEmail"
                        value={adminEmail}
                        onChange={this.onChange}
                        placeholder="Enter email of user"
                      />
                      <input type="text" name="pin" value={pin} onChange={this.onChange} placeholder="Input pin" />
                    </Flex>
                    <Button normal onClick={event => this.onSubmit(event, giveAdminAccess)}>
                      Provide access
                    </Button>
                    {error && <ErrorMessage error={error} />}
                  </form>
                )}
              </Mutation>
            </Flex>
          </div>
        </div>
        <div className="view-free-members">
          <strong style={{ marginBottom: '5px' }}>Current users with free access:</strong>
          <Query query={ALL_FREE_USERS_QUERY}>
            {({ data, loading }) => (
              <Fragment>
                {loading && <div>Loading...</div>}
                <strong>{data && data.allFreeUsers && data.allFreeUsers.length}</strong>

                <Link to={routes.FREE_ACCESS}>
                  <Button normal>View all users</Button>
                </Link>
              </Fragment>
            )}
          </Query>
        </div>
      </Flex>
    );
  }
}

export default MemberAccess;
