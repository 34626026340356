import { createGlobalStyle, theme } from './index';

export const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Audiowide');
    @import url('https://fonts.googleapis.com/css?family=Jockey+One');
    @import url('https://fonts.googleapis.com/css?family=Open+Sans');
    @import url('https://fonts.googleapis.com/css?family=Fira+Sans+Extra+Condensed');
    @import url('https://fonts.googleapis.com/css?family=Fjalla+One');
    @import url('https://fonts.googleapis.com/css?family=Quicksand:500&display=swap');
    @font-face {
        font-family: 'casanova';
        src: url("/static/fonts/casanova.otf");
    }
    @font-face {
        font-family: 'athletic';
        src: url("/static/fonts/athletic.ttf");
    }
    @font-face {
        font-family: 'roboto-condensed';
        src: url("/static/fonts/roboto-condensed-bold.ttf");
    }
    @font-face {
        font-family: 'roboto-condensed-regular';
        src: url("/static/fonts/roboto-condensed-regular.ttf");
    }
    @font-face {
        font-family: 'calibri';
        src: url("/static/fonts/calibri-regular.ttf");
    }
    @font-face {
        font-family: 'calibri-bold';
        src: url("/static/fonts/calibri-bold.ttf");
    }
    
    body {
        margin: 0;
    }
    h1, h2, a, button {
        font-family: 'casanova';
        text-transform: uppercase;
        color: ${theme.text};
        display: inline-block;
    }
    h3, h4, h5, h6 {
        font-family: 'athletic', sans-serif;
        text-transform: uppercase;
        color: ${theme.text};
        letter-spacing: 1px;
    }
    h3 {
        font-size: 20px; 
    }
    h4 {
        font-size: 17px;
    }
    p, label {
        /* font-family: 'roboto-condensed'; */
        font-family: 'calibri', 'open sans';
        font-size: 16px;
        color: ${theme.lightGreyText};
    }
    span {
        display: block;
    }
    a {
        text-decoration: none;
        font-size: 12px;
        font-weight: normal;
        color: ${theme.menuColorNormal};
        transition: all 0.2s ease-in-out;
    }
    a.outer-link {
        :hover {
            opacity: 0.7;
        }
    }

    button {
        transition: all 0.2s ease-in-out;
        
        &.outside-screens {
            background-color: ${theme.doubles.link};

            :hover {
                background-color: ${theme.doubles.linkHover};
            }
            :disabled {
                background-color: ${theme.doubles.linkDisabled};
            }
        }
    }

    #maintainance-wrapper { 
        width: 360px;
        max-width: 100%;
        text-align: center;
        margin: 9rem 2rem 3rem;
        padding: 3rem 5rem;
        background-color: rgba(0,0,0,0.7);
        border: 4px solid white;
        border-radius: 4px;
        z-index: 0;

        h1 {
            color: white;
            font-size: 1.6rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
            font-family: arial;
        }
        p {
            font-size: 1.4rem;
            text-transform: capitalize;
            color: white;
            margin-bottom: 0;
            margin-top: 5px;
        }

        @media (max-width: 450px) {
            margin: 4rem 0.6rem 3rem;
            padding: 3rem 1rem;
            width: auto;

            h1 {
                font-size: 1.4rem;
            }
        }
    }
`;
