import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import * as routes from '../../../constants/routes';
import { BackLink } from '../../Universal/style';
import { Wrapper } from './style';
import withAuthorization from '../../Session/withAuthorization';
import { GET_ALL_SUBLEAGUE } from './queries';
import CheckUsers from './checkUsers';
import CheckTeam from './checkTeam';
import FilterAvailability from './filteredAvailability';

class LeagueAvailability extends Component {
  render() {
    return (
      <Fragment>
        <BackLink>
          <Link to={routes.ADMIN}>
            <FontAwesomeIcon icon={faArrowCircleLeft} /> Back to Admin Page
          </Link>
        </BackLink>

        <Wrapper>
          {/* Getting data from the subleagues table and filtering the playTypes (singles / doubles) for easier readability */}
          <div className="section">
            <h2>Available Leagues</h2>
            <Query query={GET_ALL_SUBLEAGUE}>
              {({ data, loading }) => (
                <Fragment>
                  {loading ? (
                    <p>Loading...</p>
                  ) : data && data.getSubleagueInfo && data.getSubleagueInfo.length > 0 ? (
                    <FilterAvailability getSubleagueInfo={data.getSubleagueInfo} />
                  ) : (
                    <p>No leagues available</p>
                  )}
                </Fragment>
              )}
            </Query>
          </div>

          <div className="section">
            <h2>Non active leagues</h2>
            {/* For singles */}
            <div id="check-users">
              <CheckUsers />
            </div>

            {/* For doubles */}
            <div id="check-team">
              <CheckTeam />
            </div>
          </div>
        </Wrapper>
      </Fragment>
    );
  }
}

export default withAuthorization(session => session && session.me && session.me.role === 'ADMIN')(LeagueAvailability);
