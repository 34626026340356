import { Component } from 'react';
import { UPDATED_DOUBLES_LEADERBOARD_SUBSCRIPTION } from '../queries';

// Subscription to listen to updated "leaderboard" data
class DoublesUpdatedLeaderBoardChecker extends Component {
  subscribeToDoublesUpdatedLeaderboard = () => {
    this.props.subscribeToMore({
      document: UPDATED_DOUBLES_LEADERBOARD_SUBSCRIPTION,
      // Put variables inside the subscribeToMore function.
      variables: {
        playType: this.props.type,
        leagueId: this.props.leagueId,
        subLeagueId: this.props.subLeagueId,
      },
      updateQuery: (previousResult, { subscriptionData }) => {
        // If the subcriptionData does not exist - return just the current items in the database.
        if (!subscriptionData.data) {
          return previousResult;
        }
        return Object.assign({}, previousResult, {
          getDoublesLeaderboard: [...previousResult.getDoublesLeaderboard],
        });
      },
    });
  };

  componentDidMount() {
    this.subscribeToDoublesUpdatedLeaderboard();
  }

  render() {
    return this.props.children;
  }
}

export default DoublesUpdatedLeaderBoardChecker;
