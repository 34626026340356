import styled, { theme, keyframes } from '../../../theme';

const Shake = keyframes`
  0% {
    transform: rotate(0deg);
  }
  2% {
    transform: rotate(3deg);
  }
  4% {
    transform: rotate(-3deg);
  }
  6% {
    transform: rotate(3deg);
  }
  8% {
    transform: rotate(-3deg);
  }
  10% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(0deg);
  }
  27% {
    transform: rotate(3deg);
  }
  29% {
    transform: rotate(-3deg);
  }
  31% {
    transform: rotate(3deg);
  }
  33% {
    transform: rotate(-3deg);
  }
  35% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(0deg);
  }
  52% {
    transform: rotate(3deg);
  }
  54% {
    transform: rotate(-3deg);
  }
  56% {
    transform: rotate(3deg);
  }
  58% {
    transform: rotate(-3deg);
  }
  60% {
    transform: rotate(0deg);
  }
`;

export const WinnerNotification = styled.div`
  position: absolute;
  text-align: center;
  padding: 10px 35px 32px 35px;
  border-radius: 5px;
  box-shadow: 0px 1px 4px black;
  background: rgba(255, 255, 255, 0.9);
  border-top: 5px solid ${theme.lightBlue};
  width: 450px;
  max-width: 100%;
  top: 30%;
  left: 50%;
  margin-left: -255px;
  z-index: 3;
  animation: ${Shake} 10s linear infinite;

  p {
    margin: 0;
    font-size: 18px;
    text-transform: capitalize;
    color: ${theme.blue};
  }

  @media (max-width: 560px) {
    width: auto;
    left: 0;
    right: 0;
    margin-left: 7px;
    margin-right: 7px;
  }
`;
