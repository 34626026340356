import gql from 'graphql-tag';

// Query the team
export const QUERY_TEAM = gql`
  query {
    getTeam {
      id
      profileImage
      name
      tennisCourt
      playTime
      partnerEmail
      ownerId
      leagueId
    }
  }
`;
// Query the Team Play information
export const GET_TEAMPLAY_INFO = gql`
  query {
    getTeamPlayInfo {
      id
      season
      year
      playType
      leagueId
      subLeagueId
      teamId
    }
  }
`;
