import styled, { theme } from '../../../theme';

export const Wrapper = styled.div`
  h2 + p {
    margin-bottom: 30px;
  }
  .main-wrapper {
    .filter-wrapper {
      margin-bottom: 60px;

      form {
        display: flex;
        justify-content: space-between;

        .field {
          font-family: 'calibri', 'open sans';
          font-size: 16px;
          padding: 10px 13px;
          margin: 0 4px;
          border-radius: 4px;
          background-color: ${theme.breakLine};
          flex: 3;

          label {
            display: block;
            margin-bottom: 6px;
          }

          &.filter-last-season {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
          }
        }
        button {
          margin-top: 0;
        }

        @media (max-width: 850px) {
          flex-direction: column;

          .field {
            flex: auto;
            margin: 2px 0px;

            &.filter-last-season {
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .query-wrapper {
      display: grid;

      #display-main {
        overflow-x: scroll;

        .csv-download a {
          color: white;
          background-color: maroon;
          border: 1px solid maroon;
          border-radius: 4px;
          padding: 10px 15px;
          margin-bottom: 35px;
          outline: none;

          &:hover,
          &:focus {
            color: maroon;
            background-color: transparent;
          }
        }
        .no-result {
          padding: 10px 30px;
          background-color: gray;
          border-radius: 4px;
          text-align: center;
          color: white;
        }
        .table {
          display: table;
          width: 100%;
          text-align: center;

          .heading {
            background-color: #c6c6c6;

            p {
              color: black;
              font-weight: 800;
              margin: 5px 0;
            }
          }
          .table-row {
            display: table-row;

            .table-cell {
              display: table-cell;
              vertical-align: middle;
              padding: 0 5px;
            }
            :nth-child(2) .table-cell p {
              margin: 10px 0 4px 0;
            }
            :nth-child(n + 3) .table-cell p {
              margin: 4px 0;
            }
            :nth-child(n + 3):nth-child(odd) {
              background-color: ${theme.breakLine};
            }
          }
        }
      }
    }
  }
`;
