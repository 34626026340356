import React, { Fragment } from 'react';
import { Query } from '@apollo/react-components';
import Hashids from 'hashids';

import FixtureTable from './fixtureTable';
import FinalFixture from './finalFixture';
import { Table } from '../style';
import { H3, P } from '../../../Universal/style';
import { GET_OTHER_TEAM, GET_FINAL_DOUBLES_FIXTURE } from '../queries';

const hashids = new Hashids('fleximazing', 10);

// The reason I've created this component is because I needed to sort the data.generateFixture array.
// We can only declare variables before a return statement.
// Sorted by week.
// session and teamOwnerId are used to allow owners of teams to access the upload link only
const DisplayFixture = ({
  getAllFixture,
  currentSeasonDate,
  currentSeason,
  type,
  year,
  getTeam,
  currentTeamId,
  session,
  teamOwnerId,
  currentWeek,
}) => {
  // Declare weeks
  const weeks = [1, 2, 3, 4, 5, 6, 7, 8];
  let sortedData = getAllFixture.slice().sort((a, b) => {
    return a.week - b.week;
  });

  // Determining all week dates
  const week1Date = new Date(currentSeasonDate);
  const week2Date = new Date(new Date(currentSeasonDate).setDate(new Date(currentSeasonDate).getDate() + 7));
  const week3Date = new Date(new Date(currentSeasonDate).setDate(new Date(currentSeasonDate).getDate() + 14));
  const week4Date = new Date(new Date(currentSeasonDate).setDate(new Date(currentSeasonDate).getDate() + 21));
  const week5Date = new Date(new Date(currentSeasonDate).setDate(new Date(currentSeasonDate).getDate() + 28));
  const week6Date = new Date(new Date(currentSeasonDate).setDate(new Date(currentSeasonDate).getDate() + 35));
  const week7Date = new Date(new Date(currentSeasonDate).setDate(new Date(currentSeasonDate).getDate() + 42));
  const week8Date = new Date(new Date(currentSeasonDate).setDate(new Date(currentSeasonDate).getDate() + 49));
  const week9Date = new Date(new Date(currentSeasonDate).setDate(new Date(currentSeasonDate).getDate() + 56));
  const week10Date = new Date(new Date(currentSeasonDate).setDate(new Date(currentSeasonDate).getDate() + 63));

  // Determine if week exist in array
  const week1Exist = sortedData.some(v => +v.week === 1);
  const week2Exist = sortedData.some(v => +v.week === 2);
  const week3Exist = sortedData.some(v => +v.week === 3);
  const week4Exist = sortedData.some(v => +v.week === 4);
  const week5Exist = sortedData.some(v => +v.week === 5);
  const week6Exist = sortedData.some(v => +v.week === 6);
  const week7Exist = sortedData.some(v => +v.week === 7);
  const week8Exist = sortedData.some(v => +v.week === 8);

  // Function to return weekly data --> if existant
  const filterWeek = (fetchweek, sortedData, currentTeamId, session, teamOwnerId) => {
    const theWeek = sortedData.filter(v => +v.week === fetchweek);
    const score = JSON.parse(theWeek[0].score);
    const theDate = new Date(theWeek[0].startDate);
    const otherTeam = hashids.encode(theWeek[0].opponentTeamId);
    const uploadEncrypt = hashids.encode(theWeek[0].id);
    const uploadDecodedId = theWeek[0].id;
    const profileLink = `/doubles/fixtures/profile/${otherTeam}`;
    const uploadLink = `/doubles/fixtures/upload/${uploadEncrypt}`;
    const { week, opponentTeamId } = theWeek[0];

    return (
      <Weekly
        week={week}
        score={score}
        theDate={theDate}
        profileLink={profileLink}
        uploadLink={uploadLink}
        opponentTeamId={opponentTeamId}
        uploadDecodedId={uploadDecodedId}
        currentTeamId={currentTeamId}
        session={session}
        teamOwnerId={teamOwnerId}
      />
    );
  };

  return (
    <Table>
      <div className="table-row">
        <div className="table-cell">
          <H3 MT={'0'} MB={'0'}>
            Week
          </H3>
        </div>
        <div className="table-cell">
          <H3 MT={'0'} MB={'0'}>
            Date
          </H3>
          <P MT={'5px'} lowercase bold>
            (Week Starting)
          </P>
        </div>
        <div className="table-cell">
          <H3 MT={'0'} MB={'0'}>
            Team
          </H3>
        </div>
        <div className="table-cell">
          <H3 MT={'0'} MB={'0'}>
            Scores
          </H3>
          <P MT={'5px'} lowercase bold>
            ({getTeam.name} vs Opponent)
          </P>
        </div>
        <div className="table-cell"></div>
        <div className="table-cell"></div>
      </div>

      {/* Loop through the first 8 weeks of play */}
      {weeks.map((currentWeek, i) => {
        if (currentWeek === 1) {
          if (week1Exist) {
            // If the week exist in the "sortedData" array then execute function to search for it and return data and "fixtureTable"
            return <Fragment key={i}>{filterWeek(1, sortedData, currentTeamId, session, teamOwnerId)}</Fragment>;
          } else {
            return <FixtureTable key={i} theDate={week1Date} week={'1'} bye={true} />;
          }
        } else if (currentWeek === 2) {
          if (week2Exist) {
            return <Fragment key={i}>{filterWeek(2, sortedData, currentTeamId, session, teamOwnerId)}</Fragment>;
          } else {
            return <FixtureTable key={i} theDate={week2Date} week={'2'} bye={true} />;
          }
        } else if (currentWeek === 3) {
          if (week3Exist) {
            return <Fragment key={i}>{filterWeek(3, sortedData, currentTeamId, session, teamOwnerId)}</Fragment>;
          } else {
            return <FixtureTable key={i} theDate={week3Date} week={'3'} bye={true} />;
          }
        } else if (currentWeek === 4) {
          if (week4Exist) {
            return <Fragment key={i}>{filterWeek(4, sortedData, currentTeamId, session, teamOwnerId)}</Fragment>;
          } else {
            return <FixtureTable key={i} theDate={week4Date} week={'4'} bye={true} />;
          }
        } else if (currentWeek === 5) {
          if (week5Exist) {
            return <Fragment key={i}>{filterWeek(5, sortedData, currentTeamId, session, teamOwnerId)}</Fragment>;
          } else {
            return <FixtureTable key={i} theDate={week5Date} week={'5'} bye={true} />;
          }
        } else if (currentWeek === 6) {
          if (week6Exist) {
            return <Fragment key={i}>{filterWeek(6, sortedData, currentTeamId, session, teamOwnerId)}</Fragment>;
          } else {
            return <FixtureTable key={i} theDate={week6Date} week={'6'} bye={true} />;
          }
        } else if (currentWeek === 7) {
          if (week7Exist) {
            return <Fragment key={i}>{filterWeek(7, sortedData, currentTeamId, session, teamOwnerId)}</Fragment>;
          } else {
            return <FixtureTable key={i} theDate={week7Date} week={'7'} bye={true} />;
          }
        } else if (currentWeek === 8) {
          if (week8Exist) {
            return <Fragment key={i}>{filterWeek(8, sortedData, currentTeamId, session, teamOwnerId)}</Fragment>;
          } else {
            return <FixtureTable key={i} theDate={week8Date} week={'8'} bye={true} />;
          }
        }
        return '';
      })}
      {/* Displaying week 9 */}
      {/* Only need to pass down the "date". Since final fixture doesn't have data, then we need to pass "date" down. */}
      <FixtureTable theDate={week9Date} blank={true} />
      {/* Displaying week 10 --> first query the week 10 fixture / final fixture if it exists */}
      <Query query={GET_FINAL_DOUBLES_FIXTURE} variables={{ season: currentSeason, playType: type, week: '10', year }}>
        {({ data, loading, subscribeToMore }) => {
          if (loading) {
            return '';
          }
          if (data && data.getFinalDoublesFixture) {
            const { getFinalDoublesFixture } = data;
            return (
              <FinalFixture
                getFinalFixture={getFinalDoublesFixture}
                week10Date={week10Date}
                subscribeToMore={subscribeToMore}
                teamId={getTeam.id}
                currentTeamId={currentTeamId}
                session={session}
                teamOwnerId={teamOwnerId}
                currentWeek={currentWeek}
              />
            );
          }
          return '';
        }}
      </Query>
    </Table>
  );
};

// Query weekly data with opponent information
const Weekly = ({
  week,
  score,
  theDate,
  profileLink,
  uploadLink,
  opponentTeamId,
  uploadDecodedId,
  currentTeamId,
  session,
  teamOwnerId,
}) => (
  <Fragment>
    <Query query={GET_OTHER_TEAM} variables={{ id: opponentTeamId }}>
      {({ data }) => (
        <FixtureTable
          data={data}
          week={week}
          theDate={theDate}
          score={score}
          profileLink={profileLink}
          uploadLink={uploadLink}
          uploadDecodedId={uploadDecodedId}
          currentTeamId={currentTeamId}
          normal={true}
          session={session}
          teamOwnerId={teamOwnerId}
        />
      )}
    </Query>
  </Fragment>
);

export default DisplayFixture;
