import React, { Component, Fragment } from 'react';
import { Query } from '@apollo/react-components';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import * as routes from '../../../constants/routes';

import { Flex } from '../../Universal/style';
import { NoResults } from '../../Error/style';
import MessageScreenTeam from './messageScreenTeam';
import { DOUBLES_FIXTURE_QUERY } from '../fixtures/queries';
import SideBar from './sidebar';
import MessageScreen from '../../Singles/message/messageScreen';
import TooltipSection from '../../Account/tooltip';

// Message to show when no season fixture has been detected.
const NoFixtureInfo = ({ session, teamOwnerId, getSeasonStatus }) => {
  return (
    <NoResults>
      <div className="no-results-inner">
        <FontAwesomeIcon icon={faInfoCircle} />
        {getSeasonStatus.doublesMatchGenerator ? (
          <Fragment>
            <p>There weren't enough teams in your league for the current season.</p>
            <p style={{ marginTop: '6px' }}>A minimum of 4 teams is needed.</p>
          </Fragment>
        ) : (
          <Fragment>
            <p>Fixtures have not been created.</p>
            <p style={{ marginTop: '6px' }}>
              {session && session.me && session.me.id === teamOwnerId
                ? 'You will be able to message your partner and other teams after the fixture has been generated.'
                : 'You will be able to message your partner after the fixture has been generated.'}
            </p>
          </Fragment>
        )}
      </div>
    </NoResults>
  );
};

class DoublesMessage extends Component {
  constructor() {
    super();
    this.state = {
      tooltip: false,
    };
  }

  handleTooltip = () => {
    this.setState(prev => ({ tooltip: !prev.tooltip }));
  };

  render() {
    const {
      session,
      season,
      year,
      type,
      currentTeamId,
      currentTeamName,
      teamOwnerId,
      partnerData,
      getSeasonStatus,
    } = this.props;
    const { tooltip } = this.state;
    const tooltipContent = {
      html: <p>Only team leaders can message other teams.</p>,
    };

    return (
      <Fragment>
        {/* Heading with Tooltip section */}
        <TooltipSection
          heading={'Messages'}
          type={'doubles'}
          handleTooltip={this.handleTooltip}
          tooltip={tooltip}
          tooltipContent={tooltipContent}
          tooltipClass={'tooltip-with-heading'}
        />

        {/* Query the season fixture for the user */}
        <Query query={DOUBLES_FIXTURE_QUERY} variables={{ season, playType: type, year }}>
          {({ data, loading }) => {
            if (loading) {
              return <h3>Loading...</h3>;
            }

            // If the fixture for the season exists --> then proceed with finding the opponent team info etc..
            if (data && data.getAllDoublesFixture && data.getAllDoublesFixture.length > 0) {
              const { getAllDoublesFixture } = data;

              return (
                <Flex noWrap messageWrapper className="message-section-wrapper">
                  {/* Display the side bar with all opponents */}
                  <SideBar
                    getAllDoublesFixture={getAllDoublesFixture}
                    currentTeamId={currentTeamId}
                    teamOwnerId={teamOwnerId}
                    session={session}
                    partnerData={partnerData}
                  />
                  {/* The below are "routes" to the message screen --> from chatLinks created in the SideBar section */}
                  <Switch>
                    <Route
                      exact
                      path={routes.DOUBLES_MESSAGE}
                      render={() => <DefaultScreen teamOwnerId={teamOwnerId} session={session} />}
                    />
                    <Route
                      path={routes.DOUBLES_MESSAGE_TEAM}
                      render={() => (
                        <MessageScreenTeam
                          session={session}
                          getAllDoublesFixture={getAllDoublesFixture}
                          currentTeamId={currentTeamId}
                          currentTeamName={currentTeamName}
                          teamOwnerId={teamOwnerId}
                        />
                      )}
                    />
                    <Route
                      path={routes.DOUBLES_MESSAGE_USER}
                      render={() => <MessageScreen doubles={true} session={session} partnerData={partnerData} />}
                    />
                    <Redirect to={routes.DOUBLES_MESSAGE} />
                  </Switch>
                </Flex>
              );
            } else {
              // Display notification that no Fixture info exists yet
              return (
                <Fragment>
                  {getSeasonStatus.length > 0 && (
                    <NoFixtureInfo session={session} teamOwnerId={teamOwnerId} getSeasonStatus={getSeasonStatus[0]} />
                  )}
                </Fragment>
              );
            }
          }}
        </Query>
      </Fragment>
    );
  }
}

// Displays the default message screen (before any of the sidebar team are clicked)
const DefaultScreen = ({ teamOwnerId, session }) => (
  <Fragment>
    {teamOwnerId !== session.me.id ? (
      <p>Message your partner by clicking on their name located on the left.</p>
    ) : (
      <p>Message a team by clicking on their name located on the left.</p>
    )}
  </Fragment>
);

// Redirect screen to land on prior to the actual message screen with another player -> this is to get the "MessageScreen" component to "remount".
const RedirectScreenTeam = props => {
  const { team } = props.match.params;
  return <Redirect to={`/doubles/message/team/${team}`} />;
};
const RedirectScreenTeamWithRouter = withRouter(RedirectScreenTeam);

// Redirect screen to land on prior to the actual message screen with another player -> this is to get the "MessageScreen" component to "remount".
const RedirectScreenPartner = props => {
  const { user } = props.match.params;
  return <Redirect to={`/doubles/message/partner/${user}`} />;
};
const RedirectScreenPartnerWithRouter = withRouter(RedirectScreenPartner);

export default DoublesMessage;
export { RedirectScreenTeamWithRouter, RedirectScreenPartnerWithRouter };
