import styled, { theme } from '../../theme';
import backImage from '../../resources/images/message-back-bw.jpg';

export const ErrorWrapper = styled.ul`
  border-top: 1px solid ${theme.breakLine};
  padding-top: 20px;
  margin-top: 0;

  li:last-child {
    margin-bottom: 0;
  }
  a {
    display: inline-block;
    text-transform: capitalize;
    font-family: 'open sans', sans-serif;
    font-size: 14px;
    color: black;
    text-decoration: underline;
    cursor: pointer;
    :hover {
      opacity: 0.7;
    }
  }
`;
export const Li = styled.li`
  color: ${theme.errorBack};
  margin: 0 auto;
  font-family: 'open sans', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
`;

// No results
export const NoResults = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 7px;
  border-radius: 4px;
  background-image: url(${backImage});
  background-size: cover;
  background-position: center;
  color: white;
  z-index: 1;

  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: 93%;
    /* background-color: rgba(255, 165, 0, 0.55);  orange */
    background-color: rgba(192, 192, 192, 0.5);
    z-index: 0;
  }
  .no-results-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;

    svg {
      font-size: 32px;
      color: white;
    }
    p {
      text-transform: capitalize;
      margin-bottom: 0;
      color: white;
      font-weight: 800;
      text-align: center;
    }
  }
`;
