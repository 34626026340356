import React from 'react';
import ReactDOM from 'react-dom';
import { withApollo, ApolloProvider } from '@apollo/react-hoc';
import { ApolloClient, HttpLink, ApolloLink, split } from '@apollo/client';
import { getMainDefinition } from 'apollo-utilities';
import { WebSocketLink } from '@apollo/link-ws';
import { onError } from '@apollo/link-error';
import { InMemoryCache } from '@apollo/client/cache';

import App from './components/App';
import { signOut } from './components/SignOut';

/************** HttpLink **************/
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_LINK,
});

/************** wsLink **************/
const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPHQL_WSS_LINK,
  options: {
    reconnect: true,
    lazy: true,
  },
});

wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
  wsLink.subscriptionClient.maxConnectTimeGenerator.max;

/************** terminatingLink **************/
const terminatingLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

/************** authLink **************/
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem('token');

    if (token) {
      headers = { ...headers, 'x-token': token };
    }

    return { headers };
  });

  return forward(operation);
});

/************** errorLink **************/
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      // console.log('GraphQL error', message);

      if (
        extensions &&
        extensions.code === 'UNAUTHENTICATED' &&
        message === 'Context creation failed: Your session expired. Sign in again.'
      ) {
        signOut(client);
      }
    });
  }

  if (networkError) {
    // console.log('Network error', networkError);

    if (networkError.statusCode >= 401) {
      signOut(client);
    }
  }
});

/************** link **************/
const link = ApolloLink.from([authLink, errorLink, terminatingLink]);

const cache = new InMemoryCache();

const client = new ApolloClient({
  link,
  cache,
});

/************** app with client **************/
const AppWithClient = withApollo(App);

ReactDOM.render(
  <ApolloProvider client={client}>
    <AppWithClient />
  </ApolloProvider>,
  document.getElementById('root')
);
