import gql from 'graphql-tag';

// Fetch all fixtures for admin user
export const DOUBLES_GET_COMPLETE_FIXTURES = gql`
  query {
    doublesAdminGetAllFixture {
      score
      teamWinnerId
    }
  }
`;

// Fetch week 10 fixtures
export const DOUBLES_FINALS_FIXTURE_QUERY = gql`
  query {
    doublesAdminGetWeek10Fixture {
      id
      score
      teamWinnerId
    }
  }
`;
