import React, { Component, Fragment } from 'react';
import { Mutation } from '@apollo/react-components';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';

import { sanitizeInput } from '../../abstracts/helpers';

import Loading from '../Loading';
import { Button, Form } from '../Universal/style';
import { Input, Notification } from './style';
import ErrorMessage from '../Error';
import { ErrorWrapper, Li } from '../Error/style';
import * as routes from '../../constants/routes';

const SIGN_UP = gql`
  mutation($firstname: String!, $lastname: String!, $email: String!, $password: String!, $token: String) {
    signUp(firstname: $firstname, lastname: $lastname, email: $email, password: $password, token: $token) {
      id
      email
    }
  }
`;
export const RESEND_CONFIRMATION_MUTATION = gql`
  mutation($userId: ID) {
    resendConfirmation(userId: $userId)
  }
`;

const INITIAL_STATE = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  success: false,
  sentConfirmation: false,

  termsPrivacyChecked: false,
  termsError: false,
};

class SignUpForm extends Component {
  constructor() {
    super();
    this.state = { ...INITIAL_STATE };
  }

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  sentConfirmation = async resendConfirmation => {
    await resendConfirmation().then(() => {
      this.setState({ sentConfirmation: true });
    });
  };

  handleTermsCheck = () => {
    this.setState(prev => {
      let newtermsError = false;
      if (prev.termsPrivacyChecked) {
        newtermsError = true;
      } else {
        newtermsError = false;
      }

      return {
        termsError: newtermsError,
        termsPrivacyChecked: !prev.termsPrivacyChecked,
      };
    });
  };

  onSubmit = (event, signUp) => {
    event.preventDefault();
    const { termsPrivacyChecked } = this.state;

    if (termsPrivacyChecked) {
      signUp().then(async ({ data }) => {
        if (data && data.signUp) {
          this.setState({ success: false });
        } else {
          this.setState({ ...INITIAL_STATE });
          this.setState({ success: true });
        }
      });
    } else {
      this.setState({ termsError: true });
    }
  };

  render() {
    const { fromInvitation, match } = this.props;
    const { password, passwordConfirmation, success, sentConfirmation, termsPrivacyChecked, termsError } = this.state;
    let { firstname, lastname, email } = this.state;

    const isInvalid =
      password !== passwordConfirmation || password === '' || email === '' || firstname === '' || lastname === '';

    // Password validation
    let passwordError = false;
    if (password !== '' && passwordConfirmation !== '') {
      if (password !== passwordConfirmation) {
        passwordError = true;
      }
      if (password.length < 7) {
        passwordError = true;
      }
    }
    const combinedError = passwordError || termsError;

    // If accessing Sign up page via invitation link
    let token = '';
    if (fromInvitation) {
      token = match.params.token;
    }

    // Format string to be capitalized
    // Sanitize inputs
    email = sanitizeInput({ string: email, type: 'email' }).toLowerCase();
    firstname = sanitizeInput({ string: firstname }).toLowerCase();
    firstname = firstname.charAt(0).toUpperCase() + firstname.slice(1);
    lastname = sanitizeInput({ string: lastname }).toLowerCase();
    lastname = lastname.charAt(0).toUpperCase() + lastname.slice(1);

    return (
      <Mutation mutation={SIGN_UP} variables={{ firstname, lastname, email, password, token }}>
        {(signUp, { data, loading, error }) => {
          const invitationErrors =
            (error && error.message === 'GraphQL error: partnerNotSame') ||
            (error && error.message === 'GraphQL error: invitationUsed');

          if (success) {
            return (
              <Notification>
                <li className="green">
                  A validation link has been sent to your email. Please click on it to verify. Please also check your
                  junk folder.
                </li>
              </Notification>
            );
          } else {
            return (
              <Fragment>
                <Form onSubmit={event => this.onSubmit(event, signUp)}>
                  {/* For the firstname field */}
                  <Input
                    name="firstname"
                    value={firstname}
                    onChange={this.onChange}
                    type="text"
                    placeholder="First Name"
                  />

                  {/* For the lastname field */}
                  <Input
                    name="lastname"
                    value={lastname}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Last Name"
                  />

                  {/* For the email field */}
                  <Input name="email" value={email} onChange={this.onChange} type="text" placeholder="Email Address" />

                  {/* For the password field */}
                  <Input
                    name="password"
                    value={password}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Password"
                  />

                  {/* For the confirm password field */}
                  <Input
                    name="passwordConfirmation"
                    value={passwordConfirmation}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Confirm Password"
                  />

                  <div className="terms-checkbox">
                    <label>
                      <input type="checkbox" name="terms-privacy" onClick={() => this.handleTermsCheck()} />
                      <div className="tick">✓</div>
                    </label>
                    <p>
                      I agree to the{' '}
                      <a href="https://flexitennisleagues.com.au/terms" target="_blank" rel="noopener noreferrer">
                        Terms & Condition
                      </a>{' '}
                      and{' '}
                      <a href="https://flexitennisleagues.com.au/privacy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                  <Button
                    className="outside-screens"
                    disabled={!termsPrivacyChecked || isInvalid || loading}
                    type="submit"
                  >
                    {loading ? <Loading /> : 'Sign Up'}
                  </Button>

                  {error && (
                    <Fragment>
                      {invitationErrors ? (
                        // This is for the invitation errors
                        <ErrorWrapper>
                          {error.message === 'GraphQL error: partnerNotSame' && (
                            <Fragment>
                              <Li>
                                Either the invitation link is no longer valid or you have not entered the invitee email
                                address. Please consult with your partner.
                              </Li>
                              <Li>
                                To register normally, please register from the <Link to={routes.SIGN_UP}>SignUp</Link>{' '}
                                page.
                              </Li>
                            </Fragment>
                          )}
                          {error.message === 'GraphQL error: invitationUsed' && (
                            <Li>
                              The invitation link is no longer valid. To register normally, please register from the{' '}
                              <Link to={routes.SIGN_UP}>SignUp</Link> page.
                            </Li>
                          )}
                        </ErrorWrapper>
                      ) : (
                        // This is for the normal server errors
                        <ErrorMessage error={error} />
                      )}
                    </Fragment>
                  )}

                  {combinedError && (
                    // These are for the frontend validation -> password and terms errors
                    <ErrorWrapper>
                      {passwordError && (
                        <Fragment>
                          {password.length < 7 && <Li>Password must be at least 7 characters long</Li>}
                          {password.length >= 7 && password !== '' && passwordConfirmation !== '' && (
                            <Li>Password must be the same</Li>
                          )}
                        </Fragment>
                      )}
                      {termsError && <Li>You must accept the Terms of Condition and Privacy Policy to signup</Li>}
                    </ErrorWrapper>
                  )}
                </Form>

                {data && data.signUp && (
                  // Mutation resolver for re-sending confirmation email if user hasn't recieved it.
                  <Mutation mutation={RESEND_CONFIRMATION_MUTATION} variables={{ userId: data.signUp.id }}>
                    {resendConfirmation => (
                      <Notification>
                        <li>
                          An email has already been sent to {data.signUp.email}.{' '}
                          {sentConfirmation ? (
                            <div className="send-confirmation-button no-hover">Confirmation has been sent!</div>
                          ) : (
                            <div
                              className="send-confirmation-button"
                              onClick={() => this.sentConfirmation(resendConfirmation)}
                            >
                              Click here to resend.
                            </div>
                          )}
                        </li>
                        <li>Please click on the link to verify</li>
                      </Notification>
                    )}
                  </Mutation>
                )}
              </Fragment>
            );
          }
        }}
      </Mutation>
    );
  }
}

export default SignUpForm;
