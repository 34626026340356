import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';

import ErrorMessage from '../Error';
import Loading from '../Loading';

import { Button, Form } from '../Universal/style';
import { Input } from '../SignIn/style';
import { Notification } from '../SignUp/style';

const RESET_PASSWORD = gql`
  mutation($password: String!, $token: String!) {
    resetPassword(thePassword: $password, token: $token) {
      result
    }
  }
`;

const INITIAL_STATE = {
  password: '',
  confirmPassword: '',
  sent: false,
};

class ResetForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onSubmit = (event, resetPassword) => {
    event.preventDefault();
    resetPassword().then(async ({ data }) => {
      this.setState({ ...INITIAL_STATE });
      this.setState({ sent: true });

      await this.props.refetch();
    });
  };

  render() {
    const { token } = this.props.match.params;
    const { password, confirmPassword, sent } = this.state;
    const isInvalid = password === '' || confirmPassword === '' || password !== confirmPassword;

    return (
      <Mutation mutation={RESET_PASSWORD} variables={{ password, token }}>
        {(resetPassword, { loading, error }) => (
          <div>
            {sent ? (
              <Notification>
                <li className="green">Password was successfully updated.</li>
              </Notification>
            ) : (
              <Form onSubmit={event => this.onSubmit(event, resetPassword)}>
                <Input
                  name="password"
                  value={password}
                  onChange={this.onChange}
                  type="password"
                  placeholder="New password"
                />
                <Input
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={this.onChange}
                  type="password"
                  placeholder="Confirm New password"
                />
                <Button className="outside-screens" disabled={isInvalid || loading} type="submit">
                  {loading ? <Loading /> : 'Reset Password'}
                </Button>

                {error && <ErrorMessage error={error} />}
              </Form>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default ResetForm;
