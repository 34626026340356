import React, { Component, Fragment } from 'react';
import { Query, Mutation } from '@apollo/react-components';

import { master } from '../../../resources/resources';
import { GET_ALL_SUBLEAGUE, CHECK_TEAMS_LEAGUE, GET_LEAGUE, GET_OWNER_FROM_TEAM, UPDATE_USER_LEAGUE } from './queries';
import { GET_OTHER_TEAM } from '../../Doubles/fixtures/queries';
import { Button } from '../../Universal/style';

class CheckTeam extends Component {
  constructor() {
    super();
    this.state = {
      formTeamId: 0,
      location: 'select-option',
      league: 'select-option',
      skill: 'select-option',
      subLeagueId: 0,
    };
  }

  prefillFormValues = object => {
    const { id, teamId } = object;
    this.setState({ formTeamId: teamId });
    const theRowClicked = document.getElementsByClassName(`table-row-team-${id}`)[0];
    // Getting all rows so we can remove the active class
    const normalRow = document.getElementsByClassName('table-row-team');
    if (normalRow && normalRow.length > 0) {
      for (let i = 0; i < normalRow.length; i++) {
        normalRow[i].classList.remove('active');
      }
    }
    // Adding active class to the one clicked
    theRowClicked.classList.add('active');
  };

  onChange = event => {
    const { name, value } = event.target;

    if (name === 'location') {
      this.setState({ league: 'select-option' });
    }
    this.setState({ [name]: value });
  };

  submitUpdate = async (event, updateUserPlay) => {
    event.preventDefault();
    await updateUserPlay().then(() => {
      this.setState({
        formTeamId: 0,
        location: 'select-option',
        league: 'select-option',
        skill: 'select-option',
        subLeagueId: 0,
      });
    });
  };

  render() {
    const { formTeamId, location, league, skill, subLeagueId } = this.state;
    const locationsArray = master.locations;
    const regionArray = master.regions;
    const skillsArray = master.skills;

    const showRegion = location !== 'select-option';
    const filteredRegion = regionArray.filter(region => region.location === location);

    const isInvalid =
      formTeamId === 0 ||
      location === 'select-option' ||
      league === 'select-option' ||
      skill === 'select-option' ||
      subLeagueId === 0;

    return (
      <Fragment>
        <h3>Doubles players</h3>
        <Query query={CHECK_TEAMS_LEAGUE}>
          {({ data, loading }) => (
            <Fragment>
              {loading ? (
                <p>Loading...</p>
              ) : data && data.checkUsersLeagueDoubles && data.checkUsersLeagueDoubles.length > 0 ? (
                <div className="grid">
                  <div className="overflow">
                    <div className="table">
                      <div className="table-row">
                        <div className="table-cell">
                          <p>Location</p>
                        </div>
                        <div className="table-cell">
                          <p>Skill</p>
                        </div>
                        <div className="table-cell">
                          <p>Team Name</p>
                        </div>
                        <div className="table-cell">
                          <p>Player</p>
                        </div>
                        <div className="table-cell">
                          <p>Owner Email</p>
                        </div>
                      </div>
                      {data.checkUsersLeagueDoubles.map(object => {
                        const { id, leagueId, teamId } = object;
                        return (
                          <div
                            key={id}
                            className={`table-row table-row-team table-row-team-${id}`}
                            onClick={() => this.prefillFormValues(object)}
                          >
                            <Query query={GET_LEAGUE} variables={{ id: leagueId }}>
                              {({ data, loading }) => (
                                <Fragment>
                                  {loading && <p>Loading...</p>}
                                  {data && data.getLeague && (
                                    <Fragment>
                                      <div className="table-cell">
                                        <p>{data.getLeague.location}</p>
                                      </div>
                                      <div className="table-cell">
                                        <p>{data.getLeague.skill}</p>
                                      </div>
                                    </Fragment>
                                  )}
                                </Fragment>
                              )}
                            </Query>
                            <Query query={GET_OTHER_TEAM} variables={{ id: teamId }}>
                              {({ data, loading }) => (
                                <Fragment>
                                  {loading && <p>Loading...</p>}
                                  {data && data.getOtherTeam && (
                                    <div className="table-cell">
                                      <p>{data.getOtherTeam.name}</p>
                                    </div>
                                  )}
                                </Fragment>
                              )}
                            </Query>
                            <Query query={GET_OWNER_FROM_TEAM} variables={{ id: teamId }}>
                              {({ data, loading }) => (
                                <Fragment>
                                  {loading && <p>Loading...</p>}
                                  {data && data.getOwnerFromTeam && (
                                    <Fragment>
                                      <div className="table-cell">
                                        <p>
                                          {data.getOwnerFromTeam.firstname} {data.getOwnerFromTeam.lastname}
                                        </p>
                                      </div>
                                      <div className="table-cell email-cell">
                                        <p>{data.getOwnerFromTeam.email}</p>
                                      </div>
                                    </Fragment>
                                  )}
                                </Fragment>
                              )}
                            </Query>
                          </div>
                        );
                      })}
                    </div>
                    <Mutation
                      mutation={UPDATE_USER_LEAGUE}
                      variables={{
                        playType: 'doubles',
                        location: league,
                        skill,
                        subLeagueId: +subLeagueId,
                        teamId: formTeamId,
                      }}
                      refetchQueries={() => [{ query: CHECK_TEAMS_LEAGUE }, { query: GET_ALL_SUBLEAGUE }]}
                    >
                      {(updateUserPlay, { error }) => (
                        <div className="update-form">
                          <div className="flex">
                            <h3>
                              Update team section <span>(for all player(s))</span>
                            </h3>
                            <Query query={GET_OTHER_TEAM} variables={{ id: formTeamId }}>
                              {({ data, loading }) => (
                                <Fragment>
                                  {loading && <p>Loading...</p>}
                                  {data && data.getOtherTeam && (
                                    <h4>
                                      <span>Team Name:</span> {data.getOtherTeam.name}
                                    </h4>
                                  )}
                                </Fragment>
                              )}
                            </Query>
                          </div>

                          <form onSubmit={event => this.submitUpdate(event, updateUserPlay)}>
                            <div className="form-content">
                              {/* For the location field selection */}
                              <div className="field">
                                <h4>Location</h4>
                                <select name="location" value={location} onChange={this.onChange}>
                                  <option disabled value="select-option">
                                    Select Option
                                  </option>
                                  {locationsArray.map(aLocation => {
                                    const joined = aLocation.replace(/\s+/g, '-').toLowerCase();
                                    return (
                                      <option key={joined} value={aLocation}>
                                        {aLocation}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>

                              {/* For the region selection -> only activate/show if a "location" field is chosen. */}
                              {/* For the location fields */}
                              {showRegion && (
                                <div className="field">
                                  <h4>Region</h4>
                                  <select name="league" value={league} onChange={this.onChange}>
                                    <option disabled value="select-option">
                                      Select Option
                                    </option>
                                    {filteredRegion[0].inner.map(region => {
                                      const joined = region.replace(/\s+/g, '-').toLowerCase();
                                      return (
                                        <option key={joined} value={region}>
                                          {region}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              )}

                              {/* For the level field selection */}
                              <div className="field">
                                <h4>Level</h4>
                                <select name="skill" value={skill} onChange={this.onChange}>
                                  <option disabled value="select-option">
                                    Select Option
                                  </option>
                                  {skillsArray.map(aSkill => {
                                    const skillName = aSkill.name;
                                    const joined = skillName.replace(/\s+/g, '-').toLowerCase();
                                    return (
                                      <option key={joined} value={skillName}>
                                        {skillName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>

                              {/* For the subleague Id field */}
                              <div className="field">
                                <h4>SubLeagueId</h4>
                                <input type="number" name="subLeagueId" value={subLeagueId} onChange={this.onChange} />
                              </div>
                            </div>

                            <Button normal type="submit" disabled={isInvalid}>
                              Update
                            </Button>
                          </form>
                          {error && <p className="error">League doesn't exist</p>}
                        </div>
                      )}
                    </Mutation>
                  </div>
                </div>
              ) : (
                <p>All teams are part of an active league</p>
              )}
            </Fragment>
          )}
        </Query>
      </Fragment>
    );
  }
}

export default CheckTeam;
