import React, { Fragment } from 'react';
import { Button } from '../../../../Universal/style';

const GenerateDoublesFinals = ({ doublesAdminGetWeek10Fixture, doublesFinalFixture }) => {
  if (doublesAdminGetWeek10Fixture && doublesAdminGetWeek10Fixture.length > 0) {
    return <div className="status-bar active">Doubles Finals Generated</div>;
  } else {
    return (
      <Fragment>
        <div className="status-bar empty">Not yet generated</div>
        <Button onClick={() => doublesFinalFixture()}>Generate Finals</Button>
      </Fragment>
    );
  }
};

export default GenerateDoublesFinals;
