// Helpers to format data

// Sanitize Input
export const sanitizeInput = ({ string, type }) => {
    let regex = /[?&/<>]/g;
    switch (type) {
        case 'email' :
            regex = /[^@+-_~.A-Za-z0-9]/g;
            break;
        case 'int' :
            regex = /\D/g;
            break;
        default :
            break;
    }

    return string.replace(regex, ' ');
}

// Format date to be in the format of day/month/year
export const formatDate = (date) => {
    // Function to append zeros if either date or month is less then 10.
    const addZero = (digit) => {
        if (digit < 10) {
            digit = `0${digit}`;
        }
        return digit;
    }
    
    const day = addZero(date.getDate());
    const month = addZero(date.getMonth() + 1);
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}