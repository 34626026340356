import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';

import { ToggleLabel } from '../style';
import { Flex } from '../../Universal/style';
import { GENDER_SWITCH_MUTATION } from '../queries';

class GenderToggleSwitch extends Component {
  ToggleCheck = async genderSwitch => {
    await genderSwitch();
    await this.props.refetch();
  };

  render() {
    const { getSeasonStatus } = this.props;

    return (
      <Flex className="payable-status">
        <div className="payable-column payable-column-1">
          <p>Toggle whether to enable Singles Men's and Women's league registrations.</p>
          <Mutation mutation={GENDER_SWITCH_MUTATION}>
            {genderSwitch => (
              <ToggleLabel>
                <input
                  type="checkbox"
                  defaultChecked={getSeasonStatus.showGendersRego}
                  onClick={() => this.ToggleCheck(genderSwitch)}
                />
                <span className="slider round"></span>
              </ToggleLabel>
            )}
          </Mutation>
        </div>
      </Flex>
    );
  }
}

export default GenderToggleSwitch;
