import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Tilt from 'react-tilt';

import * as routes from '../../constants/routes';
import isLoggedIn from '../Session/isLoggedIn';

import { Flex } from '../Universal/style';
import { SignInWidth } from '../SignIn/style';
import AutomaticBackground from '../SignIn/automaticBackground';
import logo from '../../resources/images/logo-white-bk-circle.png';
import ForgotForm from './forgotForm';
import Footer from '../Footer';

const ForgotPage = ({ refetch }) => (
  <AutomaticBackground>
    <SignInWidth>
      <Flex noWrap between>
        <h1>Reset password</h1>
        <Tilt className="Tilt" options={{ max: 35, scale: 1.05 }}>
          <div className="Tilt-inner">
            <img style={{ width: '80px' }} src={logo} alt="Logo" />
          </div>
        </Tilt>
      </Flex>
      <ForgotForm refetch={refetch} />
      <p>
        <Link to={routes.SIGN_IN}>Back to Login</Link>
      </p>
    </SignInWidth>
    <Footer />
  </AutomaticBackground>
);

const ForgotLink = () => (
  <p>
    <Link to={routes.FORGOT_PASSWORD} className="outer-link">
      Forgot Password
    </Link>
  </p>
);

export default withRouter(isLoggedIn(session => session && session.me)(ForgotPage));

export { ForgotForm, ForgotLink };
