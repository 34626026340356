import React, { Component } from 'react';

import { SignInFormWrapper } from './style';
// import viewImages1 from '../../resources/images/viewscreen/viewscreen-1.jpg';
// import viewImages2 from '../../resources/images/viewscreen/viewscreen-2.jpg';
// import viewImages3 from '../../resources/images/viewscreen/viewscreen-3.jpg';
// import viewImages4 from '../../resources/images/viewscreen/viewscreen-4.jpg';
// import viewImages5 from '../../resources/images/viewscreen/viewscreen-5.jpg';
// import viewImages6 from '../../resources/images/viewscreen/viewscreen-6.jpg';
// import viewImages7 from '../../resources/images/viewscreen/viewscreen-7.jpg';

class AutomaticBackground extends Component {
  constructor() {
    super();
    this.state = {
      currentIndex: 0,
    };
  }

  randomiseArray = backImagesArray => {
    let currentIndex = backImagesArray.length;
    while (currentIndex !== 0) {
      const randomIndex = Math.floor(currentIndex * Math.random());
      currentIndex -= 1;
      const temporaryValue = backImagesArray[currentIndex];
      backImagesArray[currentIndex] = backImagesArray[randomIndex];
      backImagesArray[randomIndex] = temporaryValue;
    }
    return backImagesArray;
  };

  ImageRotation = backImagesArray => {
    const { currentIndex } = this.state;
    const newArray = this.randomiseArray(backImagesArray);
    this.timeout = setTimeout(() => {
      this.setState(prev => {
        if (prev.currentIndex < backImagesArray.length - 1) {
          return { currentIndex: prev.currentIndex + 1 };
        } else {
          return { currentIndex: 0 };
        }
      });
    }, 30000);

    return newArray[currentIndex];
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    // const backImagesArray = [viewImages1, viewImages2, viewImages3, viewImages4, viewImages5, viewImages6, viewImages7];
    // bkImage={this.ImageRotation(backImagesArray)} -> place as prop to have randomised background images
    return <SignInFormWrapper slidingBack>{this.props.children}</SignInFormWrapper>;
  }
}

export default AutomaticBackground;
