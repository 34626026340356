import React, { Component, Fragment } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import IPGeolocationAPI from 'ip-geolocation-api-javascript-sdk';
import Tilt from 'react-tilt';

import RegisterPage from '../Register';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import Confirmation from '../SignUp/confirmation';
import Forgot from '../Forgot';
import Reset from '../Forgot/Reset';
import DoublesMaster from '../Doubles';
import SinglesMaster from '../Singles';
import UnsubscribePage from '../Unsubscribe';
import IndividualResults from '../PastResults/individual';
import WinnersResults from '../PastResults/winners';
import Lifetime from '../Lifetime';
import withSession from '../Session/withSession';
import ErrorBoundary from '../ErrorBoundary';

import { GlobalStyle } from '../../theme/GlobalStyle';

import AutomaticBackground from '../SignIn/automaticBackground';
import logo from '../../resources/images/logo-white-bk-circle.png';
import Footer from '../Footer';

import * as routes from '../../constants/routes';
import history from '../../constants/history';

const ipgeolocationApi = new IPGeolocationAPI(process.env.REACT_APP_GEOLOCATION, false);

class App extends Component {
  constructor() {
    super();
    this.state = {
      testMode: true,
      preventCountry: true,
      isloading: true,
    };
  }

  componentDidMount() {
    ipgeolocationApi.getGeolocation(response => {
      const { country_code2 } = response;
      if (country_code2 === 'AU' || country_code2 === 'JP') {
        this.setState({ preventCountry: false });
      }
      this.setState({ isloading: false });
    });
  }

  render() {
    const { session, refetch, client } = this.props;
    const { testMode, preventCountry, isloading } = this.state;

    return (
      <Fragment>
        <GlobalStyle />
        {testMode ? (
          <AutomaticBackground>
            <div id="maintainance-wrapper" style={{ zIndex: 0 }}>
              <Tilt className="Tilt" options={{ max: 35, scale: 1.05 }}>
                <div className="Tilt-inner">
                  <img style={{ width: '80px' }} src={logo} alt="Logo" />
                </div>
              </Tilt>
              <h1>This App is no longer available</h1>
              <p>
                Thank you for your interest. A new App is currently in the works!{' '}
                <span style={{ display: 'inline-block' }} role="img" aria-label="thumbs up">
                  👍
                </span>
              </p>
            </div>
            <Footer />
          </AutomaticBackground>
        ) : isloading ? (
          ''
        ) : preventCountry ? (
          <div style={{ textAlign: 'center' }}>
            <h1>Unavailable </h1>
            <p>Ooops, this app is not available in your country.</p>
          </div>
        ) : (
          <Router history={history}>
            <ErrorBoundary>
              <Switch>
                <Route path={routes.LANDING} render={() => <RegisterPage session={session} refetch={refetch} />} />
                <Route exact path={routes.SIGN_IN} render={() => <SignInPage refetch={refetch} client={client} />} />
                <Route exact path={routes.SIGN_UP} render={() => <SignUpPage refetch={refetch} />} />
                <Route exact path={routes.ACCOUNT} render={() => <AccountPage session={session} refetch={refetch} />} />
                <Route path={routes.ADMIN} render={() => <AdminPage session={session} />} />
                <Route
                  exact
                  path={routes.TEAM_INVITE}
                  render={() => <SignUpPage refetch={refetch} fromInvitation={true} />}
                />
                <Route exact path={routes.FORGOT_PASSWORD} render={() => <Forgot refetch={refetch} />} />
                <Route exact path={routes.RESET} render={() => <Reset refetch={refetch} />} />
                <Route exact path={routes.CONFIRMATION} render={() => <Confirmation refetch={refetch} />} />
                <Route path={routes.SINGLES} render={() => <SinglesMaster session={session} refetch={refetch} />} />
                <Route path={routes.DOUBLES} render={() => <DoublesMaster session={session} refetch={refetch} />} />
                <Route path={routes.UNSUBSCRIBE} render={() => <UnsubscribePage />} />
                <Route path={routes.PAST_RESULTS} render={() => <IndividualResults session={session} />} />
                <Route path={routes.ALL_PAST_WINNERS} render={() => <WinnersResults session={session} />} />
                <Route path={routes.LIFETIME} render={() => <Lifetime session={session} />} />
                <Route exact path="/" render={() => <Redirect to={routes.LANDING} />} />
                <Redirect to={routes.LANDING} />
              </Switch>
            </ErrorBoundary>
          </Router>
        )}
      </Fragment>
    );
  }
}

export default withSession(App);
