import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Mutation } from '@apollo/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import withAuthorization from '../../Session/withAuthorization';
import { sanitizeInput } from '../../../abstracts/helpers';

import * as routes from '../../../constants/routes';
import { BackLink, Button, Flex } from '../../Universal/style';
import { ErrorWrapper, Li } from '../../Error/style';
import { EMAIL_PROMOTION_MUTATION } from '../queries';

class CustomEmailPage extends Component {
  constructor() {
    super();
    this.state = {
      subject: '',
      heading: '',
      paragraph: '',
      paid: false,

      isMelbourne: false,

      formError: '',
    };
  }

  handlePaidCheckbox = () => {
    this.setState(prev => ({ paid: !prev.paid }));
  };

  handleMelbourneCheckbox = () => {
    this.setState(prev => ({ isMelbourne: !prev.isMelbourne }));
  };

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onSubmit = async (event, emailSignUp) => {
    event.preventDefault();
    const { subject, heading, paragraph } = this.state;
    let error = false;

    if (subject === '' || heading === '' || paragraph === '') {
      error = true;
    }

    if (!error) {
      await emailSignUp();
      this.setState({ subject: '', heading: '', paragraph: '' });
    } else {
      this.setState({ formError: 'All fields must be populated.' });
    }
  };

  render() {
    let { subject, heading, paragraph } = this.state;
    const { formError, paid, isMelbourne } = this.state;
    const isInvalid = subject === '' || heading === '' || paragraph === '';
    
    // Sanitize inputs
    subject = sanitizeInput({ string: subject });
    heading = sanitizeInput({ string: heading });
    paragraph = sanitizeInput({ string: paragraph });

    return (
      <Fragment>
        <BackLink>
          <Link to={routes.ADMIN}>
            <FontAwesomeIcon icon={faArrowCircleLeft} /> Back to Admin Page
          </Link>
        </BackLink>

        <h2>Custom Email Promotion</h2>
        <div className="custom-email-section">
          <Mutation
            mutation={EMAIL_PROMOTION_MUTATION}
            variables={{ custom: true, subject, heading, paragraph, paid, isMelbourne }}
          >
            {emailSignUp => (
              <form onSubmit={event => this.onSubmit(event, emailSignUp)}>
                <div className="inner-form-fields">
                  <label>
                    <FontAwesomeIcon icon={faQuestionCircle} /> Subject
                  </label>
                  <input onChange={this.onChange} name="subject" value={subject} placeholder="Subject of the message" />
                  <label>
                    <FontAwesomeIcon icon={faQuestionCircle} /> Heading
                  </label>
                  <input onChange={this.onChange} name="heading" value={heading} placeholder="Intro of message" />
                  <label>
                    <FontAwesomeIcon icon={faQuestionCircle} /> Message
                  </label>
                  <textarea onChange={this.onChange} name="paragraph" value={paragraph} placeholder="Message content" />

                  {/* checkbox area */}
                  <PaidCheckbox paid={paid} handlePaidCheckbox={this.handlePaidCheckbox} />
                  <MelbourneCheckbox isMelbourne={isMelbourne} handleMelbourneCheckbox={this.handleMelbourneCheckbox} />
                </div>
                <Button normal type="submit" disabled={isInvalid}>
                  Send Emails
                </Button>
                {formError.length > 0 && (
                  <ErrorWrapper className="form-error">
                    <Li>{formError}</Li>
                  </ErrorWrapper>
                )}
              </form>
            )}
          </Mutation>
        </div>
      </Fragment>
    );
  }
}

// Email only registered players
const PaidCheckbox = ({ paid, handlePaidCheckbox }) => (
  <Flex alignCenter>
    <label style={{ marginRight: '0.5rem' }}>
      <FontAwesomeIcon icon={faQuestionCircle} />{' '}
      {paid ? 'Registered players email has been checked.' : 'Email registered players only?'}
    </label>
    <input type="checkbox" name="paid" checked={paid} onClick={() => handlePaidCheckbox()} />
  </Flex>
);

// Email players located in Melbourne only. This sends the email + removes their registration access
const MelbourneCheckbox = ({ isMelbourne, handleMelbourneCheckbox }) => (
  <Flex alignCenter>
    <label style={{ marginRight: '0.5rem' }}>
      <FontAwesomeIcon icon={faQuestionCircle} />{' '}
      {isMelbourne ? 'Melbourne has been checked.' : 'Is this for Melbourne?'}
    </label>
    <input type="checkbox" name="isMelbourne" checked={isMelbourne} onClick={() => handleMelbourneCheckbox()} />
  </Flex>
);

export default withAuthorization(session => session && session.me && session.me.role === 'ADMIN')(CustomEmailPage);
