import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';

import { sanitizeInput } from '../../../abstracts/helpers';

import { Flex, Button } from '../../Universal/style';
import { SUBSCRIBE_TO_NEWSLETTER_MUTATION } from '../queries';

class SubscribeToNewsletter extends Component {
  constructor() {
    super();
    this.state = {
      addEmail: '',
      removeEmail: '',
    };
  }

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onSubmit = async (event, subscribeToNewsletter) => {
    event.preventDefault();
    await subscribeToNewsletter();
    this.setState({ addEmail: '', removeEmail: '' });
  };

  render() {
    let { addEmail, removeEmail } = this.state;

    // Sanitize inputs
    addEmail = sanitizeInput({ string: addEmail, type: 'email' });
    removeEmail = sanitizeInput({ string: removeEmail, type: 'email' });


    return (
      <div className="a-form-section">
        <p>Subscribe user to newsletter</p>
        <Flex className="a-form-wrapper">
          <Mutation mutation={SUBSCRIBE_TO_NEWSLETTER_MUTATION} variables={{ email: addEmail, type: 'add' }}>
            {(subscribeToNewsletter, { error }) => (
              <form>
                <label>Add</label>
                <input
                  type="text"
                  name="addEmail"
                  value={addEmail}
                  onChange={this.onChange}
                  placeholder="Enter email of user"
                />
                <Button normal onClick={event => this.onSubmit(event, subscribeToNewsletter)}>
                  Add
                </Button>
                {error && <div className="error-message">Email doesn't exist.</div>}
              </form>
            )}
          </Mutation>
          <Mutation mutation={SUBSCRIBE_TO_NEWSLETTER_MUTATION} variables={{ email: removeEmail, type: 'remove' }}>
            {(subscribeToNewsletter, { error }) => (
              <form>
                <label>Remove</label>
                <input
                  type="text"
                  name="removeEmail"
                  value={removeEmail}
                  onChange={this.onChange}
                  placeholder="Enter email of user"
                />
                <Button normal onClick={event => this.onSubmit(event, subscribeToNewsletter)}>
                  Remove
                </Button>
                {error && <div className="error-message">Email doesn't exist.</div>}
              </form>
            )}
          </Mutation>
        </Flex>
      </div>
    );
  }
}

export default SubscribeToNewsletter;
