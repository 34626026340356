import gql from 'graphql-tag';

// Query - Leaderboard on rightsidebar
export const GET_DOUBLES_LEADERBOARD = gql`
  query($teamId: ID!) {
    getDoublesLeaderboard(teamId: $teamId) {
      id
      points
      wins
      losses
      teamId
    }
  }
`;
// Subscription to listen to new submitted results to the "Scores" database table
export const UPDATED_DOUBLES_LEADERBOARD_SUBSCRIPTION = gql`
  subscription($playType: String!, $leagueId: ID!, $subLeagueId: ID!) {
    updatedDoublesLeaderboard(playType: $playType, leagueId: $leagueId, subLeagueId: $subLeagueId) {
      id
      points
      wins
      losses
      teamId
    }
  }
`;

// Query - Weekly results
export const GET_DOUBLES_RESULTS = gql`
  query($week: Int!) {
    getDoublesResults(week: $week) {
      id
      score
      loserId
      winnerId
    }
  }
`;
// Query - Weekly results --> Mobile
export const GET_DOUBLES_RESULTS_MOBILE = gql`
  query($week: Int!) {
    getDoublesResultsMobile(week: $week) {
      id
      score
      loserId
      winnerId
    }
  }
`;

// Subscription to listen to new submitted results to the "Scores" database table
export const NEW_DOUBLES_RESULT_SUBSCRIPTION = gql`
  subscription($playType: String!, $leagueId: ID!, $subLeagueId: ID!, $week: Int!) {
    newDoublesResultSubmission(playType: $playType, leagueId: $leagueId, subLeagueId: $subLeagueId, week: $week) {
      id
      score
      loserId
      winnerId
    }
  }
`;
