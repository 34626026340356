import React, { Component, Fragment } from 'react';
import { Mutation } from '@apollo/react-components';

import { Button } from '../../Universal/style';
import { EMAIL_PROMOTION_MUTATION } from '../queries';

class ClickEmailPromotion extends Component {
  clickEmailPromotion = async (emailSignUp, refetch) => {
    await emailSignUp();
    await refetch();
  };

  render() {
    const { getSeasonStatus, refetch } = this.props;
    return (
      <Fragment>
        {/* Don't send email if the "emailPromotion" column in the SeasonStatus table is set to true - this means we have already sent out the promotions. */}
        {getSeasonStatus.emailPromotion ? (
          <p>
            Email promotions has been sent out for the upcoming season{' '}
            <span style={{ display: 'inline-block' }} role="img" aria-label="tennis-ball">
              🚀
            </span>
          </p>
        ) : (
          <Mutation mutation={EMAIL_PROMOTION_MUTATION} variables={{ paid: false }}>
            {emailSignUp => (
              <Fragment>
                <p>Send default promotion email to all unregistered users.</p>
                <Button normal onClick={() => this.clickEmailPromotion(emailSignUp, refetch)}>
                  Send Emails
                </Button>
              </Fragment>
            )}
          </Mutation>
        )}
      </Fragment>
    );
  }
}

export default ClickEmailPromotion;
