import React from 'react';
import { H4 } from '../style';
import { LogoSection } from '../LeftsideBar/style';
import logo from '../../../resources/images/logo.png';

export const MobileLogo = ({ type, genderType }) => (
  <LogoSection type={type} genderType={genderType} className="mobile-logo">
    <div className="logo-wrapper">
      <img src={logo} alt="Logo" />
    </div>
    <H4 logo>Flexi tennis leagues</H4>
  </LogoSection>
);
