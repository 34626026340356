import React from 'react';
import { Query, Mutation } from '@apollo/react-components';

import GenerateFinals from './generateFinals.js';
import { FINALS_FIXTURE_QUERY, GENERATE_FINALS_FIXTURE } from '../../../../Universal/queries';

const ControlFinalsFixture = ({ seasonDate }) => (
  // Query finals fixture for week 10 and if array is empty then display button to "generate" final fixtures otherwise don't display button
  <Query query={FINALS_FIXTURE_QUERY}>
    {({ data, loading, subscribeToMore }) => {
      if (loading) {
        return <p style={{ margin: '0' }}>Loading...</p>;
      }
      if (data && data.adminGetWeek10Fixture) {
        const { adminGetWeek10Fixture } = data;
        return (
          // Resolver to generate finals fixture
          <Mutation
            mutation={GENERATE_FINALS_FIXTURE}
            variables={{ seasonDate }}
            refetchQueries={() => [{ query: FINALS_FIXTURE_QUERY }]}
          >
            {finalFixture => (
              <GenerateFinals
                finalFixture={finalFixture}
                subscribeToMore={subscribeToMore}
                adminGetWeek10Fixture={adminGetWeek10Fixture}
              />
            )}
          </Mutation>
        );
      }
      return '';
    }}
  </Query>
);

export default ControlFinalsFixture;
