import styled, { theme, css } from '../../theme';
import { keyframes } from 'styled-components';

export const ProfileImageIcon = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  color: ${theme.orange};

  svg {
    z-index: 1;
  }

  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${props => (props.imageLoading ? 'rgba(255, 255, 255, 0.65)' : 'rgba(255, 255, 255, 0.4)')};
    z-index: 0;
  }
`;
export const Input = styled.input`
  max-width: 100%;
  margin: 5px 0;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  outline: none;
  ${props =>
    props.MR &&
    css`
      margin-right: ${props.MR};
    `};

  :disabled {
    color: darkgray;
  }
  ::placeholder {
    font-style: italic;
  }
`;
export const TextArea = styled.textarea`
  font-family: 'arial';
  max-width: 100%;
  margin: 5px 0;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  outline: none;
  :disabled {
    color: darkgray;
  }
  ::placeholder {
    font-style: italic;
  }
`;
export const ConfirmationWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const fadeOut = keyframes`
    40% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;
export const H2 = styled.h2`
  width: 140px;
  padding: 18px 24px;
  font-size: 14px;
  border: 2px solid ${theme.doubles.link};
  border-radius: 3px;
  background-color: white;
  color: ${theme.doubles.link};
  text-align: center;
  line-height: 21px;
  animation: ${fadeOut} 2s linear;
`;

export const EmailSetting = styled.div`
  margin-top: 50px;
  margin-bottom: 30px;
  padding: 13px 20px;
  border: 2px dashed #838383;
  border-radius: 4px;
  background-color: #f9f9f9;

  h4 {
    margin: 0;
  }
  p {
    margin-bottom: 10px;
    font-family: 'roboto-condensed';
  }
  label {
    position: relative;
    display: inline-block;
    width: 51px;
    height: 28px;

    input {
      opacity: 0;
      height: 0;
      width: 0;

      :checked + span {
        background-color: orange;

        :before {
          left: 24px;
        }
      }
    }
    span {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #dedede;
      border-radius: 65px;

      :before {
        position: absolute;
        content: '';
        background-color: white;
        left: 5px;
        top: 2px;
        width: 23px;
        height: 23px;
        border-radius: 40px;
        transition: 0.5s all ease-in-out;
      }
    }
  }
`;
