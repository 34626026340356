import React, { Fragment } from 'react';
import { Query, Mutation } from '@apollo/react-components';

import GenerateFixture from './generateFixture';
import { GENERATE_FIXTURE, GET_COMPLETE_FIXTURES, FIXTURE_QUERY } from '../../../../Universal/queries';

const ControlNormalFixture = ({ season, seasonDate, closed, year }) => (
  <Fragment>
    {/* Mutation to generate fixture */}
    <Mutation
      mutation={GENERATE_FIXTURE}
      variables={{ season, seasonDate }}
      // Used to fetch all users in the current season fixture for the messages page.
      refetchQueries={() => {
        return [
          {
            query: FIXTURE_QUERY,
            variables: {
              season,
              playType: 'singles',
              year,
            },
          },
          { query: GET_COMPLETE_FIXTURES },
        ];
      }}
    >
      {generateFixture => (
        // Query all fixture in the Fixture database --> if empty array, then the "GenerateFixture" component will appear.
        // Button to generate fixture.
        <Query query={GET_COMPLETE_FIXTURES}>
          {({ data, loading, subscribeToMore }) => {
            if (loading) {
              return '';
            }
            if (data && data.adminGetAllFixture) {
              const { adminGetAllFixture } = data;
              return (
                <GenerateFixture
                  generateFixture={generateFixture}
                  adminGetAllFixture={adminGetAllFixture}
                  subscribeToMore={subscribeToMore}
                  closed={closed}
                />
              );
            }
            return '';
          }}
        </Query>
      )}
    </Mutation>
  </Fragment>
);

export default ControlNormalFixture;
