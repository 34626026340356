import React, { Component } from 'react';
import { Query } from '@apollo/react-components';
import { Image, Transformation } from 'cloudinary-react';
import 'element-scroll-polyfill';

import { OPPONENT_QUERY } from '../Universal/queries';
import placeholderImage from '../../resources/images/placeholder-profile.jpg';
import { ScrollIndicator } from '../Singles/fixtures/style';

class AllLifetime extends Component {
  constructor() {
    super();
    this.state = {
      hasScroll: false,
      topPosition: 0,
      leftPosition: 0,
      indicatorHeight: 0,
      arrowBorder: 0,
    };
  }

  scrollElement = element => {
    if (element) {
      this.element = element;
      const clientWidth = element.clientWidth;
      const scrollWidth = element.scrollWidth;
      const topPosition = element.clientHeight / 2;

      if (scrollWidth > clientWidth) {
        this.setState({
          hasScroll: true,
          topPosition,
          indicatorHeight: element.clientHeight / 3,
          arrowBorder: element.clientHeight / 27,
        });
      }
    }
  };

  handleOnScroll = event => {
    const { clientWidth, scrollLeft, scrollWidth } = event.target;

    this.setState({ leftPosition: scrollLeft });
    if (scrollLeft === scrollWidth - clientWidth) {
      this.setState({ hasScroll: false });
    } else {
      this.setState({ hasScroll: true });
    }
  };

  handleRightShift = () => {
    const scrollWidth = this.element.scrollWidth;
    this.element.scrollTo(scrollWidth, 0);
    this.setState({ hasScroll: false });
  };

  render() {
    const { hasScroll, topPosition, leftPosition, indicatorHeight, arrowBorder } = this.state;
    const halfHeight = indicatorHeight / 2;

    const { getAllLifetime } = this.props;
    if (getAllLifetime.length > 20) {
      getAllLifetime.length = 20;
    }

    return (
      <div className="section-wrapper">
        <h2>Top 20</h2>
        <div className="table-wrapper" ref={this.scrollElement} onScroll={this.handleOnScroll}>
          <div className="table">
            <div className="table-row heading">
              <div className="table-cell">
                <p>Rank</p>
              </div>
              <div className="table-cell">
                <p>Points</p>
              </div>
              <div className="table-cell">
                <p>Wins</p>
              </div>
              <div className="table-cell">
                <p>Losses</p>
              </div>
              <div className="table-cell">
                <p>Player</p>
              </div>
            </div>
            {getAllLifetime.map((result, lifetimeIndex) => (
              <div key={result.id} className="table-row">
                <div className="table-cell">
                  <p>{lifetimeIndex + 1}</p>
                </div>
                <div className="table-cell">
                  <p>{result.points}</p>
                </div>
                <div className="table-cell">
                  <p>{result.wins}</p>
                </div>
                <div className="table-cell">
                  <p>{result.losses}</p>
                </div>
                <Query query={OPPONENT_QUERY} variables={{ id: result.userId }}>
                  {({ data, loading }) => (
                    <div className="table-cell">
                      {loading && <p>Loading..</p>}
                      {data && data.user && (
                        <div className="profile-wrapper">
                          <div className="profile-inner">
                            {data.user.profileImage ? (
                              <Image
                                className="profile-image"
                                cloudName={process.env.REACT_APP_CLOUD_NAME}
                                publicId={data.user.profileImage}
                              >
                                <Transformation width="20" height="20" gravity="faces" crop="fill" />
                              </Image>
                            ) : (
                              <img src={placeholderImage} alt="player-profile" />
                            )}
                            <p>
                              {data.user.firstname} {data.user.lastname}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </Query>
              </div>
            ))}
          </div>
          {hasScroll && (
            <ScrollIndicator
              top={topPosition - halfHeight}
              right={leftPosition}
              height={indicatorHeight}
              arrowBorder={arrowBorder}
              onClick={() => this.handleRightShift()}
            >
              <div className="scroll-indicator"></div>
            </ScrollIndicator>
          )}
        </div>
      </div>
    );
  }
}

export default AllLifetime;
