import gql from 'graphql-tag';

// Fetch FINAL fixture for week 9 - to display upcoming opponent for week 10 and on week 10 the final opponent - left sidebar && Fixture table.
export const GET_FINAL_DOUBLES_FIXTURE = gql`
  query($season: String!, $playType: String!, $week: String!, $year: String!) {
    getFinalDoublesFixture(season: $season, playType: $playType, week: $week, year: $year) {
      id
      week
      startDate
      score
      playType
      opponentTeamId
      pendingScore
      leagueId
      teamId
    }
  }
`;
