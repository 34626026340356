import gql from 'graphql-tag';

/************************ All dates query  ***********************/
export const ALL_CREATED_DATES = gql`
  query {
    showAllDates {
      id
      year
      previousSpring
      summer
      autumn
      winter
      spring
      nextSummer
    }
  }
`;
/************************ Subscription for the Dates created ***********************/
export const DATES_CREATED = gql`
  subscription {
    datesCreated {
      dates {
        id
        year
        previousSpring
        summer
        autumn
        winter
        spring
        nextSummer
      }
    }
  }
`;

// Remove a row of season dates
export const REMOVE_SEASON_DATES_MUTATION = gql`
  mutation($id: ID!) {
    removeDates(id: $id)
  }
`;
