import React, { Component, Fragment } from 'react';
import { Query } from '@apollo/react-components';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersCog, faBell } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../theme';

import { master } from '../../resources/resources';
import * as routes from '../../constants/routes';
import SignOutButton from '../SignOut';

import { Flex, Width } from '../Universal/style';
import { Ul, MenuItemLi } from './style';
import {
  NEW_SINGLES_MESSAGES_QUERY,
  NEW_SINGLES_MESSAGES_SUBSCRIPTION,
  NEW_DOUBLES_MESSAGES_QUERY,
  NEW_DOUBLES_MESSAGES_SUBSCRIPTION,
} from './queries';
import { Notification } from '../Singles/message/style';

const Navigation = ({ session, currentTeamId, isTeamOwner }) => (
  <Fragment>
    {session && session.me && (
      <NavigationAuth session={session} currentTeamId={currentTeamId} isTeamOwner={isTeamOwner} />
    )}
  </Fragment>
);

const NavigationAuth = ({
  session: {
    me: { id, paid, playType, genderType, role },
  },
  currentTeamId,
  isTeamOwner,
}) => {
  const singlesNav = master.singlesNav;
  const doublesNav = master.doublesNav;
  const normalNav = master.normalNav;

  const themeColor =
    genderType === 'men' ? theme.men.link : genderType === 'women' ? theme.women.link : theme.mixed.link;
  const activeClass =
    genderType === 'men'
      ? 'active active-singles-men'
      : genderType === 'women'
      ? 'active active-singles-women'
      : 'active active-singles-mixed';

  return (
    <Flex between back className="navigation-wrapper">
      <Query query={NEW_SINGLES_MESSAGES_QUERY}>
        {({ data, loading, subscribeToMore }) => {
          const singlesData = data;
          const singlesLoad = loading;
          const singlesSub = subscribeToMore;

          if (singlesLoad) {
            return '';
          }
          if (singlesData && singlesData.allNewOpponentMessages) {
            return (
              <Ul>
                {paid ? (
                  playType === 'singles' ? (
                    <NavGenerator
                      data={singlesNav}
                      role={role}
                      color={themeColor}
                      active={activeClass}
                      singles={true}
                      messageData={singlesData}
                      messageLoad={singlesLoad}
                      subscribeToMore={singlesSub}
                      userId={id}
                    />
                  ) : (
                    <Query query={NEW_DOUBLES_MESSAGES_QUERY}>
                      {({ data, loading, subscribeToMore }) => (
                        <NavGenerator
                          data={doublesNav}
                          role={role}
                          color={theme.doubles.link}
                          active={'active active-doubles'}
                          doubles={true}
                          messageData={singlesData}
                          messageLoad={singlesLoad}
                          subscribeToMore={singlesSub}
                          doublesMessage={data}
                          doublesLoad={loading}
                          doublesSub={subscribeToMore}
                          userId={id}
                          teamId={currentTeamId}
                          isTeamOwner={isTeamOwner}
                        />
                      )}
                    </Query>
                  )
                ) : (
                  <NavGenerator data={normalNav} role={role} active={'active'} />
                )}
              </Ul>
            );
          }
          return '';
        }}
      </Query>
      {genderType && genderType.length > 0 ? (
        <SignOutButton genderType={genderType} />
      ) : playType === 'doubles' ? (
        <SignOutButton doubles={true} />
      ) : (
        <SignOutButton />
      )}
    </Flex>
  );
};

const NavGenerator = ({
  data,
  role,
  color,
  active,
  singles,
  messageData,
  messageLoad,
  subscribeToMore,
  userId,
  doubles,
  doublesMessage,
  doublesLoad,
  doublesSub,
  teamId,
  isTeamOwner,
}) => {
  return (
    <Fragment>
      {data.map((v, index) => {
        const joined = v.text.replace(/\s+/g, '-').toLowerCase();
        return (
          <Fragment key={joined}>
            <MenuItemLi color={color}>
              <NavLink
                className={`menu-item menu-item-${v.text}`}
                activeClassName={active}
                style={{ outline: 'none', textDecoration: 'none' }}
                to={v.route}
              >
                {/* For the text content */}
                <div className="text">
                  <FontAwesomeIcon icon={v.icon} />
                  <Width MT={'4px'} noMB full>
                    {v.text}
                  </Width>
                </div>
              </NavLink>

              {/* Notification section */}
              {index === 2 && singles && (
                <SinglesNotificationWrapper
                  messageData={messageData}
                  messageLoad={messageLoad}
                  subscribeToMore={subscribeToMore}
                  userId={userId}
                />
              )}
              {index === 3 && doubles && (
                <DoublesNotificationWrapper
                  messageData={messageData}
                  messageLoad={messageLoad}
                  subscribeToMore={subscribeToMore}
                  userId={userId}
                  doublesMessage={doublesMessage}
                  doublesLoad={doublesLoad}
                  subscribeToMoreDoubles={doublesSub}
                  teamId={teamId}
                  isTeamOwner={isTeamOwner}
                />
              )}
            </MenuItemLi>
          </Fragment>
        );
      })}
      {role === 'ADMIN' && (
        <MenuItemLi color={color}>
          <NavLink activeClassName={active} style={{ outline: 'none', textDecoration: 'none' }} to={routes.ADMIN}>
            <div className="text">
              <FontAwesomeIcon icon={faUsersCog} />
              <Width MT={'4px'} full noMB>
                Admin
              </Width>
            </div>
          </NavLink>
        </MenuItemLi>
      )}
    </Fragment>
  );
};

class SinglesNotificationWrapper extends Component {
  constructor() {
    super();
    this.state = {
      amount: 0,
      amountDoubles: 0,
    };
  }

  subscribeToNewMessages = () => {
    this.props.subscribeToMore({
      document: NEW_SINGLES_MESSAGES_SUBSCRIPTION,
      variables: { userId: this.props.userId },
      updateQuery: (previousResult, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousResult;
        }
        const { allNewOpponentMessagesSub } = subscriptionData.data;
        return Object.assign({}, previousResult, {
          ...previousResult.allNewOpponentMessages,
          allNewOpponentMessages: [...previousResult.allNewOpponentMessages, allNewOpponentMessagesSub],
        });
      },
    });
  };

  updateAmount = () => {
    const { messageData, doubles, doublesMessage, isTeamOwner } = this.props;
    if (messageData && messageData.allNewOpponentMessages && messageData.allNewOpponentMessages.length > 0) {
      const { allNewOpponentMessages } = messageData;
      this.setState({ amount: allNewOpponentMessages.length });
    }
    if (
      isTeamOwner &&
      doubles &&
      doublesMessage &&
      doublesMessage.allOpponentTeamMessages &&
      doublesMessage.allOpponentTeamMessages.length > 0
    ) {
      const { allOpponentTeamMessages } = doublesMessage;
      this.setState({ amountDoubles: allOpponentTeamMessages.length });
    }
  };

  componentDidMount() {
    this.subscribeToNewMessages();
    this.updateAmount();
  }

  componentDidUpdate(prevProps) {
    const { messageData, doublesMessage, isTeamOwner } = this.props;
    if (messageData && messageData.allNewOpponentMessages) {
      if (prevProps.messageData.allNewOpponentMessages !== messageData.allNewOpponentMessages) {
        const { allNewOpponentMessages } = messageData;
        this.setState({ amount: allNewOpponentMessages.length });
      }
    }
    if (isTeamOwner && doublesMessage && doublesMessage.allOpponentTeamMessages) {
      if (prevProps.doublesMessage.allOpponentTeamMessages !== doublesMessage.allOpponentTeamMessages) {
        const { allOpponentTeamMessages } = doublesMessage;
        this.setState({ amountDoubles: allOpponentTeamMessages.length });
      }
    }
  }

  render() {
    const { amount, amountDoubles } = this.state;
    const { messageLoad } = this.props;
    if (messageLoad) {
      return '';
    }
    if (amount > 0 || amountDoubles > 0) {
      return (
        <Notification amount={amount + amountDoubles} navigation={true}>
          <FontAwesomeIcon icon={faBell} />
        </Notification>
      );
    }
    return '';
  }
}

// This notification for DOUBLES is feeding the all props back into the SINGLES notification component
class DoublesNotificationWrapper extends Component {
  subscribeToDoublesNewMessages = () => {
    this.props.subscribeToMoreDoubles({
      document: NEW_DOUBLES_MESSAGES_SUBSCRIPTION,
      variables: { teamId: this.props.teamId },
      updateQuery: (previousResult, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousResult;
        }
        const { allOpponentTeamMessagesSub } = subscriptionData.data;
        return Object.assign({}, previousResult, {
          ...previousResult.allOpponentTeamMessages,
          allOpponentTeamMessages: [...previousResult.allOpponentTeamMessages, allOpponentTeamMessagesSub],
        });
      },
    });
  };

  componentDidMount() {
    this.subscribeToDoublesNewMessages();
  }

  render() {
    const { messageData, messageLoad, subscribeToMore, userId, doublesMessage, doublesLoad, isTeamOwner } = this.props;
    if (doublesLoad) {
      return '';
    }
    return (
      <SinglesNotificationWrapper
        messageData={messageData}
        messageLoad={messageLoad}
        subscribeToMore={subscribeToMore}
        userId={userId}
        doublesMessage={doublesMessage}
        doubles={true}
        isTeamOwner={isTeamOwner}
      />
    );
  }
}

export default Navigation;
