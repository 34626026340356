import React, { Fragment } from 'react';
import { Query } from '@apollo/react-components';

import { master } from '../../../resources/resources';
import logo from '../../../resources/images/logo.png';
import { NormalSideBar } from './style';
import { H3, H4, P, DetailsBox, Width, SideBoxWrapper } from '../style';
import { LogoSection } from './style';
import { GET_FINAL_FIXTURE } from '../queries';
import { GET_FINAL_DOUBLES_FIXTURE } from './doubles/queries';
import ScheduledMatch from './singles/scheduledMatch';
import DoublesScheduledMatch from './doubles/scheduledMatch';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import FeedbackSlider from './slider';

const LeftsideBar = ({
  admin,
  session,
  leagueInfoData,
  leagueLoading,
  getAllFixture,
  seasonDates,
  currentSeason,
  year,
  type,
  fixtureLoading,
  genderType,
}) => {
  return (
    <Width full>
      <LogoSection type={type} genderType={genderType} className="original-leftside-app-logo">
        <div className="logo-wrapper">
          <img src={logo} alt="Logo" />
        </div>
        <H4 logo>Flexi tennis leagues</H4>
      </LogoSection>

      {session && session.me && session.me.paid && !admin ? (
        session.me.playType === 'singles' ? (
          <Singles
            session={session}
            leagueInfoData={leagueInfoData}
            leagueLoading={leagueLoading}
            getAllFixture={getAllFixture}
            seasonDates={seasonDates}
            currentSeason={currentSeason}
            year={year}
            type={type}
            genderType={genderType}
          />
        ) : (
          <Doubles
            session={session}
            leagueInfoData={leagueInfoData}
            leagueLoading={leagueLoading}
            getAllFixture={getAllFixture}
            seasonDates={seasonDates}
            currentSeason={currentSeason}
            year={year}
            type={type}
            fixtureLoading={fixtureLoading}
          />
        )
      ) : (
        <Normal session={session} />
      )}
    </Width>
  );
};

const Singles = ({
  type,
  leagueInfoData,
  leagueLoading,
  getAllFixture,
  seasonDates,
  currentSeason,
  year,
  genderType,
}) => (
  <Fragment>
    <SideBoxWrapper borderTop>
      <H3 MB={'15px'}>Season Details</H3>
      {/* The flip effect from react-reveal */}
      <Flip left>
        <DetailsBox>
          <H4 dbHeading>League</H4>
          <P bold darkgray MT={'0'} MB={'18px'}>
            {leagueInfoData && leagueInfoData.getLeagueInfo
              ? leagueInfoData.getLeagueInfo.location
              : 'Information not yet available'}
          </P>
          <H4 dbHeading>Level</H4>
          <P bold darkgray MT={'0'} MB={'0'}>
            {leagueLoading ? (
              'Loading...'
            ) : leagueInfoData && leagueInfoData.getLeagueInfo ? (
              <Fragment>{leagueInfoData.getLeagueInfo.skill}</Fragment>
            ) : (
              'Information not yet available'
            )}
          </P>
        </DetailsBox>
      </Flip>
    </SideBoxWrapper>
    <SideBoxWrapper>
      <H3 MB={'15px'}>Scheduled Matches</H3>
      <Flip left>
        <DetailsBox>
          {/* If the "getAllFixture" array is populated and more than 0, then display the "current" and "next week" opponents. */}
          {/* When the "Fixture" tab gets clicked, then the fixture will be generated and at the same time "refetches" the FIXTURE_QUERY (this one) */}
          {getAllFixture && getAllFixture.length > 0 ? (
            <Query query={GET_FINAL_FIXTURE} variables={{ season: currentSeason, year, playType: type, week: '10' }}>
              {({ data, loading }) => {
                // Need to ensure loading first so that data exists when we use it in ScheduleMatch
                if (loading) {
                  return <p style={{ margin: '0' }}>Loading...</p>;
                }
                if (data && data.getFinalFixture) {
                  const { getFinalFixture } = data;
                  return (
                    <ScheduledMatch
                      getAllFixture={getAllFixture}
                      getFinalFixture={getFinalFixture}
                      seasonDates={seasonDates}
                      genderType={genderType}
                    />
                  );
                }
                return '';
              }}
            </Query>
          ) : (
            <Fragment>
              <H4 dbHeading>This Week</H4>
              <P bold darkgray MT={'0'} MB={'0'}>
                Currently unavailable
              </P>
            </Fragment>
          )}
        </DetailsBox>
      </Flip>
    </SideBoxWrapper>
    <FeedbackSlider />
  </Fragment>
);

/******************** Doubles section ******************/
const Doubles = ({
  type,
  leagueInfoData,
  leagueLoading,
  getAllFixture,
  seasonDates,
  currentSeason,
  year,
  fixtureLoading,
}) => (
  <Fragment>
    <SideBoxWrapper borderTop>
      <H3 MB={'15px'}>Season Details</H3>
      {/* The flip effect from react-reveal */}
      <Flip left>
        <DetailsBox>
          <H4 dbHeading>League</H4>
          <P bold darkgray MT={'0'} MB={'18px'}>
            {leagueInfoData && leagueInfoData.getLeagueInfo
              ? leagueInfoData.getLeagueInfo.location
              : 'Information not yet available'}
          </P>
          <H4 dbHeading>Level</H4>
          <P bold darkgray MT={'0'} MB={'0'}>
            {leagueLoading ? (
              'Loading...'
            ) : leagueInfoData && leagueInfoData.getLeagueInfo ? (
              <Fragment>{leagueInfoData.getLeagueInfo.skill}</Fragment>
            ) : (
              'Information not yet available'
            )}
          </P>
        </DetailsBox>
      </Flip>
    </SideBoxWrapper>
    <SideBoxWrapper>
      <H3 MB={'15px'}>Scheduled Matches</H3>
      <Flip left>
        <DetailsBox>
          {/* If the "getAllFixture" array is populated and more than 0, then display the "current" and "next week" opponents. */}
          {/* When the "Fixture" tab gets clicked, then the fixture will be generated and at the same time "refetches" the FIXTURE_QUERY (this one) */}
          {fixtureLoading ? (
            <p>Loading...</p>
          ) : getAllFixture && getAllFixture.length > 0 ? (
            <Query
              query={GET_FINAL_DOUBLES_FIXTURE}
              variables={{ season: currentSeason, year, playType: type, week: '10' }}
            >
              {({ data, loading }) => {
                // Need to ensure loading first so that data exists when we use it in ScheduleMatch
                if (loading) {
                  return <p style={{ margin: '0' }}>Loading...</p>;
                }
                if (data && data.getFinalDoublesFixture) {
                  const { getFinalDoublesFixture } = data;
                  return (
                    <DoublesScheduledMatch
                      getAllFixture={getAllFixture}
                      getFinalDoublesFixture={getFinalDoublesFixture}
                      seasonDates={seasonDates}
                    />
                  );
                }
                return '';
              }}
            </Query>
          ) : (
            <Fragment>
              <H4 dbHeading>This Week</H4>
              <P bold darkgray MT={'0'} MB={'0'}>
                Currently unavailable
              </P>
            </Fragment>
          )}
        </DetailsBox>
      </Flip>
    </SideBoxWrapper>
    <FeedbackSlider />
  </Fragment>
);

const Normal = ({ session }) => {
  return (
    <NormalSideBar>
      <div className="about-section">
        <H3 MB={'15px'}>{master.about.subheading}</H3>
        <Fade left>
          <p>{master.about.information}</p>
          <p>
            Whether you are looking to improve your game, get social or play competitive, FTL caters for all. Developed
            and designed for players, this platform revolutionizes the tennis world.
          </p>
        </Fade>
      </div>
      <div className="giphy-section">
        <img style={{ width: '100%' }} src="https://media.giphy.com/media/3j0XGxLkLwxZ67VP0W/giphy.gif" alt="giphy" />
      </div>
    </NormalSideBar>
  );
};

export default LeftsideBar;
