import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import Tilt from 'react-tilt';

import * as routes from '../../constants/routes';
import Loading from '../Loading';
import isLoggedIn from '../Session/isLoggedIn';
import Footer from '../Footer';

import AutomaticBackground from '../SignIn/automaticBackground';
import logo from '../../resources/images/logo-white-bk-circle.png';

import { Flex, Button } from '../Universal/style';
import { SignInWidth } from '../SignIn/style';
import { Notification } from '../SignUp/style';

const UNSUBSCRIBE_TOKEN = gql`
  mutation($token: String!) {
    unsubscribeUser(token: $token)
  }
`;

const UnsubscribePage = props => {
  let { token } = props.match.params;
  if (!token) token = '';
  return (
    <AutomaticBackground>
      <SignInWidth>
        <Flex noWrap between>
          <h1>Unsubscribe</h1>
          <Tilt className="Tilt" options={{ max: 35, scale: 1.05 }}>
            <div className="Tilt-inner">
              <img style={{ width: '80px' }} src={logo} alt="Logo" />
            </div>
          </Tilt>
        </Flex>
        <Mutation mutation={UNSUBSCRIBE_TOKEN} variables={{ token }}>
          {(unsubscribeUser, { loading, error }) => (
            <StartMutationOnMount unsubscribeUser={unsubscribeUser} loading={loading} error={error}>
              <Notification>
                <li className="green">Awesome, you have been unsubscribed.</li>
              </Notification>
              <Link to={routes.SIGN_IN} style={{ width: '100%' }}>
                <Button>Go to Login</Button>
              </Link>
            </StartMutationOnMount>
          )}
        </Mutation>
      </SignInWidth>
      <Footer />
    </AutomaticBackground>
  );
};

class StartMutationOnMount extends Component {
  componentDidMount() {
    this.props.unsubscribeUser();
  }

  render() {
    const { loading, error } = this.props;
    return (
      <div>
        {loading && <Loading />}
        {error ? (
          <Fragment>
            <Notification>
              <li>The url token does not exist.</li>
            </Notification>
            <Link to={routes.SIGN_IN} style={{ width: '100%' }}>
              <Button>Go to Login</Button>
            </Link>
          </Fragment>
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}

export default withRouter(isLoggedIn(session => session && session.me)(UnsubscribePage));
