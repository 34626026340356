import React from 'react';
import { Query } from '@apollo/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import withAuthorization from '../../Session/withAuthorization';
import { MainWrapper } from '../style';
import { NoResults } from '../../Error/style';
import GenderResult from './genderResult';
import DoubleResult from './doubleResult';
import { PAST_WINNERS_QUERY } from '../queries';
import DashboardLayout from '../../DashboardLayout';
import { master } from '../../../resources/resources';

const WinnersResults = ({ session }) => {
  const genderArray = master.leagueType;

  return (
    <DashboardLayout session={session} classExist={'main-result'} breakExist={true}>
      <MainWrapper>
        <h1>Past Winners</h1>
        <Query query={PAST_WINNERS_QUERY}>
          {({ data, loading }) => {
            if (loading) {
              return <h3>Loading</h3>;
            }
            if (data && data.getWinners && data.getWinners.length > 0) {
              return (
                <div>
                  {genderArray.map((gender, index) => (
                    <GenderResult key={index} genderType={gender} />
                  ))}
                  <DoubleResult />
                </div>
              );
            }
            return (
              <NoResults>
                <div className="no-results-inner">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <p>No results to display.</p>
                </div>
              </NoResults>
            );
          }}
        </Query>
      </MainWrapper>
    </DashboardLayout>
  );
};

export default withAuthorization(session => session && session.me && !session.me.paid)(WinnersResults);
