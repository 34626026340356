import React, { PureComponent } from 'react';

import { NEW_RESULT_SUBSCRIPTION } from '../../../queries';

class NewResultsMobileListener extends PureComponent {
  subscribeToNewResultMobile = () => {
    this.props.subscribeToMore({
      document: NEW_RESULT_SUBSCRIPTION,
      variables: {
        playType: this.props.type,
        leagueId: this.props.leagueId,
        subLeagueId: this.props.getPlayInfo.subLeagueId,
        week: this.props.currentWeek,
        genderType: this.props.getPlayInfo.genderType,
      },
      updateQuery: (previousResult, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousResult;
        }

        // create an exact object of the same shape as the initial query data and merge the previous result with the new result.
        return Object.assign({}, previousResult, {
          // getResultsMobile will return an array as specified in the "scores" schema
          getResultsMobile: [...previousResult.getResultsMobile, subscriptionData.data.newResultSubmission],
        });
      },
    });
  };
  componentDidMount() {
    this.subscribeToNewResultMobile();
  }
  render() {
    return <div></div>;
  }
}

export default NewResultsMobileListener;
