import gql from 'graphql-tag';

// Query the team
export const ALL_DOUBLES_RESULTS_QUERY = gql`
  query($leagueId: ID!, $subLeagueId: ID!) {
    getAllDoublesWeeklyResults(leagueId: $leagueId, subLeagueId: $subLeagueId) {
      id
      week
      score
      loserId
      winnerId
      leagueId
    }
  }
`;
