import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';

import { ToggleLabel } from '../style';
import { Flex, Button } from '../../Universal/style';
import { PAYABLE_SWITCH_MUTATION, PAYABLE_PRICE_MUTATION } from '../queries';

class PayableToggleSwitch extends Component {
  constructor() {
    super();
    this.state = {
      price: 0,
    };
  }

  componentDidMount() {
    this.setState({ price: this.props.getSeasonStatus.price });
  }

  onChange = event => {
    this.setState({ price: event.target.value });
  };

  ToggleCheck = async payableSwitch => {
    await payableSwitch();
    await this.props.refetch();
  };

  onSubmit = async (event, updatePayablePrice) => {
    event.preventDefault();
    await updatePayablePrice();
    await this.props.refetch();
  };

  render() {
    const { getSeasonStatus } = this.props;
    const { price } = this.state;

    return (
      <Flex className="payable-status">
        <div className="payable-column payable-column-1">
          <p>Toggle whether season is payable or not.</p>
          <Mutation mutation={PAYABLE_SWITCH_MUTATION}>
            {payableSwitch => (
              <ToggleLabel>
                <input
                  type="checkbox"
                  defaultChecked={getSeasonStatus.payable}
                  onClick={() => this.ToggleCheck(payableSwitch)}
                />
                <span className="slider round"></span>
              </ToggleLabel>
            )}
          </Mutation>
        </div>
        <div className="payable-column payable-column-2">
          <p>Manage Payable Price Below</p>
          <Mutation mutation={PAYABLE_PRICE_MUTATION} variables={{ price }}>
            {updatePayablePrice => (
              <div className="price-section">
                <strong>Currently set to: ${getSeasonStatus.price}</strong>
                <form onSubmit={event => this.onSubmit(event, updatePayablePrice)}>
                  <input type="number" name="price" value={price} onChange={this.onChange} />
                  <Button normal type="submit">
                    Update
                  </Button>
                </form>
              </div>
            )}
          </Mutation>
        </div>
      </Flex>
    );
  }
}

export default PayableToggleSwitch;
