import React, { Component, Fragment } from 'react';

import withAuthorization from '../../Session/withAuthorization';
import { ProfileForm, HeadingSection } from '../../Account';

class SinglesDash extends Component {
  render() {
    const { session, refetch, leagueId, getPlayInfo } = this.props;
    return (
      <div>
        {session && session.me && (
          <Fragment>
            <HeadingSection session={session} leagueId={leagueId} getPlayInfo={getPlayInfo} />
            <ProfileForm session={session} refetch={refetch} emailSetting={true} />
          </Fragment>
        )}
      </div>
    );
  }
}

export default withAuthorization(
  session => session && session.me && session.me.paid && session.me.playType === 'singles'
)(SinglesDash);
