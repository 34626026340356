import React, { PureComponent } from 'react';
import Hashids from 'hashids';

import GetSeasonFixtureTeam from './getSeasonFixtureTeam';
import { Aside, H5 } from '../style';
import GetSeasonFixtureUsers from '../../../Singles/message/sidebar/getSeasonFixtureUsers';

const hashids = new Hashids('fleximazing', 10);

// Using the PureComponent to eliminate the re-rendering of the component and it's children -> components that are declared inside it as well.
class SideBar extends PureComponent {
  constructor() {
    super();
    this.state = {
      allFixture: [],
    };
  }

  componentDidMount() {
    const { getAllDoublesFixture } = this.props;
    let duplicateItem = [];
    let set = new Set();

    // Creating a hash table to determine if an opponentTeamId is found more then once.
    // If so, then push the ID of that fixture into an array which we will filter out below.
    getAllDoublesFixture.forEach(v => {
      if (set.has(v.opponentTeamId)) {
        duplicateItem.push(v.id);
      } else {
        set.add(v.opponentTeamId);
      }
    });

    // Excluding the fixture Id that has the duplicate opponentTeamId.
    const newArray = getAllDoublesFixture.filter(v => !duplicateItem.includes(v.id));
    this.setState({ allFixture: newArray });
  }

  render() {
    const { currentTeamId, teamOwnerId, session, partnerData } = this.props;
    const { allFixture } = this.state;

    return (
      <Aside className="aside-section">
        {/* Only owner of team can message other teams */}
        {session.me.id === teamOwnerId && (
          <div className="opponent-section">
            <h5>Opponents</h5>
            {/* Loop through the "getAllFixture" array to get each players opponentTeamId */}
            {allFixture.map(fixture => {
              const { opponentTeamId, id } = fixture;
              const teamEncoded = hashids.encode(opponentTeamId);
              const chatLink = `/doubles/redirect/${teamEncoded}`;
              return (
                <GetSeasonFixtureTeam
                  key={id}
                  opponentTeamId={opponentTeamId}
                  chatLink={chatLink}
                  currentTeamId={currentTeamId}
                  teamEncoded={teamEncoded}
                />
              );
            })}
          </div>
        )}
        <div className="partner-section">
          {/* No margin top for partners */}
          <H5 noMarginTop={session.me.id !== teamOwnerId}>Your partner</H5>
          {partnerData && partnerData.getPartnerInfo ? (
            <PartnerSidebar getPartnerInfo={partnerData.getPartnerInfo} session={session} />
          ) : (
            <p style={{ padding: '0 12px' }}>Partner is not yet active.</p>
          )}
        </div>
      </Aside>
    );
  }
}

// For the partner chat section. This won't display if partner is not found.
const PartnerSidebar = ({ getPartnerInfo, session }) => {
  const partnerEncoded = hashids.encode(getPartnerInfo.id);
  const partnerChatLink = `/doubles/partner/redirect/${partnerEncoded}`;
  return (
    <GetSeasonFixtureUsers
      opponentId={getPartnerInfo.id}
      chatLink={partnerChatLink}
      session={session}
      userEncoded={partnerEncoded}
    />
  );
};

export default SideBar;
