import styled, { theme, css } from '../../../theme';
import backImage from '../../../resources/images/singles-bw.jpg';
import backImage2 from '../../../resources/images/doubles-bw.jpg';
import { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  a {
    padding: 10px 16px 0;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: capitalize;
    font-family: 'roboto-condensed';
    font-size: 16px;
    color: ${props =>
      props.genderType === 'men'
        ? theme.men.link
        : props.genderType === 'women'
        ? theme.women.link
        : props.genderType === 'mixed'
        ? theme.mixed.link
        : theme.doubles.link};
  }
  a:hover,
  a:focus {
    color: ${props =>
      props.genderType === 'men'
        ? theme.men.linkHover
        : props.genderType === 'women'
        ? theme.women.linkHover
        : props.genderType === 'mixed'
        ? theme.mixed.linkHover
        : theme.doubles.linkHover};
  }
`;
export const Table = styled.div`
  display: table;
  width: 100%;

  .table-row {
    display: table-row;
  }
  .table-cell {
    display: table-cell;
  }
  .table-row > .table-cell:first-child {
    padding-left: 16px;
  }
  .table-row > .table-cell {
    padding-right: 10px;
  }
  .table-row > .table-cell:last-child {
    padding-right: 16px;
  }
  .table-row:first-child .table-cell {
    padding-bottom: 10px;
    border-bottom: 1px solid ${theme.breakLine};
  }
  .table-row:nth-child(n + 2) > .table-cell {
    padding-top: 2px;
    padding-bottom: 2px;
    vertical-align: middle;
  }
  .table-row:nth-child(n + 2) > .table-cell:last-child {
    text-align: center;
  }
  .table-row:nth-child(2) .table-cell {
    padding-top: 13px;
  }
  .table-row:nth-child(odd) {
    background-color: ${theme.grayBack};
  }
  .table-cell .image-overflow {
    margin-right: 9px;
    width: 25px;
    height: 25px;
  }
`;

const LightShine = keyframes`
  to {
    opacity: 1;
    left: 1400%;
  }
`;

export const NormalSideBar = styled.div`
  .box-wrapper {
    position: relative;
    padding-bottom: 35px;
    margin-bottom: 30px;
    border-bottom: 1px solid ${theme.breakLine};
  }
  .light-reflection {
    position: relative;
    overflow: hidden;
    padding-bottom: 0;
    z-index: 0;

    a {
      width: 100%;

      .lifetime-button {
        position: relative;
        text-align: center;
        padding: 6px;
        margin: 0;
        text-transform: capitalize;
        background-color: darkorange;
        color: white;
        font-family: 'calibri','open sans';
        font-size: 16px;
        z-index: 2;

        :hover {
          opacity: 0.8;
        }
      }
    }
  }
  .light-reflection:before {
    position: absolute;
    content: '';
    transform: rotate(30deg);
    opacity: 1;
    top: 0;
    left: -80%;
    /* height: 230%; */
    height: 100%;
    width: 100%;
    background: rgba(220, 220, 220, 0.13);
    background: linear-gradient( 2deg,rgba(255,255,208,0.13) 0%,rgba(220,220,220,0.13) 33%,rgba(220,220,220,0.5) 73%,rgba(220,220,220,0) 100%);
    animation: ${LightShine} 10s infinite;
    z-index: 1;
  }
  .past-results-separator {
    padding: 0px 12px;
    margin-bottom: 20px;
    padding-bottom: 10px;

    :last-child {
      margin-bottom: 6px;
      border-bottom: none;
      padding-bottom: 0;
    }
    a {
      display: block;
    }
    p {
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
      padding: 15px;
      border-radius: 3px;
      color: white;
      background-image: url('${backImage}');
      background-size: cover;
      background-position: center;
      text-transform: capitalize;
      z-index: 1;

      :before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 3px;
        z-index: -1;
      }
    }
    p.back2 {
      background-image: url('${backImage2}');
      background-position: bottom;
    }
    .lifetime-result {
      letter-spacing: 2px;
      font-family: 'athletic';
      background-image: none;
      color: darkorange;
      font-size: 28px;
      padding: 0;
      text-align: center;
    }
    .both:before {
      background: linear-gradient(to left, rgba(0,128,167,0.7), rgba(8, 99, 66, 0.7));
    }
    .singles:before {
      background-color: rgba(0,128,167,0.8);
    }
    .doubles:before {
      background-color: rgba(8, 99, 66, 0.8);
    }
    .no-results:before {
      background-color: rgba(88,88,88,0.65);
    }
    .hover:hover {
      opacity: 0.8;
    }
  }
  .quote-section {
    background-color: white;
    padding: 20px 15px;
    border-radius: 5px;
    border: 2px dashed #ffa700;

    h5 {
      font-size: 14px;
      margin: 0;
      color: #ffa700;
    }
    p {
      :last-child {
        margin-bottom: 0;
        font-weight: 600;
      }
      .quote {
        font-style: italic;
      }
    }
  }
`;

export const HeadingWithTooltip = styled.div`
  .heading-tooltip {
    display: flex;
    align-items: center;
  }
  .question-icon {
    margin-top: 5px;
    margin-left: 10px;
    cursor: pointer;
    color: ${props =>
      props.type === 'doubles'
        ? theme.doubles.link
        : props.gender === 'women'
        ? theme.women.link
        : props.gender === 'men'
        ? theme.men.link
        : theme.mixed.link};
    font-size: 20px;

    :hover {
      opacity: 0.8;
    }
  }
  .tooltip {
    position: absolute;
    top: 60px;
    z-index: 2;
    background-color: ${props =>
      props.type === 'doubles'
        ? theme.doubles.link
        : props.gender === 'women'
        ? theme.women.link
        : props.gender === 'men'
        ? theme.men.link
        : theme.mixed.link};
    border-radius: 4px;
    text-align: center;
    padding: 11px 8px;

    p {
      margin: 0;
      color: white;
    }
    :before {
      content: '';
      position: absolute;
      top: -10px;
      left: 30px;
      width: 0px;
      height: 0px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid
        ${props =>
          props.type === 'doubles'
            ? theme.doubles.link
            : props.gender === 'women'
            ? theme.women.link
            : props.gender === 'men'
            ? theme.men.link
            : theme.mixed.link};
    }
  }

  ${props =>
    props.heading &&
    css`
      position: relative;

      .question-icon {
        margin-top: 0;
      }
      .tooltip {
        top: 70px;
        background-color: ${props =>
          props.type === 'doubles'
            ? theme.doubles.link
            : props.gender === 'women'
            ? theme.women.link
            : props.gender === 'men'
            ? theme.men.link
            : theme.mixed.link};

        p {
          width: 320px;
          max-width: 100%;
        }
      }

      @media (max-width: 1066px) {
        .tooltip.tooltip-with-heading:before {
          left: 186px !important;
        }
      }
      @media (max-width: 1202px) {
        .tooltip {
          right: auto !important;
        }
      }
      @media (max-width: 579px) {
        .tooltip.tooltip-with-heading {
          top: 60px;
          max-width: 100%;

          :before {
            left: 153px !important;
          }
        }
      }
    `};
`;
