import React, { Component, Fragment } from 'react';
import { Query } from '@apollo/react-components';
import 'element-scroll-polyfill';

import { Adjust, Flex, P, H2, H3 } from '../../../Universal/style';
import { ScoresPendingWrapper, PendingBox } from './style';
import { OPPONENT_QUERY } from '../../../Universal/queries';
import { ScrollIndicator } from '../style';

// This is to display the pending score for approval once the user sends it to the opponent.
class PendingScore extends Component {
  // For the scroll handling -- START
  constructor() {
    super();
    this.state = {
      hasScroll: false,
      topPosition: 0,
      leftPosition: 0,
      indicatorHeight: 0,
      arrowBorder: 0,
    };
  }

  scrollElement = element => {
    if (element) {
      this.element = element;
      const clientWidth = element.clientWidth;
      const scrollWidth = element.scrollWidth;
      const topPosition = element.clientHeight / 2;

      if (scrollWidth > clientWidth) {
        this.setState({
          hasScroll: true,
          topPosition,
          indicatorHeight: element.clientHeight / 3,
          arrowBorder: element.clientHeight / 27,
        });
      }
    }
  };

  handleOnScroll = event => {
    const { clientWidth, scrollLeft, scrollWidth } = event.target;

    this.setState({ leftPosition: scrollLeft });
    if (scrollLeft === scrollWidth - clientWidth) {
      this.setState({ hasScroll: false });
    } else {
      this.setState({ hasScroll: true });
    }
  };

  handleRightShift = () => {
    const scrollWidth = this.element.scrollWidth;
    this.element.scrollTo(scrollWidth, 0);
    this.setState({ hasScroll: false });
  };
  // For the scroll handling -- END

  render() {
    // For scroll handling
    const { hasScroll, topPosition, leftPosition, indicatorHeight, arrowBorder } = this.state;
    const halfHeight = indicatorHeight / 2;

    const { getScoreApproval, opponentFirstname, opponentLastname } = this.props;

    const { score, winnerId } = getScoreApproval[0];
    const scoreArray = JSON.parse(score);
    const reverseScoreArray = scoreArray.map(theScore => theScore.reverse());

    return (
      <Fragment>
        <div>
          <P color={'black'} fontsize={'18px'}>
            You have submitted the following scores to <span className="red">{opponentFirstname}</span>{' '}
            <span className="red">{opponentLastname}</span> for approval.
          </P>
          <P>
            Scores will either be accepted or denied by your opponent. Email notifications will be sent for any updates.
          </P>
        </div>
        <ScoresPendingWrapper borderColor={'crimson'} style={{ display: 'inline-grid' }}>
          <div
            style={{ overflowX: 'scroll', position: 'relative' }}
            ref={this.scrollElement}
            onScroll={this.handleOnScroll}
          >
            <Flex MT={'30px'} alignCenter noWrap>
              <Flex style={{ marginRight: '10px', width: '300px' }} grow>
                <H2 width={'100%'} margin={'0 0 10px 0'} color={'crimson'}>
                  Winning Player:
                </H2>
                <Query query={OPPONENT_QUERY} variables={{ id: +winnerId }}>
                  {({ data, loading }) => {
                    if (loading) {
                      return <p>Loading...</p>;
                    }
                    if (data && data.user) {
                      const { firstname, lastname } = data.user;
                      return (
                        <H3 margin={'0'}>
                          <div className="gray">
                            {firstname} {lastname}
                          </div>
                        </H3>
                      );
                    }
                    return '';
                  }}
                </Query>
              </Flex>
              <Flex center alignCenter noWrap>
                {reverseScoreArray.map((eachScore, i) => (
                  <Adjust key={i} MR={'5px'}>
                    <PendingBox MB={'5px'} border={'crimson'}>
                      {eachScore[0]}
                    </PendingBox>
                    <PendingBox border={'crimson'}>{eachScore[1]}</PendingBox>
                  </Adjust>
                ))}
              </Flex>
            </Flex>
            {hasScroll && (
              <ScrollIndicator
                top={topPosition - halfHeight}
                right={leftPosition}
                height={indicatorHeight}
                arrowBorder={arrowBorder}
                onClick={() => this.handleRightShift()}
              >
                <div className="scroll-indicator"></div>
              </ScrollIndicator>
            )}
          </div>
        </ScoresPendingWrapper>
      </Fragment>
    );
  }
}

export default PendingScore;
