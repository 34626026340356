import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';

import { sanitizeInput } from '../../../abstracts/helpers';

import { Button } from '../../Universal/style';
import { REMOVE_MEMBER_ACCESS, GET_USER_PLAY_INFO } from './queries';

class RemoveAccess extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      type: 'select-option',
    };
  }

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onSubmit = async (event, removeMemberAccess) => {
    event.preventDefault();
    await removeMemberAccess();
    this.setState({ email: '' });
  };

  render() {
    const { type } = this.state;
    let { email } = this.state;
    const isInvalid = email === '' || type === 'select-option';

    // Sanitize inputs
    email = sanitizeInput({ string: email, type: 'email' });

    return (
      <Mutation
        mutation={REMOVE_MEMBER_ACCESS}
        variables={{ email, type }}
        refetchQueries={() => [{ query: GET_USER_PLAY_INFO, variables: { email } }]}
      >
        {removeMemberAccess => (
          <form onSubmit={event => this.onSubmit(event, removeMemberAccess)}>
            <div className="heading">
              <FontAwesomeIcon icon={faShieldAlt} /> <h3>Remove Membership</h3>
            </div>
            <div className="form-content">
              <div className="field">
                <input
                  style={{ marginBottom: '7px' }}
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  placeholder="Input email here"
                />
                <select name="type" value={type} onChange={this.onChange}>
                  <option disabled value="select-option">
                    Select Option
                  </option>
                  <option value="singles">Singles</option>
                  <option value="doubles">Doubles</option>
                </select>
                <Button normal type="submit" disabled={isInvalid}>
                  Remove
                </Button>
              </div>
            </div>
          </form>
        )}
      </Mutation>
    );
  }
}

export default RemoveAccess;
