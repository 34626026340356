import styled, { theme, css } from '../../theme';

export const Ul = styled.ul`
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;

  @media (max-width: 579px) {
    width: 100%;
  }
`;

export const MenuItemLi = styled.li`
  position: relative;

  a {
    padding: 15px 25px;
    text-align: center;
    ${props =>
      props.color &&
      css`
        color: ${props.color};
      `};

    &:hover,
    &:focus {
      background-color: #484848;
      ${props =>
        props.color &&
        css`
          background-color: ${props.color};
        `};
      color: white;
    }

    &.active {
      background-color: ${theme.text};
    }
    &.active-singles-men {
      background-color: ${theme.men.link};
    }
    &.active-singles-women {
      background-color: ${theme.women.link};
    }
    &.active-singles-mixed {
      background-color: ${theme.mixed.link};
    }
    &.active-doubles {
      background-color: ${theme.doubles.link};
    }
    &.active .text {
      color: white;
    }
  }
`;
