import React, { PureComponent, Fragment } from 'react';
import { Query, Mutation } from '@apollo/react-components';
import { NavLink, withRouter } from 'react-router-dom';

import { H4, Status } from '../style';
import { NEW_OPPONENT_TEAM_MESSAGE, REMOVE_TEAM_NOTIFICATION } from '../queries';
import { NEW_DOUBLES_MESSAGES_QUERY } from '../../../Navigation/queries';
import { OPPONENT_QUERY } from '../../../Universal/queries';
import { OnlineChecker } from '../../../Singles/message/sidebar/getSeasonFixtureUsers';
import { GET_OTHER_TEAM } from '../../fixtures/queries';
import GetNewNotificationsWithRouter from './notification';
import NewMessageSubscription from './newMessageSubscription';

class GetSeasonFixtureTeam extends PureComponent {
  render() {
    const { opponentTeamId, chatLink, currentTeamId, teamEncoded } = this.props;
    return (
      // This Query will get all the opponent team messages for the same channel.
      // The variable "opponentTeamId" will search in the TeamMessage table for "teamId" that matches the "opponentTeamId" and the "opponentTeamId" as the "teamId".
      // This will return all message that the opponent team writes to the current team.
      <Query query={NEW_OPPONENT_TEAM_MESSAGE} variables={{ opponentTeamId, teamId: currentTeamId }}>
        {({ data, loading, refetch, subscribeToMore }) => {
          const newOpponentMessageRefetch = refetch;
          const newMessageSubscribe = subscribeToMore;

          if (loading) {
            return '';
          }
          if (data && data.getOpponentTeamMessage) {
            const { getOpponentTeamMessage } = data;

            return (
              // This Query will query the current "opponentId" and return their data.
              <Query query={GET_OTHER_TEAM} variables={{ id: opponentTeamId }}>
                {({ data, loading }) => {
                  if (loading) {
                    return <p>Loading...</p>;
                  }
                  if (data && data.getOtherTeam) {
                    const { getOtherTeam } = data;

                    return (
                      // This opponent query will just query the ownerId from the GET_OTHER_TEAM query and use it to determine if the owner is online or not.
                      <Query query={OPPONENT_QUERY} variables={{ id: getOtherTeam.ownerId }}>
                        {({ data, loading, refetch, subscribeToMore }) => {
                          if (loading) {
                            return <p>Loading...</p>;
                          }

                          if (data && data.user) {
                            const { user } = data;

                            return (
                              <NavLink style={{ position: 'relative' }} to={chatLink} activeClassName="active-link">
                                {/* Checking if the user is online or not */}
                                {user.online ? (
                                  <Status className="status" online></Status>
                                ) : (
                                  <Status className="status" offline></Status>
                                )}
                                <DetermineActiveTeamWithRouter getOtherTeam={getOtherTeam} teamEncoded={teamEncoded} />
                                <OnlineChecker
                                  subscribeToMore={subscribeToMore}
                                  user={user.id}
                                  userData={user}
                                  updatedUserRefetch={refetch}
                                />

                                {/* If the opponent has send a new message then show an alert (notification) */}
                                {getOpponentTeamMessage && getOpponentTeamMessage.length > 0 && (
                                  <Mutation
                                    mutation={REMOVE_TEAM_NOTIFICATION}
                                    variables={{ opponentTeamId, teamId: currentTeamId }}
                                    // When the "remove notification" resolver executes, then we need to refetch the opponentMessages to show updated notifcation field from TRUE to FALSE.
                                    refetchQueries={() => {
                                      return [
                                        {
                                          query: NEW_DOUBLES_MESSAGES_QUERY,
                                        },
                                      ];
                                    }}
                                  >
                                    {removeTeamNotification => (
                                      <Fragment>
                                        <GetNewNotificationsWithRouter
                                          newOpponentMessageRefetch={newOpponentMessageRefetch}
                                          getOpponentTeamMessage={getOpponentTeamMessage}
                                          chatLink={chatLink}
                                          removeTeamNotification={removeTeamNotification}
                                        />
                                      </Fragment>
                                    )}
                                  </Mutation>
                                )}

                                <NewMessageSubscription
                                  subscribeToMore={newMessageSubscribe}
                                  newOpponentMessageRefetch={newOpponentMessageRefetch}
                                  opponentTeamId={opponentTeamId}
                                  currentTeamId={currentTeamId}
                                />
                              </NavLink>
                            );
                          }
                          return '';
                        }}
                      </Query>
                    );
                  } else {
                    return '';
                  }
                }}
              </Query>
            );
          } else {
            return '';
          }
        }}
      </Query>
    );
  }
}

const DetermineActiveTeam = ({ location, getOtherTeam, teamEncoded }) => {
  const { pathname } = location;
  return <H4 orange={pathname === `/doubles/message/team/${teamEncoded}`}>{getOtherTeam.name}</H4>;
};
const DetermineActiveTeamWithRouter = withRouter(DetermineActiveTeam);

export default GetSeasonFixtureTeam;
