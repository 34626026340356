import React, { PureComponent } from 'react';
import { NEW_OPPONENT_MESSAGE_SUBSCRIPTION } from '../../../Universal/queries';

// This component will determine if there are any NEW opponent messages for each opponent.
class NewMessageSubscription extends PureComponent {
  subscribeToNotifications = () => {
    this.props.subscribeToMore({
      document: NEW_OPPONENT_MESSAGE_SUBSCRIPTION,
      variables: {
        opponentId: this.props.opponentId,
        userId: this.props.userId,
      },
      updateQuery: (previousResult, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousResult;
        }

        return Object.assign({}, previousResult, {
          getOpponentMessage: [...previousResult.getOpponentMessage, subscriptionData.data.newOpponentMessage],
        });
      },
    });
  };

  componentDidMount() {
    this.subscribeToNotifications();
    this.props.newOpponentMessageRefetch();
  }

  render() {
    return <div></div>;
  }
}

export default NewMessageSubscription;
