import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import * as routes from '../../../constants/routes';

import { H3, P, Adjust, Break } from '../../Universal/style';
import { ProgressWrapper, Progress, Text, CountDown } from '../style';

// Header progress bar
class ProgressBar extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      closed: false,
      progressWidth: '',
      countdown: false,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  determineWeek = (session, currentWeek, redirectDate, adminRedirect, endDate) => {
    if (currentWeek === 0) {
      if (session.me.role === 'ADMIN') {
        this.interval = setInterval(() => this.getTimeUntil(adminRedirect, 'beforeSeason'), 1000);
      } else {
        this.interval = setInterval(() => this.getTimeUntil(redirectDate, 'beforeSeason'), 1000);
      }
    } else if (currentWeek === 1) {
      this.setState({ loading: false, closed: true, progressWidth: '5' });
    } else if (currentWeek === 2) {
      this.setState({ loading: false, closed: true, progressWidth: '15' });
    } else if (currentWeek === 3) {
      this.setState({ loading: false, closed: true, progressWidth: '25' });
    } else if (currentWeek === 4) {
      this.setState({ loading: false, closed: true, progressWidth: '35' });
    } else if (currentWeek === 5) {
      this.setState({ loading: false, closed: true, progressWidth: '45' });
    } else if (currentWeek === 6) {
      this.setState({ loading: false, closed: true, progressWidth: '55' });
    } else if (currentWeek === 7) {
      this.setState({ loading: false, closed: true, progressWidth: '65' });
    } else if (currentWeek === 8) {
      this.setState({ loading: false, closed: true, progressWidth: '75' });
    } else if (currentWeek === 9) {
      this.setState({ loading: false, closed: true, progressWidth: '85' });
    } else if (currentWeek === 10) {
      this.setState({ closed: true, countdown: true, progressWidth: '100' });
      this.interval = setInterval(() => this.getTimeUntil(endDate, 'redirect'), 1000);
    }
  };

  componentDidMount() {
    const { session, currentWeek, redirectDate, adminRedirect, endDate } = this.props;
    this.determineWeek(session, currentWeek, redirectDate, adminRedirect, endDate);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentWeek !== this.props.currentWeek) {
      const { session, currentWeek, redirectDate, adminRedirect, endDate } = this.props;
      this.determineWeek(session, currentWeek, redirectDate, adminRedirect, endDate);
    }
  }

  // This will create a timer countdown to notify users about the deadline of the end of season.
  // Redirect to homepage on deadline.
  getTimeUntil = async (deadline, type) => {
    const { removeMembership, refetch } = this.props;
    let deadlineNew = new Date(deadline).getTime();
    let now = new Date().getTime();
    let distance = deadlineNew - now;

    if (distance <= 0) {
      // Redirect to the homepage
      if (type === 'redirect') {
        // Resolver to set the paid to false for all users as well as null for playType
        await removeMembership();
        await refetch();
        this.props.history.push(routes.LANDING);
      } else {
        // NOT using the standard "react router dom" history.push because it won't update the logic in the "SinglesMasterInner" component above when the season time has reached.
        // Need to use the JS window.location.href so that it also REFRESHES the page and not use the cache content.
        if (this.props.type === 'singles') {
          window.location.href = `${window.location.origin}/singles/fixtures/`;
        } else {
          window.location.href = `${window.location.origin}/doubles/fixtures/`;
        }
      }
    } else {
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
      let minutes = Math.floor((distance / (1000 * 60)) % 60);
      let seconds = Math.floor((distance / 1000) % 60);
      this.setState({ days, hours, minutes, seconds, loading: false });
    }
  };

  // Must clear interval on componentWillUnmount so that we don't get an error with the setState before component mounts.
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { loading, closed, progressWidth, countdown, days, hours, minutes, seconds } = this.state;
    const { type, currentWeek, genderType } = this.props;

    return (
      <div>
        {loading ? (
          <h3>Loading...</h3>
        ) : !closed ? (
          <Adjust center MB={'35px'} MT={'35px'}>
            <h5 style={{ marginBottom: '0', color: 'black' }}>Season starts in</h5>
            <CountdownTimer days={days} hours={hours} minutes={minutes} seconds={seconds} />
          </Adjust>
        ) : (
          <Fragment>
            <Adjust MB={'30px'}>
              <H3 MB={'10px'} className="progress-bar-heading">
                Season Progress
              </H3>
              <ProgressWrapper type={type} genderType={genderType}>
                <Progress type={type} genderType={genderType} width={progressWidth}>
                  <Text className="the-week-progress-bar">
                    {currentWeek === 10 ? 'Final Week' : `Week ${currentWeek}`}
                  </Text>
                </Progress>
              </ProgressWrapper>
            </Adjust>
            {!countdown && <Break MB={'60px'}></Break>}
            {countdown && (
              <Adjust center MB={'40px'}>
                <h5 style={{ marginBottom: '0', color: 'black' }}>Season ends in</h5>
                <CountdownTimer days={days} hours={hours} minutes={minutes} seconds={seconds} />
                <P color={'dimgray'} MT={'3px'}>
                  At completion, you will be redirected to the Register page
                </P>
              </Adjust>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}
// Since we are using the "history" prop to redirect URL to register page in the "getTimeUntil" function, we need withRouter to access it.
const ProgressBarWithRouter = withRouter(ProgressBar);

const CountdownTimer = ({ days, hours, minutes, seconds }) => (
  <CountDown>
    {days} {days < 2 ? 'day' : 'days'} | {hours} {hours < 2 ? 'hour' : 'hours'} | {minutes}{' '}
    {minutes < 2 ? 'minute' : 'minutes'} | {seconds} {seconds < 2 ? 'second' : 'seconds'}
  </CountDown>
);

export { ProgressBar };
export default ProgressBarWithRouter;
