import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Query } from '@apollo/react-components';
import { CSVLink } from 'react-csv';

import * as routes from '../../../constants/routes';
import { BackLink, Select, Button } from '../../Universal/style';
import withAuthorization from '../../Session/withAuthorization';
import { Wrapper } from './style';
import { VIEW_LEAGUE_REGISTRATION } from './queries';
import { master } from '../../../resources/resources';

class LeagueRegistration extends Component {
  constructor() {
    super();
    this.state = {
      registeredStatus: 'select-option',
      location: 'select-option',
      skill: 'select-option',
      playedPastSeason: false,

      registeredStatusSubmit: '',
      leagueSubmit: '',
      skillSubmit: '',
      playedPastSeasonSubmit: false,
    };
  }

  onChange = event => {
    const { name, value } = event.target;

    if (name === 'location') {
      this.setState({ league: 'select-option' });
    }
    this.setState({ [name]: value });
  };

  handlePastSeason = () => {
    this.setState(prev => ({ playedPastSeason: !prev.playedPastSeason }));
  };

  setStateAsync = state => {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { registeredStatus, league, skill, playedPastSeason } = this.state;
    let hasError = false;

    // Handling validation
    if (registeredStatus === 'registered') {
      if (league === 'select-option' || skill === 'select-option') {
        hasError = true;
      }
    }
    if (registeredStatus === 'select-option') {
      hasError = true;
    }

    if (!hasError) {
      if (registeredStatus === 'unregistered') {
        // If unregistered, the location and skill values should not be populated.
        this.setStateAsync({
          registeredStatusSubmit: registeredStatus,
          leagueSubmit: '',
          skillSubmit: '',
          playedPastSeasonSubmit: playedPastSeason,
        });
      } else if (registeredStatus === 'registered') {
        this.setStateAsync({
          registeredStatusSubmit: registeredStatus,
          leagueSubmit: league,
          skillSubmit: skill,
          playedPastSeasonSubmit: playedPastSeason,
        });
      }
    }
  };

  render() {
    const { registeredStatus, location, league, skill, playedPastSeason } = this.state;
    const { registeredStatusSubmit, leagueSubmit, skillSubmit, playedPastSeasonSubmit } = this.state;

    const showLocationSkill = registeredStatus === 'registered';

    const locationsArray = master.locations;
    const regionsArray = master.regions;
    const skillsArray = master.skills;

    const showRegions = location !== 'select-option';
    const filteredRegion = regionsArray.filter(region => region.location === location);

    const isInvalid =
      registeredStatus === 'select-option' ||
      (registeredStatus === 'registered' && (league === 'select-option' || skill === 'select-option'));

    return (
      <Fragment>
        <BackLink>
          <Link to={routes.ADMIN}>
            <FontAwesomeIcon icon={faArrowCircleLeft} /> Back to Admin Page
          </Link>
        </BackLink>

        <Wrapper>
          <h2>League Registrations</h2>

          <p>Select options to view league registrations.</p>

          <div className="main-wrapper">
            <div className="filter-wrapper">
              <form onSubmit={event => this.handleSubmit(event)}>
                {/* Select fields for registered / unregistered players */}
                <div className="field">
                  <label>Registered/unregistered</label>
                  <Select name="registeredStatus" value={registeredStatus} onChange={this.onChange}>
                    <option disabled value="select-option">
                      Select option
                    </option>
                    <option value="unregistered">Unregistered</option>
                    <option value="registered">Registered</option>
                  </Select>
                </div>

                {/* If registered is chosen then show the location and skill fields */}
                {showLocationSkill && (
                  <Fragment>
                    {/* For the location field */}
                    <div className="field">
                      <label>Location</label>
                      <Select name="location" value={location} onChange={this.onChange}>
                        <option disabled value="select-option">
                          Select Option
                        </option>
                        {locationsArray.map(aLocation => {
                          const joined = aLocation.replace(/\s+/g, '-').toLowerCase();
                          return (
                            <option key={joined} value={aLocation}>
                              {aLocation}
                            </option>
                          );
                        })}
                      </Select>
                    </div>

                    {/* For the region selection -> only activate/show if a "location" field is chosen. */}
                    {/* For the location fields */}
                    {showRegions && (
                      <div className="field">
                        <label>Region</label>
                        <Select name="league" value={league} onChange={this.onChange}>
                          <option disabled value="select-option">
                            Select Option
                          </option>
                          {filteredRegion[0].inner.map(region => {
                            const joined = region.replace(/\s+/g, '-').toLowerCase();
                            return (
                              <option key={joined} value={region}>
                                {region}
                              </option>
                            );
                          })}
                        </Select>
                      </div>
                    )}

                    {/* For the skill level field */}
                    <div className="field">
                      <label>Skill Level</label>
                      <Select name="skill" value={skill} onChange={this.onChange}>
                        <option disabled value="select-option">
                          Select Option
                        </option>
                        {skillsArray.map(aSkill => {
                          const skillName = aSkill.name;
                          const joined = skillName.replace(/\s+/g, '-').toLowerCase();
                          return (
                            <option key={joined} value={skillName}>
                              {skillName}
                            </option>
                          );
                        })}
                      </Select>
                    </div>
                  </Fragment>
                )}

                {/* Check if players have played last season */}
                <div className="field filter-last-season">
                  <label>Filter last season</label>
                  <input
                    type="checkbox"
                    name="playedPastSeason"
                    defaultChecked={playedPastSeason}
                    onClick={() => this.handlePastSeason()}
                  />
                </div>

                {/* The submit button */}
                <Button normal disabled={isInvalid}>
                  Filter
                </Button>
              </form>
            </div>

            {/* This is the main wrapper for the query */}
            <div className="query-wrapper">
              <Query
                query={VIEW_LEAGUE_REGISTRATION}
                variables={{
                  registeredStatus: registeredStatusSubmit,
                  location: leagueSubmit,
                  skill: skillSubmit,
                  playedPastSeason: playedPastSeasonSubmit,
                }}
              >
                {({ data, loading }) => (
                  <Fragment>
                    {loading && <p>Loading data...</p>}
                    {data && data.checkLeagueRegistration && (
                      <DisplayLeagueRegistrations checkLeagueRegistration={data.checkLeagueRegistration} />
                    )}
                  </Fragment>
                )}
              </Query>
            </div>
          </div>
        </Wrapper>
      </Fragment>
    );
  }
}

// Display data if available from the filter chosen
const DisplayLeagueRegistrations = ({ checkLeagueRegistration }) => {
  // Headers set for the CSV download -> used to map the headings in the spreadsheet.
  const headers = [
    { label: 'First Name', key: 'firstname' },
    { label: 'Last Name', key: 'lastname' },
    { label: 'Email', key: 'email' },
  ];

  return (
    <div id="display-main">
      {checkLeagueRegistration.length > 0 ? (
        <Fragment>
          <div className="csv-download">
            <CSVLink
              data={checkLeagueRegistration}
              headers={headers}
              filename={'ftl-registration-list.csv'}
              className="csv-button"
              target="_blank"
            >
              Download CSV File
            </CSVLink>
          </div>
          <div className="table">
            <div className="table-row heading">
              <div className="table-cell">
                <p>Firstname</p>
              </div>
              <div className="table-cell">
                <p>Lastname</p>
              </div>
              <div className="table-cell">
                <p>Email</p>
              </div>
            </div>
            {checkLeagueRegistration.map((player, index) => (
              <div key={index} className="table-row">
                <div className="table-cell">
                  <p>{player.firstname}</p>
                </div>
                <div className="table-cell">
                  <p>{player.lastname}</p>
                </div>
                <div className="table-cell">
                  <p>{player.email}</p>
                </div>
              </div>
            ))}
          </div>
        </Fragment>
      ) : (
        <p className="no-result">No players to display</p>
      )}
    </div>
  );
};

export default withAuthorization(session => session && session.me && session.me.role === 'ADMIN')(LeagueRegistration);
