import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import 'element-scroll-polyfill';

import * as routes from '../../../constants/routes';

import FixtureProfile from './fixtureProfile';
import FixtureUpload from './fixtureUpload';
import DisplayFixture from './fixtureTable';
import { NoResults } from '../../Error/style';
import { ScrollIndicator } from '../../Singles/fixtures/style';
import TooltipSection from '../../Account/tooltip';

class DoublesFixtures extends Component {
  render() {
    const {
      session,
      type,
      year,
      currentSeason,
      currentSeasonDate,
      fixtureData,
      fixtureLoading,
      refetchFixtureQuery,
      currentWeek,
      getTeam,
      currentTeamId,
      teamOwnerId,
      nextSeasonDate,
      getSeasonStatus,
    } = this.props;

    return (
      <div className="fixture-wrapper">
        {session && session.me && getSeasonStatus.length > 0 && (
          <Fragment>
            <Switch>
              <Route
                exact
                path={routes.DOUBLES_FIXTURES}
                render={() => (
                  <FixtureMain
                    fixtureData={fixtureData}
                    loading={fixtureLoading}
                    currentSeason={currentSeason}
                    currentSeasonDate={currentSeasonDate}
                    type={type}
                    year={year}
                    getTeam={getTeam}
                    currentTeamId={currentTeamId}
                    session={session}
                    teamOwnerId={teamOwnerId}
                    currentWeek={currentWeek}
                    getSeasonStatus={getSeasonStatus[0]}
                  />
                )}
              />
              <Route
                exact
                path={routes.DOUBLES_PROFILES}
                render={() => <FixtureProfile fixtureData={fixtureData} session={session} teamOwnerId={teamOwnerId} />}
              />
              <Route
                exact
                path={routes.DOUBLES_UPLOAD}
                render={() => (
                  <FixtureUpload
                    fixtureData={fixtureData}
                    refetchFixtureQuery={refetchFixtureQuery}
                    currentWeek={currentWeek}
                    getTeam={getTeam}
                    session={session}
                    teamOwnerId={teamOwnerId}
                    nextSeasonDate={nextSeasonDate}
                  />
                )}
              />
              <Redirect to={routes.DOUBLES_FIXTURES} />
            </Switch>
          </Fragment>
        )}
      </div>
    );
  }
}

// This is the MAIN fixtures page. If there is data from the "generateFixture" resolver, then display the main content. If not, then display other message.
class FixtureMain extends Component {
  constructor() {
    super();
    this.state = {
      hasScroll: false,
      topPosition: 0,
      leftPosition: 0,
      indicatorHeight: 0,
      arrowBorder: 0,

      tooltip: false,
    };
  }

  handleTooltip = () => {
    this.setState(prev => ({ tooltip: !prev.tooltip }));
  };

  scrollElement = element => {
    if (element) {
      this.element = element;
      const clientWidth = element.clientWidth;
      const scrollWidth = element.scrollWidth;
      const topPosition = element.clientHeight / 2;

      if (scrollWidth > clientWidth) {
        this.setState({
          hasScroll: true,
          topPosition,
          indicatorHeight: element.clientHeight / 3,
          arrowBorder: element.clientHeight / 27,
        });
      }
    }
  };

  handleOnScroll = event => {
    const { clientWidth, scrollLeft, scrollWidth } = event.target;
    this.setState({ leftPosition: scrollLeft });
    if (scrollLeft === scrollWidth - clientWidth) {
      this.setState({ hasScroll: false });
    } else {
      this.setState({ hasScroll: true });
    }
  };

  handleRightShift = () => {
    const scrollWidth = this.element.scrollWidth;
    this.element.scrollTo(scrollWidth, 0);
    this.setState({ hasScroll: false });
  };

  render() {
    const {
      fixtureData,
      loading,
      currentSeasonDate,
      currentSeason,
      type,
      year,
      getTeam,
      currentTeamId,
      session,
      teamOwnerId,
      currentWeek,
      getSeasonStatus,
    } = this.props;
    const { hasScroll, topPosition, leftPosition, indicatorHeight, arrowBorder, tooltip } = this.state;
    const tooltipContent = {
      html: (
        <p>
          Your scheduled matches for the season.
          <br />
          Only team leaders can upload scores.
        </p>
      ),
    };

    return (
      <Fragment>
        {/* Heading with Tooltip section */}
        <TooltipSection
          heading={'Fixtures'}
          type={'doubles'}
          handleTooltip={this.handleTooltip}
          tooltip={tooltip}
          tooltipContent={tooltipContent}
          tooltipClass={'tooltip-with-heading'}
        />

        <div ref={this.scrollElement} onScroll={this.handleOnScroll} className="fixture-table-wrapper">
          {loading ? (
            <h3>Loading Fixtures...</h3>
          ) : fixtureData && fixtureData.getAllDoublesFixture.length > 0 ? (
            <DisplayFixture
              getAllFixture={fixtureData.getAllDoublesFixture}
              currentSeasonDate={currentSeasonDate}
              currentSeason={currentSeason}
              type={type}
              year={year}
              getTeam={getTeam}
              currentTeamId={currentTeamId}
              session={session}
              teamOwnerId={teamOwnerId}
              currentWeek={currentWeek}
            />
          ) : (
            <NoResults>
              <div className="no-results-inner">
                <FontAwesomeIcon icon={faInfoCircle} />

                {getSeasonStatus.doublesMatchGenerator ? (
                  <Fragment>
                    <p>There weren't enough teams in your league for the current season.</p>
                    <p style={{ marginTop: '6px' }}>A minimum of 4 teams is needed.</p>
                  </Fragment>
                ) : (
                  <p>Fixtures are not yet available. Please check back later.</p>
                )}
              </div>
            </NoResults>
          )}
          {hasScroll && (
            <ScrollIndicator
              top={topPosition - indicatorHeight / 2}
              right={leftPosition}
              height={indicatorHeight}
              arrowBorder={arrowBorder}
              onClick={() => this.handleRightShift()}
            >
              <div className="scroll-indicator"></div>
            </ScrollIndicator>
          )}
        </div>
      </Fragment>
    );
  }
}

export default DoublesFixtures;
