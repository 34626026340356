import React, { Component, Fragment } from 'react';
import { Mutation } from '@apollo/react-components';

import { sanitizeInput } from '../../../abstracts/helpers';

import { Button, Flex } from '../../Universal/style';
import { UPDATE_PARTNER_MUTATION, RESEND_INVITE_MUTATION, PARTNER_QUERY } from './queries';
import ErrorMessage from '../../Error';

class UpdatedPartner extends Component {
  constructor() {
    super();
    this.state = {
      updatedPartnerEmail: '',
      updatePartnerScreen: false,
      isResend: false,
    };
  }

  updateState = async (name, resendInvite) => {
    this.setState({ [name]: true });
    if (name === 'isResend') {
      await resendInvite();
    }
  };

  onChange = event => {
    this.setState({ updatedPartnerEmail: event.target.value });
  };

  onSubmit = async (event, updateTeamPartner) => {
    event.preventDefault();
    const { updatedPartnerEmail } = this.state;
    if (updatedPartnerEmail && updatedPartnerEmail.length > 0) {
      await updateTeamPartner();
      this.setState({ updatedPartnerEmail: '' });
      await this.props.refetch();
    }
  };

  render() {
    const { updatePartnerScreen, isResend } = this.state;
    let { updatedPartnerEmail } = this.state;
    const isInvalid = updatedPartnerEmail === '';

    // Sanitize inputs
    updatedPartnerEmail = sanitizeInput({ string: updatedPartnerEmail, type: 'email' }).toLowerCase();

    return (
      <Mutation
        mutation={UPDATE_PARTNER_MUTATION}
        variables={{ email: updatedPartnerEmail }}
        refetchQueries={() => {
          return [
            {
              query: PARTNER_QUERY,
            },
          ];
        }}
      >
        {(updateTeamPartner, { loading, error }) => (
          <Fragment>
            <Flex className="partner-options-section">
              <Mutation mutation={RESEND_INVITE_MUTATION}>
                {resendInvite => (
                  <Fragment>
                    {isResend ? (
                      <div className="invite-sent">Invite sent!</div>
                    ) : (
                      <div className="partner-option" onClick={() => this.updateState('isResend', resendInvite)}>
                        Re-send Invite
                      </div>
                    )}
                  </Fragment>
                )}
              </Mutation>

              {/* Section to update partner */}
              {updatePartnerScreen ? (
                <form onSubmit={event => this.onSubmit(event, updateTeamPartner)}>
                  <label>Update partner</label>
                  <input
                    type="text"
                    value={updatedPartnerEmail}
                    onChange={this.onChange}
                    name="update-partner-email"
                    placeholder="New partner email"
                  />
                  <Button doubles disabled={isInvalid || loading} normal type="submit">
                    Update Partner
                  </Button>
                </form>
              ) : (
                <div className="partner-option" onClick={() => this.updateState('updatePartnerScreen')}>
                  Change Partner
                </div>
              )}
            </Flex>
            {error && <ErrorMessage error={error} />}
          </Fragment>
        )}
      </Mutation>
    );
  }
}

export default UpdatedPartner;
