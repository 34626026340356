import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Hashids from 'hashids';
import { Query } from '@apollo/react-components';
import { H4, P } from '../../style';
import { ScheduleWrapper } from '../style';

import { OPPONENT_QUERY } from '../../queries';

const hashids = new Hashids('fleximazing', 10);

/******************** Determining the opponent for each week ******************/
class ScheduledMatch extends Component {
  constructor() {
    super();
    // Setting the default player ID values to be "0" --> otherwise it feeds the Query with null value if default is set with null. Gives an error.
    this.state = {
      currentWeek: 0,
      currentWeekPlayer: 0,
      nextWeekPlayer: 0,
      bye: false,
      byeNextWeek: false,
    };
  }

  componentDidMount() {
    const { getAllFixture, getFinalFixture, seasonDates } = this.props;

    let { previousSpring, summer, autumn, winter, spring, nextSummer } = seasonDates;
    previousSpring = new Date(previousSpring);
    summer = new Date(summer);
    autumn = new Date(autumn);
    winter = new Date(winter);
    spring = new Date(spring);
    nextSummer = new Date(nextSummer);

    const seasonDateArray = [previousSpring, summer, autumn, winter, spring, nextSummer];

    if (getAllFixture && getAllFixture.length > 0) {
      const currentTime = new Date().getTime();
      const sortedData = getAllFixture.slice().sort((a, b) => a.week - b.week);
      // Determining if weeks exists in the "sortedData" array
      const week1Exist = sortedData.some(v => +v.week === 1);
      const week2Exist = sortedData.some(v => +v.week === 2);
      const week3Exist = sortedData.some(v => +v.week === 3);
      const week4Exist = sortedData.some(v => +v.week === 4);
      const week5Exist = sortedData.some(v => +v.week === 5);
      const week6Exist = sortedData.some(v => +v.week === 6);
      const week7Exist = sortedData.some(v => +v.week === 7);
      const week8Exist = sortedData.some(v => +v.week === 8);

      const customFindIndex = (array, callback) => {
        let index = -1;
        for (let i = 0; i < array.length; i++) {
          if (callback(array[i])) {
            index = i;
          }
        }
        return index;
      };

      const filteredWeek = (theWeek, sortData, currentWeek, nextWeekExist) => {
        // If the current week opponent exist
        if (currentWeek) {
          const filtered = sortData.filter(v => +v.week === theWeek);
          this.setState({
            currentWeekPlayer: filtered[0].opponentId,
          });
        } else {
          this.setState({ bye: true });
        }
        // If the next week opponent exist
        if (nextWeekExist) {
          this.setState({
            nextWeekPlayer: sortData[customFindIndex(sortData, i => +i.week === theWeek) + 1].opponentId,
          });
        } else {
          this.setState({ byeNextWeek: true });
        }
      };

      // Looping through all season dates for the current year.
      seasonDateArray.forEach(season => {
        // Need to change these to future days + 7
        const week1 = new Date(season).setDate(season.getDate() + 7);
        const week2 = new Date(season).setDate(season.getDate() + 14);
        const week3 = new Date(season).setDate(season.getDate() + 21);
        const week4 = new Date(season).setDate(season.getDate() + 28);
        const week5 = new Date(season).setDate(season.getDate() + 35);
        const week6 = new Date(season).setDate(season.getDate() + 42);
        const week7 = new Date(season).setDate(season.getDate() + 49);
        const week8 = new Date(season).setDate(season.getDate() + 56);
        const week9 = new Date(season).setDate(season.getDate() + 63);
        const week10 = new Date(season).setDate(season.getDate() + 70);

        // Sort the array first before updating the states accordingly
        // Looping through the "getAllFixture" array.
        // If the week is equal to "1" and the "current time" is greater than the season date (after the season date) and less then the week1 date --> update the week information accordingly
        // Logic continues throughout. We need to know what week loop we are in, so that we can get the "opponent Id".
        if (season <= currentTime && currentTime < week1) {
          this.setState({ currentWeek: 1 });
          filteredWeek(1, sortedData, week1Exist, week2Exist);
        } else if (week1 <= currentTime && currentTime < week2) {
          this.setState({ currentWeek: 2 });
          filteredWeek(2, sortedData, week2Exist, week3Exist);
        } else if (week2 <= currentTime && currentTime < week3) {
          this.setState({ currentWeek: 3 });
          filteredWeek(3, sortedData, week3Exist, week4Exist);
        } else if (week3 <= currentTime && currentTime < week4) {
          this.setState({ currentWeek: 4 });
          filteredWeek(4, sortedData, week4Exist, week5Exist);
        } else if (week4 <= currentTime && currentTime < week5) {
          this.setState({ currentWeek: 5 });
          filteredWeek(5, sortedData, week5Exist, week6Exist);
        } else if (week5 <= currentTime && currentTime < week6) {
          this.setState({ currentWeek: 6 });
          filteredWeek(6, sortedData, week6Exist, week7Exist);
        } else if (week6 <= currentTime && currentTime < week7) {
          this.setState({ currentWeek: 7 });
          filteredWeek(7, sortedData, week7Exist, week8Exist);
        } else if (week7 <= currentTime && currentTime < week8) {
          this.setState({ currentWeek: 8 });
          if (week8Exist) {
            filteredWeek(8, sortedData, true, false);
          } else {
            filteredWeek(8, sortedData, false, false);
          }
        }

        // For week 9 and 10 --> we need to execute a function to calculate the FINAL schedule
        // Only ADMIN user can execute the function to generate "final fixture" from Admin page
        let finalOpponent = 0;
        // Checking "getFinalFixture" is populated
        if (getFinalFixture && getFinalFixture.length > 0) {
          finalOpponent = +getFinalFixture[0].opponentId;
        }

        if (week8 <= currentTime && currentTime < week9) {
          this.setState({
            currentWeek: 9,
            nextWeekPlayer: finalOpponent,
          });
        } else if (week9 <= currentTime && currentTime <= week10) {
          this.setState({
            currentWeek: 10,
            currentWeekPlayer: finalOpponent,
          });
        }
      });
    }
  }

  render() {
    const { currentWeek, currentWeekPlayer, nextWeekPlayer, bye, byeNextWeek } = this.state;
    const { genderType } = this.props;

    return (
      <ScheduleWrapper genderType={genderType}>
        <H4 dbHeading>This Week</H4>
        <div>
          {/* If in week 10 and there is no currentWeekPlayer (which is equal to 0 by default) then display "Not In Finals" */}
          {currentWeek === 0 ? (
            <P bold darkgray MT={'0'} MB={'0'}>
              Currently unavailable
            </P>
          ) : currentWeek === 10 && currentWeekPlayer === 0 ? (
            <P bold darkgray MT={'0'} MB={'0'}>
              Not In Finals
            </P>
          ) : currentWeek === 9 ? (
            <P bold darkgray MT={'0'} MB={'18px'}>
              Catch Up Week
            </P>
          ) : bye ? (
            <P bold darkgray MT={'0'} MB={'18px'}>
              Bye Week
            </P>
          ) : (
            // Query the opponent player
            <Query query={OPPONENT_QUERY} variables={{ id: currentWeekPlayer }}>
              {({ data, loading }) => {
                const playerEncoded = hashids.encode(currentWeekPlayer);
                const profileLink = `/singles/fixtures/profile/${playerEncoded}`;

                return (
                  <Fragment>
                    {/* Need to have both logics because of the P tag having margin bottom */}
                    {currentWeek === 10 ? (
                      <P bold darkgray MT={'0'} MB={'0'}>
                        {loading && 'Loading...'}
                        {data && data.user && (
                          <Link to={profileLink}>
                            {data.user.firstname} {data.user.lastname}
                          </Link>
                        )}
                        {` - Finals Week`}
                      </P>
                    ) : (
                      <P bold darkgray MT={'0'} MB={'18px'}>
                        {loading && 'Loading...'}
                        {data && data.user && (
                          <Link to={profileLink}>
                            {data.user.firstname} {data.user.lastname}
                          </Link>
                        )}
                      </P>
                    )}
                  </Fragment>
                );
              }}
            </Query>
          )}
        </div>
        {/* Only display upcoming when currentWeek is greater than 0 or less than 10 --> 10 is last week so no upcoming opponent */}
        {0 < currentWeek && currentWeek < 10 && (
          <div>
            <H4 dbHeading>Upcoming</H4>
            <P bold darkgray MT={'0'} MB={'0'}>
              <Query query={OPPONENT_QUERY} variables={{ id: nextWeekPlayer }}>
                {({ data, loading }) => {
                  const playerEncoded = hashids.encode(nextWeekPlayer);
                  const profileLink = `/singles/fixtures/profile/${playerEncoded}`;

                  return (
                    <Fragment>
                      {loading && 'Loading...'}
                      {currentWeek === 8 ? (
                        // Display "catch up week" text only in week 8 for the "upcoming" week
                        'Catch Up Week - Week 9'
                      ) : (
                        <Fragment>
                          {byeNextWeek ? (
                            `Bye Week`
                          ) : data && data.user ? (
                            <Link to={profileLink}>
                              {data.user.firstname} {data.user.lastname}
                            </Link>
                          ) : (
                            `TBC`
                          )}
                          {` - `} {currentWeek === 9 ? `Finals Week` : `Week ${currentWeek + 1}`}
                        </Fragment>
                      )}
                    </Fragment>
                  );
                }}
              </Query>
            </P>
          </div>
        )}
      </ScheduleWrapper>
    );
  }
}

export default ScheduledMatch;
