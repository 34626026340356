import gql from 'graphql-tag';

// Fetch the current week fixture
export const CURRENT_DOUBLES_FIXTURE_QUERY = gql`
  query($fixtureId: ID!) {
    getCurrentDoublesFixture(fixtureId: $fixtureId) {
      id
      week
      startDate
      opponentTeamId
      pendingScore
      score
    }
  }
`;
// Query score to approve
export const GET_SCORE_APPROVAL_DOUBLES = gql`
  query($fixtureId: ID) {
    getScoreApprovalDoubles(fixtureId: $fixtureId) {
      id
      score
      teamWinnerId
      isApproved
      opponentTeamId
      opponentTeamFixtureId
      teamId
      teamFixtureId
      adjustment
    }
  }
`;

// Listen for when a score needs to be approved
export const PENDING_DOUBLES_SUBSCRIPTION = gql`
  subscription PendingDoublesApproval($fixtureId: ID!) {
    pendingDoublesScoreReceived(fixtureId: $fixtureId) {
      id
      score
      teamWinnerId
      isApproved
      opponentTeamId
      opponentTeamFixtureId
      teamId
      teamFixtureId
      adjustment
    }
  }
`;

// Approve the score
export const DOUBLES_APPROVE_SCORE = gql`
  mutation(
    $scoreApprovalId: ID!
    $opponentTeamFixtureId: ID!
    $teamFixtureId: ID!
    $score: String!
    $currentWeek: Int!
    $nextSeasonDate: String!
  ) {
    approveDoublesScore(
      scoreApprovalId: $scoreApprovalId
      opponentTeamFixtureId: $opponentTeamFixtureId
      teamFixtureId: $teamFixtureId
      score: $score
      currentWeek: $currentWeek
      nextSeasonDate: $nextSeasonDate
    ) {
      score
    }
  }
`;

/******************* Fixture Screens - Fixture Profile - Fixture Upload - Upload Score Form ******************/
export const SEND_PENDING_DOUBLES_SCORE = gql`
  mutation(
    $type: String!
    $fixtureId: ID!
    $opponentTeamId: ID!
    $score: [[String]]!
    $checkedWinner: Int
    $adjustment: String
    $feedback: String
  ) {
    doublesScoreApproval(
      type: $type
      fixtureId: $fixtureId
      opponentTeamId: $opponentTeamId
      score: $score
      checkedWinner: $checkedWinner
      adjustment: $adjustment
      feedback: $feedback
    ) {
      id
      score
      teamWinnerId
    }
  }
`;
