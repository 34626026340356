import styled, { theme } from '../../theme';
import backImage from '../../resources/images/error-background.jpg';

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  .main-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 auto;
    flex-direction: column;
    overflow: hidden;

    .background {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 110%;
      height: 110%;
      background-image: url(${backImage});
      background-size: cover;
      background-position: center;

      :before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
      }
    }
    .error-content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: rgba(0, 0, 0, 0.3);
      padding: 30px 55px;
      margin: 0 10px;
      border-radius: 7px;
      z-index: 1;

      .heading-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
          margin: 0;
          margin-right: 15px;
          color: white;
          text-shadow: 1px 1px 1px black;
        }
        .site-logo {
          z-index: 1;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 90px;
          }
        }
      }
      .support-info {
        display: flex;
        align-items: center;
        color: white;
        font-family: 'roboto-condensed-regular';
        font-size: 20px;

        svg {
          margin-right: 7px;
          color: chartreuse;

          :hover {
            opacity: 0.8;
            cursor: pointer;
          }
        }
        .text {
          a {
            font-family: 'roboto-condensed-regular';
            font-size: 20px;
            text-transform: none;
            color: chartreuse;

            :hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }

  @media (max-width: 785px) {
    .error-content-wrapper {
      h1 {
        font-size: 26px;
      }
    }
  }
`;
export const FurtherInfo = styled.div`
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  opacity: 0.8;
  background: white;
  color: black;
  font-family: 'calibri';
  padding: 15px 30px;
  border-left: 8px solid chartreuse;
  border-radius: 3px;
  z-index: 1;

  p {
    color: black;
    text-transform: capitalize;
    padding-bottom: 3px;
    margin-top: 0;
    border-bottom: 1px solid ${theme.breakLine};
  }
  ul {
    margin-bottom: 0;
  }
`;
